import Breadcrumb from "react-bootstrap/Breadcrumb";

export default function BreadcrumbCard({ item }) {
  return (
    <Breadcrumb className="ms-Breadcrumb">
      <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
      <Breadcrumb.Item href="/urbanest">Urbanest</Breadcrumb.Item>
      <Breadcrumb.Item active>{item}</Breadcrumb.Item>
    </Breadcrumb>
  );
}
