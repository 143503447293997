import { Col, Container, Row } from "react-bootstrap";
import { Desc, Heading, AnchorButton } from "../../Global/Typography";
import "./ProjectAtGlance.scss";

import { useState } from "react";
import DownloadBrochure from "../../Global/DownloadBrochure";

export default function ProjectAtGlance({ inView }) {
    const [show, setShow] = useState(false);
    const handleShow = (e) => {
        e.preventDefault();
        setShow(true);
    };

    const dataset = {
        subject: "Engrace Brochure",
        fileName: "EngraceBrochure.pdf",
        filePath: "./EngraceByModernSpaaces.pdf",
    };

    const handleClose = () => setShow(false);
    // ProjectAtGlanceListitems
    const ProjectAtGlanceListitems = [
        {
            info: "80 Lacs",
            desc: "Onwards",
        },
        {
            info: "2, 2.5 & 3 BHK ",
            desc: "1306 - 2091 sq.ft",
            // desc: "448-1564 sq.ft",
            // desc: "to be updated sq.ft",
        },
        {
            info: "50+ Amenities",
            desc: "Indoor & Outdoor",
        },
        {
            info: "Home Loan",
            desc: "5 Pre Approved Banks",
        },
        {
            info: "RERA Approved",
            desc: "RERA mentioned in the brochure",
            // desc: "To be updated",
        },
        {
            info: "Sompura Gate",
            desc: "Sarjapur Road",
        },
        {
            info: "Legal Compliant",
            desc: "BMRDA Approved, CC Received & OC Compliant",
        },
        {
            info: "Possession",
            // desc: "Mar 2022",
            desc: "March 2024 onwards",
        },
    ];
    return (
        <section className={`section ${inView ? "inView" : "outView"}`}>
            <Container>
                {/* className="text-md-center" */}
                <Row>
                    {/* lg={10} */}
                    <Col xs={12} className="mx-md-auto">
                        <Heading
                            // classList="my-4 my-md-4 pr-5 pr-md-3"
                            classList="my-4 my-md-5 pb-md-4 pr-5 pr-md-3 "
                            variant="h1"
                            text="Project at glance"
                        />
                        {/* <Desc
              classList="mb-3 pb-3 mb-md-5 pb-md-5"
              text="Come, discover homes for dynamic living at Soul Tree by Modern Spaaces. Premium 1, 2 and 3 BHK apartments were built especially for the city dwellers among us. Wrapped in the natural surrounding & designed to aid your key life experiences, these fully automated smart homes should be your next destination."
            /> */}
                    </Col>
                </Row>
                <Row>
                    {ProjectAtGlanceListitems.map(({ info, desc }, idx) => {
                        return (
                            <Col
                                className="mb-4 pb-4 mb-md-5 pb-md-5 ProjectAtGlanceList"
                                xs={12}
                                md={6}
                                lg={4}
                                key={idx}
                            >
                                <Heading
                                    classList="ProjectAtGlance__info"
                                    variant="h4"
                                    text={info}
                                />
                                <Desc
                                    classList="ProjectAtGlance__desc mb-0"
                                    text={desc}
                                />
                            </Col>
                        );
                    })}
                </Row>
                {/* <Row>
                    <Col>
                        <AnchorButton
                            href="#"
                            classList="-btn mt-3 text-orange"
                            text="Download Brochure"
                            onClick={handleShow}
                        />
                    </Col>
                </Row> */}
            </Container>

            {/* DownloadBrochure */}
            <DownloadBrochure
                title={"Download Brochure"}
                show={show}
                handleClose={handleClose}
                content={dataset}
            />
        </section>
    );
}
