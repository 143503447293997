import { Container, Row, Col } from "react-bootstrap";
import { Heading } from "../../Global/Typography";
import BlockCard from "../../Global/BlockCard";
// import BlockImage1 from "../../../images/Engrace/Blocks/BlockImage1 .webp";
// import BlockImage2 from "../../../images/Engrace/Blocks/BlockImage2 .webp";
// import BlockImage3 from "../../../images/Engrace/Blocks/BlockImage3 .webp";
// import BlockImage4 from "../../../images/Engrace/Blocks/BlockImage4 .webp";
import PrevArrow from "../../../images/Common/PrevArrow.svg";
import NextArrow from "../../../images/Common/NextArrow.svg";

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "./Blocks.scss";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const BlockImage1 = "/images/Engrace/Blocks/engrace-phase-1-banner.webp";
const BlockImage2 = "/images/Engrace/Blocks/engrace-phase-2-banner.webp";
const BlockImage3 = "/images/Engrace/Blocks/engrace-phase-3-banner.webp";
const BlockImage4 = "/images/Engrace/Blocks/engrace-phase-4-banner.webp";

export default function Blocks({ inView }) {
    const BlocksData = [
        {
            image: BlockImage1,
            imgAlt: "Engrace 1",
            title: "Engrace 1",
            // phases: "Phase 1",
            type: "2, 2.5, 3 BHK",
            SqFt: "1304 - 2091 Sq. ft.",
            price: "70Lacs - 1.2Cr",
            // label: "Onwards",
            unit: "68 Units",
            possession: "March 2024",
            isAvailable: false,
        },
        {
            image: BlockImage2,
            imgAlt: "Engrace 2",
            title: "Engrace 2",
            // phases: "Phase 1",
            type: "2, 2.5, 3 BHK",
            SqFt: "1304 - 2025 Sq. ft.",
            price: "70Lacs - 1.2Cr",
            // label: "Onwards",
            unit: "340 Units",
            possession: "March 2024",
            isAvailable: false,
        },
        {
            image: BlockImage3,
            imgAlt: "Engrace 3",
            title: "Engrace 3",
            // phases: "Phase 1",
            type: "2 & 3 BHK",
            SqFt: "1462 - 1817 Sq. ft.",
            price: "88Lacs - 1.09Cr",
            // label: "Onwards",
            unit: "76 Units",
            possession: "July 2025",
            isAvailable: false,
        },
        {
            image: BlockImage4,
            imgAlt: "Engrace 4",
            title: "Engrace 4",
            // phases: "Phase 1",
            type: "3 BHK",
            SqFt: "1620 - 2244 Sq. ft.",
            price: "98Lacs - 1.35Cr",
            // label: "Onwards",
            unit: "145 Units",
            possession: "March 2026",
            isAvailable: false,
            // availability: "Few Units Available",
        },
    ];

    return (
        <section
            className={`section bg-white Blocks pt-md-0 ${
                inView ? "inView" : "outView"
            }
    `}
        >
            <Container className="Blocks-Container position-relative">
                <Row className=" ">
                    <Col
                        xs={12}
                        md={6}
                        lg={6}
                        xl={12}
                        className="pb-4 pb-md-5 text-center"
                    >
                        <Heading
                            variant="h1"
                            classList="Blocks__title"
                            text="Blocks"
                        />
                    </Col>
                    <Col xs={12} className="px-md-4">
                        <div className="Blocks__Slider">
                            <Swiper
                                className="BlocksSlider"
                                id="BlocksSlider"
                                tag="section"
                                wrapperTag="ul"
                                spaceBetween={10}
                                loop={false}
                                keyboard={{
                                    enabled: true,
                                }}
                                navigation={{
                                    prevEl: ".ArrowButtonBlock__ArrowPrevBlocks",
                                    nextEl: ".ArrowButtonBlock__ArrowNextBlocks",
                                }}
                                onSwiper={(swiper) => console.log(swiper)}
                                // onSlideChange={(swiper) =>
                                //   console.log("slide index changed to: ", swiper.activeIndex)
                                // }
                                onInit={(swiper) =>
                                    console.log("Swiper initialized", swiper)
                                }
                                breakpoints={{
                                    300: {
                                        slidesPerView: 1,
                                        spaceBetween: 30,
                                    },
                                    450: {
                                        slidesPerView: 2,
                                        spaceBetween: 0,
                                    },

                                    768: {
                                        slidesPerView: 2,
                                        spaceBetween: 0,
                                    },
                                    1024: {
                                        slidesPerView: 3,
                                        spaceBetween: 0,
                                    },
                                    1300: {
                                        slidesPerView: 4,
                                        spaceBetween: 0,
                                    },
                                }}
                            >
                                {BlocksData.map((item, index) => (
                                    <SwiperSlide tag="li" key={index}>
                                        <BlockCard item={item} />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </Col>
                </Row>
                {/* <Row className="ArrowButtons">
                    <div className="ArrowButtonBlock">
                        <div className="ArrowButtonBlock__Arrow ArrowButtonBlock__ArrowPrevBlocks">
                            <img
                                src={PrevArrow}
                                className="-image"
                                alt="Blocks"
                            />
                        </div>
                        <div className="ArrowButtonBlock__Arrow ArrowButtonBlock__ArrowNextBlocks">
                            <img
                                src={NextArrow}
                                className="-image"
                                alt="Blocks"
                            />
                        </div>
                    </div>
                </Row> */}
            </Container>
        </section>
    );
}
