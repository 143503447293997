import { Container, Row, Col } from "react-bootstrap";
import { Desc, Heading } from "../../Global/Typography";
// AnchorButton,
import { useState } from "react";

import PrevArrow from "../../../images/Common/PrevArrow.svg";
import NextArrow from "../../../images/Common/NextArrow.svg";

// import SoultreeCourt from "../../../images/SoulTree/Court/SoultreeCourt.webp";
// import SunriseCourt from "../../../images/SoulTree/Court/SunriseCourt.webp";
// import TheTranquilCourt from "../../../images/SoulTree/Court/TheTranquilCourt.webp";
// import MultiPurposeCourt from "../../../images/SoulTree/Court/MultiPurposeCourt.webp";
// import TheRejuvenationCourt from "../../../images/SoulTree/Court/TheRejuvenationCourt.webp";
// import KidsPlayArea from "../../../images/SoulTree/ThereIsSomething/KidsPlayArea.webp";
import OutdoorSeating1 from "../../../images/SoulTree/ThereIsSomething/OutdoorSeating1.webp";

import SwiperCore, {
    Navigation,
    Pagination,
    Scrollbar,
    A11y,
    Autoplay,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "./OutdoorSeating.scss";
import DownloadBrochure from "../../Global/DownloadBrochure";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);


const SoultreeCourt = "/images/SoulTree/Court/soul-tree-court-banner.webp";
const SunriseCourt = "/images/SoulTree/Court/soul-tree-sunrise-court-banner.webp";
const TheTranquilCourt = "/images/SoulTree/Court/soul-tree-tranquil-court-banner.webp";
const  MultiPurposeCourt = "/images/SoulTree/Court/soul-tree-multi-purpose-court-banner.webp";
const TheRejuvenationCourt = "/images/SoulTree/Court/soul-tree-rejuvenation-court-banner.webp";

export default function OutdoorSeating({ inView }) {
    const [show, setShow] = useState(false);
    const handleShow = (e) => {
        e.preventDefault();
        setShow(true);
    };
    // console.log(
    //   "🚀 ~ file: OutdoorSeating.js ~ line 31 ~ handleShow ~ handleShow",
    //   handleShow
    // );
    const handleClose = () => setShow(false);

    const OutdoorSeatingData = [
        {
            title: "There is a little something for each member of your family",
            desc: "Area designed to satisfy your outdoor needs and keep you motivated to work and socialize. With the various facilities available at your fingertips, you can enjoy a calm space to work or inspire yourself to break a sweat.",
            // button: "Download Clubhouse Brochure",
            button: "Download Amenities Brochure",
            imageList: [
                {
                    image: SoultreeCourt,
                    imgAlt:"Soul Tree Court",
                    // label: "Soultree Court",
                    title: "Soultree Court",
                    desc: "The SOUL TREE COURT has been designed around the most important feature on site, the Banyan tree. The entire area around it has been orchestrated to form an open air theatre with the tree as its central focus. This area also duplicates in function to form spaces of contemplative introspection, yoga and meditation.",
                },
                {
                    image: SunriseCourt,
                    imgAlt:"Sunrise Court",
                    // label: "Kids Play Area",
                    title: "Sunrise Court",
                    desc: "The Sunrise Court is located at the north east corner of the site, allowing for the first rays of the sun to be experienced in the early hours of the morning. This forms an ideal zone for early morning seating and relaxation while absorbing the sun rays.",
                },
                {
                    image: TheTranquilCourt,
                    imgAlt:"Tranquil Court",
                    // label: "Sports Area",
                    title: "The Tranquil Court",
                    desc: "The Tranquil Court located within the serenity block functions as a multi-purpose activity zone for all age groups, right from kids, toddlers to senior citizens.",
                },
                {
                    image: MultiPurposeCourt,
                    imgAlt:"Mulit Purpose Court",
                    // label: "Sports Area",
                    title: "Multi-Purpose Court",
                    desc: "The Multi-purpose Court located between the Nirvana and Bliss blocks allows for play activities like basketball, kids play zones, a walking track and chit chat corner.",
                },
                {
                    image: TheRejuvenationCourt,
                    imgAlt:"Rejuvenation Court",
                    // label: "Sports Area",
                    title: "The Rejuvenation Court",
                    desc: "The Rejuvenation Court functions as a healing court with its reflexology path, they also have a fragrance and butterfly gardens. Materials with highly tactile qualities are used to have an additional effect on the senses.",
                },
            ],
            image: OutdoorSeating1,
            label: "Outdoor seating",
        },
    ];

    return (
        <section
            className={`section bg-white ${inView ? "inView" : "outView"}
    `}
        >
            {OutdoorSeatingData.map((item, idx) => {
                return (
                    <Container
                        key={idx}
                        className="OutdoorSeating OutdoorSeating--small"
                    >
                        <div className="OutdoorSeating__Slider">
                            <Swiper
                                className="OutdoorSeatingSlider"
                                id="OutdoorSeatingSlider"
                                tag="section"
                                wrapperTag="ul"
                                spaceBetween={0}
                                keyboard={{
                                    enabled: true,
                                }}
                                speed={500}
                                loop={true}
                                autoplay={true}
                                pagination={{
                                    clickable: true,
                                    el: ".swiper-pagination-OutdoorSeating",
                                }}
                                navigation={{
                                    prevEl: ".ArrowButtonBlock__ArrowPrevWhite",
                                    nextEl: ".ArrowButtonBlock__ArrowNextWhite",
                                }}
                                onSwiper={(swiper) => console.log(swiper)}
                                // onSlideChange={(swiper) =>
                                //   console.log("slide index changed to: ", swiper.activeIndex)
                                // }
                                onInit={(swiper) =>
                                    console.log("Swiper initialized", swiper)
                                }
                                breakpoints={{
                                    300: {
                                        slidesPerView: 1,
                                        spaceBetween: 30,
                                    },
                                }}
                            >
                                {item.imageList.map((item, index) => (
                                    <SwiperSlide tag="li" key={index}>
                                        <Row className="align-items-center">
                                            <Col
                                                xs={12}
                                                md={6}
                                                lg={6}
                                                xl={5}
                                                className="px-3 px-md-5"
                                            >
                                                <div className="imagePatch">
                                                    <img
                                                        src={item.image}
                                                        className="OutdoorSeating__image w-100 mb-0"
                                                        alt={item.imgAlt}
                                                    />
                                                    <span className="-label">
                                                        {item.title}
                                                    </span>
                                                </div>
                                            </Col>
                                            <Col
                                                xs={12}
                                                md={6}
                                                lg={6}
                                                xl={7}
                                                className="pt-5 pt-md-0 px-4 px-md-5"
                                            >
                                                <Heading
                                                    variant="h1"
                                                    classList="OutdoorSeating__title"
                                                    text={item.title}
                                                />
                                                <Desc
                                                    classList="OutdoorSeating__desc mt-3 mb-5 pb-5"
                                                    text={item.desc}
                                                />
                                                {/* <AnchorButton
                          href="#"
                          classList="OutdoorSeating__btn my-3"
                          text={item.button}
                          onClick={handleShow}
                        /> */}
                                            </Col>
                                        </Row>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                            <Row className="ArrowButtons pl-md-3">
                                <div className="swiper-pagination swiper-pagination-OutdoorSeating"></div>
                                {/* <div className="swiper-pagination swiper-pagination-OutdoorSeating bg-trans"></div> */}
                                <div className="ArrowButtonBlock">
                                    <div className="ArrowButtonBlock__Arrow ArrowButtonBlock__ArrowPrevWhite">
                                        <img
                                            src={PrevArrow}
                                            className="-image"
                                            alt="Blocks"
                                        />
                                    </div>
                                    <div className="ArrowButtonBlock__Arrow ArrowButtonBlock__ArrowNextWhite">
                                        <img
                                            src={NextArrow}
                                            className="-image"
                                            alt="Blocks"
                                        />
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </Container>
                );
            })}

            {/* DownloadBrochure */}
            <DownloadBrochure
                title={"Download Brochure"}
                show={show}
                handleClose={handleClose}
            />
        </section>
    );
}
