import { useState } from "react";
import NewLaunchProjectModal from "../../components/Global/NewLaunchProjectModal/NewLaunchProjectModal";
import Projects from "../Projects";
import {
  completedProjects,
  ongoingProjects,
  upcomingProjects,
} from "./constants";
import "./showcase.scss";

export default function Showcase({ inView }) {
  const allProjectsData = {
    ongoing: ongoingProjects,
    upcoming: upcomingProjects,
    completed: completedProjects,
  };
  const [showNewLaunchModal, setShowNewLaunchModal] = useState(false);
  const [reraApproved, setReraApproved] = useState(false);

  const handleNewLaunchProjectClick = (isReraApproved) => {
    setReraApproved(isReraApproved);
    setShowNewLaunchModal(true);
  };

  return (
    <>
      <section
        className={`section section-showcase bg-white ${
          inView ? "inView" : "outView"
        }
      `}
      >
        <div className="content">
          <div className="sub-heading ">
            <span>Our Projects</span>
          </div>

          <Projects
            onNewLaunchProjectClick={handleNewLaunchProjectClick}
            projectsData={allProjectsData}
          ></Projects>
        </div>
      </section>
      <NewLaunchProjectModal
        show={showNewLaunchModal}
        handleClose={() => setShowNewLaunchModal(false)}
        reraApproved={reraApproved}
      />
    </>
  );
}
