import { Container, Row, Col } from "react-bootstrap";
import ClubCard from "./ClubCard";

// import MeetGreetandTalkCropanduseleft from "../../../images/SoulTree/ClubConnect/MeetGreetandTalkCropanduseleft.webp";
// import MinglemixLaughCropuseleftside from "../../../images/SoulTree/ClubConnect/MinglemixLaughCropuseleftside.webp";
// import PlayandEnjoy from "../../../images/SoulTree/ClubConnect/PlayandEnjoy.webp";
// import Relaxrejuvenate from "../../../images/SoulTree/ClubConnect/Relaxrejuvenate.webp";
// import UniteCelebrateCropuserightside from "../../../images/SoulTree/ClubConnect/UniteCelebrateCropuserightside.webp";

// import clubhouse1 from "../../../images/Soulace/clubconnect/image 7.webp";
// import clubhouse6 from "../../../images/Soulace/clubconnect/Image 25.webp";
// import clubhouse2 from "../../../images/Soulace/clubconnect/Image 26.webp";
// import clubhouse4 from "../../../images/Soulace/clubconnect/Image 27.webp";
// import clubhouse5 from "../../../images/Soulace/clubconnect/Image 28.webp";
// import clubhouse3 from "../../../images/Soulace/clubconnect/Image 29.webp";
// import clubhouse7 from "../../../images/Soulace/clubconnect/Image 30.webp";
import PrevArrow from "../../../images/Common/PrevArrow.svg";
import NextArrow from "../../../images/Common/NextArrow.svg";



import SwiperCore, {
    Navigation,
    Pagination,
    Scrollbar,
    A11y,
    Autoplay,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// import { Heading } from "../../Global/Typography";

const clubhouse1 = "/images/Soulace/clubconnect/soulace-temperature-controlled-pool-banner.webp"
const clubhouse2 = "/images/Soulace/clubconnect/soulace-guest-room-banner.webp";
const clubhouse3 = "/images/Soulace/clubconnect/soulace-indoor-sport-zone-banner.webp";
const clubhouse4 = "/images/Soulace/clubconnect/soulace-party-spaces-banner.webp";
const clubhouse5 = "/images/Soulace/clubconnect/soulace-social-spaces-banner.webp";
const clubhouse6 = "/images/Soulace/clubconnect/soulace-fitness-zone-banner.webp";
const clubhouse7 = "/images/Soulace/clubconnect/soulace-media-zone-banner.webp";




SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

export default function Clubs({ inView }) {
    // const BlocksData = [
    //   {
    //     title: "Meet, Greet & Talk",
    //     label:
    //       "From lobbies of the living areas, various seating clusters in & around the project to the corridor spaces there are various thoughtfully designed ‘pause points’ to incorporate enthusiastic welcomes and lingering goodbyes. Wholesome architectural enhancements for you to meet, greet & talk.",
    //     image: MeetGreetandTalkCropanduseleft,
    //   },
    //   {
    //     title: "Mingle, Mix & Laugh",
    //     label:
    //       "Soul Tree is sprinkled with an array of open spaces including dedicated senior citizen seating areas meant to merge the social and the personal with group-friendly and quiet spaces. The green patch between the living areas is created with landscapes – lush greenery interspersed with seating facilities and calming water bodies where you can mingle, mix & laugh.",
    //     image: MinglemixLaughCropuseleftside,
    //   },
    //   {
    //     title: "Play & Enjoy",
    //     label:
    //       "With multiple kids-play areas scattered around the project & specifically designed toddlers play areas toddlers play areas accompanied by parents seating benches. we aim to cater to the demands of each & every age group. There is a Basket Ball Court, motorised Cricket net, Skating & Biking Ring and so much more for you to play & enjoy to the fullest.",
    //     image: PlayandEnjoy,
    //   },
    //   {
    //     title: "Relax & Rejuvenate",
    //     label:
    //       "It’s a space that is designed to serve today’s global lifestyles and new-age living. Reflexology Barefoot Paths with Pebbles are meant to give you a relaxing break from your hectic lifestyle while the elevated east-facing sunrise court, Yoga zone, various green parks including a butterfly garden, walking path, multiple outdoor gyms & around the project jogging path will help you to relax & rejuvenate.",
    //     image: Relaxrejuvenate,
    //   },
    //   {
    //     title: "Unite & Celebrate",
    //     label:
    //       "Soul Tree lends its name from a rare auspicious banyan tree centring the Amphi Theatre space a uniquely designed space to encourage the celebrations of every nature. Adding to an already impressive Clubhouse packed with world-class facilities & the project dedicated to giving you a cause to unite & celebrate life every day.",
    //     image: UniteCelebrateCropuserightside,
    //   },
    // ];
    const BlocksData = [
        {
            title: "Temperature Controlled Pool",
            label: "Experience the epitome of luxury as you stay blissfully warm all-year round with a dip in the temperature controlled pool.",
            image: clubhouse1,
            imgAlt:"Soulace - Tempertaure Controlled Pool",
        },
        {
            title: "Guest Rooms",
            label: "Need more space for extra house guests? Put them up in our comfy guest rooms on-site, so they can stay close to you!",
            image: clubhouse2,
            imgAlt:"Soulace - Guest Room",
        },
        {
            title: "Indoor Sports Zone",
            label: "Every day is a new game when you can take your pick from our choice of sports courts & game zones featuring Squash, Badminton, Table Tennis & Billiards.",
            image: clubhouse3,
            imgAlt:"Soulace - Indoor Sport Zone",
        },
        {
            title: "Party Spaces",
            label: "The fun never stops when you have access to a Multipurpose Hall and a massive Terrace Party Deck, with both covered & open spaces, to host all your functions!",
            image: clubhouse4,
            imgAlt:"Soulace- Party Spaces",
        },
        {
            title: "Social Spaces",
            label: "Take a short trip to the Cafe if you need to socialise without heading into the city or catch up over some engaging Board Games.",

            // label:
            //   "A space that is designed to cater to all your business needs like co-working space, meeting area & cafe.",

            image: clubhouse5,
            imgAlt:"Soulace - Social Spaces",
        },
        {
            title: "Fitness Zone",
            label: "Sweat it out in the Gym or find your center on our Yoga Deck as you embark on your fitness journey!",
            image: clubhouse6,
            imgAlt:"Soulace - Fitness Zone",
        },
        {
            title: "Media Room",
            label: "Organize movie screenings or get the gang together for IPL season in our 21 seater Media Room.",
            image: clubhouse7,
            imgAlt:"Soulace - Media Zone ",
        },
    ];

    return (
        <>
            <Container className="Blocks-Container Blocks-Container--ClubsContainter">
                <Row className="pb-5 mb-5">
                    <Col xs={12} className="pl-4 pl-md-5 ml-md-5 px-0 pr-md-0">
                        <div className="Blocks__Slider">
                            <Swiper
                                className="BlocksSlider"
                                id="BlocksSlider"
                                tag="section"
                                wrapperTag="ul"
                                speed={400}
                                spaceBetween={10}
                                autoplay={true}
                                loop={true}
                                keyboard={{
                                    enabled: true,
                                }}
                                onSwiper={(swiper) => console.log(swiper)}
                                // onSlideChange={(swiper) =>
                                //   console.log("slide index changed to: ", swiper.activeIndex)
                                // }
                                onInit={(swiper) =>
                                    console.log("Swiper initialized", swiper)
                                }
                                breakpoints={{
                                    300: {
                                        slidesPerView: 1.4,
                                        spaceBetween: 20,
                                    },
                                    450: {
                                        slidesPerView: 2.1,
                                        spaceBetween: 20,
                                    },

                                    768: {
                                        slidesPerView: 3.1,
                                        spaceBetween: 20,
                                    },
                                    1024: {
                                        slidesPerView: 4.1,
                                        spaceBetween: 20,
                                    },
                                    1300: {
                                        slidesPerView: 4.2,
                                        spaceBetween: 38,
                                    },
                                }}
                                pagination={{
                                    clickable: true,
                                    el: ".swiper-pagination-Clubs",
                                }}
                                navigation={{
                                    prevEl: ".ArrowButtonBlock__ArrowPrevArchitech",
                                    nextEl: ".ArrowButtonBlock__ArrowNextArchitech",
                                }}
                            >
                                {BlocksData.map((item, index) => (
                                    <SwiperSlide tag="li" key={index}>
                                        <ClubCard item={item} />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </Col>
                </Row>

                <Row className="ArrowButtons">
                    <div className="ArrowButtonBlock">
                        <div className="swiper-pagination swiper-pagination-Clubs"></div>
                        <div className="ArrowButtonBlock__Arrow ArrowButtonBlock__ArrowPrevArchitech d-none">
                            <img
                                src={PrevArrow}
                                className="-image"
                                alt="ArchitecturalSlider"
                            />
                        </div>
                        <div className="ArrowButtonBlock__Arrow ArrowButtonBlock__ArrowNextArchitech d-none">
                            <img
                                src={NextArrow}
                                className="-image"
                                alt="ArchitecturalSlider"
                            />
                        </div>
                    </div>
                </Row>
            </Container>
        </>
    );
}
