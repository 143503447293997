import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import calenderIcon from "../../../images/Common/calender-outline.svg";
import readIcon from "../../../images/Common/document-text.svg";
import { Desc, DescSmall, Heading } from "../../Global/Typography";
import RightChevron from "../../Global/Typography/RightChevron";
import { FeaturedBlogs } from "../constants";
import "./FeaturedBlog.scss";

export default function FeaturedBlog({ inView }) {
    const [activeFeaturedBlog, setActiveFeaturedBlog] = useState(0);

    const renderActiveBlog = (featuredBlog) => {
        return (
            <Container className="content">
                <Row className="align-items-center py-2">
                    <Col xs={12} className="" style={{ marginTop: "2rem" }}>
                        <Heading
                            classList="featured-blog-heading"
                            variant="h1"
                            text="Featured Blog"
                        />
                    </Col>
                </Row>
                <div className="featured-blog-wrapper">
                    <div className="featured-blog-image bg-dark">
                        <Link to={`blogs/${featuredBlog.slug}`}>
                            <img
                                src={featuredBlog.image}
                                alt={featuredBlog.imgAlt}
                            />
                        </Link>
                        {/* <div className="label">{featuredBlog.label}</div> */}
                    </div>
                    <div className="featured-blog-content">
                        <div className="featured-blog-text">
                            <Link to={`blogs/${featuredBlog.slug}`}>
                                <Heading
                                    variant="h3"
                                    classList="featured-blog-title"
                                    text={featuredBlog.title}
                                />
                            </Link>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    flex: "1",
                                }}
                            >
                                <DescSmall
                                    classList="featured-blog-desc"
                                    text={featuredBlog.desc}
                                />

                                <div className="d-flex flex-column">
                                    <div className="content-separator mb-1"></div>
                                    <div className="d-flex flex-row ">
                                        {featuredBlog.time && (
                                            <div className="d-flex flex-row align-items-center mr-2">
                                                <img
                                                    src={readIcon}
                                                    alt="read"
                                                    className="mr-2"
                                                />
                                                <Desc
                                                    classList="featured-blog-meta mb-0"
                                                    text={`${featuredBlog.time}`}
                                                />
                                            </div>
                                        )}
                                        .
                                        {featuredBlog.date && (
                                            <div className="d-flex flex-row align-items-center ml-2">
                                                <img
                                                    src={calenderIcon}
                                                    alt="read"
                                                    className="mr-2"
                                                />
                                                <Desc
                                                    classList="featured-blog-meta mb-0"
                                                    text={`Published on ${featuredBlog.date}`}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="featured-blog-footer">
                            <Link
                                to={`blogs/${featuredBlog.slug}`}
                                className="btn flex-grow-1 flex-sm-grow-0 justify-content-center"
                            >
                                <div className="btn-text">Read Blog</div>
                            </Link>
                            {FeaturedBlogs.length > 1 && (
                                <div className="featured-blog-navigate">
                                    <div
                                        className={`circle left-chevron ${
                                            activeFeaturedBlog === 0
                                                ? "disabled"
                                                : ""
                                        }`}
                                        onClick={(prev) =>
                                            setActiveFeaturedBlog(
                                                (prev) => prev - 1
                                            )
                                        }
                                    >
                                        <RightChevron
                                            stroke={
                                                activeFeaturedBlog === 0
                                                    ? "#00000040"
                                                    : "#000"
                                            }
                                        />
                                    </div>
                                    <div
                                        className={`circle right-chevron ${
                                            activeFeaturedBlog ===
                                            FeaturedBlogs.length - 1
                                                ? "disabled"
                                                : ""
                                        }`}
                                        onClick={() =>
                                            setActiveFeaturedBlog(
                                                (prev) => prev + 1
                                            )
                                        }
                                    >
                                        <RightChevron
                                            stroke={
                                                activeFeaturedBlog ===
                                                FeaturedBlogs.length - 1
                                                    ? "#00000040"
                                                    : "#000"
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Container>
        );
    };

    return (
        <section
            className={`section featured-blog-section pb-4 pb-md-0 ${
                inView ? "inView" : "outView"
            }`}
        >
            {renderActiveBlog(FeaturedBlogs[activeFeaturedBlog])}
        </section>
    );
}
