import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import PatternWhiteSmall from "../../../images/Urbanest/PatternWhiteSmall.svg";
import ComingSoon from "../../Global/ComingSoon";
import { AnchorGradientButtonAhref, Heading } from "../../Global/Typography";
import "./Blog.scss";

export default function Blog({ inView }) {
    const [show, setShow] = useState(false);

    const handleShow = (e) => {
        e.preventDefault();
        setShow(true);
    };
    console.log(
        "🚀 ~ file: Blog.js ~ line 15 ~ handleShow ~ handleShow",
        handleShow
    );
    const handleClose = () => setShow(false);
    return (
        <section
            className={`section overflow-hidden mb-5 py-0 ${
                inView ? "inView" : "outView"
            }`}
        >
            <Container className="bg-BdarkBlack position-relative p-4 p-md-5">
                {/* <Row className="p-2 p-md-4"> */}
                <Row className="px-2 px-md-4">
                    <Col xs={11} md={10} lg={8} className="">
                        <Heading
                            classList="my-4 text-white"
                            variant="h1Md"
                            // text="Sarjapur Road: Bengaluru’s best area for buying a home"
                            text="Factors Impacting Real Estate Location | Modern Spaaces"
                        />
                        <AnchorGradientButtonAhref
                            // href="blogs/why-sarjapura-best-area-to-buy-house " //to be changed later, add complete url if needed
                            href="/blogs/factors-impacting-real-estate-location"
                            classList="-btn my-3 px-4"
                            text="Read Blog"
                            // onClick={handleShow}
                            target="_blank"
                        />
                    </Col>
                </Row>
                <img
                    src={PatternWhiteSmall}
                    alt="PatternWhiteSmall"
                    className="PatternWhiteSmall"
                />
                <img
                    src={PatternWhiteSmall}
                    alt="PatternWhiteSmall"
                    className="PatternWhiteLarge"
                />
            </Container>

            {/* ComingSoon */}
            <ComingSoon show={show} handleClose={handleClose} />
        </section>
    );
}
