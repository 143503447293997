import "./Specifications.scss";
import React, { useState } from "react";
import {
    Row,
    Container,
    Accordion,
    Card,
    Col,
    useAccordionToggle,
} from "react-bootstrap";
import { Desc, Heading, DescSmall } from "../../Global/Typography";
import Minus from "../../../images/Common/MinusWhite.svg";
import Plus from "../../../images/Common/PlusWhite.svg";

function CustomToggle({ children, eventKey, handleClick }) {
    const decoratedOnClick = useAccordionToggle(eventKey, () => {
        handleClick();
    });

    return (
        <div className="cardHeader" onClick={decoratedOnClick}>
            {children}
        </div>
    );
}
export default function Specifications({ inView }) {
    const [activeKey, setActiveKey] = useState(0);

    const SpecificationsItems = [
        {
            title: "Civil",
            list: [
                {
                    label: "Structure",
                    desc: "Full R.C.C framed structure, designed as per relevant BIS codes for earth quake resistance and structurally efficient systems.",
                    items: [],
                },
                {
                    label: "Walls",
                    desc: "All walls external and internal are 6’’ concrete and steel framed walls.",
                    items: [],
                },
            ],
        },
        {
            title: "Architecture",
            list: [
                {
                    label: "Doors ",
                    desc: "",
                    items: [
                        "Main Door - Engineered Wood with Teak Vineer fitted with digital lock.",
                        "Internal Doors - Engineered wood with laminate finish.",
                    ],
                },
                {
                    label: "Windows",
                    desc: "Fenesta premium wooden finish UPVC windows with mosquito mesh.",
                    items: [],
                },
                {
                    label: "Flooring ",
                    desc: "Italian marble flooring in living, dining, kitchen & bedrooms.",
                    items: [],
                },
                {
                    label: "Bathroom ",
                    desc: "",
                    items: [
                        "Flooring : Anti-Skid Ceramic tiles",
                        "Wall : Ceramic Tiles",
                        "False Ceiling : Grid Ceiling with shadow channels",
                    ],
                },
                {
                    label: "Paint ",
                    desc: "",
                    items: [
                        "Internal Walls : Asian Premium emulsion over putty care",
                        "External walls : Asian emulsion with textured finish",
                    ],
                },
            ],
        },
        {
            title: "Plumbing",
            list: [
                {
                    label: "Ceramic Fittings",
                    desc: "",
                    items: ["TOTO or equivalent"],
                },
                {
                    label: "Chrome Platted Fittings",
                    desc: "",
                    items: ["Hans Grohe or equivalent"],
                },
                {
                    label: "Noiseless water pressure pump",
                    desc: "",
                    items: ["Grundfos or equivalent"],
                },
            ],
        },
        {
            title: "Electrical",
            list: [
                {
                    label: "Switches",
                    desc: "",
                    items: ["Panasonic or equivalent"],
                },
                {
                    label: "Wiring",
                    desc: "",
                    items: ["Fire resistant Polycab or equivalent"],
                },
                {
                    label: "Heat Pump",
                    desc: "",
                    items: [
                        "Centralised Emerson heat pump of 300 Ltr capacity",
                    ],
                },
            ],
        },
        {
            title: "Grid Power/Backup",
            list: [
                {
                    label: "EB Power",
                    desc: "",
                    items: ["10 KV"],
                },
                {
                    label: "DG Backup",
                    desc: "",
                    items: ["100% back up for houses & common areas"],
                },
            ],
        },
    ];
    // {
    //   title: "Architechture",
    //   list: [
    //     "Architechture - Structure: R.C.C framed structure, designed as per relevant BIS codes for earth quake resistance and structurally efficient systems implemented",
    //     "External walls with 6’’ solid concrete blocks and internal walls with 4’’ solid concrete blocks Plastering",
    //     "Plastering: Sponge finish for external walls, smooth",
    //   ],
    // },
    // {
    //   title: "Electrical",
    //   list: [
    //     "Electrical - Structure: R.C.C framed structure, designed as per relevant BIS codes for earth quake resistance and structurally efficient systems implemented",
    //     "External walls with 6’’ solid concrete blocks and internal walls with 4’’ solid concrete blocks Plastering",
    //     "Plastering: Sponge finish for external walls, smooth",
    //   ],
    // },
    // {
    //   title: "Plumbing, Painting & Services",
    //   list: [
    //     "Plumbing - Structure: R.C.C framed structure, designed as per relevant BIS codes for earth quake resistance and structurally efficient systems implemented",
    //     "External walls with 6’’ solid concrete blocks and internal walls with 4’’ solid concrete blocks Plastering",
    //     "Plastering: Sponge finish for external walls, smooth",
    //   ],
    // },

    return (
        <section
            className={`section  ${inView ? "inView" : "outView"}
  `}
        >
            {/* <Container> */}
            <Container className="Specifications Specifications--black">
                <Row>
                    <Col xs={12} lg={6} className="VerticalDivider">
                        <Heading
                            variant="h1"
                            classList="-title"
                            text="Specifications"
                        />
                        <Desc
                            classList="-desc my-3 "
                            text="The finer details."
                        />
                    </Col>
                    <Col xs={12}>
                        <Accordion className="Accordion" defaultActiveKey={1}>
                            {SpecificationsItems.map((item, index) => (
                                <Card key={index}>
                                    <CustomToggle
                                        className="AccordionHeading"
                                        as={Card.Header}
                                        eventKey={index + 1}
                                        handleClick={() => {
                                            if (activeKey === index) {
                                                setActiveKey(null);
                                            } else {
                                                setActiveKey(index);
                                            }
                                        }}
                                    >
                                        <Heading
                                            variant="h4"
                                            classList="-title my-0 mr-5 pr-5"
                                            text={item.title}
                                        />
                                        <img
                                            className="PlusMinus"
                                            src={
                                                activeKey === index
                                                    ? Minus
                                                    : Plus
                                            }
                                            alt={"Minus Plus"}
                                        />
                                    </CustomToggle>
                                    <Accordion.Collapse eventKey={index + 1}>
                                        <Card.Body className="">
                                            <ul className="Accordion__list px-3">
                                                {item.list.map(
                                                    (
                                                        { label, desc, items },
                                                        i0
                                                    ) => {
                                                        return (
                                                            <div key={i0}>
                                                                <Heading
                                                                    variant="h7"
                                                                    // classList="-desc"
                                                                    text={label}
                                                                />

                                                                <li className="mb-2">
                                                                    {desc ? (
                                                                        <DescSmall
                                                                            classList="my-2"
                                                                            text={
                                                                                desc
                                                                            }
                                                                        />
                                                                    ) : null}

                                                                    {items
                                                                        ? items.map(
                                                                              (
                                                                                  it,
                                                                                  i1
                                                                              ) => {
                                                                                  return (
                                                                                      <DescSmall
                                                                                          key={
                                                                                              i1
                                                                                          }
                                                                                          classList="mt-2 mb-0"
                                                                                          text={
                                                                                              it
                                                                                          }
                                                                                      />
                                                                                  );
                                                                              }
                                                                          )
                                                                        : null}
                                                                </li>
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </ul>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            ))}
                        </Accordion>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}
