import { Container, Row, Col } from "react-bootstrap";
import { Desc, Heading } from "../../Global/Typography";
import TranquilCourtImage from "../../../images/Urbanest/RecreationZone/TranquilCourtImage.png";
// import TranquilCourtImageLarge from "../../../images/Urbanest/RecreationZone/TranquilCourtImageLarge.png";
// import TranquilCourtImageLarge from "../../../images/Urbanest/RecreationZone/TranquilCourtImageLarge.jpg";
import TranquilCourtImageSmall from "../../../images/Urbanest/RecreationZone/TranquilCourtImageSmall.png";
import patternVideo from "../../../images/Urbanest/RecreationZone/patternVideo.mp4";
// import patternGIF from "../../../images/Urbanest/RecreationZone/patternGIF.gif";
import "./TranquilCourt.scss";

const TranquilCourtImageLarge = "/images/Urbanest/RecreationZone/ubranest-amenities-banner.png";
const patternGIF = "/images/Urbanest/RecreationZone/urbanest-amenities-gif-banner.gif";

export default function TranquilCourt({ inView }) {
  const TranquilCourtData = [
    {
      title: "Amenities that define lifestyle",
      desc: "Experience a myriad of especially thought out amenities at Urbanest, where space is designed to serve today’s global lifestyles and new-age living while leaving sufficient room for age-old Indian interaction & community culture.",
      imageLarge: TranquilCourtImageLarge,
      imageSmall: TranquilCourtImageSmall,

      patternVideo: patternVideo,
      patternGIF: patternGIF,
      image: TranquilCourtImage,
      listItems: [
        "Indoor Swimming Pool",
        "Badminton & Basketball Court",
        "Table Tennis & Squash Court",
        "Wall Climbing & Skating Rink ",
        "Well-equipped Gym",
        "Meditation & Yoga Zone",
        "Co-working Space ",
        "Amphitheatre & Landscape Area",
        "Senior Citizen Park",
        "24/7 Security & Surveillance ",
      ],
    },
  ];

  return (
    <section
      className={`section bg-white ${inView ? "inView" : "outView"}
    `}
    >
      {TranquilCourtData.map((item, idx) => {
        return (
          <Container key={idx} className="TranquilCourt">
            <Row>
              <Col xs={12} md={5} lg={5} xl={6} className="pr-md-4 pr-xl-4">
                <Heading
                  variant="h1"
                  classList="TranquilCourt__title my-3"
                  text={item.title}
                />
                <Desc classList="TranquilCourt__desc my-3 " text={item.desc} />
                <ul className="TranquilCourtList">
                  {item.listItems.map((i, j) => (
                    <li key={j}>
                      <Desc classList="my-1" text={i} />
                    </li>
                  ))}
                </ul>
              </Col>
            </Row>
            <div className="TranquilCourtImage -RecreationZone imagePatch">
              {/* <img
                src={item.image}
                className="TranquilCourt__image"
                alt="TranquilCourt"
              /> */}
              {/* SEPARATE */}
              <img
                src={item.imageLarge}
                className="TranquilCourt__imageLarge"
                alt="Urbanest - Amenities that define Life"
              />

              <img
                src={item.patternGIF}
                className="TranquilCourt__imageSmall"
                alt="Urbanest Amenities"
              />

              {/* <video
                controls={false}
                className="TranquilCourt__imageSmall"
                loop={true}
                muted={true}
                autoPlay={true}
                playsInline={true}
              >
                <source src={item.patternVideo} type="video/mp4" />
              </video> */}
            </div>
          </Container>
        );
      })}
    </section>
  );
}
