import React from "react";
import { Row } from "react-bootstrap";
import { Desc, Heading } from "../../Global/Typography";
import GrassPattern from "../GrassPattern";
import render1 from "../../../images/Engrace/architectural/tobeused/render1.webp";
import entrance from "../../../images/Engrace/architectural/tobeused/entrance.webp";
import PrevArrow from "../../../images/Common/PrevArrow.svg";
import NextArrow from "../../../images/Common/NextArrow.svg";
import "./ArchitecturalSlider.scss";

import SwiperCore, {
    Navigation,
    Pagination,
    Scrollbar,
    A11y,
    Controller,
    Autoplay,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Controller, Autoplay]);

export default function ArchitecturalSlider({ inView }) {
    const ArchitecturalSliderData = [
        {
            title: "2 & 3 BHKs",
            desc: "Spacious living that enhances lifestyle in Bengaluru",
            image: entrance,
        },
        // {
        //   title: "2 & 3 BHKs",
        //   desc: "Spacious living that enhances lifestyle in Bengaluru",
        //   image: entrance,
        // },
        {
            title: "A home that is personal ",
            desc: "Negligible common walls ",
            image: render1,
        },
        {
            title: "2 - 4 Balconies for every home",
            desc: "Escape the dark & damp homes",
            image: render1,
        },
    ];

    return (
        <section
            className={`section bg-white ArchitecturalSlider ${inView ? "inView" : "outView"
                }
    `}
        >
            <GrassPattern />
            <Row className="ArchitecturalSlider__Row">
                <Swiper
                    wrapperTag="ul"
                    className="ArchitecturalSliderLists"
                    slidesPerView={1}
                    speed={500}
                    // speed={3400}
                    spaceBetween={0}
                    effect={"fade"}
                    fadeEffect={{
                        crossFade: true,
                    }}
                    // observeParents={true}
                    // observer={true}

                    autoplay={true}
                    loop={true}
                    direction="horizontal"
                    pagination={{
                        clickable: true,
                        el: ".swiper-pagination-Architech",
                    }}
                    navigation={{
                        prevEl: ".ArrowButtonBlock__ArrowPrevArchitech",
                        nextEl: ".ArrowButtonBlock__ArrowNextArchitech",
                    }}
                >
                    {ArchitecturalSliderData.map(
                        ({ image, title, desc }, index) => (
                            <SwiperSlide
                                tag="li"
                                key={index}
                                className="-block-list"
                            >
                                <div className="-image-block">
                                    <img
                                        src={image}
                                        className="-image"
                                        alt="ArchitecturalSlider"
                                    />
                                </div>
                                <div className="-architech-co-block">
                                    <div className="-architech-co-block__inner">
                                        <Heading
                                            variant="h1"
                                            classList="-title"
                                            text={title}
                                        />
                                        <Desc
                                            classList="-desc my-3 "
                                            text={desc}
                                        />
                                    </div>
                                </div>
                            </SwiperSlide>
                        )
                    )}
                </Swiper>
            </Row>
            <Row className="ArrowButtons">
                <div className="ArrowButtonBlock">
                    <div className="swiper-pagination swiper-pagination-Architech"></div>
                    <div className="ArrowButtonBlock__Arrow ArrowButtonBlock__ArrowPrevArchitech">
                        <img
                            src={PrevArrow}
                            className="-image"
                            alt="ArchitecturalSlider"
                        />
                    </div>
                    <div className="ArrowButtonBlock__Arrow ArrowButtonBlock__ArrowNextArchitech">
                        <img
                            src={NextArrow}
                            className="-image"
                            alt="ArchitecturalSlider"
                        />
                    </div>
                </div>
            </Row>
        </section>
    );
}
