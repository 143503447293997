import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import newLaunchSvg from "../../images/launching_soon.svg";
import locationIcon from "../../images/location-icon.svg";
import soldOutTag from "../../images/sold_out.svg";
import arrowIcon from "../../images/vuesax/linear/arrow-right.svg";
import "./index.scss";

const ProjectCard = ({ projectData, status, onNewLaunchProjectClick }) => {
    const onExistingProjectClick = () => {
        window.location.href = projectData.link;
    };

    const handleClick = () => {
        const isSereneHeights = projectData.projectName === "Serene Heights";
        onNewLaunchProjectClick(isSereneHeights);
    };

    const ProjectLocation = () => {
        return (
            <>
                <img className="mt-1" src={locationIcon} alt="location" />
                <div className="d-sm-flex flex-sm-column align-items-baseline">
                    <span className="project-info-info">
                        {projectData.propertyLocation.addressLine1}
                    </span>
                    <span className="d-inline d-sm-none">,&nbsp;</span>
                    <span className="project-info-desc">
                        {`${projectData.propertyLocation.addressLine2}, ${projectData.propertyLocation.city}`}
                    </span>
                </div>
            </>
        );
    };

    const ProjectInfo = ({ value, label }) => {
        return (
            <>
                <div className="project-info-info">{value}</div>
                <div className="project-info-desc">{label}</div>
            </>
        );
    };

    const renderProjectInfo = (projectType) => {
        switch (projectType) {
            case "ONGOING": {
                return (
                    <Card.Text>
                        {/* <div className="desc fc-dark-3 mb-4">{projectData.projectDesc}</div> */}
                        <div className="project-info">
                            <div className="info-grid">
                                <div className="d-flex flex-column flex-grow-1">
                                    <ProjectInfo
                                        value={projectData.propertySize.size}
                                        label={projectData.propertySize.type}
                                    />
                                </div>
                                <div className="d-flex align-items-center flex-grow-1">
                                    <span className="info-separator"></span>
                                    <div className="d-flex flex-grow-1 flex-column align-items-center">
                                        <ProjectInfo
                                            value={
                                                projectData.propertyStartingPrice
                                            }
                                            label={"Onwards"}
                                        />
                                    </div>
                                    <span className="info-separator"></span>
                                </div>
                                <div className="d-flex flex-column align-items-center flex-grow-1">
                                    <ProjectInfo
                                        value={projectData.propertyUnits}
                                        label={"Units"}
                                    />
                                </div>
                            </div>
                            <div className="project-info-2 align-items-sm-end justify-content-between d-flex flex-column flex-sm-row">
                                <div
                                    className="d-flex align-self-start align-items-center align-items-sm-start mb-4 mb-sm-0"
                                    style={{ gap: "0.5rem" }}
                                >
                                    <ProjectLocation />
                                </div>
                                <div
                                    className=""
                                    style={{ height: "min-content" }}
                                >
                                    {projectData.newLaunch ? (
                                        <button onClick={handleClick}>
                                            <span className="btn-text">
                                                View Project
                                            </span>
                                            <img
                                                className="ml-2"
                                                src={arrowIcon}
                                                alt="view-project"
                                            />
                                        </button>
                                    ) : (
                                        <Link to={projectData.link}>
                                            <button>
                                                <span className="btn-text">
                                                    View Project
                                                </span>
                                                <img
                                                    className="ml-2"
                                                    src={arrowIcon}
                                                    alt="view-project"
                                                />
                                            </button>
                                        </Link>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Card.Text>
                );
            }
            case "UPCOMING": {
                return (
                    <Card.Text>
                        {/* <div className="desc fc-dark-3 mb-4">{projectData.projectDesc}</div> */}
                        <div className="project-info upcoming-project-info">
                            <div className="info-grid">
                                <div className="d-flex">
                                    <div className="d-flex flex-column flex-grow-1">
                                        <ProjectInfo
                                            value={
                                                projectData.propertySize.size
                                            }
                                            label={
                                                projectData.propertySize.type
                                            }
                                        />
                                    </div>
                                    <span
                                        className="info-separator mr-4  d-none d-sm-flex"
                                        style={{ height: "3.75rem" }}
                                    ></span>
                                </div>
                                <div className="d-flex align-items-center flex-grow-1">
                                    <div
                                        className="d-flex flex-grow-1 align-self-start align-items-center align-items-sm-start mb-4 mb-sm-0"
                                        style={{ gap: "0.5rem" }}
                                    >
                                        <ProjectLocation />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card.Text>
                );
            }
            case "COMPLETED": {
                return (
                    <Card.Text>
                        <div className="project-info completed-project-info">
                            <div className="info-grid">
                                <div className="d-flex align-items-center flex-grow-1">
                                    <div className="d-flex flex-column flex-grow-1">
                                        <ProjectInfo
                                            value={
                                                projectData.propertySize.size
                                            }
                                            label={
                                                projectData.propertySize.type
                                            }
                                        />
                                    </div>
                                    <span className="info-separator"></span>
                                </div>
                                <div className="d-flex align-items-center flex-grow-1">
                                    <div className="d-flex flex-column align-items-center flex-grow-1">
                                        <ProjectInfo
                                            value={projectData.propertyUnits}
                                            label={"Units"}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="project-info-2 align-items-sm-end justify-content-between d-flex flex-column flex-sm-row">
                                <div
                                    className="d-flex align-self-start align-items-center align-items-sm-start mb-4 mb-sm-0"
                                    style={{ gap: "0.5rem" }}
                                >
                                    <ProjectLocation />
                                </div>
                                <div
                                    className=""
                                    style={{ height: "min-content" }}
                                >
                                    <Link to={projectData.link}>
                                        <button>
                                            <span className="btn-text">
                                                View Project
                                            </span>
                                            <img
                                                className="ml-2"
                                                src={arrowIcon}
                                                alt="view-project"
                                            />
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Card.Text>
                );
            }
            default:
                return;
        }
    };

    return (
        <>
            <Card
                className={`project-card ${status}`}
                onClick={() => {
                    if (status === "ONGOING" || status === "COMPLETED") {
                        onExistingProjectClick();
                    }
                    if (status === "UPCOMING") {
                        onNewLaunchProjectClick();
                    }
                }}
            >
                <div className="img-wrapper">
                    <Card.Img
                        src={projectData.projectImage}
                        alt={projectData.imgAlt}
                        className={
                            status === "UPCOMING"
                                ? "upcoming project-image"
                                : "project-image"
                        }
                    />
                    {projectData.tag && (
                        <img
                            className="tag"
                            src={projectData.tag}
                            alt={"Tag"}
                        />
                    )}
                    {projectData.newLaunch && (
                        <img
                            className="new-launch"
                            src={newLaunchSvg}
                            alt={"Launching Soon"}
                        />
                    )}
                    {status === "COMPLETED" && (
                        <img
                            className="tag"
                            src={soldOutTag}
                            alt={"Sold Out"}
                        />
                    )}
                    {status === "UPCOMING" && !projectData.newLaunch && (
                        <div className="upcoming-tag">Coming Soon</div>
                    )}
                </div>
                <Card.Body>
                    <Card.Title>{projectData.projectName}</Card.Title>
                    {renderProjectInfo(
                        projectData.newLaunch ? "ONGOING" : status
                    )}
                </Card.Body>
            </Card>
        </>
    );
};

export default ProjectCard;
