import { Col, Container, Row } from "react-bootstrap";
import { Desc, Heading } from "../../Global/Typography";
import GrassPattern from "../GrassPattern";
// import SoulTreeLogo from "../../../images/SoulTree/SoulTreeLogo.svg";
// import BannerImage from "../../../images/SoulTree/Banner.png";
// import BannerImage from "../../../images/SoulTree/Banner.webp";
import "./Banner.scss";

const SoulTreeLogo = "/images/SoulTree/soul-tree-logo.svg";
const BannerImage = "/images/SoulTree/soul-tree-project-detail-banner.webp";

export default function Banner({ inView }) {
  return (
    <section
      className={`section overflow-hidden pb-0 ${
        inView ? "inView" : "outView"
      }`}
    >
      <GrassPattern />
      <Container className="pos-rel my-md-3">
        <Row>
          <Col xs={11} md={10} lg={7} className="pr-5 py-5 mt-5">
            <img
              src={SoulTreeLogo}
              alt="logo"
              className="SoulTreeLogo msSoulTreeBannerLogo msSoulTreeBannerLogo--sm"
            />

            <Heading
              classList="my-4 pr-md-3"
              variant="h1"
              text="Come, live the good life in a panorama of greenery"
            />
            <Desc
              classList="pr-md-3"
              text="Find the real joy of community living with a myriad of 50+ world-class amenities. It’s an emotion, a belief - ‘Life happens Outside."
            />
          </Col>
        </Row>

        <img
          src={SoulTreeLogo}
          alt="Soul Tree"
          className="SoulTreeLogo msSoulTreeBannerLogo msSoulTreeBannerLogo--md"
        />

        {/* NEW IMAGE */}
        {/* <Row>
          <Col md={12} className="p-0">
            <div className="md-container-wrap">
              <img src={BannerImage} alt="logo" className="-banner-image" />
            </div>
          </Col>
        </Row> */}
      </Container>
      <div className="md-container-wrap">
        <img src={BannerImage} alt="Soul Tree by Modern Spaaces" className="-banner-image" />
      </div>
      <GrassPattern />
    </section>
  );
}
