import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import ProgressBar from "react-scroll-progress-bar";
import calenderIcon from "../../../images/Common/calender-outline.svg";
import readIcon from "../../../images/Common/document-text.svg";
import { Desc, Heading } from "../../Global/Typography";
import "./BannerNew.scss";

const BannerNew = ({ image, title, publishedOn, time, alt, imageFit }) => {
    const [isBannerCollapsed, setBannerCollapsed] = useState(false);
    let location = useLocation();

    const handleScroll = (e) => {
        let offsetTop = document.documentElement.scrollTop;
        if (offsetTop > 15 * 30) {
            setBannerCollapsed(true);
        } else {
            setBannerCollapsed(false);
        }
    };
    window.addEventListener("scroll", handleScroll);

    return (
        <>
            <div
                className={`blog-detail-banner ${
                    isBannerCollapsed ? "collapsed" : "expanded"
                }`}
            >
                <div className="progress-bar">
                    <ProgressBar
                        height="8px"
                        bgcolor="#F6C02E"
                        duration="0.2"
                    />
                </div>
                <div className="fixed-background"></div>
                <Container
                    className="blog-detail-banner-container
          "
                >
                    <Row className="justify-content-around flex-grow-1">
                        <Col
                            xs={12}
                            md={11}
                            lg={9}
                            className=" pr-md-5 banner-wrapper"
                        >
                            <div
                                style={{ display: "grid" }}
                                className="banner-image"
                            >
                                <img
                                    src={image}
                                    alt={alt}
                                    style={{ objectFit: imageFit ?? "cover" }}
                                />
                            </div>
                            <div className="blog-header">
                                {title && (
                                    <Heading
                                        classList="mt-4 mb-0 blog-title"
                                        variant="h1"
                                        text={title}
                                    />
                                )}
                                <div className="d-flex blog-meta flex-column ">
                                    <div
                                        className={`content-separator d-sm-none mb-3 `}
                                    ></div>
                                    <div
                                        className="d-flex flex-row "
                                        style={{ lineHeight: "21px" }}
                                    >
                                        {time && (
                                            <div className="d-flex flex-row align-items-center mr-2">
                                                <img
                                                    src={readIcon}
                                                    alt="read"
                                                    className={`mr-2 ${
                                                        isBannerCollapsed
                                                            ? "d-none"
                                                            : ""
                                                    }`}
                                                />
                                                {/* <img
                                                    src={readIconWhite}
                                                    alt="read"
                                                    className={`mr-2 d-none ${
                                                        isBannerCollapsed
                                                            ? "d-none"
                                                            : "d-none"
                                                    }`}
                                                /> */}

                                                <Desc
                                                    classList="blog-desc mb-0"
                                                    text={`${time}`}
                                                />
                                            </div>
                                        )}
                                        .
                                        {publishedOn && (
                                            <div className="d-flex flex-row align-items-center ml-2">
                                                <img
                                                    src={calenderIcon}
                                                    alt="read"
                                                    className={`mr-2`}
                                                />
                                                {/* <img
                                                    src={calenderIconWhite}
                                                    alt="read"
                                                    className={`mr-2 ${
                                                        isBannerCollapsed
                                                            ? ""
                                                            : "d-none"
                                                    }`}
                                                /> */}
                                                <Desc
                                                    classList="blog-desc mb-0"
                                                    text={`Published on ${publishedOn}`}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {/* <div className="d-flex flex-row ">
                  {time && (
                    <div className="d-flex flex-row align-items-center mr-2">
                      <img
                        src={readIcon}
                        alt="read"
                        className={`mr-2 ${isBannerCollapsed ? "d-none" : ""}`}
                      />
                      <Desc classList="blog-desc mb-0" text={`${time}`} />
                    </div>
                  )}
                  .
                  {publishedOn && (
                    <div className="d-flex flex-row align-items-center ml-2">
                      <img
                        src={calenderIcon}
                        alt="read"
                        className={`mr-2 ${isBannerCollapsed ? "d-none" : ""}`}
                      />
                      <Desc
                        classList="blog-desc mb-0"
                        text={`Published on ${publishedOn}`}
                      />
                    </div>
                  )}
                </div> */}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default BannerNew;
