import { Container, Row, Col } from "react-bootstrap";
import ClubCard from "./ClubCard";

import PrevArrow from "../../../images/Common/PrevArrow.svg";
import NextArrow from "../../../images/Common/NextArrow.svg";



import SwiperCore, {
    Navigation,
    Pagination,
    Scrollbar,
    A11y,
    Autoplay,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";


const clubhouse1 = "/images/EngraceVista/clubconnect/engrace_vista_clubhouse_gym.webp"
const clubhouse2 = "/images/EngraceVista/clubconnect/squash_court.webp";
const clubhouse3 = "/images/EngraceVista/clubconnect/engrace_vista_tennis_court.webp";
const clubhouse4 = "/images/EngraceVista/clubconnect/party_lawn.webp";
const clubhouse5 = "/images/EngraceVista/clubconnect/engrace_vista_guest_rooms.webp";
const clubhouse6 = "/images/EngraceVista/clubconnect/banquet_hall.webp";
const clubhouse7 = "/images/EngraceVista/clubconnect/badminton_court.webp";




SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

export default function Clubs({ inView }) {

    const BlocksData = [
        {
            title: "Gym",
            label: "Level up your fitness journey with our gym designed to empower your goals.",
            image: clubhouse1,
            imgAlt: "Engrace Vista Clubhouse Indoor Gym State-of-the-art fitness facility for residents' wellness and workouts.",
        },
        {
            title: "Squash Court",
            label: "Squash some calories with a thrilling game on the court ",
            image: clubhouse2,
            imgAlt: "Squash Court Modern facility for players.",
        },
        {
            title: "Table Tennis",
            label: "Ace your game with some smashing moves",
            image: clubhouse3,
            imgAlt: "Enjoy a game of tennis in our well-maintained facility",
        },
        {
            title: "Party Lawn",
            label: "Where celebrations come alive beneath the starlit sky.",
            image: clubhouse4,
            imgAlt: "Party Lawn Perfect outdoor venue for celebrations and events.",
        },
        {
            title: "Guest Rooms",
            label: "  Extend a warm welcome to your guests with a comfortable stay",
            image: clubhouse5,
            imgAlt: "Engrace Vista Guest Room Comfortable and welcoming accommodation for visitors.",
        },
        {
            title: "Banquet Hall",
            label: "Host hassle free festivities and get togethers without stepping outside the community.",
            image: clubhouse6,
            imgAlt: "Banquet Hall Elegant venue for special events and gatherings.",
        },
        {
            title: "Badminton Court",
            label: "Take a quick shuttle down to the badminton court and smash it up!",
            image: clubhouse7,
            imgAlt: "Badminton Court Enjoy a game of badminton in our well-maintained facility",
        },
    ];

    return (
        <>
            <Container className="Blocks-Container Blocks-Container--ClubsContainter">
                <Row className="pb-5 mb-5">
                    <Col xs={12} className="pl-4 pl-md-5 ml-md-5 px-0 pr-md-0">
                        <div className="Blocks__Slider">
                            <Swiper
                                className="BlocksSlider"
                                id="BlocksSlider"
                                tag="section"
                                wrapperTag="ul"
                                speed={400}
                                spaceBetween={10}
                                autoplay={true}
                                loop={true}
                                keyboard={{
                                    enabled: true,
                                }}
                                breakpoints={{
                                    300: {
                                        slidesPerView: 1.4,
                                        spaceBetween: 20,
                                    },
                                    450: {
                                        slidesPerView: 2.1,
                                        spaceBetween: 20,
                                    },

                                    768: {
                                        slidesPerView: 3.1,
                                        spaceBetween: 20,
                                    },
                                    1024: {
                                        slidesPerView: 4.1,
                                        spaceBetween: 20,
                                    },
                                    1300: {
                                        slidesPerView: 4.2,
                                        spaceBetween: 38,
                                    },
                                }}
                                pagination={{
                                    clickable: true,
                                    el: ".swiper-pagination-Clubs",
                                }}
                                navigation={{
                                    prevEl: ".ArrowButtonBlock__ArrowPrevArchitech",
                                    nextEl: ".ArrowButtonBlock__ArrowNextArchitech",
                                }}
                            >
                                {BlocksData.map((item, index) => (
                                    <SwiperSlide tag="li" key={index}>
                                        <ClubCard item={item} />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </Col>
                </Row>

                <Row className="ArrowButtons">
                    <div className="ArrowButtonBlock">
                        <div className="swiper-pagination swiper-pagination-Clubs"></div>
                        <div className="ArrowButtonBlock__Arrow ArrowButtonBlock__ArrowPrevArchitech d-none">
                            <img
                                src={PrevArrow}
                                className="-image"
                                alt="ArchitecturalSlider"
                            />
                        </div>
                        <div className="ArrowButtonBlock__Arrow ArrowButtonBlock__ArrowNextArchitech d-none">
                            <img
                                src={NextArrow}
                                className="-image"
                                alt="ArchitecturalSlider"
                            />
                        </div>
                    </div>
                </Row>
            </Container>
        </>
    );
}
