import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

//import ClubTreeMask from "../../../images/Urbanest/ClubTreeMask.png";
// import ClubTreeMask from "../../../images/Urbanest/RecreationZone/TranquilCourtImageLarge.jpg";
// import ClubTreeMask from "../../../images/Engrace/clubconnect/clubhouse.webp";
import bgPatternTextImage from "../../../images/Urbanest/bgPatternTextImage.svg";
import DownloadBrochure from "../../Global/DownloadBrochure";
import { AnchorButton } from "../../Global/Typography";
import "./ClubConnect.scss";

const ClubTreeMask = "/images/Engrace/clubconnect/engrace-club-house-banner.jpg";

export default function ClubConnect() {
    const [show, setShow] = useState(false);
    const dataset = {
        subject: "Urbanest ClubConnect Brochure",
        fileName: "Urbanest-ClubConnect-Brochure.pdf",
        filePath: "./documents/Urbanest-ClubConnect-Brochure.pdf",
    };
    const handleShow = (e) => {
        // var link = document.createElement("a");
        // link.href = "./MS_302_Clubhouse_booklet.pdf";
        // link.setAttribute("download", "MS_302_Clubhouse_booklet.pdf");
        // link.dispatchEvent(new MouseEvent("click"));
        e.preventDefault();
        setShow(true);
    };

    const handleClose = () => setShow(false);
    return (
        <div className="ClubhouseWrapper">
            <Container>
                <Row>
                    <Col xs={12} className="px-0">
                        <div className="ClubhouseWrapper__info">
                            <div className="order-1 order-md-1 heroImageContent text-left px-3">
                                <h3 className="scrollMainHeading mb-0">
                                    <span className="d-md-block">The</span>
                                    <span>
                                        Club{" "}
                                        <span
                                            className="MdWhite"
                                            style={{
                                                textShadow:
                                                    "0px 0px 10px rgba(0,0,0,0.5)",
                                            }}
                                        >
                                            House
                                        </span>
                                    </span>
                                </h3>
                            </div>

                            <div className="order-3 order-md-2 ClubhouseFigureWrap Engrace-ClubhouseFigureWrap">
                                <img
                                    className="img-fluid "
                                    src={ClubTreeMask}
                                    alt="Engrace - Club House"
                                />
                            </div>

                            <div className="order-2 order-md-3 mdOverflowHidden px-3 mb-4">
                                <img
                                    className="img-fluid mb-2 my-4"
                                    src={bgPatternTextImage}
                                    alt="mask"
                                />
                                <AnchorButton
                                    href="#"
                                    classList="-btn mt-3 text-orange"
                                    text="Download Brochure"
                                    onClick={handleShow}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            {/* DownloadBrochure */}
            <DownloadBrochure
                title={"Download Brochure"}
                show={show}
                handleClose={handleClose}
                content={dataset}
            />
        </div>
    );
}
