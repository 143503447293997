import { useState } from "react";
import { InView } from "react-intersection-observer";
// RelatedArticles
import ShareBlogFour from "../Share/ShareBlogFour.js";
import Banner from "./Banner/index.js";
export default function BlogCertificate() {
  const [inViewBanner, setInViewBanner] = useState(false);
  // const [inViewRelatedArticles, setInViewRelatedArticles] = useState(false);
  const [inViewShareBlogFour, setInViewShareBlogFour] = useState(false);
  return (
    <>
      <InView
        className="bgPattern"
        onChange={setInViewShareBlogFour}
        triggerOnce={true}
      >
        <ShareBlogFour
          shareUrl={
            "https://modernspaaces.com/blogs/certificate-of-occupancy-simple-guide-to-what-it-means"
          }
          inView={inViewShareBlogFour}
        ></ShareBlogFour>
      </InView>
      <InView className="" onChange={setInViewBanner} triggerOnce={true}>
        <Banner inView={inViewBanner}></Banner>
      </InView>
    </>
  );
}
