import React from "react";

import Common from "./Common";
export default function ArchitecturalSlider({ inView }) {
  return (
    <>
      <Common />
    </>
  );
}
