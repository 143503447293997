import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Heading } from "../../Global/Typography";
import PlayIcon from "../../../images/PlayIcon.svg";
import BannerImage from "../../../images/SoulTree/Banner.webp";
import "./Banner.scss";
import ModalVideo from "react-modal-video";

export default function Banner({ inView }) {
  const video = "hClGN2CwSnA";
  const [isOpen, setOpen] = useState(false);
  return (
    <section
      className={`section overflow-hidden -bannerImageBlock pb-0 ${
        inView ? "inView" : "outView"
      }`}
    >
      <img src={BannerImage} alt="logo" className="-bannerImage" />
      <Container className="pos-rel my-md-3">
        <Row className="align-items-center -h60vh">
          <Col xs={10} md={6} lg={6} className="pr-5 py-5 mt-5">
            <Heading
              classList="my-4 pr-md-3 text-white"
              variant="h1"
              text="Come, live the good life in a panorama of greenery"
            />
          </Col>
          <Col xs={2} md={6} lg={6} className="pr-5 py-5 mt-5">
            <div class="video" onClick={() => setOpen(true)}>
              <div class="play-button2">
                <span>
                  <img
                    className="-blog-bannerPlayIcon"
                    src={PlayIcon}
                    alt="PlayIcon"
                  />
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="TheHeartAndSoul__image-block ele">
        <ModalVideo
          channel="youtube"
          autoPlay
          isOpen={isOpen}
          // videoId="L61p2uyiMSo"
          videoId={video}
          onClose={() => setOpen(false)}
        />
      </div>
    </section>
  );
}
