import "./Share.scss";
import ShareButton from "./ShareButton";

// import StickyBox from "react-sticky-box";

export default function ShareBlogFour({ inView }) {
  return (
    <section className="shareLinkBlock -only-desktop ">
      <ShareButton
        shareUrl={
          "https://modernspaaces.com/blogs/why-sarjapura-best-area-to-buy-house"
        }
      />
    </section>
  );
}
