// import floorPlan from "../../../images/Common/floor-plan.svg";
// import masterPlan from "../../../images/Common/master-plan.svg";
// import projectBrochure from "../../../images/Common/project-brochure.svg";
import SoulaceBrochure from "../../../documents/Soulace/Soulace_Brochure.pdf";
import SoulaceMasterPlan from "../../../documents/Soulace/Soulace_Master_Plan.pdf";
import SoulaceFloorPlan from "../../../documents/Soulace/Soulace_Floor_Plan.pdf";

const floorPlan = "/images/Common/floor-plan-banner.svg";
const masterPlan = "/images/Common/master-plan-banner.svg";
const projectBrochure = "/images/Common/project-brochure-banner.svg";

export const ProjectResourcesData = [
    {
        key: "floorPlan",
        name: "Floor Plan",
        icon: floorPlan,
        IconAlt:"Floor Plan",
        resourceContent: {
            subject: "Soulace Floor Plan",
            fileName: "Soulace_Floor_Plan.pdf",
            filePath: SoulaceFloorPlan,
        },
    },
    {
        key: "masterPlan",
        name: "Master Plan",
        icon: masterPlan,
        IconAlt:"Master Plan",
        resourceContent: {
            subject: "Soulace Master Plan",
            fileName: "Soulace_Master_Plan.pdf",
            filePath: SoulaceMasterPlan,
        },
    },
    {
        key: "projectBrochure",
        name: "Project Brochure",
        icon: projectBrochure,
        IconAlt:"Project Brochure",
        resourceContent: {
            subject: "Soulace Brochure",
            fileName: "Soulace_Brochure.pdf",
            filePath: SoulaceBrochure,
        },
    },
];
