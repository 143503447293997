import { Container, Row, Col } from "react-bootstrap";
import { Desc, Heading } from "../../Global/Typography";
// import { Desc, Heading } from "../../Global/Typography";
// import TranquilCourtImage from "../../../images/SoulTree/TranquilCourt/TranquilCourtImage.png";
// import TranquilCourtImageLarge from "../../../images/SoulTree/TranquilCourt/TranquilCourtImageLarge.png";
import TranquilCourtImageSmall from "../../../images/SoulTree/TranquilCourt/TranquilCourtImageSmall.png";
import patternVideo from "../../../images/SoulTree/TranquilCourt/patternVideo.mp4";
import "./TranquilCourt.scss";
const TranquilCourtImageLarge =
    "/images/Soulace/TranquilCourt/soulace-outdoor-amenities-banner.png";

export default function TranquilCourt({ inView }) {
    const TranquilCourtData = [
        {
            title: "Tranquil Court",
            desc: "Soulace offers an array of outdoor amenities for a holistic and engaging living experience.",
            imageLarge: TranquilCourtImageLarge,
            imageSmall: TranquilCourtImageSmall,

            patternVideo: patternVideo,
            // image: TranquilCourtImage,
            listItems: [
                "1.3 km Peripheral walking track",
                "Hoop Court",
                "Football Court",
                "Tennis Court",
                "Yoga Court",
                "Pet Park",
                "Produce Garden",
                "Seniors Court",
                "Outdoor Dining",
                "Kids Play Area",
                "Banyan Kund",
                "Zen Garden",
                "Fruit Orchard",
            ],
        },
    ];

    return (
        <>
            <Container className="py-2 my-md-3">
                <Row className="justify-content-center">
                    <Col xs={12} md={6} lg={6} xl={12} className=" text-center">
                        <Heading variant="h1" classList="" text="Amenities" />
                    </Col>
                </Row>
            </Container>

            <section
                className={`section bg-white ${inView ? "inView" : "outView"}
    `}
            >
                {TranquilCourtData.map((item, idx) => {
                    return (
                        <Container key={idx} className="TranquilCourt">
                            <Row>
                                <Col
                                    xs={12}
                                    md={5}
                                    lg={5}
                                    xl={4}
                                    className="pr-md-5 pr-xl-4"
                                >
                                    {/* AS OF NOW */}
                                    {/* <Heading
                    variant="h1"
                    classList="TranquilCourt__title my-3"
                    text={item.title}
                  /> */}
                                    <Desc
                                        classList="TranquilCourt__desc my-3 "
                                        text={item.desc}
                                    />
                                    <ul className="TranquilCourtList">
                                        {item.listItems.map((i, j) => (
                                            <li key={j}>
                                                <Desc
                                                    classList="my-2"
                                                    text={i}
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                </Col>
                            </Row>
                            <div className="TranquilCourtImage">
                                {/* <img
                src={item.image}
                className="TranquilCourt__image"
                alt="TranquilCourt"
              /> */}
                                {/* SEPARATE */}
                                <img
                                    src={item.imageLarge}
                                    className="TranquilCourt__imageLarge"
                                    alt="Soulace a array of Outdoor Amenities  "
                                />

                                <video
                                    controls={false}
                                    className="TranquilCourt__imageSmall"
                                    loop={true}
                                    muted={true}
                                    autoPlay={true}
                                    playsInline={true}
                                >
                                    <source
                                        src={item.patternVideo}
                                        type="video/mp4"
                                    />
                                </video>
                            </div>
                        </Container>
                    );
                })}
            </section>
        </>
    );
}
