import Comparision from "../../src/images/Blogs/CostComparison.png";

const data = [
  {
    id: "abc104",
    slug: "comparing-the-cost-building-vs-buying-homes",
    image: Comparision,
    label: "Industry",
    title: " Comparing the Cost - Building vs Buying Homes  ",
    publishedOn: "Dec, 2021",
    time: "2 min read",
    description:
      "Experience the future with smart homes! Discover how smart home technology enhances convenience, security, and energy efficiency.Lets find out more about it in this blog.",
    authorImage:
      "https://images.unsplash.com/photo-1600188769099-d25b4ec79659?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    authorName: "Noveen Reddy",
    department: "Marketing Head",
    contactNumber: "987654321",
    // imageOther: "https://modernspaaces.com/BlogListImages/BlogFour/Img01.webp",
    description11:
      "In Bangalore's bustling home market, picking a place isn't just about four walls. The choice between crafting a custom-made home or embracing a pre-built house is more than a practical decision. On one hand, there's the idea of creating a home that totally reflects your style. On the other hand, there's the charm of walking into a place that's already got its own story. But it seamlessly blends with your personal touch, turning it into a place that resonates with you and feels like it has been yours all along.",
    description12:
      "Choosing the perfect home involves considering various essential aspects beyond the mere structure. Location, price, property condition, future prospects, legal concerns, and the community environment collectively shape the ideal choice.",
    description13:
      "Homebuyers must try to buy or build a home which fulfills at least a few, if not all, of these critical criteria to ensure their chosen home aligns seamlessly with their needs, aspirations, and long-term vision of a perfect home.",

    list1: [
      {
        label: "Location",
        description:
          " The property's geographical placement is crucial, considering proximity to workplaces, schools, transportation, amenities, safety, and overall neighborhood desirability.",
      },
      {
        label: "Price and Affordability",
        description:
          " Affordability remains a pivotal factor, encompassing the property's cost, associated expenses (such as registration taxes and maintenance), and whether it fits within the buyer's budget.",
      },
      {
        label: "Property Condition and Suitability",
        description:
          " The home's condition, size, layout, and overall suitability for the buyer's needs, including the number of rooms, amenities, and any necessary renovations or modifications.",
      },
      {
        label: "Future Potential and Resale Value",
        description:
          "Evaluating the property's potential for appreciation, future development, or changes in the neighborhood that might impact its value, influencing the decision-making process.",
      },
      {
        label: "Legal and Documentation Aspects",
        description:
          "Ensuring all legal aspects, property documentation, clear titles, and compliance with regulations are in place before making a purchase to avoid any legal complications in the future. Always invest in properties built by reputed builders who have completed and delivered all their projects on time.",
      },
      {
        label: "Community and Neighborhood",
        description:
          "The presence of like minded community and neighborhood, offers a range of benefits, such as communal spaces, clubs, or associations. A supportive community can act as an extended family, especially in times of need.",
      },
    ],
    title2: "Construction cost of a house in Bangalore",
    list2: [
      {
        label: "Choosing a site",
        list: [
          "Bangalore's soaring demand for residential development significantly impacts the cost per square foot of construction, often reaching higher figures. On average, building in Bangalore typically ranges from Rs 1500 to Rs 1700 per square foot, yet this figure can significantly fluctuate based on specific needs and location.",
          "Opting for sought-after areas further escalates costs; recent surveys highlight a substantial surge in property values within central Bangalore, witnessing construction expenses soar from Rs 2500 to Rs 3500 per square foot within a short span.",
          "Construction of an independent house is generally more expensive compared to high-rise apartments because of the maximum utilization of space.",
          "The cost of land in Bangalore can vary significantly depending on the location. Prime areas in the city tend to be more expensive, while suburban or outlying areas may have lower land costs. If you already own a piece of land, building a home might be a more cost-effective option.",
        ],
      },
      {
        label: "Approval from BBMP and BMRDA",
        list: [
          "After a site has been selected it is important to get your plan approved from the BBMP or BMRDA authorities. So 10% of cost should be reserved  for all kinds of bureaucratic work and obtaining approvals for electrical and sanitary works.",
        ],
      },
      {
        label: "Time and Effort",
        list: [
          "Building a home requires time and effort, including securing permits, working with architects and builders, and overseeing the construction process.",
          "For the working professional, time is the most scarce resource so spending time is equivalent to spending money.",
          "Whereas buying a home in a high-rise apartment or a Villa community is often quicker and more straightforward, with numerous amenities making the whole process cost effective and providing you with an unmatched lifestyle upgrade.",
        ],
      },
      {
        label: "Inflation",
        list: [
          "Inflation significantly affects construction costs, materials, and labor, potentially escalating expenses for building a home. It also impacts property prices, making land acquisition and pre-existing homes more expensive.",
          "Fluctuating interest rates due to inflation affect financing costs for both building and buying. Budgeting becomes challenging, leading to possible cost overruns.",
          "However, high inflation can increase property values over time, benefiting resale. Understanding inflation's impact on costs, budgeting, and property values is crucial when deciding to build or buy.",
        ],
      },
      {
        label: "Extended Wait Time",
        list: [
          "Constructing a new house typically takes around seven months, not including the planning and approval phases. In contrast, closing on an existing home usually happens within 30 to 45 days.",
          "During this construction period, you'll need to cover living expenses, which could mean paying rent or your current home loan alongside the construction costs, leading to financial strain.",
        ],
      },
      {
        label: "Stress and Attention to Detail",
        list: [
          "Building a house requires you to make multiple decisions, from purchasing the land to selecting home designs, flooring, fixtures, cabinets, countertops, and more, all while staying within your budget. Managing these numerous details and dealing with incremental expenses demands considerable time and effort",
        ],
      },
      {
        label: "Budget Oversight",
        list: [
          "The excitement of building a new home can lead to overspending. The multitude of details and decisions can easily result in exceeding your original budget if not managed carefully.",
        ],
      },
      {
        label: "Market Conditions",
        list: [
          "Real estate market conditions can change over time. In a buyer's market, it may be more affordable to purchase an existing home, as sellers may be more willing to negotiate on prices. In a seller's market, property prices tend to rise, making it more appealing to invest in property.",
        ],
      },
      {
        label: "Resale Value",
        list: [
          "Existing homes often come with an established neighborhood and potentially higher resale value. New construction might take time to appreciate in value.",
        ],
      },
      {
        label: "Financing",
        list: [
          "Your ability to secure financing can also influence your decision. Loans for new construction may differ from those for buying existing homes.",
        ],
      },
    ],

    conclusion: "Final Insights",
    conclusion1:
      "In summary, while building a house offers the opportunity for a custom living space, it comes with drawbacks such as a lengthy construction process, limited negotiation room in pricing, potential inconveniences due to construction, increased stress in managing the myriad of details, and the risk of overspending.",
    conclusion2:
      "But buying a ready made home is a quicker and a more straightforward option. It provides the advantage of immediate occupation without any construction related stress. Always remember to carefully consider the options before making a decision.",
  },
];

export default data;
