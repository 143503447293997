// import RERA from "../images/Blogs/reraInfluence.png";
// import PunishmentRera from "../images/Blogs/punishment.jpg";

const RERA = "/images/Blogs/rera-act-influence-banner.png"
const PunishmentRera = "/images/Blogs/rera-guildlines-banner.jpg";

const data = [
  {
    id: "abc102",
    slug: "rera-how-it-benefits-homeowners-and-developers",
    image: RERA,
    imgAlt:"RERA Act Influence on Homebuyers and Developers ",
    image2: PunishmentRera,
    image2Alt: "Penalities for non compliance under RERA Guidelines",
    label: "Real Estate",
    desc: "Bangalore being the center of India's high-tech industry, also rightfully known as the Silicon Valley of India, is home to about 9 million people…",
    title:
      "RERA's Influence: How Does It Benefit the Homeowners and Developers?",
    title1: "Guidelines of the RERA Act 2016",
    title2: "What is the RERA Act? ",
    title3: "How does RERA impact home buyers?",
    title3description:
      "The Real Estate Regulation and Development Act,or RERA, has introduced significant reforms to the Indian real estate sector.",
    title4: "How does RERA affect Developers?",
    title5: "Punishment for failing to follow RERA guidelines.",
    title6: "How is Karnataka working towards implementing RERA?",
    title6description:
      "Karnataka has implemented RERA by establishing a regulatory authority and an official portal which has been developed for the benefit of all the stakeholders of the real estate sector in the state.",
    Challenges: "Challenges and Limitations of RERA Implementation",
    Faq: "FAQs",
    publishedOn: "Jan, 2024",
    time: "4 min read",
    description: (
      <>
        {`India's real estate sector is a vital part of the country's economy, contributing about 7% to the GDP and generating numerous job opportunities. However, it faced a lack of proper regulations for a long time. To tackle this issue, the Indian government introduced the `}
        <a href="https://www.naredco.in/notification/pdfs/Karnataka%20Real%20Estate%20(Regulation%20and%20Development)%20Rules,%202017.pdf">  RERA Act in 2016. </a>{`This law aimed to bring fairness and clear rules for both homebuyers and construction companies. Its goal was to fix the confusion and differences in how things were managed in the real estate industry.`}
      </>
    ),
    authorImage:
      "https://images.unsplash.com/photo-1600188769099-d25b4ec79659?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    authorName: "Noveen Reddy",
    department: "Marketing Head",
    contactNumber: "987654321",
    // imageOther: "https://modernspaaces.com/BlogListImages/BlogFour/Img01.webp",
    lightDescriptionList: [
      {
        label: "Regulating India's Real Estate Sector",
        list: [
          "RERA stands for the Real Estate Regulatory Authority Act (2016). The government made the homeowners an active part of the process by granting them access to detailed information about the project and providing grievance redressal to them. Investing in properties with a RERA license is considered wise due to the transparency and support it offers the buyers.",
          "According to the RERA Act, it is obligatory for the builders to register all residential and commercial real estate projects if the land area exceeds 500 square meters. So any land or plot exceeding 500 square meters has to be RERA registered and approved before the project is launched. This registration process helps create better transparency in the execution of launched projects.",
        ],
      },
      {
        label: "Is RERA applicable all over India?",
        list: [
          "RERA (Real Estate Regulatory Authority) was made obligatory by the Indian government to be applied uniformly across all states and union territories. However, certain regions encountered challenges in implementing RERA.",
          "For instance, Nagaland faced difficulties in establishing RERA within its state, prompting a notice from the Supreme Court seeking clarification.",
          "Similarly, as of March 13, 2023, other areas such as Arunachal Pradesh, Meghalaya, Sikkim, West Bengal, and the Union Territory of Jammu and Kashmir were also noted for their struggles in executing the RERA Act.",
        ],
      },
    ],
    list: [
      {
        label: "Each state is required to",
        description: [
          "Set up a structured system",
          "Establish an online platform for project registrations. ",
          "Address consumer grievances.",
          "Supervise real estate ventures within their respective states.",
        ],
      },
      {
        label: "RERA license",
        description: [
          "An agent or a company has to obtain a license to deal in any kind of real estate work. The registration process for agents under RERA (Real Estate Regulatory Authority) is straightforward. Agents are required to submit  a fee and an application to the Authority for registration. ",
          "The advantages of RERA registration for agents include builder details, ensuring ethical handling of clients' funds, and offering precise and authentic information to the general public.",
        ],
      },
      {
        label: "Registration of Real Estate Projects",
        description: [
          "The developers have to mandatorily register their projects before they initiate any new project. This registration ensures that the project adheres to standardized norms and provides accurate information to potential buyers.",
        ],
      },
      {
        label: "Transparency and Accountability",
        description: [
          "Developers must provide accurate and detailed information about their projects on the RERA website. This includes project details, layout plans, approvals, completion timelines, and other relevant information. This helps the homebuyers to be sure about their investment.",
        ],
      },
      {
        label: "Adherence to Project Timelines",
        description: [
          "Developers are obligated to complete their projects within the specified time frame mentioned in the agreement with the buyer. Delays can lead to penalties.",
        ],
      },
      {
        label: "Advance Payments",
        description: [
          "The act limits the advance amount that developers can ask for to 10% before the formal sale agreement is signed.",
        ],
      },
      {
        label: "Quality Control",
        description: [
          "The RERA Act emphasizes the need for quality construction and workmanship, ensuring that the buyer receives what was promised.",
        ],
      },
      {
        label: "Carpet Area Definition",
        description: [
          "The act specifies that the price of a project should be based on the carpet area of the property, which is the actual usable area within the walls excluding the balconies. This prevents developers from manipulating pricing based on other measurements.",
        ],
      },
      {
        label: "Complaints and Redressal",
        description: [
          " The act establishes a process for addressing grievances and complaints from buyers. It also aims to establish a Real Estate Appellate Tribunal for quicker resolution of disputes.",
        ],
      },
      {
        label: "Penalty for Violations",
        description: [
          "In case of non-compliance with the provisions   of the act, developers can face penalties, fines, or even imprisonment.",
        ],
      },
    ],
    Impactonbuyers: [
      {
        label: "Transparency",
        description: [
          "It mandates developers to provide accurate project information on the RERA website, limits advance payments to 10% before formal sale agreements, and enforces strict completion timelines to prevent delays.",
        ],
      },
      {
        label: "Home loans made easy",
        description: [
          "RERA approved projects make it easier for the home buyers to avail loan from banks making it easier to own a home.",
        ],
      },
      {
        label: "Redressal mechanism",
        description: [
          "In cases of delays, buyers are entitled to compensation, fostering accountability. The act ensures construction quality, clear property titles, and establishes a grievance redressal mechanism through the Real Estate Regulatory Authority (RERA).",
        ],
      },
      {
        label: "No misleading Advertisement",
        description: [
          "RERA prevents misleading advertising and safeguards buyers from false claims. These provisions collectively enhance transparency, protect buyers' interests, and promote better practices in the real estate industry.",
        ],
      },
      {
        label: " Website",
        description: [
          "Each state has to set up a website dedicated to RERA making it easier for the consumer to access information regarding the project, amenities provided, completion date and other related details.",
        ],
      },
    ],
    affectonDevelopers: [
      {
        label: " RERA license",
        description: [
          " It is required that the Developers register their projects with the respective state's RERA before advertising or selling them. All brokers will have to compulsorily register their projects on the website to obtain a license to operate.The registration number allotted will remain valid for five years.The license has to be renewed every 5 years.This adds a level of legitimacy and accountability to the projects. ",
        ],
      },
      {
        label: "Financial Discipline",
        description: [
          "The act prevents developers from diverting funds collected for a specific project to other purposes. Developers have to maintain a separate escrow account for each individual project. 70% of the advance taken from the homebuyers have to be maintained in the escrow account for construction related developments and land acquisitions. This helps in safeguarding the homebuyers from delays and prevents developers filing for bankruptcy.",
        ],
      },
      {
        label: "Compliance and Accountability",
        description: [
          " Developers must adhere to the approved plans, specifications, and commitments made to buyers. The act holds developers accountable for fulfilling their obligations as stated in the agreement.",
        ],
      },
      {
        label: "Timely Completion",
        description: [
          " Developers are motivated to complete projects on time to avoid penalties and compensation payments to buyers in case of delays.",
        ],
      },
      {
        label: "Quality Assurance",
        description: [
          " Developers must ensure that the quality of construction and workmanship meets the standards mentioned in the agreement. This encourages better quality control in the industry.",
        ],
      },
      {
        label: "Credibility and Trust",
        description: [
          "Adhering to the regulations established by RERA enhances a developer's credibility and helps build trust with potential buyers.",
        ],
      },
      {
        label: "Consumer Confidence",
        description: [
          "The increased transparency and protection provided by RERA can boost consumer confidence in the real estate market, potentially leading to increased sales.",
        ],
      },
      {
        label: "Quarterly Updates ",
        description: [
          "The builder/ developers have to update all the details like the number of units sold, government approvals received or not, any ongoing litigations e.t.c. Every three months details about the progress in construction and other important information related to it helps the homebuyers to stay informed and up to date with the project related details.",
        ],
      },
    ],

    title7 :"What happens if homebuyers miss payments under RERA?",
    description71:"If a homebuyer fails to pay the agreed payment and does not respond to repeated notifications in such a scenario, the developer is allowed to take action by filing an appeal to cancel the sale deed.",
    description72:(<>
    {`As per Section 19(6) of the RERA Act, every`}<a href="https://docs.google.com/document/d/1_CB6UtlV733JLWXNAZ8QIu6FGWNW0f1K6Bo1P75Hlew/edit#heading=h.r7sqh7j549db"> allottee, </a>{`who has entered  into an agreement for the sale of an apartment, plot, or building, is obligated to make payments within the stipulated time frame specified in the agreement. 
`}
    </>),
    description73:"The provision aims to ensure timely and responsible financial commitments from buyers, allowing developers to address non-compliance through legal means",

    title8:"Penalties for Non-Compliance by Homebuyers Under RERA Act",
    list8:[
      {
        label:"Failure to follow orders of RERA Authority",
        description:["RERA includes a provision for imposing penalties on homebuyers who do not follow the directives of the Real Estate Regulatory Authority. In the event of non-compliance, the Authority may levy a penalty of up to five percent of the property's cost."]
      },
      {
        label:"Failure to follow orders of REAT",
        description:["Failure to comply with orders from the Real Estate Appellate Tribunal may lead to imprisonment for a maximum of one year or a fine amounting to ten percent of the property's cost."]
      }
    ],

    title9:"What is REAT?",
    description91:"The Real Estate Appellate Tribunal (REAT) is a specific tribunal established to review and hear appeals concerning decisions made by the Real Estate Regulatory Authority (RERA) under the RERA act. If someone is dissatisfied with a decision or order from RERA, they can file an appeal with REAT, representing the initial level of appeal for such matters.",
    
    KarbatakaRera: [
      <>
        {`The Karnataka RERA maintains a list of all the`} <a href="/">real estate projects,</a>{`registration of projects and a comprehensive list of approved and rejected projects.The Karnataka RERA ensures that all real estate projects in the state are registered The Karnataka government has issued directions to State Bank of India (SBI) and Canara Bank to open RERA designated accounts. Official Website:`}
        <a href="https://rera.karnataka.gov.in/"> Karnataka RERA</a>
      </>,
    ],

    challengesofrera: [
      {
        label: "Enforcement Hurdles",
        description: [
          "Despite penalties for non-compliance, the enforcement of RERA regulations has been slow allowing some developers to violate rules without consequences, causing financial losses for buyers.",
        ],
      },
      {
        label: "Delivery Delays",
        description: [
          "Although RERA sets rules for timely project completion, instances of project delays persist, accompanied by insufficient compensation for affected homebuyers. The compensation process can be long drawn and complex.",
        ],
      },
      {
        label: "Authority's Constraints",
        description: [
          "State-level RERA authorities possess limited powers to penalize developers, leading to a lack of accountability.",
        ],
      },
      {
        label: "Escalating Costs",
        description: [
          "RERA's compliance requirements have driven up costs for developers thereby inflating property prices, impacting the consumers.",
        ],
      },
      {
        label: "Overburdening Compliance",
        description: [
          " Developers now face additional compliance costs due to project registration, escrow account management, and regular progress updates, ultimately influencing project costs.",
        ],
      },
      {
        label: "Approval Challenges",
        description: [
          " The approval process for new projects faces delays due to the need for multiple clearances from various authorities before registration with RERA.",
        ],
      },
      {
        label: "Sector Limitation",
        description: [
          "RERA focuses solely on residential real estate, excluding commercial projects, causing regulatory disparities between the two sectors.",
        ],
      },
      {
        label: "Inconsistent Rules",
        description: [
          "RERA operates on a state-by-state basis, leading to varying rules and regulations. This inconsistency challenges developers, operating across multiple states.",
        ],
      },
    ],

    ClosingThoughts: "Conclusion",
    noteStartItems: [
      "The Real Estate Regulatory Authority Act (RERA) has influenced both the homebuyers as well as the developers.",
      "For homebuyers, RERA has been a ray of hope, providing access to accurate project information, protecting their investments, and offering a platform for dispute resolution. The Act's provisions on project registration, accountability, adherence to timelines, and quality control have empowered buyers to make informed decisions, while provisions in the law for  compensation and redressal have upheld their rights.",
      "Developers are segregated and legitimized through RERA. Focus on project registration, maintenance of escrow accounts, and adherence to commitments has elevated credibility and trust in the industry.",
      "Despite its positive impact, challenges and limitations persist in RERA's implementation. Enforcement hurdles, delivery delays, escalating costs, and varied state regulations are areas that need attention for more effective outcomes.By addressing and rectifying the shortcomings of the RERA Act, its effectiveness and reliability can be further enhanced",
    ],

    faqs: [
      {
        label: "What is RERA?",
        description: [
          "RERA is Real Estate Regulatory Act enacted to regulate the real estate sector. It aims to protect the rights of the buyer and hold the developers accountable. It equips the homeowners with legal resources in case of any grievances.",
        ],
      },
      {
        label: "What is the RERA carpet area?",
        description: [
          "RERA Carpet Area refers to the effective usable space within a property, excluding walls, balconies, or any shared areas.",
        ],
      },
      {
        label: "What happens If i do not buy a RERA approved property?",
        description: [
          "RERA ensures that the homebuyer remains protected and are given their due rights. So if a property is not RERA approved then the consumer can face several problems without access to any complaint mechanism.",
        ],
      },
      {
        label:
          "What is the difference between RERA carpet area and carpet area?",
        description: [
          "The distinction lies in the inclusion of inner walls within a flat. While the standard carpet area does not consider the  inner walls, the RERA carpet area includes these inner walls within the flat.",
        ],
      },
      {
        label: "How can I file a complaint against a builder?",
        description: [
          "If a builder does not deliver what he promised to do so or there are any other complaints then the consumer can lodge an official complaint against the builder through the online portal.",
        ],
      },
      {
        label: "How can I know if a project is RERA approved?",
        description: [
          "You can login to the official webpage and enter the RERA license number of the project. All the relevant details pertaining to the project can be easily assessed online.",
        ],
      },
    ],
  },
];

export default data;
