import { Col, Container, Row } from "react-bootstrap";
import { Desc, DescLabel } from "../../../Global/Typography";
import BannerNew from "../../BannerNew";
import "../../BlogComparingCost/Banner/BlogComparision.scss";

import BlogFourData from "../../../../db/BlogComparingCost";
import ShareButton from "../../Share/ShareButton";

export default function Banner({ inView }) {
  const currentPage =
    "Comparing the pros and cons of buying a home vs buying an apartment. This blog aims to guide you in making an informed decision.";
  return (
    <section className={`section  ${inView ? "inView" : "outView"}`}>
      {/* <Container className="container">
        <Row className="align-items-center">
          <Col xs={12} md={12} lg={12} className="py-2">
            <BreadcrumbBlogCard item={currentPage} />
          </Col>
        </Row>
      </Container> */}

      <BannerNew
        image={BlogFourData[0].image}
        title={BlogFourData[0].title}
        publishedOn={BlogFourData[0].publishedOn}
        time={BlogFourData[0].time}
      />

      <Container className="blog-detail-container">
        {BlogFourData.map((item, idx) => {
          return (
            <Row key={idx} className="justify-content-around">
              {/* {item.image && (
                <Col xs={12} md={12} lg={12} className="pb-4">
                  <img
                    src={item.image}
                    alt={item.title}
                    className="-blog-banner"
                  />
                </Col>
              )} */}
              <Col xs={12} md={11} lg={9} className="pb-4 pr-md-5">
                <div className="blog-header margin">
                  {/* {item.title && (
                    <Heading classList="my-3" variant="h1" text={item.title} />
                  )}
                  {item.publishedOn && (
                    <Desc
                      classList=""
                      text={`Published on ${item.publishedOn}`}
                    />
                  )} */}
                  {item.description11 && (
                    <Desc
                      classList="font-benton-book header"
                      text={item.description11}
                    />
                  )}
                  {item.description12 && (
                    <Desc
                      classList="font-benton-book header"
                      text={item.description12}
                    />
                  )}
                  {item.description13 && (
                    <Desc
                      classList="font-benton-book header"
                      text={item.description13}
                    />
                  )}
                </div>

                {item.list1 &&
                  item.list1.map((i0, idx) => {
                    return (
                      <div key={idx}>
                        <DescLabel
                          classList="my-1 py-2 font-benton-book header"
                          text={i0.description}
                          label={i0.label}
                        />
                        {i0.list1 && (
                          <ul className="blog-list-circle header">
                            {i0.list1.map((i1, idx1) => {
                              return (
                                <li key={idx1}>
                                  <p className="-descBlack mb-1 font-benton-book header">
                                    {i1}
                                  </p>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </div>
                    );
                  })}
                
                <div>
                  {item.title2 && (
                    <p className="-descBlack font-benton-book fw-bold  margin">
                      {item.title2}
                    </p>
                  )}
                  {item.list2 &&
                    item.list2.map((i0, idx) => {
                      return (
                        <div key={idx}>
                          <DescLabel
                            classList="my-1 py-2 font-benton-book header "
                            text={i0.description}
                            label={i0.label}
                          />
                          {i0.list && (
                            <ul className="blog-list-circle  header">
                              {i0.list.map((i1, idx1) => {
                                return (
                                  <li key={idx1}>
                                    <p className="font-benton-book header">
                                      {i1}
                                    </p>
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                        </div>
                      );
                    })}
                </div>

                <div className="blog-content ">
                  {item.conclusion && (
                    <p className="-descBlack font-benton-book fw-bold  margin">
                      {item.conclusion}
                    </p>
                  )}
                   {item.conclusion1&& (
                    <Desc
                      classList="font-benton-book header"
                      text={item.conclusion1}
                    />
                  )}
                   {item.conclusion2 && (
                    <Desc
                      classList="font-benton-book header"
                      text={item.conclusion2}
                    />
                  )}

                 </div>

                <section className="shareLinkBlock -only-mobile ">
                  <ShareButton shareUrl="https://modernspaaces.com/blogs/comparing-the-cost-building-vs-buying-homes" />
                </section>
              </Col>
              <Col xs={12} md={4} lg={4} className="my-0 py-0 -aside-block">
                {/* <Fade>
                  <div className="-blog-author">
                    <p className="--label">Written By</p>
                    <img
                      src={item.authorImage}
                      alt={item.authorName}
                      className="-authorImage"
                    />

                    <h2 className="my-3 -authorName">{item.authorName}</h2>
                    <h3 className="my-1 -department">{item.department}</h3>

                    <AnchorGradientLinkAhref
                      href={`tel:${item.contactNumber}`}
                      classList="-btn-link mt-4 d-block"
                      text="Ask expert"
                      // text={"Read More"}
                      target="_blank"
                    />
                  </div>
                </Fade> */}
              </Col>
            </Row>
          );
        })}
        <Row className="simpleShadow my-3 py-3 justify-content-around">
          <Col xs={12} md={11} lg={9} className="pb-4 pr-md-5 p-2">
            <div
              className="shareLinkBlock1 d-flex flex-row p-4 "
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.04)",
                gap: "1rem",
                borderRadius: "1rem",
              }}
            >
              <ShareButton shareUrl="https://modernspaaces.com/blogs/comparing-the-cost-building-vs-buying-homes" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
