import { Link } from "react-router-dom";
import OrangeArrow from "../../../images/Blogs/OrangeArrow.svg";
import MapIcon from "./MapIcon";
import RightArrowIcon from "./RightArrowIcon";
import style from "./Typography.module.scss";

export const Desc = ({ text, classList = "" }) => (
  <p className={`${style.desc} ${classList}`}>{text}</p>
);
export const DescLabel = ({
  text,
  classList = "",
  label,
  showColon = true,
}) => (
  <p className={`${style.descBlack} ${classList}`}>
    <strong className="pr-2 font-benton-bold">
      {label} {showColon && ":"}
    </strong>
    {text}
  </p>
);

export const DescSmall = ({ text, classList = "" }) => (
  <p className={`${style.descSmall} ${classList}`}>{text}</p>
);

export const HashTagTitle = ({ text, classList = "" }) => (
  <h1 className={`${style.HashTagTitle} ${classList}`}>{text}</h1>
);

export const AnchorTag = ({ text, classList = "", href, onClick }) => (
  <Link
    className={`${style.AnchorTag} ${classList}`}
    to={href}
    onClick={onClick}
  >
    <span className="mr-3">{text}</span>
    <RightArrowIcon />
  </Link>
);

export const AnchorButton = ({ text, classList = "", href, onClick }) => (
  <Link
    className={`${style.AnchorButton} ${classList}`}
    to={href}
    onClick={onClick}
  >
    {text}
  </Link>
);

export const AnchorGradientButton = ({
  text,
  classList = "",
  href,
  onClick,
  target,
}) => (
  <Link
    className={`${style.AnchorGradientButton} ${classList}`}
    to={href}
    onClick={onClick}
    target={target}
  >
    {text}
  </Link>
);

export const AnchorGradientButtonAhref = ({
  text,
  classList = "",
  href,
  onClick,
  target,
}) => (
  <a
    className={`${style.AnchorGradientButton} ${classList}`}
    href={href}
    onClick={onClick}
    target={target}
  >
    {text}
  </a>
);
export const AnchorGradientLinkAhref = ({
  text,
  classList = "",
  href,
  onClick,
  target,
}) => (
  <a
    className={`${style.AnchorGradientLink} ${classList}`}
    href={href}
    onClick={onClick}
    target={target}
  >
    {text}
    <img className="mx-2" src={OrangeArrow} alt="OrangeArrow"></img>
  </a>
);

export const AnchorButtonIcon = ({
  text,
  classList = "",
  icon,
  href,
  target,
}) => (
  <Link
    className={`${style.AnchorButtonIcon} ${classList}`}
    to={href}
    target={target}
  >
    {icon ? <MapIcon /> : ""}
    <span className={icon ? "ml-3" : ""}>{text}</span>
  </Link>
);

export const Heading = ({ text, desc, classList = "", variant = "h1" }) => {
  if (variant === "h1") {
    return (
      <>
        <h1 className={`${style.heading} ${classList}`}>{text}</h1>{" "}
      </>
    );
  }

  if (variant === "h2") {
    return (
      <>
        <h2 className={`${style.h2Heading} ${classList}`}>{text}</h2>{" "}
      </>
    );
  }

  if (variant === "h3") {
    return (
      <>
        <h3 className={`${style.h3heading} ${classList}`}>{text}</h3>{" "}
      </>
    );
  }

  if (variant === "h4") {
    return (
      <>
        <h4 className={`${style.h4heading} ${classList}`}>{text}</h4>{" "}
      </>
    );
  }

  if (variant === "h5") {
    return (
      <>
        <h5 className={`${style.h5heading} ${classList}`}>{text}</h5>{" "}
      </>
    );
  }

  if (variant === "h6") {
    return (
      <>
        <h6 className={`${style.h6heading} ${classList}`}>{text}</h6>{" "}
      </>
    );
  }

  if (variant === "h7") {
    return (
      <>
        <h6 className={`${style.h7heading} ${classList}`}>{text}</h6>{" "}
      </>
    );
  }

  if (variant === "h1Lg") {
    return (
      <>
        <h1 className={`${style.h1Lg} ${classList}`}>{text}</h1>{" "}
      </>
    );
  }

  if (variant === "h1Md") {
    return (
      <>
        <h1 className={`${style.h1Md} ${classList}`}>{text}</h1>{" "}
      </>
    );
  }

  return (
    <>
      <h1 className={`${style.h1heading} ${classList}`}>{text}</h1>{" "}
    </>
  );
};
