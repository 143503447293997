// Share
import {
    FacebookShareButton,
    LinkedinShareButton,
    InstapaperShareButton,
} from "react-share";

import Facebook from "../../../images/SocialIcons/Facebook.svg";
import Instagram from "../../../images/SocialIcons/Instagram.svg";
import LinkedIn from "../../../images/SocialIcons/LinkedIn.svg";
import { Heading } from "../../Global/Typography";
import "./Share.scss";

export default function ShareButton({ inView, shareUrl }) {
    // const shareUrl = "https://modernspaaces.com/";
    const title = "ModernSpaaces Blog";

    return (
        <>
            <Heading classList="m-0" variant="h3" text="Share : " />
            <div
                className="shareLinkBlockList d-flex flex-row"
                style={{ gap: "1rem" }}
            >
                <LinkedinShareButton url={shareUrl} quote={title}>
                    <img src={LinkedIn} alt="LinkedIn" title="LinkedIn" />
                </LinkedinShareButton>
                <FacebookShareButton url={shareUrl} quote={title}>
                    <img src={Facebook} alt="Facebook" title="Facebook" />
                </FacebookShareButton>
                {/* <InstapaperShareButton
                    url={shareUrl}
                    quote={title}
                    className="-share-link"
                > */}
                <a href="https://www.instagram.com/modernspaaces/">
                    <img src={Instagram} alt="Instagram" title="Instagram" />
                </a>
                {/* </InstapaperShareButton> */}
            </div>
        </>
    );
}
