import { useState } from "react";
import { InView } from "react-intersection-observer";
// RelatedArticles
import ShareBlogOne from "./../Share/ShareBlogOne.js";
import Banner from "./Banner";
export default function BlogOne() {
    const [inViewBanner, setInViewBanner] = useState(false);
    // const [inViewRelatedArticles, setInViewRelatedArticles] = useState(false);
    const [inViewShareBlogOne, setInViewShareBlogOne] = useState(false);
    return (
        <>
            <InView
                className="bgPattern"
                onChange={setInViewShareBlogOne}
                triggerOnce={true}
            >
                <ShareBlogOne
                    shareUrl={
                        "https://modernspaaces.com/blogs/list-of-amenities-to-consider-buying-home"
                    }
                    inView={inViewShareBlogOne}
                ></ShareBlogOne>
            </InView>
            <InView className="" onChange={setInViewBanner} triggerOnce={true}>
                <Banner inView={inViewBanner}></Banner>
            </InView>
        </>
    );
}
