import { useState } from "react";
import { InView } from "react-intersection-observer";
import { ContactUs } from "../components/SoulTree";
import { Footer, FooterLogo } from "../components/index";
import StoryOfGrowth from "./StoryOfGrowth";
import Blogs from "./blogs/blogs";
import Hero from "./hero/hero";
import Showcase from "./showcase/showcase";
import { Helmet } from "react-helmet-async";

// import GetInTouch from "./getintouch/getintouch";

export default function Home() {
  const [inViewShowcase, setInViewShowcase] = useState(false);
  const [inViewTestimonials, setInViewTestimonials] = useState(false);
  const [inViewHero, setInViewHero] = useState(false);
  const [inViewFeatures, setInViewFeatures] = useState(false);
  const [inViewVisit, setInViewVisit] = useState(false);
  const [inViewFooter, setInViewFooter] = useState(false);
  const [inViewBlogs, setInViewBlogs] = useState(false);
  const [inViewStoryOfGrowth, setInViewStoryOfGrowth] = useState(false);
  const [inViewContactUs, setInViewContactUs] = useState(false);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Modern Spaaces - Real Estate Builders and Developers</title>
        <meta
          name="description"
          content="Crafting homes with fervor, Modern Spaaces prioritizes buyer satisfaction and wellness in every thoughtfully designed project. Integrating technology and nature seamlessly into our design.
"
        />
      </Helmet>

      <InView onChange={setInViewHero} triggerOnce={true}>
        <Hero inView={inViewHero}></Hero>
      </InView>
      <InView onChange={setInViewStoryOfGrowth} triggerOnce={true}>
        <StoryOfGrowth inView={inViewStoryOfGrowth}></StoryOfGrowth>
      </InView>

      <InView onChange={setInViewShowcase} triggerOnce={true}>
        <Showcase inView={inViewShowcase}></Showcase>
      </InView>
      {/* <InView onChange={setInViewFeatures} triggerOnce={true}>
        <Features inView={inViewFeatures}></Features>
      </InView> */}
      <InView onChange={setInViewBlogs} triggerOnce={true}>
        <Blogs inView={inViewBlogs}></Blogs>
      </InView>
      {/* <InView onChange={setInViewTestimonials} triggerOnce={true}>
                <Testimonials inView={inViewTestimonials}></Testimonials>
            </InView> */}
      {/* <InView onChange={setInViewVisit} triggerOnce={true}>
        <Visit inView={inViewVisit}></Visit>
      </InView> */}

      <InView
        className="bgPattern bgPattern--HrGrassPattern"
        onChange={setInViewContactUs}
        triggerOnce={true}
      >
        <ContactUs inView={inViewContactUs}></ContactUs>
      </InView>

      <FooterLogo />
      <InView
        onChange={setInViewFooter}
        triggerOnce={true}
        style={{ overflow: "visible" }}
      >
        <Footer inView={inViewFooter}></Footer>
      </InView>
      {/* <GetInTouch /> */}
    </>
  );
}
