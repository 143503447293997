import Certificate from "../../src/images/Blogs/certificateOC.png"
import OCAndCC from "../../src/images/Blogs/DiffOC&CC.png"



const data = [
  {
    id: "abc104",
    slug: "certificate-of-occupancy-simple-guide-to-what-it-means",
    image: Certificate,
    image2:OCAndCC,
   
    label: "Industry",
    title: "Certificate of Occupancy - A Simple Guide to What it means",
    publishedOn: "Dec, 2021",
    time: "2 min read",
    description:
      "Experience the future with smart homes! Discover how smart home technology enhances convenience, security, and energy efficiency.Lets find out more about it in this blog.",
    authorImage:
      "https://images.unsplash.com/photo-1600188769099-d25b4ec79659?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    authorName: "Noveen Reddy",
    department: "Marketing Head",
    contactNumber: "987654321",
    // imageOther: "https://modernspaaces.com/BlogListImages/BlogFour/Img01.webp",
    description11:"The Real Estate Regulatory Act (RERA) of 2016 was formulated to uphold and protect the rights of the homebuyers, additionally, it provides the builders with a framework and protocol to help create and maintain a standard in the construction industry.",
    description12:"One of its key provisions made it compulsory to obtain the OC (Occupancy Certificate) to ensure the rights and interests of the homebuyers are met. This is through maintaining a stringent inspection post-construction to ensure the safety and quality of the building.",
    
    title2:"What is a certificate of occupancy?:",
    description21:"A Certificate of Occupancy (CO) or Occupation Certificate is like a stamp of approval from the local government for newly built structures. It's a confirmation that the building meets all the safety standards and rules set by the authorities, making it safe for people to live or work in.",
    description22:"This certificate also ensures that the building has access to essential facilities like water, electricity, and sanitation. Homeowners can only get this Occupancy Certificate after the Completion Certificate is issued, giving them the green light to move in.",

    title3:"How to get a certificate of Occupancy  (OC)?:",
    description31:"Once the building is all set and constructed, the builder, promoter, or developer needs to apply for the Occupancy Certificate (OC) through the RERA website.",
    description32:"The OC is generally granted within 30 days of form submission. The builder has to upload the following documents:",
    list3:[
     "Certificate of Project Commencement",
     "Certificate of Project Completion",
     "Fire and pollution Certificates",
     "No Objection Certificate (NOC)",
     "Construction and Approval Plans",
     "A document detailing the floor area, certified by an authorized architect.",
     "A photograph of the completed structure along with a copy of the approved building plan.",
     "Photos demonstrating rainwater harvesting and solar panels.",
     "Tax assessment documents and the most recent property tax receipt."
    ],
    conclusion31:"A homeowner having access to the plan layout can also apply for obtaining OC after submitting the relevant documents.",


    title4:"What are the parameters checked before issuing an Occupancy Certificate?:",
    description4:"An occupancy certificate is issued only when the following protocols are followed:",
    list4:[
      "Compliance with the legally approved construction plans.",
      "Building codes and regulations are followed throughout the construction process.",
      "Drainage, electricity, and sanitation facilities should be present.",
      "NOC, other clearances from the appointed authority including the fire department."
    ],

    title5:"What is the difference between OC and CC ?:",

    title6:"What does an OC mean for the Homeowner?:",
    description6:"A house/ apartment should have an OC because - ",
    list6:[
      "OC implies the safety of the project and its adherence to the standard protocols.",
      "It is illegal to sell a property without an OC because it is considered to be an unauthorized structure.",
      "Without an OC you are not the legal owner of the property.",
      "In the absence of an OC the legal authorities can initiate legal action against the homeowner.",
      "Possession of OC makes it easier to obtain a home loan."
    ],

    title7:"What  if my property is under BMRDA or Panchayat?:",
    description7:"To obtain an Occupancy Certificate (OC) for properties falling under BMRDA or Panchayat jurisdictions, the local governing body overseeing the region manages the process. This involves thorough inspections by the relevant authority to verify adherence to approved plans, compliance with building regulations, and assurance of safety standards throughout the construction. Only upon satisfying these criteria will the authority issue the OC, affirming the property's suitability for occupancy.",

    title8:"What legal action can be taken if the builder fails to provide an OC?:",
    description81:"In accordance with Section 11(4)(b) of The Real Estate (Regulation and Development) Act, 2016, it is the duty of the promoter to obtain the Occupancy Certificate (OC) from the appropriate authority and ensure its availability to the buyers.",
    description82:"If a builder fails to provide an OC despite repeated requests, homeowners can take the following legal actions:",
    list8:[
      {
        label:"File a Legal Complaint",
        description:"According to the Real Estate Regulatory Authority (RERA), you can file a legal complaint against the builder or developer for his inability to provide the OC."
      },
      {
        label:"File an RTI (Right to Information)",
        description:" You can file an RTI application to understand the reasons for the delay in the project and know the status of the OC."
      },
      {
        label:"Serve a Legal Notice",
        description:"Serving a legal notice to the builder can put additional pressure on them to provide the OC."
      },
      {
        label:"Claim Compensation",
        description:"RERA has specified compensation from the builder in cases of delays and non-compliance."
      },
    ],
  conclusion:"Conclusion:",
  conclusion1:"To sum it up, a Certificate of Occupancy (OC) stands as a crucial document in property ownership, serving as official confirmation that a newly built structure complies with established legal and safety regulations. This certification is obtained after thorough inspections by local authorities, ensuring that the building adheres to zoning ordinances, building codes, and safety standards.",
  conclusion2:"It not only validates the completion of construction but also verifies that the property is suitable for occupancy based on its intended use. The OC  guarantees that the building is safe and habitable, providing assurance to prospective buyers or tenants regarding the structure's compliance with essential regulations and the overall safety of the constructed premises.",

   
    
  },
];

export default data;
