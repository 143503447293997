import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ModalVideo from "react-modal-video";
import { Desc, Heading, AnchorTag } from "../../Global/Typography";
// import TheHeartAndSoulImage from "../../../images/SoulTree/TheHeartAndSoulImage.png";
import VideoIconImage from "../../../images/SoulTree/VideoIconImage.svg";
import "./TheHeartAndSoul.scss";

const TheHeartAndSoulImage = "/images/SoulTree/soul-tree-sarjapur-video-banner.png"

export default function TheHeartAndSoul({ inView }) {
  const [isOpen, setOpen] = useState(false);
  return (
    <section
      className={`section my-md-5 py-md-5 ${inView ? "inView" : "outView"}`}
    >
      <Container className="text-left TheHeartAndSoul">
        <Row>
          <Col xs={12} md={5} lg={5} xl={4}>
            <div className="TheHeartAndSoul__content-block">
              <Heading
                variant="h1"
                classList="TheHeartAndSoul__title"
                text="The Heart and the Soul"
              />
              <Desc
                classList="TheHeartAndSoul__desc my-3 "
                text="A work of art inside and out. At Modern Spaaces, the passion for design transcends architecture and is reflected in the utmost care in the selection of equipment and materials. Form, function and robustness, in perfect harmony."
              />
              <AnchorTag
                href="#"
                classList="TheHeartAndSoul__link mt-4"
                text="View the walkthrough"
                onClick={() => setOpen(true)}
              />
            </div>
          </Col>
        </Row>
        <div className="TheHeartAndSoul__image-block">
          <ModalVideo
            channel="youtube"
            autoPlay
            isOpen={isOpen}
            // videoId="L61p2uyiMSo"
            // videoId="hClGN2CwSnA"
            videoId="KQclIldMmw0"
            onClose={() => setOpen(false)}
          />
          <img
            src={TheHeartAndSoulImage}
            className="TheHeartAndSoul__image"
            alt="Soul Tree in Sompura Gate, Sarjapur"
          />
          <div className="videoIconImage" onClick={() => setOpen(true)}>
            <img
              src={VideoIconImage}
              className="TheHeartAndSoul__videoIcon"
              alt="TheHeartAndSoul"
            />
          </div>
        </div>
      </Container>
    </section>
  );
}
