import { Col, Container, Row } from "react-bootstrap";
import DownloadBrochure from "../../Global/DownloadBrochure";
import { AnchorButton, Desc, Heading } from "../../Global/Typography";
import "./ProjectAtGlance.scss";
import { useState } from "react";
export default function ProjectAtGlance({ inView }) {
  const [show, setShow] = useState(false);
  const handleShow = (e) => {
    e.preventDefault();
    setShow(true);
  };
  const handleClose = () => setShow(false);

  const dataset = {
    subject: "Project77 Brochure",
    fileName: "Urbanest-77East-Phase02-Brochure.pdf",
    filePath: "./documents/Urbanest-77East-Phase02-Brochure.pdf",
  };
  // ProjectAtGlanceListitems
  const ProjectAtGlanceListitems = [
    {
      info: "60 Lacs",
      desc: "Onwards",
    },
    {
      info: "3 BHK",
      desc: "1410- 1445 sq ft. | 48 units",
    },
    {
      info: "50+ Amenities",
      desc: "Indoor & Outdoor",
    },
    {
      info: "Home Loan",
      desc: "5 Preapproved Banks",
    },
    {
      info: "RERA Approved",
      desc: "RERA number available for all blocks",
    },
    {
      info: "Sompura Gate",
      desc: "Sarjapur Road",
    },
    {
      info: "Legal Compliant",
      desc: "BMRDA Approved, CC Received & OC Compliant",
    },
    {
      info: "Possession by",
      desc: "Apr 2022",
    },
  ];
  return (
    <section className={`section ${inView ? "inView" : "outView"}`}>
      <Container className="text-left">
        <Row>
          <Col xs={12} lg={10} className="">
            <Heading
              // classList="my-4 my-md-4 pr-5 pr-md-3"
              classList="my-4 my-md-5 pb-md-4 pr-5 pr-md-3 "
              variant="h1"
              text="Project at glance"
            />
            {/* <Desc
              classList="mb-3 pb-3 mb-md-5 pb-md-5"
              text="Built with keeping the comfort, design and aesthetic as prime focus, 77 East is a small community living in premium spaces. The successful handover of 77 East Phase 1 speaks volumes about the project and how the new residents have shown love to their new home. "
            /> */}
          </Col>
        </Row>
        <Row>
          {ProjectAtGlanceListitems.map(({ info, desc }, idx) => {
            return (
              <Col
                className="mb-4 pb-4 mb-md-5 pb-md-5 ProjectAtGlanceList"
                xs={12}
                md={6}
                lg={4}
                key={idx}
              >
                <Heading
                  classList="ProjectAtGlance__info"
                  variant="h4"
                  text={info}
                />
                <Desc classList="ProjectAtGlance__desc mb-0" text={desc} />
              </Col>
            );
          })}
        </Row>
        <Row>
          <Col>
            <AnchorButton
              href="#"
              classList="-btn mt-3 text-orange"
              text="Download Brochure"
              onClick={handleShow}
            />
          </Col>
        </Row>
      </Container>

      {/* DownloadBrochure */}
      <DownloadBrochure
        title={"Download Brochure"}
        show={show}
        handleClose={handleClose}
        content={dataset}
      />
    </section>
  );
}
