import { Col, Container, Row } from "react-bootstrap";
// AnchorButtonIcon
import { Desc, Heading } from "../../Global/Typography";
// import SchoolsImage from "../../../images/SoulTree/Schools/SchoolsImage.png";
import "./Schools.scss";

const SchoolsImage = "/images/Soulace/Schools/education-centers-banner.png";

export default function Schools({ inView }) {
    const SchoolsData = [
        {
            title: "What about my children’s education?",
            // desc: "Hey! The best of the best are just 5 to 15 mins away. You are investing into a neighbourhood which is the next HSR in the making. Upcoming tech parks, major residential townships from top builders, shopping malls, entertainment and eateries make it an ideal investment for real estate appreciation.",
            desc: (
                <div>
                    Serene Heights is located within 3 Kms radius of Bangalore's{" "}
                    <b>premier schools</b> and <b>colleges</b>, ensuring access
                    to quality education without the hassle of long commutes.
                </div>
            ),
            // button: "View on map",
            image: SchoolsImage,
            listItems: [
                <>
                    ISME college <strong>2.5 Kms</strong>
                </>,
                <>
                    Oakridge International School <strong>2 Kms</strong>
                </>,

                <>
                    Greenwood High <strong>4 Kms</strong>
                </>,
                <>
                    Shlok International School <strong>4 Kms</strong>
                </>,
                <>
                    TISB <strong>3 Kms</strong>
                    <div style={{ fontSize: "0.75rem", lineHeight: "150%" }}>
                        (The International School Bangalore)
                    </div>{" "}
                </>,
                <>
                    Inventure Academy <strong>3 Kms</strong>
                </>,
                <>
                    Global International School <strong>5 Kms</strong>
                </>,
                <>
                    Azim Premji University <strong>4 Kms</strong>
                </>,
            ],
        },
    ];

    return (
        <section
            className={`section ${inView ? "inView" : "outView"}
    `}
        >
            {SchoolsData.map((item, idx) => {
                return (
                    <Container key={idx} className="Schools">
                        <Row className="align-items-center">
                            <Col
                                xs={12}
                                md={6}
                                lg={6}
                                xl={5}
                                className="pr-md-5"
                            >
                                <div className="imagePatch">
                                    <img
                                        src={item.image}
                                        className="Schools__image w-100 mb-4 mb-md-5"
                                        alt="Education Centers in Sarjapur"
                                    />
                                </div>
                            </Col>
                            <Col
                                xs={12}
                                md={6}
                                lg={6}
                                xl={7}
                                className="px-md-5"
                            >
                                <Heading
                                    variant="h1"
                                    classList="Schools__title"
                                    text={item.title}
                                />
                                <Desc
                                    classList="Schools__desc my-3 "
                                    text={item.desc}
                                />
                                <ul className="TranquilCourtList">
                                    {item.listItems.map((i, j) => (
                                        <li key={j}>
                                            <Desc classList="my-1" text={i} />
                                        </li>
                                    ))}
                                </ul>
                                {/* <AnchorButtonIcon
                  // RECORD AUDIO BASED CHANGE
                  target="_blank"
                  href="https://www.google.com/maps/place/Soul+Tree+by+Modern+Spaaces/@12.86056,77.7643898,17z/data=!3m1!4b1!4m5!3m4!1s0x3bae738c023db8b3:0x1c0e1c2d5d103375!8m2!3d12.8605548!4d77.7665785"
                  // RECORD AUDIO BASED CHANGE
                  classList="Schools__btn my-3"
                  text={item.button}
                  icon={true}
                /> */}
                            </Col>
                        </Row>
                    </Container>
                );
            })}
        </section>
    );
}
