import "./contact-form.scss";
// import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function ContactForm({ inView }) {
  return (
    <section
      className={`section section-contact-form ${
        inView ? "inView" : "outView"
      }`}
    >
      <div className="content d-flex position-relative align-items-center">
        <div className="thank-you d-md-flex d-none"></div>
        <div className="form-container p-5 d-inline-block bg-white">
          {/* ms-thank-you-block */}
          <div className="my-4 my-md-5 ms-thank-you-block">
            <div className="heading fc-black mb-2">Thank You</div>
            <p className="fc-black my-4 summary mb-2">
              Thanks for getting in touch. We’ll be in contact soon!
            </p>
            <Link to="/">
              <button className="app-btn--solid--primary my-2">
                <span className="app-btn-text">Back to Home</span>
              </button>
            </Link>
          </div>
          {/* ms-thank-you-block */}
        </div>
      </div>
    </section>
  );
}
