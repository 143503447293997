export const StoryStats = [
  {
    stat: "01",
    unit: "Million Sq. Ft.",
    info: "Developed",
  },
  {
    stat: "03",
    unit: "Million Sq. Ft.",
    info: "Ongoing",
  },
  {
    stat: "15+",
    unit: "Million Sq. Ft.",
    info: "Upcoming",
  },
];
