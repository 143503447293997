import "./Specifications.scss";
import React, { useState } from "react";
import {
    Row,
    Container,
    Accordion,
    Card,
    Col,
    useAccordionToggle,
} from "react-bootstrap";
import { Desc, Heading, DescSmall } from "../../Global/Typography";
import Minus from "../../../images/Common/MinusWhite.svg";
import Plus from "../../../images/Common/PlusWhite.svg";

function CustomToggle({ children, eventKey, handleClick }) {
    const decoratedOnClick = useAccordionToggle(eventKey, () => {
        handleClick();
    });

    return (
        <div className="cardHeader" onClick={decoratedOnClick}>
            {children}
        </div>
    );
}
export default function Specifications({ inView }) {
    const [activeKey, setActiveKey] = useState(0);

    const SpecificationsItems = [
        {
            title: "Architecture",
            list: [
                {
                    label: "DOORS & WINDOWS",
                    desc: "",
                    items: [
                        "Main Door: 8ft High Engineered Door Frame with Engineered Wooden Flush Doors, Veneer Finish, and ISI Quality Hardware with a Smart Lock.",
                        "Internal Doors: Engineered Door Frame with Engineered Wooden Flush Doors, Veneer Finish, and ISI Quality Hardware.",
                        "Windows: UPVC/Aluminium Frames and Sliding Shutters with clear Glass & Mosquito Mesh.",
                        "Shaft Access Door from Balcony: MS frame with expanded Metal Mesh Door.",
                        "Balcony Access: UPVC/Aluminium sliding doors with provision for mosquito mesh shutter.",
                        "Kitchen Window: Sliding UPVC/Aluminium with exhaust fan provision & without mesh.",
                    ],
                },
                {
                    label: "WALL FINISHES",
                    desc: "",
                    items: [
                        "Internal walls: Smooth putty with emulsion paint.",
                        "Toilets: GVT tiles up to false ceiling.",
                        "External Walls: Texture finish with external grade weatherproof paint.",
                        "Balcony/Terraces: External grade weatherproof paint.",
                    ],
                },
                {
                    label: "CEILING",
                    desc: "",
                    items: [
                        "All internal ceiling: Smooth putty with emulsion paint.",
                        "Toilets: Modular grid false ceiling & white wash above false ceiling.",
                        "Balcony: Painted with weatherproof exterior paint.",
                    ],
                },
                {
                    label: "FLOORING",
                    desc: "",
                    items: [
                        "All Corridors and Lobbies: Anti-skid Vitrified Tiles.",
                        "Living/Dining, Bedroom & Kitchen: Vitrified Tiles & Granite Threshold for main door.",
                        "Toilets, Utilities & Balconies: Anti-skid Ceramic Tiles.",
                    ],
                },
            ],
        },
        {
            title: "Electrical & Plumbing",
            list: [
                {
                    label: "ELECTRIC WORKS",
                    desc: "",
                    items: [
                        "EB Power: 1.5 BHK-3KW, 2BHK+2.5BHK- 4KW, 3BHK- 5KW single phase power will be provided.",
                        "DG Power Backup: 1KW DG backup for individual units and 100% for common area lights.",
                        "AC: Provision and drain outlet for AC in all bedrooms and living areas.",
                        "Ethernet: One point provided in the living room (Near TV). One more point at a centralised location.",
                        "TV: Wired TV connections for living room. Provision for smart TV in all bedrooms.",
                        "Switches: Polycab/Schneider/Legerand/Equivalent.",
                        "Water Heater: Provision in all toilets & utility. Concealed copper wiring of reputed make.",
                        "Kitchen Power: For Chimney, Hob, Refrigerator, Microwave oven, Mixer/Grinder in the kitchen.",
                    ],
                },
                {
                    label: "ELEVATORS",
                    desc: "Mitsubishi/KONE/Fujitsu/Equivalent elevator with automatic rescue device.",
                    items: [],
                },
                {
                    label: "ALL BATHROOMS",
                    desc: "",
                    items: [
                        "EWC: White coloured wall mounted EWC with seat cover, concealed, flush valve, health faucet (Toto/Equivalent).",
                        "Wash Basin: Counter top wash basin (white color) with faucet.",
                        "Shower: Shower with single lever diverter.",
                    ],
                },
                {
                    label: "UTILITY",
                    desc: "Plumbing lines provision for Water Purifier, Washing Machine, Dishwasher & Instant Geyser.",
                    items: [],
                },
            ],
        },
        {
            title: "Other Services",
            list: [
                {
                    label: "CCTV",
                    desc: "",
                    items: [
                        "Provided in all Entry, Exit, Periphery & Lobby.",
                        "Provision in all lobby and corridors.",
                    ],
                },
                {
                    label: "EV CHARGING & CAR WASH",
                    desc: "",
                    items: [
                        "Car Charging Points: Power outlet provision for EV charging at dedicated parking spaces.",
                        "Car Wash Facility: Car wash point provided at a dedicated space with plumbing points (water inlet and water outlet).",
                    ],
                },
                {
                    label: "FIRE AND SAFETY",
                    desc: "",
                    items: [
                        "Fire systems will be provided as per the fire norms.",
                        "Sprinklers for all parking spaces & Driveways.",
                        "Hydrants provided at every floor.",
                    ],
                },
                {
                    label: "GAS BANK",
                    desc: "Gas bank with reticulated piped gas system to individual apartment, monitored with gas meters from any private agency.",
                    items: [],
                },
            ],
        },
    ];

    return (
        <section
            className={`section  ${inView ? "inView" : "outView"}
  `}
        >
            {/* <Container> */}
            <Container className="Specifications Specifications--black">
                <Row>
                    <Col xs={12} lg={6} className="VerticalDivider">
                        <Heading
                            variant="h1"
                            classList="-title"
                            text="Specifications"
                        />
                        <Desc
                            classList="-desc my-3 "
                            text="The finer details."
                        />
                    </Col>
                    <Col xs={12}>
                        <Accordion className="Accordion" defaultActiveKey={1}>
                            {SpecificationsItems.map((item, index) => (
                                <Card key={index}>
                                    <CustomToggle
                                        className="AccordionHeading"
                                        as={Card.Header}
                                        eventKey={index + 1}
                                        handleClick={() => {
                                            if (activeKey === index) {
                                                setActiveKey(null);
                                            } else {
                                                setActiveKey(index);
                                            }
                                        }}
                                    >
                                        <Heading
                                            variant="h4"
                                            classList="-title my-0 mr-5 pr-5"
                                            text={item.title}
                                        />
                                        <img
                                            className="PlusMinus"
                                            src={
                                                activeKey === index
                                                    ? Minus
                                                    : Plus
                                            }
                                            alt={"Minus Plus"}
                                        />
                                    </CustomToggle>
                                    <Accordion.Collapse eventKey={index + 1}>
                                        <Card.Body className="">
                                            <ul className="Accordion__list px-3">
                                                {item.list.map(
                                                    (
                                                        { label, desc, items },
                                                        i0
                                                    ) => {
                                                        return (
                                                            <div key={i0}>
                                                                <Heading
                                                                    variant="h7"
                                                                    // classList="-desc"
                                                                    text={label}
                                                                />

                                                                <li className="mb-2">
                                                                    {desc ? (
                                                                        <DescSmall
                                                                            classList="my-2"
                                                                            text={
                                                                                desc
                                                                            }
                                                                        />
                                                                    ) : null}

                                                                    {items
                                                                        ? items.map(
                                                                              (
                                                                                  it,
                                                                                  i1
                                                                              ) => {
                                                                                  return (
                                                                                      <DescSmall
                                                                                          key={
                                                                                              i1
                                                                                          }
                                                                                          classList="mt-2 mb-0"
                                                                                          text={
                                                                                              it
                                                                                          }
                                                                                      />
                                                                                  );
                                                                              }
                                                                          )
                                                                        : null}
                                                                </li>
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </ul>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            ))}
                        </Accordion>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}
