import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./BlogCard.scss";
const BlogCard = ({ blogData, variant = "dark" }) => {
    return (
        <Card
            className={`blog fc-white mx-0 mb-4 p-2 ${
                variant === "dark" ? "bg-dark-4" : "bg-white"
            } `}
        >
            <Link
                className="text-white d-flex flex-column"
                to={`/blogs/${blogData.slug}`}
            >
                <div className="img-wrapper">
                    <Card.Img className="blog-card-img" variant="top" src={blogData.image}  alt={blogData.imgAlt}/>
                    {/* <div className="label">{blogData.label}</div> */}
                </div>
                <Card.Body style={{ flex: "1 auto" }}>
                    <Card.Title className="name  mb-2">
                        {blogData.title}
                    </Card.Title>
                    <Card.Text className="desc">{blogData.desc}</Card.Text>
                </Card.Body>
                <Card.Footer className="pb-3 px-2">
                    <div className="separator mb-3"></div>
                    <small className="date float-left">{blogData.date}</small>
                    <small className="time float-right">{blogData.time}</small>
                </Card.Footer>
            </Link>
        </Card>
    );
};

export default BlogCard;
