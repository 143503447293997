import "./Specifications.scss";
import React, { useState } from "react";
import {
  Row,
  Container,
  Accordion,
  Card,
  Col,
  useAccordionToggle,
} from "react-bootstrap";
import { Desc, Heading, DescSmall } from "../../Global/Typography";
import Minus from "../../../images/Common/MinusWhite.svg";
import Plus from "../../../images/Common/PlusWhite.svg";

function CustomToggle({ children, eventKey, handleClick }) {
  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    handleClick();
  });

  return (
    <div className="cardHeader" onClick={decoratedOnClick}>
      {children}
    </div>
  );
}
export default function Specifications({ inView }) {
  const [activeKey, setActiveKey] = useState(0);

  const SpecificationsItems = [
    {
      title: "Civil",
      list: [
        {
          label: "Structure",
          desc: "R.C.C framed structure, designed as per relevant BIS codes for earthquake resistance and structurally efficient systems implemented.",
          items: [],
        },
        {
          label: "Walls",
          desc: 'External walls with 6" solid concrete blocks and internal walls with 4`` solid concrete blocks',
          items: [],
        },
        {
          label: "Plastering",
          desc: "",
          items: [
            "Sponge finish for external walls",
            "Smooth sponge finish for internal walls",
          ],
        },
      ],
    },
    {
      title: "Architecture",
      list: [
        {
          label: "Doors ",
          desc: "",
          items: [
            "Main Door - Engineered wooden door. full width jamb (with architrave)",
            "Main Door fitted with Fueb Smart Digital Door Lock",
            "Internal doors - Engineered wooden doors with laminate finish",
          ],
        },
        {
          label: "Windows",
          desc: "UPVC sliding windows with mosquito mesh and MS safety grill",
          items: [],
        },
        {
          label: "Flooring ",
          desc: "",
          items: [
            "Living, kitchen and dining",
            "bedrooms : double charged vitrified tiles",
          ],
        },
        {
          label: "Bathroom ",
          desc: "",
          items: [
            "Floor : Anti Skid ceramic tiles flooring",
            "Walls : Glazed ceramic tiles",
            "False ceiling : Grid false ceiling with PVC coated tiles and shadow channels",
          ],
        },
        {
          label: "Paint ",
          desc: "",
          items: [
            "External walls : Exterior grade emulsion",
            "Internal Walls : Emulsion over wall care putty",
          ],
        },
      ],
    },
    {
      title: "Plumbing",
      list: [
        {
          label: "CP & Sanitary Fittings & Fixtures",
          desc: "",
          items: [
            "Grohe or jaguar or equivalent make ISI CP and sanitary fittings",
            "TOTO or equivalent soft close seat cover EWC",
            "TOTO or equivalent monolithic one piece sink",
          ],
        },
      
       
      ],
    },
    {
      title: "Electrical & Services",
      list: [
       
      
        {
          label: "Electrical",
          desc: "",
          items: [
            "Anchor or equivalent",
            "Fire resistant anchor or polycab or equivalent wires",
          ],
        },
        {
          label: "Electrical Points",
          desc: "",
          items: [
            "Provision of electrical points for refrigerator, chimney and washing machine",
            "TV & Telephone point in the living room",
            "Electrical point for geyser & exhaust fan in toilets",
            "AC point for master bedrooms",
          ],
        },
        {
          label: "Grid Power and Back-up",
          desc: "",
          items: [
            "EB power : 3 KW",
            "DG power backup : 1 KW",
            "Common areas : 100% backup for lighting, lifts and utilities",
          ],
        },
        {
          label: "Elevator",
          desc: "6/8 Passenger capacity elevator of reputed make.",
          items: [],
        },
      ],
    },
  ];
  // {
  //   title: "Architechture",
  //   list: [
  //     "Architechture - Structure: R.C.C framed structure, designed as per relevant BIS codes for earth quake resistance and structurally efficient systems implemented",
  //     "External walls with 6’’ solid concrete blocks and internal walls with 4’’ solid concrete blocks Plastering",
  //     "Plastering: Sponge finish for external walls, smooth",
  //   ],
  // },
  // {
  //   title: "Electrical",
  //   list: [
  //     "Electrical - Structure: R.C.C framed structure, designed as per relevant BIS codes for earth quake resistance and structurally efficient systems implemented",
  //     "External walls with 6’’ solid concrete blocks and internal walls with 4’’ solid concrete blocks Plastering",
  //     "Plastering: Sponge finish for external walls, smooth",
  //   ],
  // },
  // {
  //   title: "Plumbing, Painting & Services",
  //   list: [
  //     "Plumbing - Structure: R.C.C framed structure, designed as per relevant BIS codes for earth quake resistance and structurally efficient systems implemented",
  //     "External walls with 6’’ solid concrete blocks and internal walls with 4’’ solid concrete blocks Plastering",
  //     "Plastering: Sponge finish for external walls, smooth",
  //   ],
  // },

  return (
    <section
      className={`section  ${inView ? "inView" : "outView"}
  `}
    >
      {/* <Container> */}
      <Container className="Specifications Specifications--black">
        <Row>
          <Col xs={12} lg={6} className="VerticalDivider">
            <Heading variant="h1" classList="-title" text="Specifications" />
            <Desc
              classList="-desc my-3 "
              text="How we build the most affordable yet valuable homes in the heart of Bengaluru"
            />
          </Col>
          <Col xs={12}>
            <Accordion className="Accordion" defaultActiveKey={1}>
              {SpecificationsItems.map((item, index) => (
                <Card key={index}>
                  <CustomToggle
                    className="AccordionHeading"
                    as={Card.Header}
                    eventKey={index + 1}
                    handleClick={() => {
                      if (activeKey === index) {
                        setActiveKey(null);
                      } else {
                        setActiveKey(index);
                      }
                    }}
                  >
                    <Heading
                      variant="h4"
                      classList="-title my-0 mr-5 pr-5"
                      text={item.title}
                    />
                    <img
                      className="PlusMinus"
                      src={activeKey === index ? Minus : Plus}
                      alt={"Minus Plus"}
                    />
                  </CustomToggle>
                  <Accordion.Collapse eventKey={index + 1}>
                    <Card.Body className="">
                      <ul className="Accordion__list px-3">
                        {item.list.map(({ label, desc, items }, i0) => {
                          return (
                            <div key={i0}>
                              <Heading
                                variant="h7"
                                // classList="-desc"
                                text={label}
                              />

                              <li className="mb-2">
                                {desc ? (
                                  <DescSmall classList="my-2" text={desc} />
                                ) : null}

                                {items
                                  ? items.map((it, i1) => {
                                      return (
                                        <DescSmall
                                          key={i1}
                                          classList="mt-2 mb-0"
                                          text={it}
                                        />
                                      );
                                    })
                                  : null}
                              </li>
                            </div>
                          );
                        })}
                      </ul>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              ))}
            </Accordion>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
