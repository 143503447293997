import { Col, Container, Row } from "react-bootstrap";
import { Desc, DescLabel } from "../../../Global/Typography";
import BannerNew from "../../BannerNew";
import "../../BlogMivanTechnology/Banner/BlogMivan.scss";
import { Helmet } from "react-helmet-async";

import BlogMivanTechnologyData from "../../../../db/BlogMivanTechnologyData.jsx";

import ShareButton from "../../Share/ShareButton";

export default function Banner({ inView }) {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>
          Revolutionizing Construction: Exploring the Mivan Technology | Modern
          Spaaces
        </title>
        <meta
          name="description"
          content="Exploring the technology of Mivan and its impact on construction. Learn more about the technology through a detailed analysis and its application by Modern Spaaces.
"
        />
        <meta
          name="Keywords"
          content="Mivan Technology, What is Mivan Technology,Mivan construction technology, Mivan formwork"
        />
        <link
          rel="canonical"
          href="https://modernspaaces.com/blogs/exploring-the-mivan-technology"
        />
      </Helmet>

      <section className={`section mivan ${inView ? "inView" : "outView"}`}>
        <BannerNew
          image={BlogMivanTechnologyData.image}
          title={BlogMivanTechnologyData.title}
          publishedOn={BlogMivanTechnologyData.publishedOn}
          time={BlogMivanTechnologyData.time}
          alt={BlogMivanTechnologyData.imgAlt}
        />

        <Container className="blog-detail-container">
          <Row
            key={BlogMivanTechnologyData.id}
            className="justify-content-around"
          >
            <Col xs={12} md={11} lg={9} className="pb-4 pr-md-5">
              <div className="blog-header ">
                <p className="font-benton-book header">
                  {BlogMivanTechnologyData.description}
                </p>
                <p className="font-benton-book header">
                  {BlogMivanTechnologyData.lightDescription}
                </p>
                <p className="font-benton-book header">
                  {BlogMivanTechnologyData.lightDescription2}
                </p>
                <div className="blog-content ">
                  <p className="-descBlack font-benton-bold margin">
                    {BlogMivanTechnologyData.title1}
                  </p>
                  <div>
                    {BlogMivanTechnologyData.Mivandescription && (
                      <ul className="blog-list-circle  header margin">
                        {BlogMivanTechnologyData.Mivandescription.map(
                          (i9, idx11) => {
                            return (
                              <li key={idx11}>
                                <p className="-descBlack mb-1 font-benton-book header">
                                  {i9}
                                </p>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    )}
                  </div>

                  {BlogMivanTechnologyData.content &&
                    BlogMivanTechnologyData.content.map((i0, idx) => {
                      return (
                        <div key={idx}>
                          <DescLabel
                            classList="my-1 py-2 font-benton-book header "
                            text={i0.description}
                            label={i0.label}
                          />
                          {i0.list && (
                            <ul className="blog-list-circle  header">
                              {i0.list.map((i1, idx1) => {
                                return (
                                  <li key={idx1}>
                                    <p className="font-benton-book header">
                                      {i1}
                                    </p>
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                        </div>
                      );
                    })}
                </div>
              </div>
              {BlogMivanTechnologyData.title2 && (
                <p className="-descBlack font-benton-bold margin">
                  {BlogMivanTechnologyData.title2}
                </p>
              )}
              <div className="imagepadding">
                <img
                  src={BlogMivanTechnologyData.image2}
                  alt={BlogMivanTechnologyData.image2Alt}
                  class="image margin"
                />
              </div>

              {BlogMivanTechnologyData.ModularFrameworkdata && (
                <p className="-descBlack font-benton-book  header margin">
                  {BlogMivanTechnologyData.ModularFrameworkdata}
                </p>
              )}
              <div className="blog-content">
                {BlogMivanTechnologyData.ModularFramework &&
                  BlogMivanTechnologyData.ModularFramework.map((i0, idx) => {
                    return (
                      <div key={idx}>
                        <DescLabel
                          classList="my-1 py-2 font-benton-book header "
                          text={i0.description}
                          label={i0.label}
                        />
                        {i0.list && (
                          <ul className="blog-list-circle header">
                            {i0.list.map((i1, idx1) => {
                              return (
                                <li key={idx1}>
                                  <p className="-descBlack mb-1 font-benton-book header">
                                    {i1}
                                  </p>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </div>
                    );
                  })}
              </div>
              <div>
              <p className="-descBlack font-benton-bold margin">
                {BlogMivanTechnologyData.title4}
              </p>
              <p className="-descBlack font-benton-book  header margin">
                  {BlogMivanTechnologyData.description41}
                </p>
                <p className="-descBlack font-benton-book  header margin">
                  {BlogMivanTechnologyData.description42}
                </p>
                <div className="blog-content">
                {BlogMivanTechnologyData.list4 &&
                  BlogMivanTechnologyData.list4.map((i0, idx) => {
                    return (
                      <div key={idx}>
                        <DescLabel
                          classList="my-1 py-2 font-benton-book header"
                          text={i0.description}
                          label={i0.label}
                        />
                        {i0.list && (
                          <ul className="blog-list-circle">
                            {i0.list.map((i1, idx1) => {
                              return (
                                <li key={idx1}>
                                  <p className="-descBlack mb-1 font-benton-book ">
                                    {i1}
                                  </p>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </div>
                    );
                  })}
              </div>
              </div>

              <p className="-descBlack font-benton-bold margin">
                {BlogMivanTechnologyData.title3}
              </p>
              <div className="blog-content">
                {BlogMivanTechnologyData.Difference &&
                  BlogMivanTechnologyData.Difference.map((i0, idx) => {
                    return (
                      <div key={idx}>
                        <DescLabel
                          classList="my-1 py-2 font-benton-book header "
                          text={i0.description}
                          label={i0.label}
                        />
                        {i0.list && (
                          <ul className="blog-list-circle header">
                            {i0.list.map((i1, idx1) => {
                              return (
                                <li key={idx1}>
                                  <p className="-descBlack mb-1 font-benton-book header">
                                    {i1}
                                  </p>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </div>
                    );
                  })}
              </div>

<div className="table-wrapper">
    <div
        style={{
            fontSize: "12px", 
        }}
    >
        {BlogMivanTechnologyData.tableData.map((item, index) => (
            <div
                className={`mivan-table-top ${index === 0 ? 'first-row' : ''} ${index === BlogMivanTechnologyData.tableData.length - 1 ? 'last-row' : ''}`}
                style={{                                 
                  backgroundColor: index % 2 === 0 ? "#eeeeee" : "white", 
                }}
                key={index}
            >
                <div
                    className="font-benton-bold table-cell table-header"
                >
                    {item.heading}
                </div>
                <div
                    className="table-cell"
                >
                    {item.description1}
                </div>
                <div
                    className="table-cell"
                >
                    {item.description2}
                </div>
            </div>
        ))}
    </div>
</div>                   

              <p className="-descBlack font-benton-bold margin">
                {BlogMivanTechnologyData.Advantages}
              </p>
              <p className="-descBlack font-benton-book  header margin">
                  {BlogMivanTechnologyData.Advantages1}
                </p>
              <div className="blog-content">
                {BlogMivanTechnologyData.AdvantagesData &&
                  BlogMivanTechnologyData.AdvantagesData.map((i0, idx) => {
                    return (
                      <div key={idx}>
                        <DescLabel
                          classList="my-1 py-2 font-benton-book header"
                          text={i0.description}
                          label={i0.label}
                        />
                        {i0.list && (
                          <ul className="blog-list-circle">
                            {i0.list.map((i1, idx1) => {
                              return (
                                <li key={idx1}>
                                  <p className="-descBlack mb-1 font-benton-book ">
                                    {i1}
                                  </p>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </div>
                    );
                  })}
              </div>
              <p className="-descBlack font-benton-bold margin">
                {BlogMivanTechnologyData.Disadvantages}
              </p>
              <p className="-descBlack font-benton-book  header margin">
                  {BlogMivanTechnologyData.Disadvantages1}
                </p>

              <div className="blog-content">
                {BlogMivanTechnologyData.DisadvantagesData &&
                  BlogMivanTechnologyData.DisadvantagesData.map((i0, idx) => {
                    return (
                      <div key={idx}>
                        <DescLabel
                          classList="my-1 py-2 font-benton-book header"
                          text={i0.description}
                          label={i0.label}
                        />
                        {i0.list && (
                          <ul className="blog-list-circle">
                            {i0.list.map((i1, idx1) => {
                              return (
                                <li key={idx1}>
                                  <p className="-descBlack mb-1 font-benton-book">
                                    {i1}
                                  </p>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </div>
                    );
                  })}
              </div>

              <p className="-descBlack font-benton-bold margin">
                {BlogMivanTechnologyData.Advancement}
              </p>
              <div className="imagepadding">
                <img
                  src={BlogMivanTechnologyData.image4}
                  alt={BlogMivanTechnologyData.image4Alt}
                  class="image margin"
                />
              </div>

              {BlogMivanTechnologyData.AdvancementData && (
                <ul className="blog-list-circle  header margin">
                  {BlogMivanTechnologyData.AdvancementData.map((i9, idx11) => {
                    return (
                      <li key={idx11}>
                        <p className="-descBlack mb-1 font-benton-book header">
                          {i9}
                        </p>
                      </li>
                    );
                  })}
                </ul>
              )}

              <p className="-descBlack font-benton-bold margin">
                {BlogMivanTechnologyData.conclusion}
              </p>

              {BlogMivanTechnologyData.conclusiondata && (
                <ul className="blog-list-circle  header">
                  {BlogMivanTechnologyData.conclusiondata.map((i9, idx11) => {
                    return (
                      <li key={idx11}>
                        <p className="-descBlack mb-1 font-benton-book header">
                          {i9}
                        </p>
                      </li>
                    );
                  })}
                </ul>
              )}
            </Col>
            <Col xs={12} md={4} lg={4} className="my-0 py-0 -aside-block"></Col>
          </Row>

          <Row className="simpleShadow my-3 py-3 justify-content-around">
            <Col xs={12} md={11} lg={9} className="pb-4 pr-md-5 p-2">
              <div
                className="shareLinkBlock1 d-flex flex-row p-4 "
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                  gap: "1rem",
                  borderRadius: "1rem",
                }}
              >
                <ShareButton shareUrl="https://modernspaaces.com/blogs/exploring-the-mivan-technology" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
