import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Heading } from "../../Global/Typography";

import Brochure from "../../../images/SoulTree/MasterPlan/Brochure.svg";
import Download from "../../../images/SoulTree/MasterPlan/Download.svg";
import FloorPlan from "../../../images/SoulTree/MasterPlan/FloorPlan.svg";
import MasterPlaImage from "../../../images/SoulTree/MasterPlan/MasterPlan.svg";

import DownloadBrochure from "../../Global/DownloadBrochure";

export default function MasterPlanDOwnloadItems({ inView }) {
  const [showMasterPlan, setShowMasterPlan] = useState(false);
  const [showFloorPlan, setShowFloorPlan] = useState(false);
  const [showBrochure, setShowBrochure] = useState(false);

  const dataSetFloorPlan = {
    subject: "Urbanest 77East Phase02 FloorPlan",
    fileName: "Urbanest-77East-Phase02-FloorPlan.pdf",
    filePath: "./documents/Urbanest-77East-Phase02-FloorPlan.pdf",
  };
  const dataSetMasterPlan = {
    subject: "Urbanest 77East Phase02 MasterPlan",
    fileName: "Urbanest-77East-Phase02-MasterPlan.pdf",
    filePath: "./documents/Urbanest-77East-Phase02-MasterPlan.pdf",
  };
  const dataSetBrochure = {
    subject: "Urbanest 77East Phase02 Brochure",
    fileName: "Urbanest-77East-Phase02-Brochure.pdf",
    filePath: "./documents/Urbanest-77East-Phase02-Brochure.pdf",
  };

  const handleShowFloorPlan = (e) => {
    e.preventDefault();
    setShowFloorPlan(true);
  };

  const handleShowMasterPlan = (e) => {
    e.preventDefault();
    setShowMasterPlan(true);
  };

  const handleShowBrochure = (e) => {
    e.preventDefault();
    setShowBrochure(true);
  };

  const handleCloseFloorPlan = () => setShowFloorPlan(false);
  const handleCloseMasterPlan = () => setShowMasterPlan(false);
  const handleCloseBrochure = () => setShowBrochure(false);

  return (
    <Container className="my-3 my-md-5 pb-md-5">
      <Row>
        <Col xs={12} lg={10} className="mx-auto">
          <ul className=" MasterPlanDOwnloadItems d-flex align-items-center justify-content-center flex-md-row flex-column">
            <li className="m-4 py-1 pb-md-2 mt-md-5 mb-md-1 mx-3">
              <div
                onClick={handleShowFloorPlan}
                className="w-100 cursor-pointer text-center d-flex align-items-center"
              >
                <img
                  src={FloorPlan}
                  className=" MasterPlanDOwnloadItems__icon"
                  alt={"Floor Plan"}
                />
                <Heading
                  variant="h7"
                  classList="px-4 -only-sm"
                  text={"Floor Plan"}
                />
                <img
                  className=" MasterPlanDOwnloadItems__downloadIcon"
                  src={Download}
                  alt="FloorPlan"
                />
              </div>
            </li>
            <li className="m-4 py-1 pb-md-2 mt-md-5 mb-md-1 mx-3">
              <div
                onClick={handleShowMasterPlan}
                className="w-100 cursor-pointer text-center d-flex align-items-center"
              >
                <img
                  src={MasterPlaImage}
                  className=" MasterPlanDOwnloadItems__icon"
                  alt={"Master Plan"}
                />
                <Heading
                  variant="h7"
                  classList="px-4 -only-sm"
                  text={"Master Plan"}
                />
                <img
                  className=" MasterPlanDOwnloadItems__downloadIcon"
                  src={Download}
                  alt="MasterPlan"
                />
              </div>
            </li>
            <li
              onClick={handleShowBrochure}
              className="cursor-pointer m-4 py-1 pb-md-2 mt-md-5 mb-md-1 mx-3 d-flex align-items-center"
            >
              <img
                src={Brochure}
                className=" MasterPlanDOwnloadItems__icon"
                alt="Brochure"
              />
              <Heading variant="h7" classList="px-4 -only-sm" text="Brochure" />
              <img
                className=" MasterPlanDOwnloadItems__downloadIcon"
                src={Download}
                alt="Brochure"
              />
            </li>
          </ul>
        </Col>
      </Row>

      <DownloadBrochure
        title={"Download Floor Plan"}
        show={showFloorPlan}
        handleClose={handleCloseFloorPlan}
        content={dataSetFloorPlan}
      />
      <DownloadBrochure
        title="Download Master Plan"
        show={showMasterPlan}
        handleClose={handleCloseMasterPlan}
        content={dataSetMasterPlan}
      />
      <DownloadBrochure
        title="Download Brochure"
        show={showBrochure}
        handleClose={handleCloseBrochure}
        content={dataSetBrochure}
      />
    </Container>
  );
}
