import ImgB1 from "../images/Blogs/ImgB1.png";

import img1 from "../images/Blogs/blog-img-1.webp";

const data = [
    {
        id: "abc101",
        slug: "list-of-amenities-to-consider-buying-home",
        image: img1,
        label: "Real estate",
        desc: "For any real estate project, its amenities are the most crucial part, as that’s what the builders use to lure potential home buyers…",
        title: "A complete list of amenities you should look for before buy house",
        publishedOn: "December, 2021",
        time: "2 min read",

        description:
            "For any real estate project, its amenities are the most crucial part, as that`s what the builders use to lure potential home buyers. They significantly influence the buyer`s decision. Some of the most sought-after amenities that most buyers these days look for are – indoor or outdoor swimming pool, park, gym, clubhouse, elevators, security systems, power backup, 24-hour water maintenance, and more. The right way to going about this is first enlisting the amenities that are non-negotiable for you. Whether you`re buying your first home or not, there are certainly some amenities that you need to look out for to get a good ROI on your hard-earned money.",
        authorImage:
            "https://images.unsplash.com/photo-1600188769099-d25b4ec79659?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
        authorName: "Noveen Reddy",
        department: "Marketing Head",
        contactNumber: "987654321",
        // imageOther: "https://modernspaaces.com/BlogListImages/BlogOne/Img01.webp",
        lightDescription:
            "All real estate amenities can be broadly divided into two main categories, depending on the segment and budget of the home buyer",
        lightDescriptionList: [
            {
                label: "Essential amenities: An affordable home with some amenities which are considered indispensable for a decent lifestyle fall under this category. Some of them include:",
                list: [
                    "Lift",
                    "Power backup",
                    "24-hours water supply",
                    "Security Maintenance",
                ],
            },
            {
                label: "Discretionary/Avoidable amenities: for the home buyers who are from the luxury segment, certain amenities like the following could be their basic requirements:",
                list: [
                    "WiFi connectivity",
                    "Gym",
                    "Club House",
                    "Lounge",
                    "Rooftop garden",
                    "Jogging track",
                    "Cinema hall",
                    "Religious place of worship",
                    "Sky lounge",
                    "Salon/spa",
                ],
            },
        ],
        paragraph:
            "Regardless of what budget segment you fall into, it`s essential to choose the amenities based on personal preferences, affordability, exclusivity, and availability. It`s advisable not to fall for marketing tactics with such amenities that might be of no use to you. Ideally, good amenities should add some variable or fixed cost to your property, thereby increasing its resale value. Apart from the amenities mentioned above, here are the ones you should keep a close eye on:",
        list: [
            {
                label: "Security system",
                description:
                    "this is the most basic yet most important amenity that all home buyers should look for to know that their precious home is secure. One cannot be home 24*7 to take care of their valuables. CCTVs and guards securing the building round the clock are now essential. That way, it`s easier to enjoy your time away from home peacefully. A residence project with a good security system is highly sought-after, and rightfully so.",
            },
            {
                label: "Power backup",
                description:
                    "this is the next most crucial thing to look for. Being in India, power glitches and inadequate power supply are not uncommon. Not only your apartment but the clubhouse, pool, and other areas also require power back up if it falls in a power-cut prone zone. Power backup isn`t just crucial for convenience but also to prevent any security mishaps. Now that we`re in a pandemic and most of us work from home, this is undoubtedly a non-negotiable. Make sure to double-check for a reliable provision of power backup before deciding upon a home.",
            },
            {
                label: "Finishing and materials used",
                description:
                    "once you have settled on the budget you want to spend on your dream home, research about the type of finishes you can get in that price bucket. If you have a low budget, you could go for vinyl flooring, plastic laminate in baths and kitchen, and cheaper carpets. With a slightly higher budget, resilient flooring like wood or tiles and a solid kitchen surfacing with slate or granite can be done. Planning out your budget and the kind of furnishings you can get can significantly reduce the hassle of house-hunting. While you`re at it, have a close look at the ceiling. It could tell you a lot about possible cracks or leaks through water spots due to any structural damage if you`re buying an old property. Pay close attention to any cracks in the wall surfaces, drywall, and the section where the ceiling and wall meet for any leakage-related warning signs.",
            },
            {
                label: "Kitchen appliances",
                description:
                    "the appliances are the most expensive parts of homes. Depending on their age and the condition they are in, the price of the home can vary. While looking at properties, make sure to assess all the appliances properly, so you don`t end up buying them all over again later on.",
            },
            {
                label: "Pool, spa, and salon",
                description:
                    "with long working hours, most working professionals hardly get any time to destress and rejuvenate themselves. Going to a spa or a salon are the ultimate ways of self-care, but most of the times, we avoid entirely going just because they`re too far. When such amenities are there, it becomes easy for some timely indulgence.",
            },
            {
                label: "Recreation and fitness center",
                description:
                    "after a long day at work, when there is a gym, amphitheater, or recreation center right next to your home, it becomes easy to chill and stay active. One can easily find the activities they`re interested in, like cycling, jogging, swimming, yoga, gym, etc. For the health-conscious, properties with such amenities are always hot-favorite.",
            },
            {
                label: "Parking lot",
                description:
                    "since parking is the most significant trouble these days, it`s also essential to get a place with enough parking space for all the vehicles you own. Apart from that, ensure that all the vital provisions like hospitals, petrol pumps, schools, markets, department stores, etc. are easily accessible from your home.",
            },
            {
                label: "Kids and senior citizens dedicated areas",
                description:
                    "while growing up, kids will be spending a significant amount of time playing outside. A property with a kids` play area can be incredibly beneficial as it will help in the overall growth of your child without worrying about their security. When you have all the essential amenities right in your complex, you`ll not need to send your children to faraway places for classes. Similarly, even the elderly community requires a separate center for socializing and exchanging meaningful interactions. It will certainly make their life easier and more fun-filled as they`ll get to do exciting things with people of similar age and taste.",
            },
        ],
        ClosingThoughts: "Closing thoughts",
        noteStart:
            "We hope your search for the perfect home gets narrowed down a little more with our list of essential and non-essential amenities that you can look for while buying a house. Remember, these amenities will increase your resale value in the future. If any, the other missing aspects can be taken care of in the future, but certain amenities can only come along with certain kinds of properties. ",
        link: "Choose wisely!",
    },
];
export default data;
