import React, { Component } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";


const vista1 = "/images/EngraceVista/galleryTabs/vista1.webp";
const vista2 = "/images/EngraceVista/galleryTabs/vista2.webp";
const vista3 = "/images/EngraceVista/galleryTabs/vista3.webp";
const vista4 = "/images/EngraceVista/galleryTabs/vista4.webp";
const vista5 = "/images/EngraceVista/galleryTabs/vista5.webp";
const vista6 = "/images/EngraceVista/galleryTabs/vista6.webp";
const vista7 = "/images/EngraceVista/galleryTabs/vista7.webp";

const images = [
  vista1,
  vista2,
  vista3,
  vista4,
  vista5,
  vista6,
  vista7

];



export default class GSlider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  render() {
    const { photoIndex, isOpen } = this.state;

    return (
      <div className="GSliderHoverEle">
        <img
          // title="Click to View More Images"
          className="LightHouseImage"
          src={vista1}
          alt="LightHouseImage"
          onClick={() => this.setState({ isOpen: true })}
        />

        <div
          className="videoIconImage videoIconImage--text"
          onClick={() => this.setState({ isOpen: true })}
        >
          View
        </div>
        {/* <div className="divBl">Click to View More Images</div> */}
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            // imageTitle={imagesCaption[photoIndex]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
      </div>
    );
  }
}
