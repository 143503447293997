import React, { useState } from "react";
import { InView } from "react-intersection-observer";

import TermsAndConditions from "../components/TermsAndConditions";
import { Footer, FooterLogo } from "../components/index";

const TermsAndConditionsPage = () => {
    const [inViewTermsAndConditions, setInViewTermsAndConditions] =
        useState(false);
    const [inViewFooter, setInViewFooter] = useState(false);

    return (
        <>
            <InView
                className="bgPattern"
                onChange={setInViewTermsAndConditions}
                triggerOnce={true}
            >
                <TermsAndConditions inView={inViewTermsAndConditions} />
            </InView>
            <FooterLogo />

            <InView
                className="bgPattern "
                onChange={setInViewFooter}
                triggerOnce={true}
            >
                <Footer inView={inViewFooter}></Footer>
            </InView>
        </>
    );
};

export default TermsAndConditionsPage;
