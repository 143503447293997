import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import Modal from "react-bootstrap/Modal";
// import bannerImage from "../../../images/real-estate-paperwork.png";
import rectangle from "../../../images/white-kite-shape.svg";
import "../contact.php";
import "../php-email-form.php";
import "./NewLaunchProjectModal.scss";

const bannerImage = "/images/view-project-details.png";
// import DatePicker from "react-datepicker";
// import { Link } from "react-router-dom";
// import West302 from "../../images/docs/302West.pdf";

// import West302 from "../../../documents/Project302/West302.pdf";

function php_email_form_submit(thisForm, action, formData) {
  fetch(action, {
    method: "POST",
    body: formData,
    headers: { "X-Requested-With": "XMLHttpRequest" },
  })
    .then((response) => {
      if (response.ok) {
        return response.text();
      } else {
        throw new Error(
          `${response.status} ${response.statusText} ${response.url}`
        );
      }
    })
    .then((data) => {
      if (data.trim() === '"OK"') {
        console.log("message sent");
        thisForm.reset();
      } else {
        throw new Error(
          data
            ? data
            : "Form submission failed and no error message returned from: " +
            action
        );
      }
    })
    .catch((error) => {
      console.log(error);
    });
}


const lsqLeadGeneration = (formData) => {
  const lsqObj = [
    {
      Attribute: "FirstName",
      Value: formData.get("name"),
    },
    {
      Attribute: "EmailAddress",
      Value: formData.get("email"),
    },
    {
      Attribute: "Phone",
      Value: formData.get("phone"),
    },
    // {
    //     Attribute: "SearchBy",
    //     Value: formData.get("mode"),
    // },
  ];
  const host = "https://api-in21.leadsquared.com/v2/";
  const accessKey = "u$rc8cdb15caad13149ec5e59639ae32ccb";
  const secretKey = "ac596128b63260bd1be2e3aff6036e728604fc6b";
  const captureLead = `LeadManagement.svc/Lead.Capture?accessKey=${accessKey}&secretKey=${secretKey}`;

  fetch(host + captureLead, {
    method: "POST",
    body: JSON.stringify(lsqObj),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => {
    console.log(res.json, "Lead Captured");
  });
};

export default function NewLaunchProjectModal({
  show,
  handleClose,
  reraApproved = false
}) {
  const initialFormData = Object.freeze({
    name: "",
    phone: "",
    email: "",
    // mode: "phone",
    message: "",
    notification: true,
  });
  const [formData, updateFormData] = useState(initialFormData);
  const [validated, setValidated] = useState(false);
  // const [type, setType] = useState("SoulTree");
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    // if (form.checkValidity() === false) {
    //   e.preventDefault();
    //   e.stopPropagation();
    //   setValidated(false);
    //   return;
    // }
    //   return;
    // } else {
    setValidated(true);
    // }
    if (form.checkValidity() === true) {
      let action = e.target.getAttribute("action");
      if (!action) {
        console.log("The form action property is not set!", action);
        return;
      }
      /* e.target.querySelector('.loading').classList.add('d-block');
      e.target.querySelector('.error-message').classList.remove('d-block');
      e.target.querySelector('.sent-message').classList.remove('d-block');*/
      let formData = new FormData(e.target);
      let message = formData.get("message");
      formData.set("message", message);
      lsqLeadGeneration(formData);
      php_email_form_submit(e.target, action, formData);
      handleClose();
      // setTimeout(myGreeting, 1500);

      // function myGreeting() {
      //   window.location.href = "/thank-you-contact";
      // }
      // window.location.href = "/thank-you-contact";
    }
  };

  // const [startDate, setStartDate] = useState(new Date());
  // const usernameRef = useRef();
  const handleChange = (e) => {
    console.log(e.target.value);
    updateFormData({
      ...formData,

      // Trimming any whitespace
      [e.target.name]:
        e.target.name === "notification"
          ? e.target.checked
          : e.target.value.trim(),
    });
  };

  return (
    <div className="">
      <Modal
        className="customModal new-launch-project-modal"
        show={show}
        onHide={handleClose}
      >
        <Modal.Body className="py-0">
          <div className="modal-banner">
            <img
              src={bannerImage}
              className="banner-image"
              alt="View Project Details"
            />
            <div className="banner-text flex-grow-1 flex-sm-grow-0">
              <div className="banner-text-heading mb-3 ">
                {reraApproved ? (
                  <>
                    No <br />
                    Pre-EMI
                    <br />
                    Till possession
                  </>
                ) : (
                  <>
                    Avail <br />
                    pre-launch
                    <br />
                    offers
                  </>
                )}
              </div>
              <div className="d-flex flex-column-reverse flex-sm-column align-items-end">
                <div className="desc mb-sm-1">
                  {reraApproved ? "RERA Approved" : "Awaiting RERA Approval!"}
                </div>
                <div className="text-marker mb-1 mb-sm-0"></div>
              </div>

              <div className="banner-text-desc d-flex justify-content-end align-items-center"></div>
            </div>
            <img
              className="d-none d-sm-block white-arrow"
              src={rectangle}
              alt="rectangle"
            />
          </div>
          <Container className="modal-form">
            <Row className="py-0 mx-0 ">
              <Col xs={12} md={12} lg={12} className="px-0">
                <div className="modal-heading">
                  Express your interest today!
                </div>
                <div className="desc">
                  {
                    reraApproved ? "Secure exclusive offer of No Pre-EMI till possession and unlock huge savings"
                    :
                    "Secure exclusive pre-launch offers and unlock huge savings"
                  }
                </div>

                <Form
                  action="https://mdrn.deta.dev/contactformbase"
                  method="post"
                  role="form"
                  className="ContactUsForm php-email-form"
                  noValidate
                  validated={validated}
                  onSubmit={handleSubmit}
                >
                  <Row className="my-0 mx-0">
                    <Form.Group
                      as={Col}
                      xs={12}
                      md={12}
                      lg={12}
                      controlId="validationCustom05"
                    >
                      {/* <Form.Label className="form-check-label ml-0">
                        Preferred mode of contact
                      </Form.Label>
                      <div className="d-flex  ">
                        <Form.Check
                          type="radio"
                          name="mode"
                          label="Phone"
                          value="phone"
                          id="radio-phone"
                          className="mr-5"
                          checked={formData.mode === "phone"}
                          onChange={handleChange}
                        />
                        <Form.Check
                          type="radio"
                          name="mode"
                          value="email"
                          label="Email"
                          id="radio-email"
                          checked={formData.mode === "email"}
                          onChange={handleChange}
                        />
                      </div> */}
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      xs={12}
                      md={12}
                      lg={12}
                      controlId="validationCustom01"
                    >
                      <Form.Label className="m-0"> Full Name *</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="name"
                        // placeholder="Enter Name"
                        placeholder=""
                        defaultValue=""
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter your name.
                      </Form.Control.Feedback>
                    </Form.Group>


                    <Form.Group
                      as={Col}
                      xs={12}
                      md={12}
                      lg={12}
                      controlId="validationCustom03"
                    >
                      <Form.Label className="">Phone Number *</Form.Label>
                      <InputGroup>
                        <InputGroup.Text id="PhoneNumber">
                          +91
                        </InputGroup.Text>
                        <Form.Control
                          required
                          type="tel"
                          pattern="[0-9]{10}"
                          name="phone"
                          placeholder=""
                          onChange={handleChange}
                          defaultValue=""
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter your number.
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      xs={12}
                      md={12}
                      lg={12}
                      controlId="validationCustom02"
                    >
                      <Form.Label className="">Email </Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        onChange={handleChange}
                        // placeholder="Enter Email"
                        placeholder=""
                        defaultValue=""
                      />

                      <Form.Control.Feedback type="invalid">
                        Please enter vaild email ID.
                      </Form.Control.Feedback>
                    </Form.Group>



                    <Form.Group
                      as={Col}
                      xs={12}
                      md={12}
                      controlId="validationCustom04"
                      className=""
                    >
                      <Form.Label className="m-0">
                        Write Your Message
                      </Form.Label>
                      <Form.Control
                        className="mt-2"
                        type="text"
                        name="message"
                        onChange={handleChange}
                        placeholder=""
                        defaultValue=""
                      />
                    </Form.Group>
                  </Row>
                  <Row
                    className="justify-content-end btns-container mx-0"
                    style={{
                      gap: "0.875rem",
                    }}
                  >
                    <Button
                      className="cancel-btn flex-grow-1 flex-sm-grow-0 px-lg-5"
                      onClick={handleClose}
                    >
                      <span className="btn-text">Cancel</span>
                    </Button>
                    <Button
                      className="submit-btn flex-grow-1 flex-sm-grow-0 px-lg-5 "
                      type="submit"
                    >
                      <span className="btn-text">Submit</span>
                    </Button>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  );
}
