import { Col, Container, Row } from "react-bootstrap";
import { Desc, Heading } from "../../Global/Typography";
import UrbanestLogo from "../../../images/Urbanest/UrbanestLogo.svg";
import "./Banner.scss";
import BreadcrumbCard from "../../Global/Breadcrumb";

export default function Banner({ inView }) {
  const currentPage = "77 East - Phase 2";
  return (
    <section
      className={`section ProjectBanner-bg ${inView ? "inView" : "outView"}`}
    >
      <Container className="ProjectBanner">
        <Row className="align-items-center">
          <Col xs={11} md={10} lg={8} className="">
            <BreadcrumbCard item={currentPage} />
            <Heading
              classList="my-4 pr-5 pr-md-3"
              variant="h1Lg"
              text="77 East - Phase 2"
            />

            <img src={UrbanestLogo} alt="logo" className="UrbanestLogo mb-4 mb-md-5" />
            <Desc
              classList="pr-md-5 mr-md-5 text-black"
              text="77 East is an all 3 BHK project focused on making a small community where people can come together to make sure you get the living environment that syncs with your thought process. Rejoice in a home designed for providing you with the lifestyle you deserve."
            />
          </Col>
        </Row>
      </Container>
      <div className="-bgShadow"></div>
    </section>
  );
}
