import { Link } from "react-router-dom";

export default function BlockCard({ item }) {
    return (
        <div className="-blocks-card-block m-2 mmd-3">
            <Link
                to={item.isAvailable ? item.toHref : "#"}
                className={item.isAvailable ? "w-100" : "w-100 pointNone"}
                onClick={(e) => {
                    if (!item.isAvailable) e.preventDefault();
                }}
            >
                {item.isAvailable ? (
                    <img src={item.image} className="-image" alt={item.imgAlt} />
                ) : (
                    <div className="-sold-out-block">
                        <img src={item.image} className="-image" alt={item.imgAlt} />
                        <div className="-sold-out">Sold Out</div>
                    </div>
                )}
                <ul className="-blocks-block">
                    <li className="-blocks-block__list">
                        <span className="-title">{item.title}</span>
                        <span className="-desc">{item.phases}</span>
                    </li>
                    <li className="-blocks-block__list">
                        <span className="-desc">{item.type}</span>

                        <span className="-label d-block">
                            {item.unit}
                            {item.availability && (
                                <span className="-label ml-2 d-inline">
                                    {`(${item.availability})`}
                                </span>
                            )}
                        </span>
                    </li>
                    <li className="-blocks-block__list">
                        <span className="-price">Area Range</span>
                        <span className="-desc">{item.SqFt}</span>
                    </li>
                    <li className="-blocks-block__list">
                        <span className="-price">{item.price}</span>
                        {item.label && (
                            <span className="-label">{item.label}*</span>
                        )}
                    </li>
                    <li className="-blocks-block__list">
                        <span className="-price">Possession</span>
                        <span className="-label">{item.possession}</span>
                    </li>
                </ul>
            </Link>
        </div>
    );
}
