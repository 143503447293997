import { useState } from "react";
import { InView } from "react-intersection-observer";
import { ArchitecturalSlider, Banner, Campus } from "../components/Projects";
// import { Banner, ContactUs } from "../components/Projects";
import { Container } from "react-bootstrap";
import { Heading } from "../components/Global/Typography";
import { ContactUs } from "../components/SoulTree";
import { Footer, FooterLogo } from "../components/index";

export default function Projects() {
    const [inViewBanner, setInViewBanner] = useState(false);
    const [inViewFooter, setInViewFooter] = useState(false);
    const [inViewContactUs, setInViewContactUs] = useState(false);
    const [inViewCampus, setInViewCampus] = useState(false);
    const [inViewArchitecturalSlider, setInViewArchitecturalSlider] =
        useState(false);

    return (
        <>
            <InView
                className="bgPattern"
                onChange={setInViewBanner}
                triggerOnce={true}
            >
                <Banner inView={inViewBanner}></Banner>
            </InView>

            <InView
                className="bgPattern bgPattern--PatternRight"
                onChange={setInViewCampus}
                triggerOnce={true}
            >
                <Campus inView={inViewCampus}></Campus>
            </InView>
            <Container>
                <Heading
                    variant="h1"
                    classList="Campus__title mb-4 mb-md-5 text-center mx-auto"
                    text="4+ Big Projects are Running Successfully.."
                />
                <hr></hr>
            </Container>

            <InView
                className="bgPattern"
                onChange={setInViewArchitecturalSlider}
                triggerOnce={true}
            >
                <ArchitecturalSlider
                    inView={inViewArchitecturalSlider}
                ></ArchitecturalSlider>
            </InView>

            <InView
                className="bgPattern bgPattern--HrGrassPattern"
                onChange={setInViewContactUs}
                triggerOnce={true}
            >
                <ContactUs inView={inViewContactUs}></ContactUs>
            </InView>
            <FooterLogo />
            <InView
                className="bgPattern "
                onChange={setInViewFooter}
                triggerOnce={true}
            >
                <Footer inView={inViewFooter}></Footer>
            </InView>
        </>
    );
}
