import { Col, Container, Row } from "react-bootstrap";
import { Desc, Heading } from "../../Global/Typography";
// import DevelopImage from "../../../images/SoulTree/Develop/DevelopImage.png";
import "./Develop.scss";

const DevelopImage = "/images/SereneHeights/MedicalCare/Medical.png";

export default function Develop({ inView }) {
    const DevelopData = [
        {
            title: "What are my options for medical care?",
            desc: (
                <div>
                    The locality features some of Bangalore's leading hospitals
                    and health centers, ensuring <b>quick access</b> to{" "}
                    <b>medical assistance</b>.
                </div>
            ),
            desc1: (
                <ul class="TranquilCourtList">
                    <li>
                        Spandana hospital - <strong>500 M</strong>
                    </li>
                    <li>
                        Manipal Clinic - <strong>500 M</strong>
                    </li>
                    <li>
                        Manipal Hospital (Sarjapur road) -{" "}
                        <strong>11 Kms</strong>
                    </li>
                    <li>
                        Natus Women and Children Hospital -{" "}
                        <strong>8 Kms</strong>
                    </li>
                    <li>
                        Belenus Champion Hospital - <strong>7 Kms</strong>
                    </li>
                    <li>
                        Rainbow Children's Hospital - <strong>12 Kms</strong>
                    </li>
                    <li>
                        Cloudnine Hospital - <strong>10 Kms</strong>
                    </li>
                </ul>
            ),
            image: DevelopImage,
        },
    ];

    return (
        <section
            className={`section ${inView ? "inView" : "outView"}
    `}
        >
            {DevelopData.map((item, idx) => {
                return (
                    <Container key={idx} className="Develop">
                        <Row className="align-items-center flex-column-reverse flex-md-row">
                            <Col
                                xs={12}
                                md={6}
                                lg={6}
                                xl={5}
                                className="pr-md-5"
                            >
                                <div className="imagePatch">
                                    <img
                                        src={item.image}
                                        className="Develop__image w-100 mb-4 mb-md-5"
                                        alt="Future in Sompura Gate, Sarjapur"
                                    />
                                </div>
                            </Col>
                            <Col
                                xs={12}
                                md={6}
                                lg={6}
                                xl={7}
                                className="px-md-5"
                            >
                                <Heading
                                    variant="h1"
                                    classList="Develop__title"
                                    text={item.title}
                                />
                                <Desc
                                    classList="Develop__desc my-3 "
                                    text={item.desc}
                                />
                                {item.desc1 && (
                                    <Desc
                                        classList="Develop__desc my-3 "
                                        text={item.desc1}
                                    />
                                )}
                            </Col>
                        </Row>
                    </Container>
                );
            })}
        </section>
    );
}
