import React from "react";
import { Row } from "react-bootstrap";
import { Desc, Heading } from "../../Global/Typography";
import GrassPattern from "../GrassPattern";
// import view1 from "../../../images/Soulace/architectural/image2.jpg";
// import view2 from "../../../images/Soulace/architectural/image3.jpg";
// import view3 from "../../../images/Soulace/architectural/image4.jpeg";
// import view4 from "../../../images/Soulace/architectural/image5.jpg";
// import view5 from "../../../images/Soulace/architectural/image6.webp";
// import common  from "../../../images/Engrace/architectural/tobeused/render2.webp";
// import Culture from "../../../images/SoulTree/Architectural/Culture.webp";
// import MoreThanJust from "../../../images/SoulTree/Architectural/MoreThanJust.webp";
// import Genrationoftoday from "../../../images/SoulTree/Architectural/Genrationoftoday.webp";
// import ArchitecturalImage from "../../../images/SoulTree/Architectural/ArchitecturalImage.png";

import NextArrow from "../../../images/Common/NextArrow.svg";
import PrevArrow from "../../../images/Common/PrevArrow.svg";
import "./ArchitecturalSlider.scss";

import SwiperCore, {
    A11y,
    Autoplay,
    Controller,
    Navigation,
    Pagination,
    Scrollbar,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Controller, Autoplay]);

const view1 = "/images/SereneHeights/architectural/low-density-min.jpg";
const view2 = "/images/SereneHeights/architectural/green-legacy.jpg";
const view3 = "/images/SereneHeights/architectural/three-balconies.jpg";
const view4 = "/images/SereneHeights/architectural/aluform-technology.png";

export default function ArchitecturalSlider({ inView }) {
    const ArchitecturalSliderData = [
        {
            title: "Low Density Living",
            desc: "With only 420 units on a 7.5 acres land parcel, this low-density project features every amenity needed for an urban lifestyle, complemented by serene spaces.",
            image: view1,
            imgAlt: "Low Density Living",
        },

        {
            title: "Green legacy - 280 Native Trees",
            desc: "Serene Heights fosters a green legacy, nurturing native trees to ensure a lush, natural environment for future generations. ",
            image: view2,
            imgAlt: "Green legacy",
        },
        {
            title: "3 Balconies For Every Unit",
            desc: "Whether it's a 2.5 or 3 BHK, every unit at Serene Heights offers the luxury of three spacious balconies.",
            image: view3,
            imgAlt: "3 Balconies For Every Unit",
        },
        {
            title: "Build to last with Aluform Technology",
            desc: "Aluform Technology has transformed our projects, by using lightweight & flexible materials with a focus on speed, quality & durability.",
            image: view4,
            imgAlt: "Aluminium Formwork technology: Innovative aluminum construction",
        },
    ];

    return (
        <section
            className={`section bg-white ArchitecturalSlider ${
                inView ? "inView" : "outView"
            }
    `}
        >
            <GrassPattern />
            <Row className="ArchitecturalSlider__Row">
                <Swiper
                    wrapperTag="ul"
                    className="ArchitecturalSliderLists"
                    slidesPerView={1}
                    speed={500}
                    // speed={3400}
                    spaceBetween={0}
                    effect={"fade"}
                    fadeEffect={{
                        crossFade: true,
                    }}
                    // observeParents={true}
                    // observer={true}

                    autoplay={true}
                    loop={true}
                    direction="horizontal"
                    pagination={{
                        clickable: true,
                        el: ".swiper-pagination-Architech",
                    }}
                    navigation={{
                        prevEl: ".ArrowButtonBlock__ArrowPrevArchitech",
                        nextEl: ".ArrowButtonBlock__ArrowNextArchitech",
                    }}
                >
                    {ArchitecturalSliderData.map(
                        ({ image, title, desc, imgAlt }, index) => (
                            <SwiperSlide
                                tag="li"
                                key={index}
                                className="-block-list"
                            >
                                <div className="-image-block">
                                    <img
                                        src={image}
                                        className="-image "
                                        alt={imgAlt}
                                    />
                                </div>
                                <div className="-architech-co-block">
                                    <div className="-architech-co-block__inner soulace">
                                        <Heading
                                            variant="h1"
                                            classList="-title"
                                            text={title}
                                        />
                                        <Desc
                                            classList="-desc my-3 "
                                            text={desc}
                                        />
                                    </div>
                                </div>
                            </SwiperSlide>
                        )
                    )}
                </Swiper>
            </Row>
            <Row className="ArrowButtons">
                <div className="ArrowButtonBlock">
                    <div className="swiper-pagination swiper-pagination-Architech"></div>
                    <div className="ArrowButtonBlock__Arrow ArrowButtonBlock__ArrowPrevArchitech">
                        <img
                            src={PrevArrow}
                            className="-image"
                            alt="ArchitecturalSlider"
                        />
                    </div>
                    <div className="ArrowButtonBlock__Arrow ArrowButtonBlock__ArrowNextArchitech">
                        <img
                            src={NextArrow}
                            className="-image"
                            alt="ArchitecturalSlider"
                        />
                    </div>
                </div>
            </Row>
        </section>
    );
}
