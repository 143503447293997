import { Col, Container, Row } from "react-bootstrap";
import { Desc, Heading } from "../../Global/Typography";
import GrassPattern from "../GrassPattern";
// import SoulTreeLogo from "../../../images/SoulTree/SoulTreeLogo.svg";
// import logo from "../../../images/Soulace/Banner/soulace.png";
// import BannerImage from "../../../images/SoulTree/Banner.png";
// import BannerImage from "../../../images/Soulace/Banner/SoulaceBanner.webp";
import "./Banner.scss";

const logo = "/images/Soulace/Banner/soulace-logo.png";
const BannerImage = "/images/Soulace/Banner/soulace-project-detail-page-banner.webp";

export default function Banner({ inView }) {
    return (
        <section
            className={`section overflow-hidden pb-0 ${
                inView ? "inView" : "outView"
            }`}
        >
            <GrassPattern />
            <Container className="pos-rel my-md-3">
                <Row>
                    <Col xs={11} md={10} lg={7} className="pr-5 py-5 mt-5">
                        <img
                            src={logo}
                            alt="logo"
                            className="SoulTreeLogo msSoulTreeBannerLogo msSoulTreeBannerLogo--sm"
                            style={{ height: "6rem" }}
                        />

                        <Heading
                            classList="my-4 pr-md-3"
                            variant="h1"
                            text="Premium 266 Independent Villas with Private Elevators
              "
                        />
                        <Desc
                            classList="pr-md-3"
                            text="Beautifully designed homes with world-class amenities and premium specifications.

              "
                        />
                    </Col>
                </Row>

                <img
                    src={logo}
                    alt="Soulace"
                    className="SoulTreeLogo msSoulTreeBannerLogo msSoulTreeBannerLogo--md"
                    style={{ height: "6rem" }}
                />

                {/* NEW IMAGE */}
                {/* <Row>
          <Col md={12} className="p-0">
            <div className="md-container-wrap">
              <img src={BannerImage} alt="logo" className="-banner-image" />
            </div>
          </Col>
        </Row> */}
            </Container>
            <div className="md-container-wrap">
                <img src={BannerImage} alt="Soulace by Modern Spaaces" className="-banner-image" />
            </div>
            <GrassPattern />
        </section>
    );
}
