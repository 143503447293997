import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Heading } from "../Global/Typography";

const TermsAndConditions = ({ inView }) => {
    return (
        <section
            className={`section ${inView ? "inView" : "outView"}`}
            style={{ marginTop: "10rem" }}
        >
            <Container>
                <Row>
                    <Col xs={12} className="mx-md-auto">
                        <Heading
                            classList="mt-5 mt-md-5 pb-md-4 pr-5 pr-md-3"
                            style={{ marginTop: "10rem" }}
                            variant="h1"
                            text="Terms and Conditions"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="mx-md-auto mb-4">
                        These Terms and Conditions ("Terms") govern your use of
                        the website modernspaaces.com (referred to as the
                        "Website") operated by Modern Spaaces ("we" or "us"). By
                        accessing or using the Website, you agree to be bound by
                        these Terms. If you disagree with any part of these
                        Terms, please do not use the Website.
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="mx-md-auto mb-4">
                        <h3>Intellectual Property: </h3>
                        The content, design, graphics, logos, trademarks, and
                        other intellectual property displayed on the Website are
                        owned by Modern Spaaces and are protected by applicable
                        copyright, trademark, and other intellectual property
                        laws. You may not reproduce, distribute, modify,
                        transmit, or use any of the intellectual property
                        displayed on the Website without our prior written
                        consent.
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="mx-md-auto mb-4">
                        <h3>Use of the Website : </h3>
                        You agree to use the Website for lawful purposes and in
                        a manner that does not infringe the rights of others,
                        restrict or inhibit anyone else's use and enjoyment of
                        the Website, or violate any applicable laws or
                        regulations. You are prohibited from using the Website
                        to transmit or post any material that is unlawful,
                        defamatory, obscene, offensive, or that infringes upon
                        the rights of any third party.
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="mx-md-auto mb-4">
                        <h3>Accuracy of Information: </h3>
                        While we strive to provide accurate and up-to-date
                        information on the Website, we make no representations
                        or warranties of any kind, express or implied, about the
                        completeness, accuracy, reliability, suitability, or
                        availability of the information, products, services, or
                        related graphics contained on the Website for any
                        purpose. Any reliance you place on such information is
                        therefore strictly at your own risk.
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="mx-md-auto mb-4">
                        <h3>Third-Party Links: </h3>
                        The Website may contain links to third-party websites
                        that are not owned or controlled by Modern Spaaces. We
                        have no control over the content, privacy policies, or
                        practices of any third-party websites. We do not endorse
                        or assume any responsibility for the content, accuracy,
                        or reliability of any third-party websites linked to
                        from the Website. Your use of any third-party websites
                        is solely at your own risk.
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="mx-md-auto mb-4">
                        <h3>Limitation of Liability: </h3>
                        In no event shall Modern Spaaces be liable for any
                        direct, indirect, incidental, special, or consequential
                        damages, including, but not limited to, damages for loss
                        of profits, goodwill, use, data, or other intangible
                        losses resulting from:
                        <ul
                            style={{ listStyle: "initial", marginLeft: "2rem" }}
                        >
                            <li>The use or inability to use the Website.</li>
                            <li>
                                The unauthorized access to or alteration of your
                                transmissions or data.
                            </li>
                            <li>
                                Any conduct or content of any third party on the
                                Website.{" "}
                            </li>
                            <li>
                                Any errors or omissions in the content displayed
                                on the Website.
                            </li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="mx-md-auto mb-4">
                        <h3>Indemnification: </h3>
                        You agree to indemnify and hold harmless Modern Spaaces,
                        its directors, employees, agents, and affiliates from
                        and against any and all claims, liabilities, damages,
                        losses, costs, or expenses, including attorney's fees,
                        arising out of or related to your use of the Website or
                        any violation of these Terms.
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="mx-md-auto mb-4">
                        <h3>Modifications to the Website and Terms: </h3>
                        Modern Spaaces reserves the right to modify, suspend, or
                        discontinue the Website at any time without prior
                        notice. We may also revise these Terms at any time by
                        updating this page. By continuing to use the Website
                        after any modifications to the Terms, you accept and
                        agree to abide by the updated Terms.
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="mx-md-auto mb-4">
                        <h3>Governing Law and Jurisdiction: </h3>
                        These Terms shall be governed by and construed in
                        accordance with the laws of Maharashtra State Governing
                        jurisdiction. Any disputes arising out of or in
                        connection with these Terms shall be subject to the
                        exclusive jurisdiction of the courts in Karnataka
                        jurisdiction.
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="mx-md-auto mb-4">
                        <h3>Severability: </h3>
                        If any provision of these Terms is found to be invalid
                        or unenforceable, the remaining provisions shall
                        continue to be valid and enforceable to the fullest
                        extent permitted by law.
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="mx-md-auto mb-4">
                        <h3>Entire Agreement: </h3>
                        These Terms constitute the entire agreement between you
                        and Modern Spaaces regarding your use of the Website and
                        supersede any prior agreements or understandings,
                        whether written or oral, relating to the subject matter
                        herein.
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="mx-md-auto mb-4">
                        <h3>Waiver: </h3>
                        The failure of Modern Spaaces to enforce any right or
                        provision of these Terms shall not constitute a waiver
                        of such right or provision. Any waiver of any provision
                        of these Terms will be effective only if it is in
                        writing and signed by Modern Spaaces.
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="mx-md-auto mb-4">
                        <h3>Contact Information: </h3>
                        If you have any questions or concerns regarding these
                        Terms, please contact us at:
                        <br />
                        <br />
                        Effective Date: This Disclaimer is updated as of 20th
                        November 2023.
                        <br />
                        <br />
                        Please note that this is a general template for Terms
                        and Conditions and may need to be customized to suit the
                        specific practices and requirements of Modern Spaaces.
                        It is recommended to seek legal counsel to ensure
                        compliance with applicable laws and regulations.
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default TermsAndConditions;
