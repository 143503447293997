import { Container, Row, Col } from "react-bootstrap";
import { Heading } from "../../Global/Typography";
import BlockCard from "../../Global/BlockCard";
// import BlocksImage from "../../../images/Urbanest/Blocks/BlocksImage.png";

// import Blocks302WestImage from "../../../images/Project302/302-West.webp";
// import Blocks77EastImage from "../../../images/Project77/77-East-phase-01.webp";
// import Blocks312WestImage from "../../../images/Project312/312-West.webp";
// import Blocks77EastPahse1Image from "../../../images/Project77/77-East-phase-02.webp";

import PrevArrow from "../../../images/Common/PrevArrow.svg";
import NextArrow from "../../../images/Common/NextArrow.svg";

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "./Blocks.scss";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const Blocks302WestImage = "/images/Urbanest/Blocks/302-west-block-banner.webp";
const Blocks77EastImage = "/images/Urbanest/Blocks/77-east-block-phase2-banner.webp";
const Blocks312WestImage = "/images/Urbanest/Blocks/312-west-block-banner.webp";
const Blocks77EastPahse1Image = "/images/Urbanest/Blocks/77-east-block-phase1-banner.webp";

export default function Blocks({ inView }) {
  const BlocksData = [
    {
      toHref: "project302",
      image: Blocks302WestImage,
      imgAlt:"302 West by Urbanest",
      title: "302 West",
      phases: "Handed Over",
      // phases: "Ready to move in",
      type: "2 & 3 BHK",
      SqFt: "1021- 1469 sq ft",
      price: "40.8 Lacs",
      label: "Onwards",
      // unit: "112 Units",
      unit: "122 Units",
      possession: "Dec 2022",
      isAvailable: false,
    },
    {
      toHref: "project77",
      image: Blocks77EastImage,
      imgAlt:"77 East by Urbanest",
      title: "77 East",
      phases: "Handed Over",
      // phases: "Phase 2 - Ready to move in",
      type: "3 BHK",
      SqFt: "1410- 1445 sq ft",
      price: "60 Lacs",
      label: "Onwards",
      unit: "48 Units",
      possession: "Apr 2022",
      isAvailable: false,
    },
    {
      // toHref: "project312",
      image: Blocks312WestImage,
      imgAlt:"312 West by Urbanest",
      title: "312 West",
      phases: "Handed Over",
      type: "1, 2, & 3 BHK ",
      SqFt: "580- 1447 sq ft",
      price: "23 Lacs",
      label: "Onwards",
      unit: "112 Units",
      possession: "Dec 2022",
      isAvailable: false,
    },
    {
      // toHref: "project77",
      image: Blocks77EastPahse1Image,
      imgAlt:"77 East by Urbanest",
      title: "77 East",
      phases: "Handed Over",
      type: "3 BHK ",
      SqFt: "1445 sq ft",
      price: "60 Lacs",
      label: "Onwards",
      unit: "48 Units",
      possession: "May 2021",
      isAvailable: false,
    },
  ];

  return (
    <section
      className={`section bg-white Blocks pt-md-0 ${
        inView ? "inView" : "outView"
      }
    `}
    >
      <Container className="Blocks-Container position-relative">
        <Row className=" ">
          <Col
            xs={12}
            md={6}
            lg={6}
            xl={12}
            className="pb-4 pb-md-5 text-center"
          >
            <Heading variant="h1" classList="Blocks__title" text="Blocks" />
          </Col>
          <Col xs={12} className="px-md-4">
            <div className="Blocks__Slider">
              <Swiper
                className="BlocksSlider"
                id="BlocksSlider"
                tag="section"
                wrapperTag="ul"
                spaceBetween={10}
                loop={false}
                keyboard={{
                  enabled: true,
                }}
                navigation={{
                  prevEl: ".ArrowButtonBlock__ArrowPrevBlocks",
                  nextEl: ".ArrowButtonBlock__ArrowNextBlocks",
                }}
                onSwiper={(swiper) => console.log(swiper)}
                // onSlideChange={(swiper) =>
                //   console.log("slide index changed to: ", swiper.activeIndex)
                // }
                onInit={(swiper) => console.log("Swiper initialized", swiper)}
                breakpoints={{
                  300: {
                    slidesPerView: 1,
                    spaceBetween: 30,
                  },
                  450: {
                    slidesPerView: 2,
                    spaceBetween: 0,
                  },

                  768: {
                    slidesPerView: 2,
                    spaceBetween: 0,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 0,
                  },
                  1300: {
                    slidesPerView: 4,
                    spaceBetween: 0,
                  },
                }}
              >
                {BlocksData.map((item, index) => (
                  <SwiperSlide tag="li" key={index}>
                    <BlockCard item={item} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </Col>
        </Row>
        <Row className="ArrowButtons ArrowButtons--LgArrows">
          <div className="ArrowButtonBlock">
            <div className="ArrowButtonBlock__Arrow ArrowButtonBlock__ArrowPrevBlocks">
              <img src={PrevArrow} className="-image" alt="Blocks" />
            </div>
            <div className="ArrowButtonBlock__Arrow ArrowButtonBlock__ArrowNextBlocks">
              <img src={NextArrow} className="-image" alt="Blocks" />
            </div>
          </div>
        </Row>
      </Container>
    </section>
  );
}
