import { Row } from "react-bootstrap";
import { Desc, Heading } from "../../Global/Typography";
// AnchorButtonIcon,
// import SmartWaterMeter from "../../../images/SoulTree/SmartGreenMinimal/SmartGreenMinimalImage3.png";
// import crossVentilation from "../../../images/Soulace/smartgreenmininal/crossventilation.webp";
// import solarpowered from "../../../images/Soulace/smartgreenmininal/solarpowered.webp";
// import waterConservation from "../../../images/Soulace/smartgreenmininal/waterConservation.webp";
// import NaturalLight from "../../../images/Soulace/smartgreenmininal/naturalLight.webp";
import PrevArrow from "../../../images/Common/PrevArrow.svg";
import NextArrow from "../../../images/Common/NextArrow.svg";
import "./SmartGreenMinimal.scss";
import KidsPlayArea from "../../../images/SoulTree/ThereIsSomething/KidsPlayArea.webp";

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import SMTPTransport from "nodemailer/lib/smtp-transport";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const SmartWaterMeter = "/images/Soulace/smartgreenminimal/soulace-smart-water-meter-banner.png";
const crossVentilation = "/images/Soulace/smartgreenminimal/soulace-cross-ventilation-banner.webp";
const solarpowered = "/images/Soulace/smartgreenminimal/soulace-solar-powered-banner.webp";
const waterConservation = "/images/Soulace/smartgreenminimal/soulace-water-conservation-banner.webp";
const NaturalLight = "/images/Soulace/smartgreenminimal/soulace-natural-light-banner.webp";

export default function SmartGreenMinimal({ inView }) {
    // const SmartGreenMinimalData = [
    //   {
    //     title: "Smart Home",
    //     desc: "Our homes come with fully automated home automation facilities. Now control your entire home with just a mobile app. Unlock the future in our smart homes and experience customized, luxury living.",
    //     image: SmartGreenMinimalImage1,
    //   },
    //   {
    //     title: "Natural Light",
    //     desc: "Our rooms, terraces & balconies are designed to take advantage of the Bangalore sun and bathe in natural light. The homes are compact and smart with good views, light and fresh air.",
    //     //  All you need for a productive day and a good night’s sleep.
    //     image: NaturalLight,
    //   },
    //   {
    //     title: "New-age Design",
    //     desc: "A work of art inside and out. At Modern Spaaces, the passion for design transcends architecture and is reflected in the utmost care in the selection of equipment and materials. ",
    //     // Form, function and robustness, in perfect harmony.
    //     image: NewAgeDesign,
    //   },
    //   {
    //     title: "Smart Water Meter",
    //     desc: "Introducing Smart Water Meters which allow you to measure, control & conserve your water consumption including controlling the taps in the house remotely, leakage detector with automated alerts & smart analytics.",
    //     image: SmartGreenMinimalImage3,
    //   },
    //   {
    //     title: "Feel good. Feel Happy",
    //     desc: "At Soul Tree, quality of life is the absolute priority. Our clubhouse attests to that. The 23,000 sq ft clubhouse has a half indoor, partially covered swimming pool for adults and toddlers. ",

    //     // It is designed to keep all your needs in mind from a host of indoor games, Badminton & Squash Court to a fully-equipped gym, a Library, Lego Room, Market Retail, ATM, Pharmacy & Laundry.
    //     image: KidsPlayArea,
    //   },
    // ];
    const SmartGreenMinimalData = [
        {
            title: "Smart water meter",
            desc: "Discover the innovative Smart Water Meters: designed to monitor, manage, and minimize your water usage. These advanced meters come with remote tap control, leakage detection, and smart analytics for a comprehensive approach to water conservation.",
            image: SmartWaterMeter,
            imgAlt:"Smart Water Meters",
        },
        {
            title: "Cross ventilation",
            desc: "Our homes are thoughtfully designed to enhance cross ventilation, significantly reducing the need for artificial cooling and lowering energy bills.",
            image: crossVentilation,
            imgAlt:"Cross Ventilation Villas",
        },
        {
            title: "Solar powered",
            desc: "Villas equipped with solar panels provide a sustainable energy solution, greatly reducing the environmental impact. They not only harness renewable energy but also lead to considerable savings on power bills for homeowners.",
            image: solarpowered,
            imgAlt:"Solar Powered",
        },
        {
            title: "Water Conservation",
            desc: "Dual piping & dual flush system for sanitary. All landscapes maintained through recycled water, water efficient fixtures, rain water harvesting and groundwater recharge.",
            image: waterConservation,
            imgAlt:"Water Conservation",
        },
        {
            title: "Natural Light",
            desc: "Our rooms,terraces & balconies are designed to take advantage of natural light.The homes are compact and smart with ample light and fresh air.",
            image: NaturalLight,
            imgAlt:"Natural Light",
        },
    ];
    return (
        <section
            className={`section bg-white SmartGreenMinimal ${
                inView ? "inView" : "outView"
            }
    `}
        >
            <div className="SmartGreenMinimal__Container">
                <div className="SmartGreenMinimal__Row">
                    <div className="SmartGreenMinimal__Content">
                        <Heading
                            variant="h1"
                            classList="SmartGreenMinimal__title"
                            text="Smart, Sustainable, Efficient"
                        />
                        <Desc
                            classList="SmartGreenMinimal__desc my-3 "
                            text="Explore Soulace"
                        />
                        {/* <AnchorButtonIcon
              href="#"
              classList="SmartGreenMinimal__btn mt-3"
              text="Download Brochure"
              icon={false}
            /> */}
                    </div>
                    <div className="SmartGreenMinimal__Slider">
                        <Swiper
                            className="SmartGreenMinimalSlider"
                            id="SmartGreenMinimalSlider"
                            tag="section"
                            wrapperTag="ul"
                            loop={true}
                            spaceBetween={10}
                            keyboard={{
                                enabled: true,
                            }}
                            navigation={{
                                prevEl: ".ArrowButtonBlock__ArrowPrevSmart",
                                nextEl: ".ArrowButtonBlock__ArrowNextSmart",
                            }}
                            onSwiper={(swiper) => console.log(swiper)}
                            // onSlideChange={(swiper) =>
                            //   console.log("slide index changed to: ", swiper.activeIndex)
                            // }
                            onInit={(swiper) =>
                                console.log("Swiper initialized", swiper)
                            }
                            breakpoints={{
                                300: {
                                    slidesPerView: 1.2,
                                },

                                768: {
                                    slidesPerView: 3,
                                    spaceBetween: 3,
                                },
                                1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 3,
                                },
                                1300: {
                                    slidesPerView: 3,
                                    spaceBetween: 3,
                                },
                            }}
                        >
                            {SmartGreenMinimalData.map(
                                ({ title, desc, image , imgAlt }, index) => (
                                    <SwiperSlide tag="li" key={index}>
                                        <div className="-image-block">
                                            <img
                                                src={image}
                                                className="SmartGreenMinimal__image"
                                                alt={imgAlt}
                                            />
                                            <div className="-content-block">
                                                <Heading
                                                    variant="h5"
                                                    classList="-title"
                                                    text={title}
                                                />
                                                <Desc
                                                    classList="-desc mt-3 mb-0"
                                                    text={desc}
                                                />
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )
                            )}
                        </Swiper>
                        <Row className="ArrowButtons">
                            <div className="ArrowButtonBlock">
                                <div className="ArrowButtonBlock__Arrow ArrowButtonBlock__ArrowPrevSmart">
                                    <img
                                        src={PrevArrow}
                                        className="-image"
                                        alt="SmartGreenMinimal"
                                    />
                                </div>
                                <div className="ArrowButtonBlock__Arrow ArrowButtonBlock__ArrowNextSmart">
                                    <img
                                        src={NextArrow}
                                        className="-image"
                                        alt="SmartGreenMinimal"
                                    />
                                </div>
                            </div>
                        </Row>
                    </div>
                </div>
            </div>
        </section>
    );
}
