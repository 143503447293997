import {
  completedProjects,
  ongoingProjects,
  upcomingProjects,
} from "../header/constants";

export const ProjectsList = [
  {
    type: "Ongoing Projects",
    items: ongoingProjects,
  },
  {
    type: "Upcoming Projects",
    items: upcomingProjects,
  },
  {
    type: "Completed Projects",
    items: completedProjects,
  },
];
