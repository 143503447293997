import Simpleguide from "../../src/images/Blogs/propertyTax.png";
import AssessmentProperty from "../../src/images/Blogs/assessmentproperty.png";
import Mortgage from "../../src/images/Blogs/Mortgage.png";

const data = [
    {
        id: "abc103",
        slug: "simple-guide-to-understanding-property-tax ",
        image: Simpleguide,
        image1: AssessmentProperty,
        image2: Mortgage,
        label: "Industry",
        title: "A simple guide to understanding Property taxes",
        publishedOn: "Dec, 2021",
        time: "2 min read",
        description:
            "India's real estate sector is a vital part of the country's economy, contributing about 7% to the GDP and generating numerous job opportunities. However, it faced a lack of proper regulations for a long time. To tackle this issue, the Indian government introduced the RERA Act in 2016. This law aimed to bring fairness and clear rules for both homebuyers and construction companies. Its goal was to fix the confusion and differences in how things were managed in the real estate industry ",
        authorImage:
            "https://images.unsplash.com/photo-1600188769099-d25b4ec79659?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
        authorName: "Noveen Reddy",
        department: "Marketing Head",
        contactNumber: "987654321",
        // imageOther: "https://modernspaaces.com/BlogListImages/BlogFour/Img01.webp",
        description1:
            "The earliest documented instance of taxation emerged in ancient Egypt, as historical records show the Pharaoh implementing a tax system that involved levying a 20 percent tax on all grain harvests. So taxes throughout history have always remained a crucial part of human existence, representing a fundamental aspect of human societies.",
        description11:
            "Even today, taxes continue to play a vital role in funding essential public services, taking various forms like income tax, Goods and Services Tax (GST), and property tax.",
        title1: "What is Property Tax?",
        title1description:
            "A property tax is a tax imposed by local or state governments on the value of real estate, which includes land and any structures or improvements on that land, such as buildings and homes. Property taxes are typically collected on an annual basis, and the amount owed is determined based on the assessed value of the property. Property owners are responsible for paying this tax to fund various local government services and infrastructure projects.",
        typesofproperty: [
            {
                label: "Types of Properties Subject to Property Taxes",
                list: [
                    "Residential properties",
                    "Commercial properties ",
                    "Vacant land",
                    "Industrial properties",
                ],
            },
        ],
        title2: "Assessment of Property",

        assessmentlist: [
            {
                label: "Self-Assessment",
                description:
                    " In many Indian cities, property owners are required to self-assess their property tax liabilities. This involves determining the property's value, by applying the relevant formula, and paying the calculated tax. But self-assessment is subject to audit by municipal authorities.",
            },
            {
                label: "Property Tax Assessment by Municipal Authorities",
                description:
                    "In some areas, municipal authorities assess the property tax themselves. They may conduct surveys and inspections to determine the property's value and tax amount. Property owners receive a tax assessment notice, and they can appeal if they disagree with the assessment.",
            },
            {
                label: "Re-Assessment and Revisions",
                description:
                    "Property tax assessments can be revised or updated under various circumstances, such as changes in property usage, renovations, or discrepancies in the initial assessment. Property owners must inform the municipal authorities of such changes.",
            },
        ],

        title3: "What are some examples of things that might be paid for with property tax revenue?",
        title3description: "Property tax serves several purposes:",
        propertytaxpurpose: [
            {
                label: "Revenue Generation",
                description:
                    "It provides a steady stream of income for municipal corporations to maintain and develop local infrastructure",
            },
            {
                label: "Equity",
                description:
                    "It ensures that property owners contribute to the cost of public services based on the value of their property.",
            },
            {
                label: "Local Development",
                description:
                    " The revenue generated is reinvested into local communities to improve living conditions.",
            },
            {
                label: "Public Education",
                description:
                    " Property taxes are a significant source of funding for public schools at the local level. They support educational infrastructure, teacher salaries, and educational programs.",
            },
            {
                label: "Public Safety",
                description:
                    "Property tax revenue can be used to fund police departments, fire departments, and emergency services. This includes salaries, equipment, and facilities.",
            },
            {
                label: "Parks and Recreation",
                description:
                    "Local parks, recreational facilities, and community centers often rely on property tax revenue for maintenance and improvements. This includes funding for parks, playgrounds, and sports facilities.",
            },
            {
                label: "Health Services",
                description:
                    "Property taxes can support local health services, such as clinics, vaccination programs, and public health initiatives.",
            },
            {
                label: "Social Services",
                description:
                    "Property tax revenue may be used for social welfare programs, such as assistance for low-income residents, affordable housing initiatives, and support for the homeless.",
            },
            {
                label: "Public Libraries",
                description:
                    "Local libraries receive funding from property taxes to maintain their collections, facilities, and provide services to the community.",
            },
            {
                label: "Waste Management",
                description:
                    "Property tax funds can be allocated to waste collection, recycling programs, and landfill management.",
            },
        ],
        title4: "How property Taxes are calculated?",
        title4description:
            "Property taxes in India are calculated based on the assessed value of the property. There are three primary methods for calculating property tax:",
        propertytaxcalculation: [
            {
                label: "Capital Value System (CVS)",
                list: [
                    "In this system, the tax is a percentage of the property's market value, determined by the government based on the property's location. Annually, the market value is revised and updated",
                    "Cities followed in Mumbai ",
                ],
            },
            {
                label: "Annual Rental Value System or Rateable Value System (RVS)",
                list: [
                    "Property tax is calculated based on the yearly rental value set by the municipal authority; factors like property size, condition, location, and amenities are considered.",
                    "Cities followed in Hyderabad, Chennai. ",
                ],
            },
            {
                label: "Unit Area Value System (UAS)",
                list: [
                    "This system levies tax based on the per unit price of the built-up area. The price per square foot per month is determined by factors like location, land value, and expected returns. It's then multiplied by the property's built-up area.",
                    "Cities followed in Delhi, Kolkata, Bengaluru, Patna, and Hyderabad ",
                ],
            },
            {
                label: "In Bangalore, the Bruhat Bengaluru Mahanagara Palike (BBMP) uses a Unit Area Value (UAV) system to determine the property tax on residential properties. The Department of Stamps and Registration has divided the BBMP's jurisdiction into six distinct value zones.These value zones affect the property tax rate applied to properties in different parts of the city. The property tax rate is determined based on the specific zone in which the property is situated.",
            },
        ],
        title8: "BBMP  Forms",
        title8description:
            "In Bangalore, property owners are required to use specific BBMP property tax forms to facilitate the tax payment process. There are six primary forms tailored to different property situations:",
        BbmpForms: [
            {
                label: "Form 1",
                description:
                    " Meant for property owners with a Property Identification Number (PID), which contains comprehensive property details, including road, plot, and ownership information.",
            },
            {
                label: "Form 2",
                description:
                    " Intended for property owners without a PID but with a Khata number, an identifier associated with the Khata certificate that offers detailed property information.",
            },
            {
                label: "Form 3",
                description:
                    " Used by property owners lacking both a PID and Khata number for their tax payments.",
            },
            {
                label: "Form 4",
                description:
                    "Employed when property information remains unchanged, covering factors like property size and status.",
            },
            {
                label: "Form 5",
                description:
                    "Required when property owners have made alterations to their property, such as adding new floors or changing the property's status.",
            },
            {
                label: "Form 6",
                description:
                    " Designed for property owners eligible for property tax exemptions, simplifying the process for qualifying individuals.",
            },
        ],
        title8Conclusion:
            "These BBMP property tax forms are designed to categorize and address various property scenarios, making it more convenient for property owners to meet their tax obligations effectively",

        title5: "Types of House Property in Income Tax",
        title5list: [
            "House property tax is applicable to various types of properties, such as residential homes, offices, shops, buildings, and land areas like parking lots.",
            "Under the Income Tax Act, there is no distinction between commercial and residential properties; they all fall under the category of 'income from house property' when it comes to income tax returns.",
            "For tax purposes, the owner of a property is the legal owner who can exercise their ownership rights independently.",
            "If a property is used for business, professional activities, or freelancing work, it is subject to taxation under the 'income from business and profession' category. This allows for the deduction of expenses related to its repair and maintenance as valid business expenditures.",
        ],

        title6: "Does paying property tax give ownership in India?",
        title6list: [
            "Property ownership in India is determined by the title deed and legal documentation related to the property. Owning a property in India involves having a sale deed or title deed in your name, which is a legal document proving ownership rights. ",
            "Paying property tax on the other hand is a mandatory financial obligation for property owners, and it is essential to ensure compliance with local municipal laws.",
            "Remember paying property tax does not automatically transfer legal ownership rights to the person paying taxes.",
        ],

        title7: "Does my mortgage pay my property taxes?",
        title7description:
            "In India, property taxes are not included in your mortgage payment, and you are responsible for paying them separately to your local municipal authority or municipal corporation. Property tax payments are paid annually either through offline or online mode. Failure to pay property taxes by the stipulated time results in a penalty of 2% per month, which accumulates over time.",

        conclision: "Conclusion",
        ConclusionData: [
            "In conclusion, property taxes in India play a critical role in local government finances and contribute to funding essential public services. Property owners, whether for residential, commercial, vacant land, or industrial properties, are responsible for paying these taxes, and they can be calculated through various methods, depending on the location.",
            "Property tax revenue serves as a vital source of income for municipal corporations, supporting infrastructure development, public education, safety services, and various community amenities.",
            "Understanding property taxes and their role in local governance is essential for property owners in India to ensure compliance and contribute to the maintenance and development of their communities.",
        ],

        Faq: "FAQs",
        Faqs: [
            {
                label: "Does paying property tax give ownership in India?",
                list: [
                    "Paying property taxes does not automatically transfer ownership of   property rights.",
                ],
            },
            {
                label: "How do property taxes work when you buy a house?",
                list: [
                    "Paying property taxes does not automatically transfer ownership of   property rights.",
                    "Capital Value System",
                    "Rateable Value System",
                    "Unit Area Value system",
                ],
            },
        ],
    },
];

export default data;
