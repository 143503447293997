import { Col, Container, Row } from "react-bootstrap";
import { Desc, Heading } from "../../Global/Typography";
// AnchorButton,
import { useState } from "react";

import NextArrow from "../../../images/Common/NextArrow.svg";
import PrevArrow from "../../../images/Common/PrevArrow.svg";

// import outdoorDining from "../../../images/Soulace/outdoorseating/image 13.webp";
// import solitudeNook from "../../../images/Soulace/outdoorseating/image 14.webp";
// import chitChatZone from "../../../images/Soulace/outdoorseating/image 15.webp";
// import silentWorkPod from "../../../images/Soulace/outdoorseating/image 16.webp";
// import seniorsCourt from "../../../images/Soulace/outdoorseating/image 17.webp";
// import meditationGarden from "../../../images/Soulace/outdoorseating/image 18.webp";
// import gym from "../../../images/Soulace/outdoorseating/image 19.webp";
// import produceGarden from "../../../images/Soulace/outdoorseating/image 20.webp";

import OutdoorSeating1 from "../../../images/SoulTree/ThereIsSomething/OutdoorSeating1.webp";

// import engrace6 from "../../../images/Engrace/outdoorseating/engrace6.webp";
import SwiperCore, {
    A11y,
    Autoplay,
    Navigation,
    Pagination,
    Scrollbar,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import DownloadBrochure from "../../Global/DownloadBrochure";
import "./OutdoorSeating.scss";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

const negligibleCommonWalls =
    "/images/EngraceVista/outdoorSeating/no_common_walls.webp";
const locatedOnSarjapurRoad =
    "/images/SereneHeights/OutdoorSeating/located-on-sarjapur-road.jpg";
const openLayout = "/images/SereneHeights/OutdoorSeating/open-layout.jpg";
const superiorFixtures =
    "/images/SereneHeights/OutdoorSeating/superior-fixtures.jpg";

const outdoorDining =
    "/images/Soulace/outdoorseating/soulace-outdoor-dining-banner.webp";
const solitudeNook =
    "/images/Soulace/outdoorseating/soulace-solitude-nook-banner.webp";
const chitChatZone =
    "/images/Soulace/outdoorseating/soulace-chit-chat-zone-banner.webp";
const silentWorkPod =
    "/images/Soulace/outdoorseating/soulace-silent-work-pod-banner.webp";
const seniorsCourt =
    "/images/Soulace/outdoorseating/soulace-seniors-court-banner.webp";
const meditationGarden =
    "/images/Soulace/outdoorseating/soulace-meditaion-garden-banner.webp";
const gym = "/images/Soulace/outdoorseating/soulace-gym-banner.webp";
const produceGarden =
    "/images/Soulace/outdoorseating/soulace-produce-garden-banner.webp";

export default function OutdoorSeating({ inView }) {
    const [show, setShow] = useState(false);
    const handleShow = (e) => {
        e.preventDefault();
        setShow(true);
    };
    // console.log(
    //   "🚀 ~ file: OutdoorSeating.js ~ line 31 ~ handleShow ~ handleShow",
    //   handleShow
    // );
    const handleClose = () => setShow(false);

    const OutdoorSeatingData = [
        {
            title: "There is a little something for each member of your family",
            desc: "Area designed to satisfy your outdoor needs and keep you motivated to work and socialize. With the various facilities available at your fingertips, you can enjoy a calm space to work or inspire yourself to break a sweat.",
            // button: "Download Clubhouse Brochure",
            button: "Download Amenities Brochure",
            imageList: [
                {
                    image: negligibleCommonWalls,
                    imgAlt: "Negligible Common Walls",
                    // label: "Sports Area",
                    title: "Negligible Common Walls",
                    desc: "From minimal to no common wall, units are designed to maximize privacy of all residents.",
                    position: "80%",
                },
                {
                    image: locatedOnSarjapurRoad,
                    imgAlt: "Located On Sarjapur Road",
                    // label: "Soultree Court",
                    title: "Located On Sarjapur Road",
                    desc: "Just a 2-minute walk from the upcoming Sompura Gate Metro Station, offering unparalleled connectivity.",
                    position: "70%",
                },
                {
                    image: openLayout,
                    imgAlt: "Open layout",
                    // label: "Kids Play Area",
                    title: "Open layout",
                    desc: "Every unit features an open layout to enhance airflow and ensure ample natural light.",
                    position: "100%",
                },
                // {
                //     image: silentWorkPod,
                //     imgAlt: "Marble Finish Tiles",
                //     // label: "Sports Area",
                //     title: "Marble Finish Tiles",
                //     desc: "Experience the elegance and allure of marble finish tiles in each home.",
                //     position: "80%",
                // },
                {
                    image: superiorFixtures,
                    imgAlt: "Superior Fixtures",
                    // label: "Sports Area",
                    title: "Superior Fixtures",
                    desc: "Quality and longevity define our fixtures and fittings, offering exceptional value for money and enduring durability.",
                    position: "90%",
                },
                // {
                //     image: meditationGarden,
                //     imgAlt: "2 Car Park for every 3 BHK",
                //     // label: "Sports Area",
                //     title: "2 Car Park for every 3 BHK",
                //     desc: "Enjoy ample space and ultimate convenience for your family",
                //     position: "20%",
                // },
            ],
            image: OutdoorSeating1,
            label: "Outdoor seating",
        },
    ];

    return (
        <section
            className={`section bg-white ${inView ? "inView" : "outView"}
    `}
        >
            {OutdoorSeatingData.map((item, idx) => {
                return (
                    <Container
                        key={idx}
                        className="OutdoorSeating OutdoorSeating--small"
                    >
                        <div className="OutdoorSeating__Slider">
                            <Swiper
                                className="OutdoorSeatingSlider"
                                id="OutdoorSeatingSlider"
                                tag="section"
                                wrapperTag="ul"
                                spaceBetween={0}
                                keyboard={{
                                    enabled: true,
                                }}
                                speed={500}
                                loop={true}
                                autoplay={true}
                                pagination={{
                                    clickable: true,
                                    el: ".swiper-pagination-OutdoorSeating",
                                }}
                                navigation={{
                                    prevEl: ".ArrowButtonBlock__ArrowPrevWhite",
                                    nextEl: ".ArrowButtonBlock__ArrowNextWhite",
                                }}
                                onSwiper={(swiper) => console.log(swiper)}
                                // onSlideChange={(swiper) =>
                                //   console.log("slide index changed to: ", swiper.activeIndex)
                                // }
                                onInit={(swiper) =>
                                    console.log("Swiper initialized", swiper)
                                }
                                breakpoints={{
                                    300: {
                                        slidesPerView: 1,
                                        spaceBetween: 30,
                                    },
                                }}
                            >
                                {item.imageList.map((item, index) => (
                                    <SwiperSlide tag="li" key={index}>
                                        <Row className="align-items-center">
                                            <Col
                                                xs={12}
                                                md={6}
                                                lg={6}
                                                xl={5}
                                                className="px-3 px-md-5"
                                            >
                                                <div className="imagePatch">
                                                    <img
                                                        src={item.image}
                                                        className="OutdoorSeating__image w-100 mb-0"
                                                        alt={item.imgAlt}
                                                        style={{
                                                            objectPosition: `${item.position}`,
                                                        }}
                                                    />
                                                    {/* <span className="-label">
                                                        {item.title}
                                                    </span> */}
                                                </div>
                                            </Col>
                                            <Col
                                                xs={12}
                                                md={6}
                                                lg={6}
                                                xl={7}
                                                className="pt-5 pt-md-0 px-4 px-md-5"
                                            >
                                                <Heading
                                                    variant="h1"
                                                    classList="OutdoorSeating__title"
                                                    text={item.title}
                                                />
                                                <Desc
                                                    classList="OutdoorSeating__desc mt-3 mb-5 pb-5"
                                                    text={item.desc}
                                                />
                                                {/* <AnchorButton
                          href="#"
                          classList="OutdoorSeating__btn my-3"
                          text={item.button}
                          onClick={handleShow}
                        /> */}
                                            </Col>
                                        </Row>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                            <Row className="ArrowButtons pl-md-3">
                                <div className="swiper-pagination swiper-pagination-OutdoorSeating"></div>
                                {/* <div className="swiper-pagination swiper-pagination-OutdoorSeating bg-trans"></div> */}
                                <div className="ArrowButtonBlock">
                                    <div className="ArrowButtonBlock__Arrow ArrowButtonBlock__ArrowPrevWhite">
                                        <img
                                            src={PrevArrow}
                                            className="-image"
                                            alt="Blocks"
                                        />
                                    </div>
                                    <div className="ArrowButtonBlock__Arrow ArrowButtonBlock__ArrowNextWhite">
                                        <img
                                            src={NextArrow}
                                            className="-image"
                                            alt="Blocks"
                                        />
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </Container>
                );
            })}

            {/* DownloadBrochure */}
            <DownloadBrochure
                title={"Download Brochure"}
                show={show}
                handleClose={handleClose}
            />
        </section>
    );
}
