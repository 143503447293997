import { Col, Container, Row } from "react-bootstrap";
import { Desc, DescLabel } from "../../../Global/Typography";
import BannerNew from "../../BannerNew";
import "../../BlogReraInfluence/Banner/BlogRera.scss";
import { Accordion, Card, Button } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

import BlogFourData from "../../../../db/BlogReraInfluence";
import ShareButton from "../../Share/ShareButton";

export default function Banner({ inView }) {
    const currentPage =
        "Discover how the RERA Act impacts home buyers and developers in India. Learn more about the basic principles and the impact it has upon home buyers and developers";
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0"
                />
                <title>
                    RERA's Influence | How does It Benefit the Homeowners and
                    Developers? | Modern Spaaces
                </title>
                <meta
                    name="Keywords"
                    content="What is RERA act , Real estate Act,Rera authority, RERA property, What is RERA in real estate"
                />
                <meta
                    name="description"
                    content="Discover how the RERA Act impacts home buyers and developers in India. Learn more about the basic principles and the impact it has upon home buyers and developers."
                />
            </Helmet>
            <section className={`section rera ${inView ? "inView" : "outView"}`}>
                {/* <Container className="container">
        <Row className="align-items-center">
          <Col xs={12} md={12} lg={12} className="py-2">
            <BreadcrumbBlogCard item={currentPage} />
          </Col>
        </Row>
      </Container> */}

                <BannerNew 
                    image={BlogFourData[0].image}
                    title={BlogFourData[0].title}
                    publishedOn={BlogFourData[0].publishedOn}
                    time={BlogFourData[0].time}
                    alt={BlogFourData[0].imgAlt}
                />

                <Container className="blog-detail-container ">
                    {BlogFourData.map((item, idx) => {
                        return (
                            <Row key={idx} className="justify-content-around">
                                {/* {item.image && (
                <Col xs={12} md={12} lg={12} className="pb-4">
                  <img
                    src={item.image}
                    alt={item.title}
                    className="-blog-banner"
                  />
                </Col>
              )} */}
                                <Col
                                    xs={12}
                                    md={11}
                                    lg={9}
                                    className="pb-4 pr-md-5"
                                >
                                    <div className="blog-header ">
                                        {/* {item.title && (
                    <Heading classList="my-3" variant="h1" text={item.title} />
                  )}
                  {item.publishedOn && (
                    <Desc
                      classList=""
                      text={`Published on ${item.publishedOn}`}
                    />
                  )} */}
                                        {item.description && (
                                            <Desc
                                                classList="font-benton-book header"
                                                text={item.description}
                                            />
                                        )}
                                        {item.lightDescription && (
                                            <Desc
                                                classList="font-benton-book header"
                                                text={item.lightDescription}
                                            />
                                        )}
                                    </div>

                                    {item.title2 && (
                                        <p className="-descBlack font-benton-bold margin">
                                            {item.title2}
                                        </p>
                                    )}
                                    <div className="blog-content ">
                                        {item.lightDescriptionList &&
                                            item.lightDescriptionList.map(
                                                (i0, idx) => {
                                                    return (
                                                        <div key={idx}>
                                                            <DescLabel
                                                                classList="my-1 py-2 font-benton-bold header"
                                                                text={
                                                                    i0.description
                                                                }
                                                                label={i0.label}
                                                            />
                                                            {i0.list && (
                                                                <ul className="blog-list-circle header">
                                                                    {i0.list.map(
                                                                        (
                                                                            i1,
                                                                            idx1
                                                                        ) => {
                                                                            return (
                                                                                <li
                                                                                    key={
                                                                                        idx1
                                                                                    }
                                                                                >
                                                                                    <p className="-descBlack mb-1 font-benton-book header">
                                                                                        {
                                                                                            i1
                                                                                        }
                                                                                    </p>
                                                                                </li>
                                                                            );
                                                                        }
                                                                    )}
                                                                </ul>
                                                            )}
                                                        </div>
                                                    );
                                                }
                                            )}
                                    </div>

                                    {item.paragraph && (
                                        <p className="-descBlack font-benton-book">
                                            {item.paragraph}
                                        </p>
                                    )}
                                    {item.title1 && (
                                        <p className="-descBlack font-benton-bold  margin">
                                            {item.title1}
                                        </p>
                                    )}
                                    {item.list && (
                                        <div className="blog-content ">
                                            {item.list.map((i, idx) => {
                                                return (
                                                    <DescLabel
                                                        classList="my-1 py-2 font-benton-book header "
                                                        text={i.description}
                                                        label={i.label}
                                                    />
                                                );
                                            })}
                                        </div>
                                    )}

                                    {item.title3 && (
                                        <p className="-descBlack font-benton-bold fw-bold margin">
                                            {item.title3}
                                        </p>
                                    )}

                                    {item.title3description && (
                                        <p className="-descBlack font-benton-book header">
                                            {item.title3description}
                                        </p>
                                    )}
                                    {item.Impactonbuyers && (
                                        <div className="blog-content">
                                            {item.Impactonbuyers.map(
                                                (i, idx) => {
                                                    return (
                                                        <DescLabel
                                                            classList="my-1 py-2 font-benton-book header"
                                                            text={i.description}
                                                            label={i.label}
                                                        />
                                                    );
                                                }
                                            )}
                                        </div>
                                    )}
                                    {item.title4 && (
                                        <p className="-descBlack font-benton-bold fw-bold margin">
                                            {item.title4}
                                        </p>
                                    )}
                                    {item.affectonDevelopers && (
                                        <div className="blog-content">
                                            {item.affectonDevelopers.map(
                                                (i, idx) => {
                                                    return (
                                                        <DescLabel
                                                            classList="my-1 py-2 font-benton-book header"
                                                            text={i.description}
                                                            label={i.label}
                                                        />
                                                    );
                                                }
                                            )}
                                        </div>
                                    )}
                                    {item.title5 && (
                                        <p className="-descBlack font-benton-bold fw-bold margin">
                                            {item.title5}
                                        </p>
                                    )}
                                    <div className="imagepadding">
                                        <img
                                            src={item.image2}
                                            alt={item.image2Alt}
                                            class="image margin"
                                        />
                                    </div>

                                    <div>
                                    {item.title7 && (
                                        <p className="-descBlack font-benton-bold fw-bold margin">
                                            {item.title7}
                                        </p>
                                    )}
                                    {item.description71 && (
                                        <p className="-descBlack font-benton-book header">
                                            {item.description71}
                                        </p>
                                    )}
                                    {item.description72 && (
                                        <p className="-descBlack font-benton-book header">
                                            {item.description72}
                                        </p>
                                    )}
                                    {item.description73 && (
                                        <p className="-descBlack font-benton-book header">
                                            {item.description73}
                                        </p>
                                    )}
                                    </div>
                                    <div>
                                    {item.title8 && (
                                        <p className="-descBlack font-benton-bold fw-bold margin">
                                            {item.title8}
                                        </p>
                                    )}
                                    {item.list8 && (
                                        <div className="blog-content">
                                            {item.list8.map(
                                                (i, idx) => {
                                                    return (
                                                        <DescLabel
                                                            classList="my-1 py-2 font-benton-book header"
                                                            text={i.description}
                                                            label={i.label}
                                                        />
                                                    );
                                                }
                                            )}
                                        </div>
                                    )}
                                    </div>
                                    <div>
                                    {item.title9 && (
                                        <p className="-descBlack font-benton-bold fw-bold margin">
                                            {item.title9}
                                        </p>
                                    )}
                                    {item.description91 && (
                                        <p className="-descBlack font-benton-book header">
                                            {item.description91}
                                        </p>
                                    )}   
                                    </div>
                                    {item.title6 && (
                                        <p className="-descBlack font-benton-bold fw-bold margin">
                                            {item.title6}
                                        </p>
                                    )}

                                    {item.title6description && (
                                        <p className="-descBlack font-benton-book header">
                                            {item.title6description}
                                        </p>
                                    )}
                                    {item.KarbatakaRera && (
                                        <ul className="blog-list-circle my-3 header">
                                            {item.KarbatakaRera.map(
                                                (i9, idx11) => {
                                                    return (
                                                        <li key={idx11}>
                                                            <p className="-descBlack mb-1 font-benton-book header">
                                                                {i9}
                                                            </p>
                                                        </li>
                                                    );
                                                }
                                            )}
                                        </ul>
                                    )}

                                    {item.Challenges && (
                                        <p className="-descBlack font-benton-bold fw-bold margin">
                                            {item.Challenges}
                                        </p>
                                    )}
                                    {item.challengesofrera && (
                                        <div className="blog-content">
                                            {item.challengesofrera.map(
                                                (i, idx) => {
                                                    return (
                                                        <DescLabel
                                                            classList="my-1 py-2 font-benton-book header"
                                                            text={i.description}
                                                            label={i.label}
                                                        />
                                                    );
                                                }
                                            )}
                                        </div>
                                    )}

                                    {item.ClosingThoughts && (
                                        <DescLabel
                                            classList="my-1 py-2 font-benton-book"
                                            label={item.ClosingThoughts}
                                        />
                                    )}
                                    <div className="blog-footer">
                                        <p className="-descBlack font-benton-book header">
                                            {item.noteStart && (
                                                <span>{item.noteStart}</span>
                                            )}
                                            {item.link && (
                                                <strong className="px-2">
                                                    {item.link}
                                                </strong>
                                            )}
                                            {item.noteEnd && (
                                                <span>{item.noteEnd}</span>
                                            )}
                                        </p>
                                    </div>
                                    {item.noteStartItems && (
                                        <ul className="blog-list-circle header">
                                            {item.noteStartItems.map(
                                                (i9, idx11) => {
                                                    return (
                                                        <li key={idx11}>
                                                            <p className="-descBlack mb-1 font-benton-book header">
                                                                {i9}
                                                            </p>
                                                        </li>
                                                    );
                                                }
                                            )}
                                        </ul>
                                    )}

                                    <section className="shareLinkBlock -only-mobile ">
                                        <ShareButton shareUrl="https://modernspaaces.com/blogs/rera-how-it-benefits-homeowners-and-developers" />
                                    </section>
                                </Col>
                                <Col
                                    xs={12}
                                    md={4}
                                    lg={4}
                                    className="my-0 py-0 -aside-block"
                                >
                                    {/* <Fade>
                  <div className="-blog-author">
                    <p className="--label">Written By</p>
                    <img
                      src={item.authorImage}
                      alt={item.authorName}
                      className="-authorImage"
                    />

                    <h2 className="my-3 -authorName">{item.authorName}</h2>
                    <h3 className="my-1 -department">{item.department}</h3>

                    <AnchorGradientLinkAhref
                      href={`tel:${item.contactNumber}`}
                      classList="-btn-link mt-4 d-block"
                      text="Ask expert"
                      // text={"Read More"}
                      target="_blank"
                    />
                  </div>
                </Fade> */}
                                </Col>
                            </Row>
                        );
                    })}
                    <Row className="simpleShadow my-3 py-3 justify-content-around">
                        <Col
                            xs={12}
                            md={11}
                            lg={9}
                            className="pb-4 pr-md-5 p-2"
                        >
                            <div
                                className="shareLinkBlock1 d-flex flex-row p-4 "
                                style={{
                                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                                    gap: "1rem",
                                    borderRadius: "1rem",
                                }}
                            >
                                <ShareButton shareUrl="https://modernspaaces.com/blogs/rera-how-it-benefits-homeowners-and-developers" />
                            </div>
                        </Col>
                    </Row>
                    <Row className="simpleShadow my-3 py-3 justify-content-around">
                        <Col
                            xs={12}
                            md={11}
                            lg={9}
                            className="pb-4 pr-md-5 p-2"
                        >
                            {BlogFourData.map((item, idx) => (
                                <div key={idx}>
                                    {item.Faq && (
                                        <p className="-descBlack font-benton-bold  margin header2">
                                            {item.Faq}
                                        </p>
                                    )}
                                    <Row>
                                        <div
                                            className="blog-content px-3"
                                            style={{
                                                width: "-webkit-fill-available",
                                            }}
                                        >
                                            {item.faqs && (
                                                <Accordion defaultActiveKey="0">
                                                    {item.faqs.map(
                                                        (faq, faqIdx) => (
                                                            <Card
                                                                key={faqIdx}
                                                                className="my-2 p-2"
                                                                style={{
                                                                    width: "fit",
                                                                    border: "none",
                                                                    backgroundColor:
                                                                        "rgba(0, 0, 0, 0.02)",
                                                                    borderRadius:
                                                                        "0.5rem",
                                                                }}
                                                            >
                                                                <Accordion.Toggle
                                                                    as={
                                                                        Card.Header
                                                                    }
                                                                    eventKey={`${faqIdx}`}
                                                                    className="font-benton-book faq"
                                                                    style={{
                                                                        border: "none",
                                                                        background:
                                                                            "none",
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display:
                                                                                "flex",
                                                                            alignItems:
                                                                                "center",
                                                                            justifyContent:
                                                                                "space-between",
                                                                            cursor: "pointer",

                                                                            borderBottom:
                                                                                "none",
                                                                        }}
                                                                        className="font-benton-bold"
                                                                    >
                                                                        {
                                                                            faq.label
                                                                        }
                                                                        <span
                                                                            style={{
                                                                                minWidth:
                                                                                    "20px",
                                                                            }}
                                                                        >
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="20"
                                                                                height="21"
                                                                                viewBox="0 0 20 21"
                                                                                fill="none"
                                                                            >
                                                                                <path
                                                                                    d="M16.5984 7.96094L11.1651 13.3943C10.5234 14.0359 9.47344 14.0359 8.83177 13.3943L3.39844 7.96094"
                                                                                    stroke="black"
                                                                                    stroke-opacity="0.32"
                                                                                    stroke-width="2"
                                                                                    stroke-miterlimit="10"
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                ></path>
                                                                            </svg>
                                                                        </span>
                                                                    </div>
                                                                </Accordion.Toggle>
                                                                <Accordion.Collapse
                                                                    eventKey={`${faqIdx}`}
                                                                >
                                                                    <Card.Body className="font-benton-book header p-3">
                                                                        {
                                                                            faq
                                                                                .description[0]
                                                                        }
                                                                    </Card.Body>
                                                                </Accordion.Collapse>
                                                            </Card>
                                                        )
                                                    )}
                                                </Accordion>
                                            )}
                                        </div>
                                    </Row>
                                </div>
                            ))}
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}
