import { useState } from "react";
import { CardDeck, Row } from "react-bootstrap";
import "./AllResources.scss";

import BlogCard from "../../BlogCard";
import { AllBlogs } from "./constants";
import ArrowDown from "../../../images/arrow-down.svg";

export default function AllResourcesItems({ inView }) {
    const [showAll, setShowAll] = useState(AllBlogs.length < 6);
    const blogsToShow = AllBlogs.slice(0, showAll ? AllBlogs.length : 6);

    return (
        <Row className="align-items-center my-3 mx-0">
            <CardDeck className=" px-sm-3">
                {blogsToShow.map((item, idx) => {
                    return <BlogCard blogData={item} />;
                })}
            </CardDeck>
            <div className="d-flex w-100 load-more">
                {!showAll && (
                    <button
                        className="btn btn--submit py-2 btn btn-primary"
                        onClick={() => {
                            setShowAll(true);
                        }}
                    >
                        <span>Load More</span>
                        <span style={{ marginLeft: "16px" }}>
                            <svg
                                width="13"
                                height="7"
                                viewBox="0 0 13 7"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M12 0.5L6.5 5.5L1 0.5"
                                    stroke="#f6c02e"
                                />
                            </svg>
                        </span>
                    </button>
                )}
            </div>
        </Row>
    );
}
