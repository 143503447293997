import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./footer.scss";

import ComingSoon from "../../components/Global/ComingSoon";
import GetInTouchForm from "../Global/ContactUsModal/GetInTouchForm";

function php_email_form_submit(thisForm, action, formData) {
    fetch(action, {
        method: "POST",
        body: formData,
        headers: { "X-Requested-With": "XMLHttpRequest" },
    })
        .then((response) => {
            if (response.ok) {
                return response.text();
            } else {
                throw new Error(
                    `${response.status} ${response.statusText} ${response.url}`
                );
            }
        })
        .then((data) => {
            if (data.trim() === '"OK"') {
                console.log("message sent");
                thisForm.reset();
                window.location.href = "/thank-you-contact";
            } else {
                throw new Error(
                    data
                        ? data
                        : "Form submission failed and no error message returned from: " +
                          action
                );
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

export default function Footer({ inView }) {
    const [validated, setValidated] = useState(false);
    const [show, setShow] = useState(false);
    const [showContactUsModal, setShowContactUsModal] = useState(false);
    const handleShow = (e) => {
        e.preventDefault();
        setShow(true);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(e);

        setValidated(true);
        const form = e.currentTarget;
        if (form.checkValidity() === true) {
            let action = e.target.getAttribute("action");
            if (!action) {
                console.log("The form action property is not set!", action);
                return;
            }
            /* e.target.querySelector('.loading').classList.add('d-block');
      e.target.querySelector('.error-message').classList.remove('d-block');
      e.target.querySelector('.sent-message').classList.remove('d-block');*/
            let formData = new FormData(e.target);
            console.log(e.target[0].value);
            let message =
                "Hi, I would like to get the latest updates on your projects";
            let subject = "Subscribe to newsletter";
            formData.set("phone", "From Subscribe");
            formData.set("email", e.target[0].value);
            formData.set("message", message);
            formData.set("subject", subject);
            formData.set("name", "From Subscribe");
            php_email_form_submit(e.target, action, formData);
            // setTimeout(myGreeting, 2000);

            // function myGreeting() {
            //   window.location.href = "/thank-you-contact";
            // }
            // window.location.href = "/";
            // function resolveAfter2Seconds() {
            //   return new Promise(resolve => {
            //     setTimeout(() => {
            //       resolve('resolved');
            //     }, 2000);
            //   });
            // }

            // async function asyncCall() {
            //   console.log('calling');
            //   const result = await resolveAfter2Seconds();
            //   window.location.href = "/thank-you-contact";
            //   // expected output: "resolved"
            // }

            // asyncCall();
        }
    };
    const handleClose = () => setShow(false);
    return (
        <>
            <footer
                className={`section section-footer pb-0 pt-0 ${
                    inView ? "inView" : "outView"
                }`}
                style={{
                    backgroundColor: "#151414",
                }}
            >
                <div className="content footer-content">
                    <Row>
                        <div className="col-md-7 pr-4 relative">
                            <p className="copy fc-light-3 mb-3 ">
                                Modern Spaaces has been creating an interesting
                                story of growth and extraordinary success. We
                                concentrate on blending technology, designs that
                                people love, and natural elements to make spaces
                                that make life better.
                            </p>
                            <div className="pr-0 pt-3 footer-menu">
                                <div
                                    className="heading fc-white"
                                    style={{
                                        marginBottom: "1.25rem",
                                    }}
                                >
                                    Menu
                                </div>
                                <Row className="items" xs={1} sm={3}>
                                    {/* <a
                  href="/project77"
                  className="list-item fc-light-3 col pr-md"
                >
                  Projects
                </a> */}
                                    {/* <Link to="/project77" className="list-item fc-light-3 col pr-md">
                  77 East
                </Link>
                <Link to="/project302" className="list-item fc-light-3 col pr-md">
                  302 West
                </Link> */}
                                    <Link
                                        to="/"
                                        className="list-item fc-white col pr-md"
                                    >
                                        Home
                                    </Link>
                                    <a
                                        href="javascript;"
                                        onClick={handleShow}
                                        className="list-item fc-white col pr-md"
                                    >
                                        Careers
                                    </a>
                                    <a
                                        href="javascript;"
                                        onClick={handleShow}
                                        className="list-item fc-white col pr-md"
                                    >
                                        Investment
                                        {/* Opportunities */}
                                    </a>
                                    <a
                                        href="/#about-us"
                                        // onClick={handleShow}
                                        className="list-item fc-white col pr-md"
                                    >
                                        About us
                                    </a>
                                    <Link
                                        to="/blogs"
                                        className="list-item fc-white col pr-md"
                                    >
                                        Blogs
                                    </Link>
                                    <a
                                        href="javascript;"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setShowContactUsModal(true);
                                        }}
                                        className="list-item fc-white col pr-md"
                                    >
                                        Contact Us
                                    </a>
                                    {/* <a
                  href="javascript;"
                  onClick={handleShow}
                  className="list-item fc-light-3 col pr-md"
                >
                  Blogs
                </a> */}
                                </Row>
                            </div>
                        </div>

                        <div className="col-md-4 offset-md-1  relative ">
                            <Row className="d-none d-sm-block">
                                <div
                                    className="col-xs-12 col"
                                    style={{ flex: "1 1 auto" }}
                                >
                                    <div className="heading fc-white">
                                        Get all the latest updates
                                    </div>
                                    <Form
                                        action="https://mdrn.deta.dev/contactformbaseone"
                                        method="post"
                                        role="form"
                                        className="ContactUsForm php-email-form Footer-form d-flex justify-content-end"
                                        noValidate
                                        validated={validated}
                                        onSubmit={handleSubmit}
                                    >
                                        <Form.Group
                                            controlId="validationCustom0445"
                                            className="px-0 mb-0 flex-grow-1"
                                        >
                                            <Form.Control
                                                type="email"
                                                className="input px-2 py-3 fc-white"
                                                style={{
                                                    borderRadius: "12px",
                                                    border: "1px solid #FFFFFF33",
                                                }}
                                                placeholder="Email Id"
                                                name="email"
                                                required
                                            />

                                            <Form.Control.Feedback type="invalid">
                                                Please enter your Email.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        {/* <Link to="thank-you-contact"> */}

                                        <Button
                                            className="app-btn--small px-4 py-2 app-btn--hollow--white  py-3 ml-0 ml-lg-3"
                                            style={{
                                                borderRadius: "12px",
                                            }}
                                            type="submit"
                                        >
                                            <span className="app-btn-text">
                                                Subscribe
                                            </span>
                                        </Button>
                                        {/* </Link> */}
                                    </Form>
                                </div>
                            </Row>
                            <Row className="flex-column pt-4">
                                <div className="col-xs-12  text-sm-left follow-us-links pb-md-0 col">
                                    <div className="heading fc-white">
                                        Follow Us
                                    </div>
                                    <div className="social">
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://www.linkedin.com/company/modern-spaaces/"
                                        >
                                            <i className="icon-social linkedin"></i>
                                        </a>
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://facebook.com/modernspaaces"
                                        >
                                            <i className="icon-social facebook"></i>
                                        </a>
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://www.instagram.com/modernspaaces/?igshid=xmjsiu8jg4y"
                                        >
                                            <i className="icon-social instagram"></i>
                                        </a>
                                        {/* <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.instagram.com/modernspaaces/?igshid=xmjsiu8jg4y"
                      >
                        <i className="icon-social youtube"></i>
                      </a> */}
                                    </div>
                                </div>
                            </Row>
                            <Row className="d-sm-none site-links">
                                <div className="d-flex col">
                                    <span className="">
                                        <Link
                                            to="/disclaimer"
                                            className="fc-white"
                                        >
                                            Disclaimer
                                        </Link>
                                    </span>
                                    <span className="mx-2 fc-light-3">|</span>

                                    <span className="">
                                        <Link
                                            to="/privacy-policy"
                                            className="fc-white"
                                        >
                                            Privacy Policy
                                        </Link>
                                    </span>
                                </div>
                            </Row>
                        </div>
                    </Row>
                </div>
                <div className="copyright ">
                    <Row className="content fc-white" xs={1} sm={2} md={2}>
                        <Col className="col-xs-12 p-0 col-md-8 text-center text-sm-left">
                            © 2024 modernspaaces.com. All rights reserved.
                        </Col>
                        <Col className="col-xs-12 col-md-4 p-0">
                            <div
                                className="d-flex justify-content-center align-items-center justify-content-sm-end "
                                style={{
                                    gap: "1rem",
                                    // justifyContent: "end",
                                }}
                            >
                                <span className="d-none d-sm-inline">
                                    <Link to="/disclaimer" className="fc-white">
                                        Disclaimer
                                    </Link>
                                </span>
                                <span className="d-none d-sm-inline separator">
                                    |
                                </span>
                                {/* <span className="d-none d-sm-inline">
                  <Link to="/terms-and-conditions" className="fc-white">
                    Terms and Conditions
                  </Link>
                </span> */}
                                <span className="d-none d-sm-inline">
                                    <Link
                                        to="/privacy-policy"
                                        className="fc-white"
                                    >
                                        Privacy Policy
                                    </Link>
                                </span>
                            </div>
                        </Col>
                    </Row>
                </div>

                {/* ComingSoon */}
                <GetInTouchForm
                    show={showContactUsModal}
                    IsNotAPlan={true}
                    handleClose={() => setShowContactUsModal(false)}
                />
                <ComingSoon show={show} handleClose={handleClose} />
            </footer>
        </>
    );
}
