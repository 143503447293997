import { InView } from "react-intersection-observer";
import { useState } from "react";
import { Footer, FooterLogo } from "../components/index";
import ContactForm from "./contact-form/contact-form";
import ContactMain from "./contact-main/contact-main";
import { Helmet } from "react-helmet-async";

export default function ContactUs() {
    const [inViewContactForm, setInViewContactForm] = useState(false);
    const [inViewFooter, setInViewFooter] = useState(false);
    const [inViewContactMain, setInViewContactMain] = useState(false);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0"
                />
                <title> Contact Us | Modern Spaaces</title>
                <meta
                    name="description"
                    content="Want to know more about the projects developed by Modern Spaaces contact us  9008007374 or visit our website www.modernspaaces.com to know more.
"
                />
            </Helmet>
            <InView onChange={setInViewContactForm} triggerOnce={true}>
                <ContactForm inView={inViewContactForm}></ContactForm>
            </InView>
            <InView onChange={setInViewContactMain} triggerOnce={true}>
                <ContactMain inView={inViewContactMain}></ContactMain>
            </InView>
            <FooterLogo />
            <InView onChange={setInViewFooter} triggerOnce={true}>
                <Footer inView={inViewFooter}></Footer>
            </InView>
        </>
    );
}
