import { Col, Container, Row } from "react-bootstrap";
import { Desc, DescLabel } from "../../../Global/Typography";
import BannerNew from "../../BannerNew";
import "./Bloghomes.scss";
import BlogFourData from "../../../../db/BlogEvolutionOfSmarthomes";
import ShareButton from "../../Share/ShareButton";

export default function Banner({ inView }) {
  const currentPage =
    "Experience the future with smart homes! Discover how smart home technology enhances convenience, security, and energy efficiency.Lets find out more about it in this blog.";
  return (
    <section className={`section  ${inView ? "inView" : "outView"}`}>
      {/* <Container className="container">
        <Row className="align-items-center">
          <Col xs={12} md={12} lg={12} className="py-2">
            <BreadcrumbBlogCard item={currentPage} />
          </Col>
        </Row>
      </Container> */}

      <BannerNew
        image={BlogFourData[0].image}
        title={BlogFourData[0].title}
        publishedOn={BlogFourData[0].publishedOn}
        time={BlogFourData[0].time}
      />

      <Container className="blog-detail-container">
        {BlogFourData.map((item, idx) => {
          return (
            <Row key={idx} className="justify-content-around">
              {/* {item.image && (
                <Col xs={12} md={12} lg={12} className="pb-4">
                  <img
                    src={item.image}
                    alt={item.title}
                    className="-blog-banner"
                  />
                </Col>
              )} */}
              <Col xs={12} md={11} lg={9} className="pb-4 pr-md-5">
                <div className="blog-header margin">
                  {/* {item.title && (
                    <Heading classList="my-3" variant="h1" text={item.title} />
                  )}
                  {item.publishedOn && (
                    <Desc
                      classList=""
                      text={`Published on ${item.publishedOn}`}
                    />
                  )} */}

                  {item.description11 && (
                    <Desc
                      classList="font-benton-book header"
                      text={item.description11}
                    />
                  )}
                </div>

                <div>
                  {item.title2 && (
                    <p className="-descBlack font-benton-book fw-bold  margin">
                      {item.title2}
                    </p>
                  )}
                  {item.description21 && (
                    <Desc
                      classList="font-benton-book header"
                      text={item.description21}
                    />
                  )}
                  {item.description22 && (
                    <Desc
                      classList="font-benton-book header"
                      text={item.description22}
                    />
                  )}
                  {item.description23 && (
                    <Desc
                      classList="font-benton-book header"
                      text={item.description23}
                    />
                  )}
                </div>

                <div className="blog-content ">
                  {item.title3 && (
                    <p className="-descBlack font-benton-book fw-bold  margin">
                      {item.title3}
                    </p>
                  )}
                  <div className="imagepadding">
                    <img src={item.image1} alt=" " class="image margin" />
                  </div>
                  {item.description31 && (
                    <Desc
                      classList="font-benton-book header"
                      text={item.description31}
                    />
                  )}
                  {item.description32 && (
                    <Desc
                      classList="font-benton-book header"
                      text={item.description32}
                    />
                  )}
                  {item.description33 && (
                    <Desc
                      classList="font-benton-book header"
                      text={item.description33}
                    />
                  )}
                  {item.description34 && (
                    <Desc
                      classList="font-benton-book header"
                      text={item.description34}
                    />
                  )}
                </div>

                <div className="blog-content ">
                  {item.title4 && (
                    <p className="-descBlack font-benton-book fw-bold margin">
                      {item.title4}
                    </p>
                  )}
                  {item.description41 && (
                    <p className="-descBlack font-benton-book header">
                      {item.description41}
                    </p>
                  )}
                  {item.description42 && (
                    <p className="-descBlack font-benton-book header">
                      {item.description42}
                    </p>
                  )}
                  {item.description43 && (
                    <p className="-descBlack font-benton-book header">
                      {item.description43}
                    </p>
                  )}
                </div>

                <div className="blog-content ">
                  {item.title5 && (
                    <p className="-descBlack font-benton-book fw-bold margin">
                      {item.title5}
                    </p>
                  )}
                  {item.description51 && (
                    <p className="-descBlack font-benton-book header">
                      {item.description51}
                    </p>
                  )}
                  {item.description52 && (
                    <p className="-descBlack font-benton-book header">
                      {item.description52}
                    </p>
                  )}
                  {item.description53 && (
                    <p className="-descBlack font-benton-book header">
                      {item.description53}
                    </p>
                  )}
                </div>

                <div className="blog-content ">
                  {item.title6 && (
                    <p className="-descBlack font-benton-book fw-bold margin">
                      {item.title6}
                    </p>
                  )}
                  {item.list6 &&
                    item.list6.map((i0, idx) => {
                      return (
                        <div key={idx}>
                          <DescLabel
                            classList="my-1 py-2 font-benton-book header"
                            text={i0.description}
                            label={i0.label}
                          />
                          {i0.list && (
                            <ul className="blog-list-circle header">
                              {i0.list6.map((i1, idx1) => {
                                return (
                                  <li key={idx1}>
                                    <p className="-descBlack mb-1 font-benton-book header">
                                      {i1}
                                    </p>
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                        </div>
                      );
                    })}
                </div>

                <div className="blog-content ">
                  {item.title7 && (
                    <p className="-descBlack font-benton-book fw-bold margin">
                      {item.title7}
                    </p>
                  )}
                  {item.description71 && (
                    <p className="-descBlack font-benton-book header">
                      {item.description71}
                    </p>
                  )}
                  {item.description72 && (
                    <p className="-descBlack font-benton-book header">
                      {item.description72}
                    </p>
                  )}
                  {item.description73 && (
                    <p className="-descBlack font-benton-book header">
                      {item.description73}
                    </p>
                  )}
                </div>

                <div className="blog-content ">
                  {item.title8 && (
                    <p className="-descBlack font-benton-book fw-bold margin">
                      {item.title8}
                    </p>
                  )}
                  {item.description81 && (
                    <p className="-descBlack font-benton-book header">
                      {item.description81}
                    </p>
                  )}
                </div>

                <div className="blog-content ">
                  {item.title9 && (
                    <p className="-descBlack font-benton-book fw-bold margin">
                      {item.title9}
                    </p>
                  )}
                  {item.description91 && (
                    <p className="-descBlack font-benton-book header">
                      {item.description91}
                    </p>
                  )}
                  {item.description92 && (
                    <p className="-descBlack font-benton-book header">
                      {item.description92}
                    </p>
                  )}
                </div>

                <div className="blog-content ">
                  {item.title10 && (
                    <p className="-descBlack font-benton-book fw-bold margin">
                      {item.title10}
                    </p>
                  )}
                  {item.list10 &&
                    item.list10.map((i0, idx) => {
                      return (
                        <div key={idx}>
                          <DescLabel
                            classList="my-1 py-2 font-benton-book header"
                            text={i0.description}
                            label={i0.label}
                          />
                          {i0.list && (
                            <ul className="blog-list-circle header">
                              {i0.list10.map((i1, idx1) => {
                                return (
                                  <li key={idx1}>
                                    <p className="-descBlack mb-1 font-benton-book header">
                                      {i1}
                                    </p>
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                        </div>
                      );
                    })}
                </div>

                <div className="blog-content ">
                  {item.title11 && (
                    <p className="-descBlack font-benton-book fw-bold margin">
                      {item.title11}
                    </p>
                  )}
                  {item.list11 &&
                    item.list11.map((i0, idx) => {
                      return (
                        <div key={idx}>
                          <DescLabel
                            classList="my-1 py-2 font-benton-book header"
                            text={i0.description}
                            label={i0.label}
                          />
                          {i0.list && (
                            <ul className="blog-list-circle header">
                              {i0.list11.map((i1, idx1) => {
                                return (
                                  <li key={idx1}>
                                    <p className="-descBlack mb-1 font-benton-book header">
                                      {i1}
                                    </p>
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                        </div>
                      );
                    })}
                </div>

                <div className="blog-content ">
                  {item.title12 && (
                    <p className="-descBlack font-benton-book fw-bold margin">
                      {item.title12}
                    </p>
                  )}
                  {item.description121 && (
                    <p className="-descBlack font-benton-book header">
                      {item.description121}
                    </p>
                  )}
                  {item.list12 &&
                    item.list12.map((i0, idx) => {
                      return (
                        <div key={idx}>
                          <DescLabel
                            classList="my-1 py-2 font-benton-book header "
                            text={i0.description}
                            label={i0.label}
                          />
                          {i0.list && (
                            <ul className="blog-list-circle  header">
                              {i0.list.map((i1, idx1) => {
                                return (
                                  <li key={idx1}>
                                    <p className="font-benton-book header">
                                      {i1}
                                    </p>
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                        </div>
                      );
                    })}
                  {item.description122 && (
                    <p className="-descBlack font-benton-book header">
                      {item.description122}
                    </p>
                  )}
                  <div className="imagepadding">
                    <img src={item.image2} alt=" " class="image margin" />
                  </div>

                  {item.description123 && (
                    <p className="-descBlack font-benton-book header">
                      {item.description123}
                    </p>
                  )}
                  {item.description124 && (
                    <p className="-descBlack font-benton-book header">
                      {item.description124}
                    </p>
                  )}
                </div>

                <section className="shareLinkBlock -only-mobile ">
                  <ShareButton shareUrl="https://modernspaaces.com/blogs/the-evolution-of-smart-homes" />
                </section>
              </Col>
              <Col xs={12} md={4} lg={4} className="my-0 py-0 -aside-block">
                {/* <Fade>
                  <div className="-blog-author">
                    <p className="--label">Written By</p>
                    <img
                      src={item.authorImage}
                      alt={item.authorName}
                      className="-authorImage"
                    />

                    <h2 className="my-3 -authorName">{item.authorName}</h2>
                    <h3 className="my-1 -department">{item.department}</h3>

                    <AnchorGradientLinkAhref
                      href={`tel:${item.contactNumber}`}
                      classList="-btn-link mt-4 d-block"
                      text="Ask expert"
                      // text={"Read More"}
                      target="_blank"
                    />
                  </div>
                </Fade> */}
              </Col>
            </Row>
          );
        })}
        <Row className="simpleShadow my-3 py-3 justify-content-around">
          <Col xs={12} md={11} lg={9} className="pb-4 pr-md-5 p-2">
            <div
              className="shareLinkBlock1 d-flex flex-row p-4 "
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.04)",
                gap: "1rem",
                borderRadius: "1rem",
              }}
            >
              <ShareButton shareUrl="https://modernspaaces.com/blogs/the-evolution-of-smart-homes" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
