import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { AnchorGradientButton, Desc, Heading } from "../../Global/Typography";
// import GrassPattern from "../GrassPattern";
import Culture from "../../../images/SoulTree/Architectural/Culture.webp";
import MoreThanJust from "../../../images/SoulTree/Architectural/MoreThanJust.webp";
import Genrationoftoday from "../../../images/SoulTree/Architectural/Genrationoftoday.webp";
// import ArchitecturalImage from "../../../images/SoulTree/Architectural/ArchitecturalImage.png";
import PrevArrow from "../../../images/Common/PrevArrow.svg";
import NextArrow from "../../../images/Common/NextArrow.svg";
import "./ArchitecturalSlider.scss";

import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Controller,
  Autoplay,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Controller, Autoplay]);

export default function ReadytoOccupy({ inView }) {
  const ArchitecturalSliderData = [
    {
      title: "More than just a dream home",
      desc: "Fully automated futuristic & intelligent luxury 1, 2 and 3 BHK apartments with a multitude of amenities located amidst green cover at Sompura Gate, Sarjapur Road",
      image: MoreThanJust,
    },
    {
      title: "For the generation of today, tomorrow & future",
      desc: "Soul Tree is made for generations for whom home is more than just a space & open spaces serve as their platform to meet, greet, enjoy & so much more",
      image: Genrationoftoday,
    },
    {
      title: "Culture Will Bring You Together, Nature Will Let You Thrive",
      desc: "Introducing homes with smart water meters, a step towards water conservation & a space designed towards maximising natural light & sustainable lifestyle.",
      image: Culture,
    },
  ];
  const ArchitecturalSliderData1 = [
    {
      title: "More than just a dream home",
      desc: "Fully automated futuristic & intelligent luxury 1, 2 and 3 BHK apartments with a multitude of amenities located amidst green cover at Sompura Gate, Sarjapur Road",
      image: MoreThanJust,
    },
    {
      title: "For the generation of today, tomorrow & future",
      desc: "Soul Tree is made for generations for whom home is more than just a space & open spaces serve as their platform to meet, greet, enjoy & so much more",
      image: Genrationoftoday,
    },
    {
      title: "Culture Will Bring You Together, Nature Will Let You Thrive",
      desc: "Introducing homes with smart water meters, a step towards water conservation & a space designed towards maximising natural light & sustainable lifestyle.",
      image: Culture,
    },
  ];

  return (
    <div className="bg-white ArchitecturalSlider NewOne">
      <Container className="my-1 my-md-5 pb-3 pb-md-5">
        <Row>
          <Heading
            variant="h1"
            classList="Campus__title my-2 my-md-4"
            text="SoulTree"
          />
        </Row>
        <Row className="ArchitecturalSlider__Row">
          <Swiper
            wrapperTag="ul"
            className="ArchitecturalSliderLists"
            slidesPerView={1}
            speed={500}
            // speed={3400}
            spaceBetween={0}
            effect={"fade"}
            fadeEffect={{
              crossFade: true,
            }}
            // observeParents={true}
            // observer={true}

            autoplay={true}
            loop={true}
            direction="horizontal"
            pagination={{ clickable: true, el: ".swiper-pagination-Architech" }}
            navigation={{
              prevEl: ".ArrowButtonBlock__ArrowPrevArchitech",
              nextEl: ".ArrowButtonBlock__ArrowNextArchitech",
            }}
          >
            {ArchitecturalSliderData.map(({ image, title, desc }, index) => (
              <SwiperSlide tag="li" key={index} className="-block-list">
                <Row>
                  <Col xs={12} md={6}>
                    <img
                      src={image}
                      className="-image w-100"
                      alt="ArchitecturalSlider"
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <Heading variant="h1" classList="-title" text={title} />
                    <Desc classList="-desc my-3 " text={desc} />
                    <AnchorGradientButton
                      href="/soultree"
                      classList="-btn my-2 mr-2 text-orange px-md-5"
                      text="Explore Projects"
                    />
                  </Col>
                </Row>
              </SwiperSlide>
            ))}
          </Swiper>
        </Row>
        <Row className="ArrowButtons">
          <div className="ArrowButtonBlock">
            <div className="swiper-pagination swiper-pagination-Architech left0"></div>
            <div className="ArrowButtonBlock__Arrow ArrowButtonBlock__ArrowPrevArchitech">
              <img
                src={PrevArrow}
                className="-image"
                alt="ArchitecturalSlider"
              />
            </div>
            <div className="ArrowButtonBlock__Arrow ArrowButtonBlock__ArrowNextArchitech">
              <img
                src={NextArrow}
                className="-image"
                alt="ArchitecturalSlider"
              />
            </div>
          </div>
        </Row>
      </Container>
      <hr></hr>
      <Container className="my-1 my-md-3 pb-2 pb-md-5">
        <Row>
          <Heading
            variant="h1"
            classList="Campus__title my-2 my-md-4"
            text="Urbanest"
          />
        </Row>
        <Row className="ArchitecturalSlider__Row">
          <Swiper
            wrapperTag="ul"
            className="ArchitecturalSliderLists"
            slidesPerView={1}
            speed={700}
            // speed={3400}
            spaceBetween={0}
            effect={"fade"}
            fadeEffect={{
              crossFade: true,
            }}
            // observeParents={true}
            // observer={true}

            autoplay={true}
            loop={true}
            direction="horizontal"
            pagination={{
              clickable: true,
              el: ".swiper-pagination-ArchitechNewOne",
            }}
            navigation={{
              prevEl: ".ArrowButtonBlock__ArrowPrevArchitechNewOne",
              nextEl: ".ArrowButtonBlock__ArrowNextArchitechNewOne",
            }}
          >
            {ArchitecturalSliderData1.map(({ image, title, desc }, index) => (
              <SwiperSlide tag="li" key={index} className="-block-list">
                <Row>
                  <Col xs={12} md={6}>
                    <Heading variant="h1" classList="-title" text={title} />
                    <Desc classList="-desc my-3 " text={desc} />
                    <AnchorGradientButton
                      href="/soultree"
                      classList="-btn my-2 mr-2 text-orange px-md-5"
                      text="Explore Projects"
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <img
                      src={image}
                      className="-image w-100"
                      alt="ArchitecturalSlider"
                    />
                  </Col>
                </Row>
              </SwiperSlide>
            ))}
          </Swiper>
        </Row>
        <Row className="ArrowButtons">
          <div className="ArrowButtonBlock">
            <div className="swiper-pagination swiper-pagination-ArchitechNewOne left0"></div>
            <div className="ArrowButtonBlock__Arrow ArrowButtonBlock__ArrowPrevArchitechNewOne">
              <img
                src={PrevArrow}
                className="-image"
                alt="ArchitecturalSlider"
              />
            </div>
            <div className="ArrowButtonBlock__Arrow ArrowButtonBlock__ArrowNextArchitechNewOne">
              <img
                src={NextArrow}
                className="-image"
                alt="ArchitecturalSlider"
              />
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
}
