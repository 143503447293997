import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Desc, Heading } from "../Global/Typography";
import "./ProjectResources.scss";
import DownloadBrochure from "../Global/DownloadBrochure";

export const ProjectResources = ({ inView, resources, project }) => {
  const [showDownloadBrochure, setShowDownloadBrochure] = useState(false);
  const [showDownloadFloorPlan, setShowDownloadFloorPlan] = useState(false);
  const [showDownloadMasterPlan, setShowDownloadMasterPlan] = useState(false);
  const handleDownloadClick = (resource) => {
    const resourceItem = resources.find((item) => item.key === resource);
    switch (resource) {
      case "floorPlan":
        resourceItem.resourceContent && setShowDownloadFloorPlan(true);
        break;
      // case "masterPlan":
      //     const link = document.createElement("a");
      //     link.href = resourceItem.resourceContent.filePath;
      //     link.setAttribute(
      //         "download",
      //         resourceItem.resourceContent.fileName
      //     );
      //     link.dispatchEvent(new MouseEvent("click"));

      //     break;

      case "masterPlan":
        resourceItem.resourceContent && setShowDownloadMasterPlan(true);
        break;

      case "projectBrochure":
        resourceItem.resourceContent && setShowDownloadBrochure(true);
        break;
      default:
        return;
    }
  };

  return (
    <section
      className={`section ProjectResources-section pt-md-0 ${
        inView ? "inView" : "outView"
      }`}
    >
      <Container>
        <Row>
          <Col className="text-center" style={{ marginBottom: "4rem" }}>
            <Heading
              // classList="my-4 my-md-4 pr-5 pr-md-3"
              classList="ProjectResource__title"
              variant="h1"
              text="Check Out Our Project Resources"
            />
            <Desc
              classList="ProjectResource__desc"
              text={`Access Project Resources: Download floor plans, master plan, and brochures to explore every detail of ${project}.`}
            />
          </Col>
        </Row>
        <Row className="resources-cards-container">
          {resources.map((resource) => {
            return (
              <Col>
                <div
                  key={resource.key}
                  className="d-flex flex-column align-items-center resource"
                >
                  <div className="resource-name">{resource.name}</div>
                  <img
                    className="resource-icon"
                    src={resource.icon}
                    alt={resource.IconAlt}
                  />
                  <div className="download-btn-wrapper">
                    <button
                      className="resource-download-btn"
                      onClick={() => handleDownloadClick(resource.key)}
                    >
                      <span className="btn-text">Download</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="21"
                        height="20"
                        viewBox="0 0 21 20"
                        fill="none"
                      >
                        <path
                          d="M5.16602 17.5H15.166M10.166 2.5L10.166 14.1667M10.166 14.1667L14.3327 10M10.166 14.1667L5.99935 10"
                          stroke="#EDAC1B"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
      <DownloadBrochure
        title={"Download Brochure"}
        show={showDownloadBrochure}
        handleClose={() => setShowDownloadBrochure(false)}
        content={
          resources.find((item) => item.key === "projectBrochure")
            .resourceContent
        }
      />
      <DownloadBrochure
        title={"Download Master Plan"}
        show={showDownloadMasterPlan}
        handleClose={() => setShowDownloadMasterPlan(false)}
        content={
          resources.find((item) => item.key === "masterPlan").resourceContent
        }
        resourceType="masterPlan"
      />
      <DownloadBrochure
        title={"Download Floor Plan"}
        show={showDownloadFloorPlan}
        handleClose={() => setShowDownloadFloorPlan(false)}
        resourceType="floorPlan"
        content={
          resources.find((item) => item.key === "floorPlan").resourceContent
        }
      />
    </section>
  );
};
