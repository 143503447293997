import React from "react";
import { Row } from "react-bootstrap";
import { Desc, Heading } from "../../Global/Typography";
import GrassPattern from "../GrassPattern";
// import view1 from "../../../images/Soulace/architectural/image2.jpg";
// import view2 from "../../../images/Soulace/architectural/image3.jpg";
// import view3 from "../../../images/Soulace/architectural/image4.jpeg";
// import view4 from "../../../images/Soulace/architectural/image5.jpg";
// import view5 from "../../../images/Soulace/architectural/image6.webp";
// import common  from "../../../images/Engrace/architectural/tobeused/render2.webp";
// import Culture from "../../../images/SoulTree/Architectural/Culture.webp";
// import MoreThanJust from "../../../images/SoulTree/Architectural/MoreThanJust.webp";
// import Genrationoftoday from "../../../images/SoulTree/Architectural/Genrationoftoday.webp";
// import ArchitecturalImage from "../../../images/SoulTree/Architectural/ArchitecturalImage.png";

import PrevArrow from "../../../images/Common/PrevArrow.svg";
import NextArrow from "../../../images/Common/NextArrow.svg";
import "./ArchitecturalSlider.scss";

import SwiperCore, {
    Navigation,
    Pagination,
    Scrollbar,
    A11y,
    Controller,
    Autoplay,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Controller, Autoplay]);

const view1 =
    "/images/Soulace/architectural/residential-community-villas-slider-banner.jpg";
const view2 =
    "/images/Soulace/architectural/contemporary-villas-slider-banner.jpg";
const view3 =
    "/images/Soulace/architectural/aluform-technology-slider-banner.jpeg";
const view4 =
    "/images/Soulace/architectural/premium-specifications-slider-banner.jpg";
const view5 =
    "/images/Soulace/architectural/project-location-bangalore-slider-banner.webp";

export default function ArchitecturalSlider({ inView }) {
    const ArchitecturalSliderData = [
        {
            title: "More than just an independent villa.",
            desc: "A large residential community of 266 villas spread across 26.5 acres.",
            image: view1,
            imgAlt: "Residential Community Villas",
        },

        {
            title: "Designed for contemporary urban lifestyle.",
            desc: "4 & 5 BHK’s with family, personal and social spaces spread across ground, first and second floor",
            image: view2,
            imgAlt: "4 & 5 BHK Contemporary Villas",
        },
        {
            title: "Build to last with Aluform Technology.",
            desc: "Aluform Technology has transformed our projects, by using lightweight & flexible materials with a focus on speed, quality & durability.",
            image: view3,
            imgAlt: "Aluform Technology by Modern Spaaces",
        },
        {
            title: "Private Elevators, Italian Marble & Many More Premium Specifications.",
            desc: "Experience a luxurious lifestyle with exceptional features like Hans Grohe, Toto, Wooden finish UPVC and others, which radiate enduring elegance and comfort.",
            image: view4,
            imgAlt: "Luxury Villa with Private Elevators ",
        },
        {
            title: "Bangalore’s Hottest Home Destination.",
            desc: "Located at Sarjapur road, Bangalore’s hottest residential development, connecting 3 major IT hubs- Electronic city, Whitefield & Bellandur ORR.",
            image: view5,
            imgAlt: "Soulace in Sarjapur Road",
        },
    ];

    return (
        <section
            className={`section bg-white ArchitecturalSlider ${
                inView ? "inView" : "outView"
            }
    `}
        >
            <GrassPattern />
            <Row className="ArchitecturalSlider__Row">
                <Swiper
                    wrapperTag="ul"
                    className="ArchitecturalSliderLists"
                    slidesPerView={1}
                    speed={500}
                    // speed={3400}
                    spaceBetween={0}
                    effect={"fade"}
                    fadeEffect={{
                        crossFade: true,
                    }}
                    // observeParents={true}
                    // observer={true}

                    autoplay={true}
                    loop={true}
                    direction="horizontal"
                    pagination={{
                        clickable: true,
                        el: ".swiper-pagination-Architech",
                    }}
                    navigation={{
                        prevEl: ".ArrowButtonBlock__ArrowPrevArchitech",
                        nextEl: ".ArrowButtonBlock__ArrowNextArchitech",
                    }}
                >
                    {ArchitecturalSliderData.map(
                        ({ image, title, desc, imgAlt }, index) => (
                            <SwiperSlide
                                tag="li"
                                key={index}
                                className="-block-list"
                            >
                                <div className="-image-block">
                                    <img
                                        src={image}
                                        className="-image "
                                        alt={imgAlt}
                                    />
                                </div>
                                <div className="-architech-co-block">
                                    <div className="-architech-co-block__inner soulace">
                                        <Heading
                                            variant="h1"
                                            classList="-title"
                                            text={title}
                                        />
                                        <Desc
                                            classList="-desc my-3 "
                                            text={desc}
                                        />
                                    </div>
                                </div>
                            </SwiperSlide>
                        )
                    )}
                </Swiper>
            </Row>
            <Row className="ArrowButtons">
                <div className="ArrowButtonBlock">
                    <div className="swiper-pagination swiper-pagination-Architech"></div>
                    <div className="ArrowButtonBlock__Arrow ArrowButtonBlock__ArrowPrevArchitech">
                        <img
                            src={PrevArrow}
                            className="-image"
                            alt="ArchitecturalSlider"
                        />
                    </div>
                    <div className="ArrowButtonBlock__Arrow ArrowButtonBlock__ArrowNextArchitech">
                        <img
                            src={NextArrow}
                            className="-image"
                            alt="ArchitecturalSlider"
                        />
                    </div>
                </div>
            </Row>
        </section>
    );
}
