import React from "react";
import { Row } from "react-bootstrap";
import { Desc, Heading } from "../../Global/Typography";
import GrassPattern from "../GrassPattern";

import PrevArrow from "../../../images/Common/PrevArrow.svg";
import NextArrow from "../../../images/Common/NextArrow.svg";
import "./ArchitecturalSlider.scss";

import SwiperCore, {
    Navigation,
    Pagination,
    Scrollbar,
    A11y,
    Controller,
    Autoplay,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Controller, Autoplay]);

const view1 =
    "/images/EngraceVista/architectural/engrace_vista_3bhk_exclusive.webp";
const view2 =
    "/images/EngraceVista/architectural/open_space_property.webp";
const view3 =
    "/images/EngraceVista/architectural/aluform_technology.jpeg";
const view5 =
    "/images/EngraceVista/architectural/sompura_hottest_destination.webp";

export default function ArchitecturalSlider({ inView }) {
    const ArchitecturalSliderData = [
        {
            title: "Exclusive 3 BHK Units ",
            desc: "Our exceptionally oversized apartments with floor areas ranging from 2116 - 2200 sq.ft provide ample room for living, entertaining, and unwinding.  ",
            image: view1,
            imgAlt: "Exclusive 3 BHK Residences - Prestigious living in limited-edition luxury homes.",
        },

        // {
        //     title: "78% Open Space ",
        //     desc: "Encompassing lush greenery and aesthetic landscaping designed to enjoy the outdoors. ",
        //     image: view2,
        //     imgAlt: "Property with 78% open space and green surroundings",
        // },
        {
            title: "Build to last with Aluform Technology.",
            desc: "Aluform Technology has transformed our projects, by using lightweight & flexible materials with a focus on speed, quality & durability.",
            image: view3,
            imgAlt: "Aluminium Formwork technology: Innovative aluminum construction.",
        },
        {
            title: "Bangalore’s Hottest Home Destination.",
            desc: "Located at Sarjapur road, Bangalore’s hottest residential development, connecting 3 major IT hubs- Electronic city, Whitefield & Bellandur ORR.",
            image: view5,
            imgAlt: " Sompura: Bangalore's hottest home destination, offering prime real estate opportunities and modern living amenities.",
        },
    ];

    return (
        <section
            className={`section bg-white ArchitecturalSlider ${inView ? "inView" : "outView"
                }
    `}
        >
            <GrassPattern />
            <Row className="ArchitecturalSlider__Row">
                <Swiper
                    wrapperTag="ul"
                    className="ArchitecturalSliderLists"
                    slidesPerView={1}
                    speed={500}
                    // speed={3400}
                    spaceBetween={0}
                    effect={"fade"}
                    fadeEffect={{
                        crossFade: true,
                    }}
                    // observeParents={true}
                    // observer={true}

                    autoplay={true}
                    loop={true}
                    direction="horizontal"
                    pagination={{
                        clickable: true,
                        el: ".swiper-pagination-Architech",
                    }}
                    navigation={{
                        prevEl: ".ArrowButtonBlock__ArrowPrevArchitech",
                        nextEl: ".ArrowButtonBlock__ArrowNextArchitech",
                    }}
                >
                    {ArchitecturalSliderData.map(
                        ({ image, title, desc, imgAlt }, index) => (
                            <SwiperSlide
                                tag="li"
                                key={index}
                                className="-block-list"
                            >
                                <div className="-image-block">
                                    <img
                                        src={image}
                                        className="-image "
                                        alt={imgAlt}
                                    />
                                </div>
                                <div className="-architech-co-block">
                                    <div className="-architech-co-block__inner soulace">
                                        <Heading
                                            variant="h1"
                                            classList="-title"
                                            text={title}
                                        />
                                        <Desc
                                            classList="-desc my-3 "
                                            text={desc}
                                        />
                                    </div>
                                </div>
                            </SwiperSlide>
                        )
                    )}
                </Swiper>
            </Row>
            <Row className="ArrowButtons">
                <div className="ArrowButtonBlock">
                    <div className="swiper-pagination swiper-pagination-Architech"></div>
                    <div className="ArrowButtonBlock__Arrow ArrowButtonBlock__ArrowPrevArchitech">
                        <img
                            src={PrevArrow}
                            className="-image"
                            alt="ArchitecturalSlider"
                        />
                    </div>
                    <div className="ArrowButtonBlock__Arrow ArrowButtonBlock__ArrowNextArchitech">
                        <img
                            src={NextArrow}
                            className="-image"
                            alt="ArchitecturalSlider"
                        />
                    </div>
                </div>
            </Row>
        </section>
    );
}
