import { Col, Container, Row } from "react-bootstrap";
import { Desc, DescLabel } from "../../../Global/Typography";
import BannerNew from "../../BannerNew";
import "../../BlogSimpleGuide/Banner/BlogSimple.scss";
import { Helmet } from "react-helmet-async";
import { Accordion, Card, Button } from "react-bootstrap";

import BlogFourData from "../../../../db/BlogSimpleGuide";
import ShareButton from "../../Share/ShareButton";

export default function Banner({ inView }) {
  const currentPage =
    " Learn about various tax forms, their calculation methods, and their essential role in government services and its impact on homeowners.";
  return (
    <>
     <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>
          A Simple Guide to Understanding Property taxes | Modern Spaaces
        </title>
        <meta
          name="Keywords"
          content="Property Tax, Home Tax, What is Property Tax,Types of property tax, how to calculate house tax, property tax payment, property tax calculator"
        />
        <link
          rel="canonical"
          href="https://modernspaaces.com/blogs/simple-guide-to-understanding-property-tax"
        />
      </Helmet>
    <section className={`section  ${inView ? "inView" : "outView"}`}>
      {/* <Container className="container">
        <Row className="align-items-center">
          <Col xs={12} md={12} lg={12} className="py-2">
            <BreadcrumbBlogCard item={currentPage} />
          </Col>
        </Row>
      </Container> */}

      <BannerNew
        image={BlogFourData[0].image}
        title={BlogFourData[0].title}
        publishedOn={BlogFourData[0].publishedOn}
        time={BlogFourData[0].time}
      />

      <Container className="blog-detail-container">
        {BlogFourData.map((item, idx) => {
          return (
            <Row key={idx} className="justify-content-around">
              {/* {item.image && (
                <Col xs={12} md={12} lg={12} className="pb-4">
                  <img
                    src={item.image}
                    alt={item.title}
                    className="-blog-banner"
                  />
                </Col>
              )} */}
              <Col xs={12} md={11} lg={9} className="pb-4 pr-md-5">
                <div className="blog-header margin">
                  {/* {item.title && (
                    <Heading classList="my-3" variant="h1" text={item.title} />
                  )}
                  {item.publishedOn && (
                    <Desc
                      classList=""
                      text={`Published on ${item.publishedOn}`}
                    />
                  )} */}
                  {item.description1 && (
                    <Desc
                      classList="font-benton-book header"
                      text={item.description1}
                    />
                  )}
                  {item.description11 && (
                    <Desc
                      classList="font-benton-book header"
                      text={item.description11}
                    />
                  )}
                </div>

                {item.title1 && (
                  <p className="-descBlack font-benton-book fw-bold  margin">
                    {item.title1}
                  </p>
                )}
                {item.title1description && (
                  <p className="-descBlack font-benton-book header margin">
                    {item.title1description}
                  </p>
                )}
                {item.typesofproperty &&
                  item.typesofproperty.map((i0, idx) => {
                    return (
                      <div key={idx}>
                        <DescLabel
                          classList="my-1 py-2 font-benton-book header"
                          text={i0.description}
                          label={i0.label}
                        />
                        {i0.list && (
                          <ul className="blog-list-circle header">
                            {i0.list.map((i1, idx1) => {
                              return (
                                <li key={idx1}>
                                  <p className="-descBlack mb-1 font-benton-book header">
                                    {i1}
                                  </p>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </div>
                    );
                  })}
                <div>
                  {item.title2 && (
                    <p className="-descBlack font-benton-book fw-bold  margin">
                      {item.title2}
                    </p>
                  )}
                  <div className="imagepadding">
                    <img src={item.image1} alt="" className="image" />
                  </div>
                </div>

                <div className="blog-content ">
                  {item.title3 && (
                    <p className="-descBlack font-benton-book fw-bold  margin">
                      {item.title3}
                    </p>
                  )}

                  {item.assessmentlist && (
                    <div className="blog-content">
                      {item.assessmentlist.map((i, idx) => {
                        return (
                          <DescLabel
                            classList="my-1 py-2 font-benton-book header"
                            text={i.description}
                            label={i.label}
                          />
                        );
                      })}
                    </div>
                  )}
                </div>

                <div className="blog-content ">
                  {item.title3 && (
                    <p className="-descBlack font-benton-book fw-bold margin">
                      {item.title3}
                    </p>
                  )}
                  {item.title3description && (
                    <p className="-descBlack font-benton-book header">
                      {item.title3description}
                    </p>
                  )}
                  {item.assessmentlist && (
                    <div className="blog-content">
                      {item.propertytaxpurpose.map((i, idx) => {
                        return (
                          <DescLabel
                            classList="my-1 py-2 font-benton-book header"
                            text={i.description}
                            label={i.label}
                          />
                        );
                      })}
                    </div>
                  )}
                </div>

                <div className="blog-content ">
                  {item.title4 && (
                    <p className="-descBlack font-benton-book fw-bold margin">
                      {item.title4}
                    </p>
                  )}
                  {item.title4description && (
                    <p className="-descBlack font-benton-book header">
                      {item.title4description}
                    </p>
                  )}
                  <div>
                    {item.propertytaxcalculation &&
                      item.propertytaxcalculation.map((i0, idx) => {
                        return (
                          <div key={idx}>
                            <DescLabel
                              classList="my-1 py-2 font-benton-book header"
                              text={i0.description}
                              label={i0.label}
                            />
                            {i0.list && (
                              <ul className="blog-list-circle header">
                                {i0.list.map((i1, idx1) => {
                                  return (
                                    <li key={idx1}>
                                      <p className="-descBlack mb-1 font-benton-book header">
                                        {i1}
                                      </p>
                                    </li>
                                  );
                                })}
                              </ul>
                            )}
                          </div>
                        );
                      })}
                  </div>
                </div>

                <div className="blog-content ">
                  {item.title8 && (
                    <p className="-descBlack font-benton-book fw-bold margin">
                      {item.title8}
                    </p>
                  )}
                  {item.title3description && (
                    <p className="-descBlack font-benton-book header">
                      {item.title8description}
                    </p>
                  )}
                  {item.BbmpForms && (
                    <div className="blog-content">
                      {item.BbmpForms.map((i, idx) => {
                        return (
                          <DescLabel
                            classList="my-1 py-2 font-benton-book header"
                            text={i.description}
                            label={i.label}
                          />
                        );
                      })}
                    </div>
                  )}
                  {item.title8Conclusion && (
                    <p className="-descBlack font-benton-book header">
                      {item.title8Conclusion}
                    </p>
                  )}
                </div>

                <div className="blog-content ">
                  {item.title5 && (
                    <p className="-descBlack font-benton-book fw-bold margin">
                      {item.title5}
                    </p>
                  )}

                  {item.title5list && (
                    <ul className="blog-list-circle header">
                      {item.title5list.map((i9, idx11) => {
                        return (
                          <li key={idx11}>
                            <p className="-descBlack mb-1 font-benton-book header">
                              {i9}
                            </p>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>

                <div className="blog-content ">
                  {item.title6 && (
                    <p className="-descBlack font-benton-book fw-bold margin">
                      {item.title6}
                    </p>
                  )}

                  {item.title6list && (
                    <ul className="blog-list-circle header">
                      {item.title6list.map((i9, idx11) => {
                        return (
                          <li key={idx11}>
                            <p className="-descBlack mb-1 font-benton-book header">
                              {i9}
                            </p>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>

                <div className="blog-content ">
                  {item.title7 && (
                    <p className="-descBlack font-benton-book fw-bold margin">
                      {item.title7}
                    </p>
                  )}
                  <div className="imagepadding">
                    <img src={item.image2} alt="" className="image" />
                  </div>
                  {item.title7description && (
                    <p className="-descBlack font-benton-book header">
                      {item.title7description}
                    </p>
                  )}
                </div>
                <div className="blog-content ">
                  {item.conclision && (
                    <p className="-descBlack font-benton-book fw-bold margin">
                      {item.conclision}
                    </p>
                  )}

                  {item.ConclusionData && (
                    <ul className="blog-list-circle header">
                      {item.ConclusionData.map((i9, idx11) => {
                        return (
                          <li key={idx11}>
                            <p className="-descBlack mb-1 font-benton-book header">
                              {i9}
                            </p>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
                <section className="shareLinkBlock -only-mobile ">
                  <ShareButton shareUrl="https://modernspaaces.com/blogs/simple-guide-to-understanding-property-tax" />


                </section>
              </Col>
              <Col xs={12} md={4} lg={4} className="my-0 py-0 -aside-block">
                {/* <Fade>
                  <div className="-blog-author">
                    <p className="--label">Written By</p>
                    <img
                      src={item.authorImage}
                      alt={item.authorName}
                      className="-authorImage"
                    />

                    <h2 className="my-3 -authorName">{item.authorName}</h2>
                    <h3 className="my-1 -department">{item.department}</h3>

                    <AnchorGradientLinkAhref
                      href={`tel:${item.contactNumber}`}
                      classList="-btn-link mt-4 d-block"
                      text="Ask expert"
                      // text={"Read More"}
                      target="_blank"
                    />
                  </div>
                </Fade> */}
              </Col>
            </Row>
          );
        })}
        <Row className="simpleShadow my-3 py-3 justify-content-around">
          <Col xs={12} md={11} lg={9} className="pb-4 pr-md-5 p-2">
            <div
              className="shareLinkBlock1 d-flex flex-row p-4 "
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.04)",
                gap: "1rem",
                borderRadius: "1rem",
              }}
            >
              <ShareButton shareUrl="https://modernspaaces.com/blogs/simple-guide-to-understanding-property-tax" />
            </div>
          </Col>
        </Row>
        <Row className="simpleShadow my-3 py-3 justify-content-around">
          <Col xs={12} md={11} lg={9} className="pb-4 pr-md-5 p-2">
            {BlogFourData.map((item, idx) => (
              <div key={idx}>
                {item.Faq && (
                  <p className="-descBlack font-benton-book fw-bold Margin header2">
                    {item.Faq}
                  </p>
                )}
                <Row>
                  <div
                    className="blog-content px-3"
                    style={{ width: "-webkit-fill-available" }}
                  >
                    {item.Faqs && (
                      <Accordion defaultActiveKey="0">
                        {item.Faqs.map((faq, faqIdx) => (
                          <Card
                            key={faqIdx}
                            className="my-2 p-2"
                            style={{
                              width: "fit",
                              border: "none",
                              backgroundColor: "rgba(0, 0, 0, 0.02)",
                              borderRadius: "0.5rem",
                            }}
                          >
                            <Accordion.Toggle
                              as={Card.Header}
                              eventKey={`${faqIdx}`}
                              className="font-benton-book faq"
                              style={{ border: "none", background: "none" }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  cursor: "pointer",
                                  fontWeight: "bolder",
                                  borderBottom: "none",
                                }}
                              >
                                {faq.label}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="21"
                                  viewBox="0 0 20 21"
                                  fill="none"
                                >
                                  <path
                                    d="M16.5984 7.96094L11.1651 13.3943C10.5234 14.0359 9.47344 14.0359 8.83177 13.3943L3.39844 7.96094"
                                    stroke="black"
                                    strokeOpacity="0.32"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={`${faqIdx}`}>
                              <Card.Body className="font-benton-book header p-3">
                                <ul className="blog-list-circle header">
                                  {faq.list.map((listItem, listIdx) => (
                                    <li key={listIdx}>
                                      <p className="-descBlack mb-1 font-benton-book header">
                                        {listItem}
                                      </p>
                                    </li>
                                  ))}
                                </ul>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        ))}
                      </Accordion>
                    )}
                  </div>
                </Row>
              </div>
            ))}
          </Col>
        </Row>
      </Container>
    </section>
    </>
  );
}
