// import floorPlan from "../../../images/Common/floor-plan.svg";
// import masterPlan from "../../../images/Common/master-plan.svg";
// import projectBrochure from "../../../images/Common/project-brochure.svg";
import SHBrochure from "../../../documents/SereneHeights/Main_Brochure-low.pdf";
import SHMasterPlan from "../../../documents/SereneHeights/SH-Master_Plan_Set-Low.pdf";
import SHFloorPlan from "../../../documents/SereneHeights/SH-Tower_Plan.pdf";

const floorPlan = "/images/Common/floor-plan-banner.svg";
const masterPlan = "/images/Common/master-plan-banner.svg";
const projectBrochure = "/images/Common/project-brochure-banner.svg";

export const ProjectResourcesData = [
    {
        key: "floorPlan",
        name: "Floor Plan",
        icon: floorPlan,
        IconAlt: "Floor Plan",
        resourceContent: {
            subject: "Serene_Heights Floor Plan",
            fileName: "Serene_Heights_Floor_Plan.pdf",
            filePath: SHFloorPlan,
        },
    },
    {
        key: "masterPlan",
        name: "Master Plan",
        icon: masterPlan,
        IconAlt: "Master Plan",
        resourceContent: {
            subject: "Serene_Heights Master Plan",
            fileName: "Serene_Heights_Master_Plan.pdf",
            filePath: SHMasterPlan,
        },
    },
    {
        key: "projectBrochure",
        name: "Project Brochure",
        icon: projectBrochure,
        IconAlt: "Project Brochure",
        resourceContent: {
            subject: "Serene_Heights Brochure",
            fileName: "Serene_Heights_Brochure.pdf",
            filePath: SHBrochure,
        },
    },
];
