import { useState } from "react";
import { InView } from "react-intersection-observer";
import { Footer, FooterLogo } from "../components/index";
import ContactForm from "./contact-form/contact-form";

export default function ThankYouContact() {
  const [inViewContactForm, setInViewContactForm] = useState(false);
  const [inViewFooter, setInViewFooter] = useState(false);

  return (
    <>
      <InView onChange={setInViewContactForm} triggerOnce={true}>
        <ContactForm inView={inViewContactForm}></ContactForm>
      </InView>
      <InView onChange={setInViewFooter} triggerOnce={true}>
        <FooterLogo />
        <Footer inView={inViewFooter}></Footer>
      </InView>
    </>
  );
}
