import { useState } from "react";
import { InView } from "react-intersection-observer";
// RelatedArticles
import ShareBlogFour from "./../Share/ShareBlogFour.js";
import Banner from "./Banner";
export default function BlogFour() {
    const [inViewBanner, setInViewBanner] = useState(false);
    // const [inViewRelatedArticles, setInViewRelatedArticles] = useState(false);
    const [inViewShareBlogFour, setInViewShareBlogFour] = useState(false);
    return (
        <>
            <InView
                className="bgPattern"
                onChange={setInViewShareBlogFour}
                triggerOnce={true}
            >
                <ShareBlogFour
                    shareUrl={
                        "https://modernspaaces.com/blogs/why-sarjapura-best-area-to-buy-house"
                    }
                    inView={inViewShareBlogFour}
                ></ShareBlogFour>
            </InView>
            <InView className="" onChange={setInViewBanner} triggerOnce={true}>
                <Banner inView={inViewBanner}></Banner>
            </InView>
        </>
    );
}
