import { Col, Container, Row } from "react-bootstrap";
import { Desc, Heading } from "../../Global/Typography";

export default function MasterPlanHeader({ inView }) {
  // MasterPlanList
  const MasterPlanListitems = [
    {
      info: "5.5 Acres",
      label: "Land",
    },
    {
      info: "25,000 sqft",
      label: "Clubhouse",
    },
  ];

  return (
    <>
      <Container className="text-center mt50 mb-md-5 pb-md-3">
        <Row>
          <Col xs={12} lg={10} className="mx-auto">
            <Heading classList="my-3" variant="h1" text="Master Plan" />
            <ul className="ulDivider d-flex align-items-center justify-content-center">
              {MasterPlanListitems.map(({ info, label }, idx) => {
                return (
                  <li
                    className="m-3 p-3 m-md-4 p-md-4 text-left"
                    xs={12}
                    md={6}
                    lg={4}
                    key={idx}
                  >
                    <Heading
                      variant="h6"
                      classList="MasterPlan__label"
                      text={label}
                    />
                    <Heading
                      classList="MasterPlan__info"
                      variant="h1"
                      text={info}
                    />
                  </li>
                );
              })}
            </ul>
            <Desc
              classList="MasterPlan__desc"
              // text="1, 2 & 3 BHK - 330 Units"
              text="1, 2 & 3 BHK - 564 Units"
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}
