import { Container, Row, Col } from "react-bootstrap";
import { Desc, Heading } from "../../Global/Typography";
// AnchorButton,
import { useState } from "react";

import PrevArrow from "../../../images/Common/PrevArrow.svg";
import NextArrow from "../../../images/Common/NextArrow.svg";



import OutdoorSeating1 from "../../../images/SoulTree/ThereIsSomething/OutdoorSeating1.webp";


import SwiperCore, {
    Navigation,
    Pagination,
    Scrollbar,
    A11y,
    Autoplay,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "./OutdoorSeating.scss";
import DownloadBrochure from "../../Global/DownloadBrochure";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);


const noCommonWalls = "/images/EngraceVista/outdoorSeating/no_common_walls.webp";
const fourUnits = "/images/EngraceVista/outdoorSeating/engrace_vista_four_units_per_floor.webp";
const exclusiveCorner = "/images/EngraceVista/outdoorSeating/exclusive_corner.webp";
const widerBalconies = "/images/EngraceVista/outdoorSeating/wider_balcony.webp";
const elevators = "/images/EngraceVista/outdoorSeating/elevator.webp";
const airConditioning = "/images/EngraceVista/outdoorSeating/air_conditioning.webp";


export default function OutdoorSeating({ inView }) {
    const [show, setShow] = useState(false);
    const handleShow = (e) => {
        e.preventDefault();
        setShow(true);
    };
    // console.log(
    //   "🚀 ~ file: OutdoorSeating.js ~ line 31 ~ handleShow ~ handleShow",
    //   handleShow
    // );
    const handleClose = () => setShow(false);

    const OutdoorSeatingData = [
        {
            title: "There is a little something for each member of your family",
            desc: "Area designed to satisfy your outdoor needs and keep you motivated to work and socialize. With the various facilities available at your fingertips, you can enjoy a calm space to work or inspire yourself to break a sweat.",
            // button: "Download Clubhouse Brochure",
            button: "Download Amenities Brochure",
            imageList: [
                {
                    image: noCommonWalls,
                    imgAlt: "Engrace Vista Homes with no common walls for increased privacy and quiet living.",
                    // label: "Sports Area",
                    title: "No common walls for Maximum Privacy",
                    desc: "Live life your way with zero shared walls and endless possibilities.",
                    position: "80%",
                },
                {
                    image: fourUnits,
                    imgAlt: "Engrace Vista Four units per floor layout, maximizing space efficiency and peaceful living.",
                    // label: "Soultree Court",
                    title: "4 Units Per Floor",
                    desc: "With only four meticulously crafted units on each floor, you'll experience a level of exclusivity that defines true elegance.",
                    position: "70%",
                },
                {
                    image: exclusiveCorner,
                    imgAlt: "Exclusive Corner",
                    // label: "Kids Play Area",
                    title: "Exclusive Corner Units",
                    desc: "Each floor houses only 4 corner units with expansive views and natural airflow.",
                    position: "100%",
                },

                {
                    image: widerBalconies,
                    imgAlt: "Wider balcony offering ample space for your personal relaxation",
                    // label: "Sports Area",
                    title: "Wider Balconies",
                    desc: "Extensive balconies provide the perfect space for soaking up the sunshine, feeling the gentle rain, admiring stunning sunsets, or simply unwinding with a soothing cup of tea.",
                    position: "80%",
                },
                {
                    image: elevators,
                    imgAlt: "Elevator Convenient mobility for residents and guests.",
                    // label: "Sports Area",
                    title: "Elevators",
                    desc: "Say goodbye to waiting in queues for the elevator. Each tower is equipped with three lifts: 2 for residents and 1 for service, ensuring smoother and more efficient mobility management.",
                    position: "90%",
                },
                // {
                //     image: airConditioning,
                //     imgAlt: "Air Conditioning with concealed ducts for maximum comfort and better aesthetic",
                //     // label: "Sports Area",
                //     title: "Air Con",
                //     desc: "Home's with concealed air con ducts across all rooms, ensuring an efficiently cooled home.",
                //     position: "20%",
                // }
            ],
            image: OutdoorSeating1,
            label: "Outdoor seating",
        },
    ];

    return (
        <section
            className={`section bg-white ${inView ? "inView" : "outView"}
    `}
        >
            {OutdoorSeatingData.map((item, idx) => {
                return (
                    <Container
                        key={idx}
                        className="OutdoorSeating OutdoorSeating--small"
                    >
                        <div className="OutdoorSeating__Slider">
                            <Swiper
                                className="OutdoorSeatingSlider"
                                id="OutdoorSeatingSlider"
                                tag="section"
                                wrapperTag="ul"
                                spaceBetween={0}
                                keyboard={{
                                    enabled: true,
                                }}
                                speed={500}
                                loop={true}
                                autoplay={true}
                                pagination={{
                                    clickable: true,
                                    el: ".swiper-pagination-OutdoorSeating",
                                }}
                                navigation={{
                                    prevEl: ".ArrowButtonBlock__ArrowPrevWhite",
                                    nextEl: ".ArrowButtonBlock__ArrowNextWhite",
                                }}
                                onSwiper={(swiper) => console.log(swiper)}
                                // onSlideChange={(swiper) =>
                                //   console.log("slide index changed to: ", swiper.activeIndex)
                                // }
                                onInit={(swiper) =>
                                    console.log("Swiper initialized", swiper)
                                }
                                breakpoints={{
                                    300: {
                                        slidesPerView: 1,
                                        spaceBetween: 30,
                                    },
                                }}
                            >
                                {item.imageList.map((item, index) => (
                                    <SwiperSlide tag="li" key={index}>
                                        <Row className="align-items-center">
                                            <Col
                                                xs={12}
                                                md={6}
                                                lg={6}
                                                xl={5}
                                                className="px-3 px-md-5"
                                            >
                                                <div className="imagePatch">
                                                    <img
                                                        src={item.image}
                                                        className="OutdoorSeating__image w-100 mb-0"
                                                        alt={item.imgAlt}
                                                        style={{
                                                            objectPosition: `${item.position}`,
                                                        }}
                                                    />
                                                    {/* <span className="-label">
                                                        {item.title}
                                                    </span> */}
                                                </div>
                                            </Col>
                                            <Col
                                                xs={12}
                                                md={6}
                                                lg={6}
                                                xl={7}
                                                className="pt-5 pt-md-0 px-4 px-md-5"
                                            >
                                                <Heading
                                                    variant="h1"
                                                    classList="OutdoorSeating__title"
                                                    text={item.title}
                                                />
                                                <Desc
                                                    classList="OutdoorSeating__desc mt-3 mb-5 pb-5"
                                                    text={item.desc}
                                                />
                                                {/* <AnchorButton
                          href="#"
                          classList="OutdoorSeating__btn my-3"
                          text={item.button}
                          onClick={handleShow}
                        /> */}
                                            </Col>
                                        </Row>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                            <Row className="ArrowButtons pl-md-3">
                                <div className="swiper-pagination swiper-pagination-OutdoorSeating"></div>
                                {/* <div className="swiper-pagination swiper-pagination-OutdoorSeating bg-trans"></div> */}
                                <div className="ArrowButtonBlock">
                                    <div className="ArrowButtonBlock__Arrow ArrowButtonBlock__ArrowPrevWhite">
                                        <img
                                            src={PrevArrow}
                                            className="-image"
                                            alt="Blocks"
                                        />
                                    </div>
                                    <div className="ArrowButtonBlock__Arrow ArrowButtonBlock__ArrowNextWhite">
                                        <img
                                            src={NextArrow}
                                            className="-image"
                                            alt="Blocks"
                                        />
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </Container>
                );
            })}

            {/* DownloadBrochure */}
            <DownloadBrochure
                title={"Download Brochure"}
                show={show}
                handleClose={handleClose}
            />
        </section>
    );
}
