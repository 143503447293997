import { Col, Container, Row } from "react-bootstrap";
import { Heading } from "../../Global/Typography";
// AnchorGradientButtonAhref
import "./AllResources.scss";
import AllResourcesItems from "./AllResourcesItems";

export default function AllResources({ inView }) {
    return (
        <section
            className={`section all-resources-section py-5 mt-3 ${
                inView ? "inView" : "outView"
            }`}
        >
            <Container className="BlogsList content">
                <Row className="align-items-center py-2 mx-0">
                    <Col xs={12} className="pl-0">
                        <Heading
                            classList="all-resources-heading"
                            variant="h1"
                            text="All Resources"
                        />
                    </Col>
                </Row>
                <AllResourcesItems />
                {/* <Row className="justify-content-center my-4">
          <AnchorGradientButtonAhref
            href="/"
            classList="-btn my-3 px-4"
            text="See More"
            target="_blank"
          />
        </Row> */}
            </Container>
        </section>
    );
}
