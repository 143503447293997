import { useState } from "react";
import { InView } from "react-intersection-observer";
// RelatedArticles
import ShareBlogTwo from "./../Share/ShareBlogTwo.js";
import Banner from "./Banner";
export default function BlogTwo() {
    const [inViewBanner, setInViewBanner] = useState(false);
    // const [inViewRelatedArticles, setInViewRelatedArticles] = useState(false);
    const [inViewShareBlogTwo, setInViewShareBlogTwo] = useState(false);
    return (
        <>
            <InView
                className="bgPattern"
                onChange={setInViewShareBlogTwo}
                triggerOnce={true}
            >
                <ShareBlogTwo
                    shareUrl={
                        "https://modernspaaces.com/blogs/things-to-consider-buying-your-first-home"
                    }
                    inView={inViewShareBlogTwo}
                ></ShareBlogTwo>
            </InView>
            <InView className="" onChange={setInViewBanner} triggerOnce={true}>
                <Banner inView={inViewBanner}></Banner>
            </InView>
        </>
    );
}
