import { Col, Container, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { AnchorGradientButton, Desc, Heading } from "./Typography";

// import Fade from "react-reveal/Fade";

export default function ComingSoon({ show, handleClose }) {
  return (
    <>
      <Modal dialogClassName="modal680" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Heading
            variant="h3"
            classList="text-center mx-auto mb-3 mb-md-4"
            text={"Hey You,"}
          />
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className="py-0">
              <Col xs={12} md={12} lg={12} className="px-0 text-md-center">
                <Heading
                  variant="h4"
                  classList="-title"
                  text="Stay tuned. We are coming soon!"
                />
                <Desc
                  classList="-desc my-3"
                  text="Meanwhile why don’t you explore our projects?"
                />
                <div className="my-5 pb-5 just-center">
                  <AnchorGradientButton
                    href="/engrace"
                    classList="-btn my-2 mr-2 text-orange"
                    text="Explore Engrace"
                    onClick={handleClose}
                  />

                  <AnchorGradientButton
                    href="/soultree"
                    classList="-btn my-2 mr-2 text-orange"
                    text="Explore Soultree"
                    onClick={handleClose}
                  />

                  <AnchorGradientButton
                    href="/urbanest"
                    classList="-btn my-2 ml-2 text-orange"
                    text="Explore Urbanest"
                    onClick={handleClose}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}
