import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ModalVideo from "react-modal-video";
import { Desc, Heading, AnchorTag } from "../../Global/Typography";
// import TheHeartAndSoulImage from "/images/Engrace/TheHeartandSoul/engrace-location-video-banner.jpeg";
import VideoIconImage from "../../../images/SoulTree/VideoIconImage.svg";
import "./TheHeartAndSoul.scss";

const TheHeartAndSoulImage = "/images/Engrace/TheHeartandSoul/engrace-location-video-banner.jpeg";

export default function TheHeartAndSoul({ inView }) {
    const [isOpen, setOpen] = useState(false);
    return (
        <section
            className={`section my-md-5 py-md-5 ${
                inView ? "inView" : "outView"
            }`}
        >
            <Container className="text-left TheHeartAndSoul">
                <Row>
                    <Col xs={12} md={5} lg={5} xl={4}>
                        <div className="TheHeartAndSoul__content-block">
                            <Heading
                                variant="h1"
                                classList="TheHeartAndSoul__title"
                                text="It's All At Home"
                            />
                            <Desc
                                classList="TheHeartAndSoul__desc my-3 "
                                text="At Engrace we tried to understand how the WFH phenomenon would evolve in a post-pandemic world."
                            />
                            <Desc
                                classList="TheHeartAndSoul__desc my-3 "
                                text="While working from home has become commonplace, people have also begun to just spend lot more time at home. So, how should the concept of a home expand in this scenario?"
                            />
                            <AnchorTag
                                href="#"
                                classList="TheHeartAndSoul__link mt-4"
                                text="View the walkthrough"
                                onClick={() => setOpen(true)}
                            />
                        </div>
                    </Col>
                </Row>
                <div className="TheHeartAndSoul__image-block">
                    <ModalVideo
                        channel="youtube"
                        autoPlay
                        isOpen={isOpen}
                        // videoId="Jh9nUzWkxV4"
                        videoId="ZhDsF-Chlis"
                        onClose={() => setOpen(false)}
                    />
                    <img
                        src={TheHeartAndSoulImage}
                        className="TheHeartAndSoul__image TheHeartAndSoul__image__engrace"
                        alt="Engrace in the heart of Sarjapur"
                    />
                    <div
                        className="videoIconImage"
                        onClick={() => setOpen(true)}
                    >
                        <img
                            src={VideoIconImage}
                            className="TheHeartAndSoul__videoIcon"
                            alt="TheHeartAndSoul"
                        />
                    </div>
                </div>
            </Container>
        </section>
    );
}
