import { Col, Container, Row } from "react-bootstrap";
import { Desc, Heading } from "../../Global/Typography";
// import { Desc, Heading } from "../../Global/Typography";
// import TranquilCourtImage from "../../../images/SoulTree/TranquilCourt/TranquilCourtImage.png";
// import TranquilCourtImageLarge from "../../../images/SoulTree/TranquilCourt/TranquilCourtImageLarge.png";
import "./TranquilCourt.scss";
// const patternVideo = "/images/SereneHeights/Amenities/butterfly-park.mp4";
import patternVideo from "../../../images/SereneHeights/butterfly-park.mp4";
const TranquilCourtImageLarge =
    "/images/SereneHeights/Amenities/amenities.webp";

export default function TranquilCourt({ inView }) {
    const TranquilCourtData = [
        {
            title: "Tranquil Court",
            desc: "Designed to suit all lifestyle & age, Serene Heights offers the ultimate blend of urban convenience and serene escapes.",
            imageLarge: TranquilCourtImageLarge,
            // imageSmall: TranquilCourtImageSmall,

            patternVideo: patternVideo,
            // image: TranquilCourtImage,
            listItems: [
                "1.5 Kms Nature Trail Jogging Track",
                "Fruit Orchard",
                "Swimming Pool",
                "Dog Park",
                "Basketball Court",
                "Tennis Court",
                "Cricket Practice Net",
                "Children Play Area",
                "Outdoor Learning Centre",
                "Bonfire",
                "Amphitheatre",
                "Party Seating",
                "Butterfly Park",
                "Forest Grove",
                "Tree Avenue Walkway",
                "Yoga Lawn",
                "Jungle Gym ",
            ],
        },
    ];

    return (
        <>
            <Container className="py-2 my-md-3">
                <Row className="justify-content-center">
                    <Col xs={12} md={6} lg={6} xl={12} className=" text-center">
                        <Heading
                            variant="h1"
                            classList=""
                            text="Exclusive Premium Amenities"
                        />
                    </Col>
                </Row>
            </Container>

            <section
                className={`section bg-white ${inView ? "inView" : "outView"}
    `}
            >
                {TranquilCourtData.map((item, idx) => {
                    return (
                        <Container key={idx} className="TranquilCourt ">
                            <Row>
                                <Col
                                    xs={12}
                                    md={5}
                                    lg={5}
                                    xl={4}
                                    className="pr-md-5 pr-xl-4"
                                >
                                    {/* AS OF NOW */}
                                    {/* <Heading
                    variant="h1"
                    classList="TranquilCourt__title my-3"
                    text={item.title}
                  /> */}
                                    <Desc
                                        classList="TranquilCourt__desc my-3 "
                                        text={item.desc}
                                    />
                                    <ul className="TranquilCourtList">
                                        {item.listItems.map((i, j) => (
                                            <li key={j}>
                                                <Desc
                                                    classList="my-2"
                                                    text={i}
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                </Col>
                            </Row>
                            <div className="TranquilCourtImage SereneHeights">
                                {/* <img
                src={item.image}
                className="TranquilCourt__image"
                alt="TranquilCourt"
              /> */}
                                {/* SEPARATE */}
                                <img
                                    src={item.imageLarge}
                                    className="TranquilCourt__imageLarge"
                                    alt="Serene Heights an array of Outdoor Amenities  "
                                />

                                <video
                                    controls={false}
                                    className="TranquilCourt__imageSmall"
                                    loop={true}
                                    muted={true}
                                    autoPlay={true}
                                    playsInline={true}
                                >
                                    <source
                                        src={item.patternVideo}
                                        type="video/mp4"
                                    />
                                </video>
                            </div>
                        </Container>
                    );
                })}
            </section>
        </>
    );
}
