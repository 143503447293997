import { Link } from "react-router-dom";
import msLogo from "../../images/logo-black.svg";
import "./mobile-menu.scss";

import { useContext, useState } from "react";
import {
    Accordion,
    AccordionContext,
    Card,
    useAccordionToggle,
} from "react-bootstrap";
import ComingSoon from "../Global/ComingSoon";
import GetInTouchForm from "../Global/ContactUsModal/GetInTouchForm";
import NewLaunchProjectModal from "../Global/NewLaunchProjectModal/NewLaunchProjectModal";
import RightArrowIconSmall from "../Global/Typography/RightArrowSmall";
import { ProjectsList } from "./constants";
// import GetInTouchForm from "../Global/GetInTouchForm";
export default function MobileMenu({ isShowMobileMenu, toggleMobileMenu }) {
    const [show, setShow] = useState(false);
    const [showContactUsModal, setShowContactUsModal] = useState(false);
    const [showNewLaunchModal, setShowNewLaunchModal] = useState(false);
    const handleShow = (e) => {
        e.preventDefault();
        setShow(true);
    };
    const handleClose = () => setShow(false);

    // const [show1, setShow1] = useState(false);
    // const handleShow1 = (e) => {
    //   e.preventDefault();
    //   setShow1(true);
    // };
    // const handleClose1 = () => setShow1(false);
    const ContextAwareToggle = ({
        projectCategoryType,
        eventKey,
        callback,
    }) => {
        const currentEventKey = useContext(AccordionContext);

        const decoratedOnClick = useAccordionToggle(
            eventKey,
            () => callback && callback(eventKey)
        );

        const isCurrentEventKey = currentEventKey === eventKey;

        return (
            <div
                className="menu-label d-flex justify-content-between"
                onClick={decoratedOnClick}
            >
                <span className="bg-white pr-2 ">{projectCategoryType}</span>
                <span>
                    {isCurrentEventKey ? (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="21"
                            viewBox="0 0 20 21"
                            fill="none"
                        >
                            <path
                                d="M16.5984 13.0396L11.1651 7.60625C10.5234 6.96458 9.47344 6.96458 8.83177 7.60625L3.39844 13.0396"
                                stroke="black"
                                stroke-opacity="0.32"
                                stroke-width="2"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    ) : (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="21"
                            viewBox="0 0 20 21"
                            fill="none"
                        >
                            <path
                                d="M16.5984 7.96094L11.1651 13.3943C10.5234 14.0359 9.47344 14.0359 8.83177 13.3943L3.39844 7.96094"
                                stroke="black"
                                stroke-opacity="0.32"
                                stroke-width="2"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    )}
                </span>
            </div>
        );
    };
    const renderProjectName = ({ label, link, type }) => {
        switch (type) {
            case "upcoming":
                return <span className="menu-item fc-black">{label}</span>;
            case "newLaunch":
                return (
                    <a
                        href="javascript;"
                        className="menu-item fc-black"
                        onClick={(e) => {
                            e.preventDefault();
                            setShowNewLaunchModal(true);
                        }}
                    >
                        {label}
                    </a>
                );
            default:
                return (
                    <Link
                        to={link}
                        className="menu-item fc-black"
                        onClick={toggleMobileMenu}
                    >
                        {label}
                    </Link>
                );
        }
    };

    return (
        <>
            <div
                className={`mobile-menu w-100 bg-white d-flex d-sm-none flex-column ${
                    isShowMobileMenu ? "show" : "hide"
                }`}
            >
                <div className="d-flex header content align-items-center justify-content-between pt-4 w-100 ">
                    <img
                        className="mobile-logo"
                        alt={"Modern Spaaces"}
                        src={msLogo}
                    />
                    <i
                        className="icon-close-nav"
                        onClick={toggleMobileMenu}
                    ></i>
                </div>

                <div className="v-menu content d-flex justify-content-between flex-column flex-grow-1 w-100">
                    <div
                        className="d-flex flex-column"
                        style={{ gap: "1.5rem" }}
                    >
                        <div className="divider"> </div>
                        <div>
                            <Accordion>
                                {ProjectsList.map((projectCategory, idx) => (
                                    <Card>
                                        <Card.Header>
                                            <ContextAwareToggle
                                                eventKey={`${idx}`}
                                                projectCategoryType={
                                                    projectCategory.type
                                                }
                                            ></ContextAwareToggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey={`${idx}`}>
                                            <Card.Body>
                                                <ul className="p-0 fw-light">
                                                    {projectCategory.items.map(
                                                        (project) => (
                                                            <li className="">
                                                                {renderProjectName(
                                                                    project
                                                                )}
                                                            </li>
                                                        )
                                                    )}
                                                </ul>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                ))}
                            </Accordion>
                        </div>
                        <div className="divider"> </div>

                        <div
                            className="d-flex flex-column"
                            style={{ gap: "1rem" }}
                        >
                            <div className="menu-label ">
                                <span className="bg-white pr-2 ">
                                    <a
                                        href="/#about-us"
                                        className="fc-black"
                                        onClick={toggleMobileMenu}
                                    >
                                        About Us
                                    </a>
                                </span>
                            </div>

                            <div className="menu-label menu-label-black">
                                <span className="bg-white pr-2 ">
                                    <a href="/blogs" className="fc-black">
                                        Blogs
                                    </a>
                                </span>
                            </div>
                        </div>
                        <div className="divider"> </div>

                        <ul
                            className="p-0 fw-light d-flex flex-column flex-grow-1"
                            style={{ gap: "0.5rem" }}
                        >
                            <li className="">
                                <a
                                    href="tel:+91 9008 288 288"
                                    className="menu-item  text-black-50"
                                >
                                    +91 9008 288 288
                                </a>
                            </li>
                            <li className="">
                                <a
                                    href="mailto:info@modernspaaces.com"
                                    className="menu-item text-black-50"
                                >
                                    info@modernspaaces.com
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="text-center btn-wrapper">
                        <button
                            className="app-btn--solid--primary d-flex justify-content-center align-items-center w-100"
                            onClick={() => {
                                toggleMobileMenu();
                                setShowContactUsModal(true);
                            }}
                        >
                            <span className="btn-text menu-item">
                                Contact Us{" "}
                            </span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="21"
                                viewBox="0 0 20 21"
                                fill="none"
                            >
                                <path
                                    d="M12.0234 5.4375L17.0818 10.4958L12.0234 15.5542"
                                    stroke={"#e8a111"}
                                    stroke-width="2"
                                    stroke-miterlimit="10"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />

                                <path
                                    d="M2.91406 10.5H16.9391"
                                    stroke={"#e8a111"}
                                    stroke-width="2"
                                    stroke-miterlimit="10"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>

            <GetInTouchForm
                show={showContactUsModal}
                IsNotAPlan={true}
                handleClose={() => setShowContactUsModal(false)}
            />
            <NewLaunchProjectModal
                show={showNewLaunchModal}
                handleClose={() => setShowNewLaunchModal(false)}
            />
            {/* ComingSoon */}
            <ComingSoon show={show} handleClose={handleClose} />
            {/* GetInTouchForm */}
            {/* <GetInTouchForm
        IsNotAPlan={false}
        show={show1}
        handleClose={handleClose1}
      /> */}
        </>
    );
}
