import imageKhata from "../../src/images/Blogs/A&BKhata.png";
import Khatacomparision from "../../src/images/Blogs/A&Bkhatadifference.png";

const data = [
    {
        id: "abc104",
        slug: "defference-between-a-khata-b-khata-and-its-impact-on-property",
        image: imageKhata,
        image2: Khatacomparision,
        label: "Industry",
        title: "Difference between A khata and B khata and its impact on Property",
        publishedOn: "Dec, 2021",
        time: "2 min read",
        description:
            "India's real estate sector is a vital part of the country's economy, contributing about 7% to the GDP and generating numerous job opportunities. However, it faced a lack of proper regulations for a long time. To tackle this issue, the Indian government introduced the RERA Act in 2016. This law aimed to bring fairness and clear rules for both homebuyers and construction companies. Its goal was to fix the confusion and differences in how things were managed in the real estate industry ",
        authorImage:
            "https://images.unsplash.com/photo-1600188769099-d25b4ec79659?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
        authorName: "Noveen Reddy",
        department: "Marketing Head",
        contactNumber: "987654321",
        // imageOther: "https://modernspaaces.com/BlogListImages/BlogFour/Img01.webp",
        title11: "What is Khata?",
        description11:
            "The concept of “Khata” was introduced in the year 2007 with the establishment of the BBMP (Bruhat Bengaluru Mahanagara Palike), a municipal corporation of Bangalore.",
        description12:
            "The word 'Khata' literally means account in Kannada and holds significant importance in property transactions within Bangalore.",
        description13:
            "Khata serves as a crucial legal document that functions as an official record that recognizes and certifies properties in the city. The Khata provides essential information about property ownership. This simplifies processes like tax payments, licensing, obtaining loans, gaining property approvals, and so forth.",
        title1: "What is Property Tax?",
        typesofkhata: [
            {
                label: "A Khata",
                description:
                    "This category signifies legal ownership and permanent property records. Owners with A Khata have paid their property taxes to the BBMP (Bangalore's municipal corporation). Having an A Khata makes it easier to handle financial transactions, apply for licenses, and seek loans.",
            },
            {
                label: "B Khata",
                description:
                    "This is a temporary document for properties facing ownership disputes or issues despite tax payments. It was created by the BBMP following a Karnataka High Court order in 2014. Owners of B Khata properties can convert them to A Khata by meeting specific requirements, such as clearing outstanding taxes and fulfilling criteria set by the BBMP.",
            },
        ],
        conclusion1:
            "The distinction between A Khata and B Khata helps in identifying properties with confirmed legal ownership (A Khata) and those under scrutiny or dispute (B Khata).",

        title2: "Comparison between A khata and B Khata",
        title3: "How to do Khata transfer in Bangalore?",
        title3list: [
            {
                label: "Step 1",
                description:
                    " In many Indian cities, property owners are required to self-assess their property tax liabilities. This involves determining the property's value, by applying the relevant formula, and paying the calculated tax. But self-assessment is subject to audit by municipal authoritiesContact a notary to attest the property's sale deed copy.",
            },
            {
                label: "Step 2",
                description:
                    "Obtain an Encumbrance Certificate, ensuring there are no financial or legal issues with the property. It usually takes about a week to get.",
            },
            {
                label: "Step 3",
                description:
                    "Visit the Seva sindhu website or the BBMP Assistant Revenue Officer Office to get the Khata application form.",
            },
            {
                label: "Step 4",
                description:
                    " Fill out the application form correctly and attach the required documents.",
            },
            {
                label: "List of documents required are",
                list: [
                    " Sale deed",
                    "Mother deed",
                    "Encumbrance certificate",
                    "Tax paid receipt",
                    "ID proof",
                    "Possession certificate",
                    "Allotment letter",
                ],
            },
            {
                label: "Step 5",
                description:
                    "  Submit the form to the BBMP office and get an acknowledgment slip  for tracking",
            },
            {
                label: "Step 6",
                description:
                    "  Check the application's status weekly; approval time can vary.",
            },
            {
                label: "Step 7",
                description:
                    " If you hear nothing for two months, file an RTI to inquire about the application's status",
            },
            {
                label: "Step 8",
                description:
                    " After approval, a BBMP revenue officer will verify the property",
            },
            {
                label: "Step 9",
                description:
                    " Pay a Khata registration fee (2% of the property value from the sale deed). You'll receive a notification for pending property tax payment, indicating the property is registered in your name. The tax should be paid within 15 days.",
            },
            {
                label: "Step 10",
                description:
                    "Once you have paid the property tax, you'll get the Khata extract in your name.Commercial properties have higher tax rates than residential properties. Transferring Khata involves fewer formalities and slightly different documents.",
            },
        ],

        title4: "Khata transfer charges in Bangalore",
        description41:
            "The A Khata registration fee is typically calculated as 2% of the property's value stated in the sale deed.",
        description42:
            "Let's say you have a property with a sale deed that states its value as Rs. 50,00,000 (50 lakhs). To calculate the A Khata registration fee, you would take 2% of this value:",
        description43: "2% of Rs. 50,00,000 = 0.02 * 50,00,000 = Rs. 1,00,000",
        description44:
            "So, the A Khata registration fee for this property would be Rs. 1,00,000.",

        title5: "Conclusion",
        Conclusion:
            "Understanding the distinction between A Khata and B Khata properties is essential for anyone dealing with real estate in Bangalore. A Khata represents legally valid properties, while B Khata pertains to properties with legal disputes. These categories impact property tax, resale, licensing, and loans. It's essential to follow the proper procedures for Khata transfer to ensure legal compliance.",

        Faq: "FAQs",
        Faqs: [
            {
                label: "What is the problem with the B Khata property in Bangalore?",
                description: [
                    "B Khata properties in Bangalore can have legal and resale challenges, limited services, and potential future legal complications.",
                ],
            },
            {
                label: "What are the documents required for the Khata transfer?",
                description: [
                    "Documents required for Khata transfer are Sale deed, Mother deed, Encumbrance certificate, Tax paid receipt, ID proof, possession certificate, allotment letter",
                ],
            },
            {
                label: "Who can apply for a Khata?",
                description: [
                    "Any title holder of a property within the BBMP Jurisdiction can apply for a khata in a prescribed form, along with relevant documents.",
                ],
            },
        ],
    },
];

export default data;
