import { useState } from "react";
import { InView } from "react-intersection-observer";
import {
    ArchitecturalSlider,
    Banner,
    Campus,
    Develop,
    SmartGreenMinimal,
    // Amenities,
    ExcellentConnectivity,
    GalleryTabs,
    MasterPlan,
    // Clubs,
    ClubConnect,
    OutdoorSeating,
    ProjectAtGlance,
    Schools,
    SocialEntertainment,
    // OtherProject,
    Specifications,
    // Blocks,
    TheHeartAndSoul,
    TranquilCourt,
} from "../components/Soulace";
// import { Banner, ContactUs } from "../components/SoulTree";
import { Footer, FooterLogo } from "../components/index";
import { Blog } from "../components/Urbanest";
import { ContactUs } from "../components/SoulTree";
import ProjectResources from "../components/ProjectResources";
import { ProjectResourcesData as resources } from "../components/Soulace/ProjectResources/constants";
import { Helmet } from "react-helmet-async";

export default function Engrace() {
    const [inViewBanner, setInViewBanner] = useState(false);
    const [inViewArchitecturalSlider, setInViewArchitecturalSlider] =
        useState(false);
    const [inViewProjectAtGlance, setInViewProjectAtGlance] = useState(false);
    // const [inViewMasterPlan, setInViewMasterPlan] = useState(false);
    const [inViewProjectResources, setInViewProjectResources] = useState(false);

    // const [inViewBlocks, setInViewBlocks] = useState(false);

    const [inViewTheHeartAndSoul, setInViewTheHeartAndSoul] = useState(false);
    // const [inViewClubs, setInViewClubs] = useState(false);
    const [inViewClubConnect, setInViewClubConnect] = useState(false);
    const [inViewTranquilCourt, setInViewTranquilCourt] = useState(false);
    const [inViewOutdoorSeating, setInViewOutdoorSeating] = useState(false);
    // const [inViewAmenities, setInViewAmenities] = useState(false);
    const [inViewSmartGreenMinimal, setInViewSmartGreenMinimal] =
        useState(false);
    const [inViewExcellentConnectivity, setInViewExcellentConnectivity] =
        useState(false);
    const [inViewCampus, setInViewCampus] = useState(false);
    const [inViewSchools, setInViewSchools] = useState(false);
    const [inViewSocialEntertainment, setInViewSocialEntertainment] =
        useState(false);
    const [inViewDevelop, setInViewDevelop] = useState(false);
    const [inViewGalleryTabs, setInViewGalleryTabs] = useState(false);
    const [inViewSpecifications, setInViewSpecifications] = useState(false);
    // const [inViewOtherProject, setInViewOtherProject] = useState(false);
    const [inViewContactUs, setInViewContactUs] = useState(false);
    const [inViewBlog, setInViewBlog] = useState(false);

    const [inViewFooter, setInViewFooter] = useState(false);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0"
                />
                <title>
                    4,5 BHK Villas for sale in Sarjapura Road | Modern Spaaces
                </title>
                <meta
                    name="description"
                    content="Soulace Villas by Modern Spaaces located on Sarjapur road are timeless and premium . 4 and 5 BHK with private elevators and an array of modern ammenities
"
                />
            </Helmet>
            <InView
                className="bgPattern"
                onChange={setInViewBanner}
                triggerOnce={true}
            >
                <Banner inView={inViewBanner}></Banner>
            </InView>

            <InView
                className="bgPattern"
                onChange={setInViewArchitecturalSlider}
                triggerOnce={true}
            >
                <ArchitecturalSlider
                    inView={inViewArchitecturalSlider}
                ></ArchitecturalSlider>
            </InView>

            <InView onChange={setInViewContactUs} triggerOnce={true}></InView>
            <InView
                className="bgPattern bgPattern--right"
                onChange={setInViewProjectAtGlance}
                triggerOnce={true}
            >
                <ProjectAtGlance
                    inView={inViewProjectAtGlance}
                ></ProjectAtGlance>
            </InView>
            <InView
                className="bgPattern"
                onChange={setInViewProjectResources}
                triggerOnce={true}
            >
                <ProjectResources
                    inView={inViewProjectResources}
                    resources={resources}
                    project="Soulace"
                ></ProjectResources>
            </InView>
            {/* <InView
                className="bgPattern bgPattern--left"
                onChange={setInViewMasterPlan}
                triggerOnce={true}
            >
                <MasterPlan inView={inViewMasterPlan}></MasterPlan>
            </InView> */}

            {/* <InView
        className="bgPattern"
        onChange={setInViewBlocks}
        triggerOnce={true}
      >
        <Blocks inView={inViewBlocks}></Blocks>
      </InView> */}

            <InView
                className="bgPattern"
                onChange={setInViewTheHeartAndSoul}
                triggerOnce={true}
            >
                <TheHeartAndSoul
                    inView={inViewTheHeartAndSoul}
                ></TheHeartAndSoul>
            </InView>

            <InView
                className="bgPattern mdOverflowHidden"
                id="club-trigger"
                onChange={setInViewClubConnect}
                triggerOnce={true}
            >
                <ClubConnect inView={inViewClubConnect}></ClubConnect>
            </InView>

            {/* <InView
        className="bgPattern mdOverflowHidden"
        onChange={setInViewClubs}
        triggerOnce={true}
      >
        <Clubs inView={inViewClubs}></Clubs>
      </InView> */}
            <InView
                className="bgPattern mt-md-2 pt-md-1"
                onChange={setInViewTranquilCourt}
                triggerOnce={true}
            >
                <TranquilCourt inView={inViewTranquilCourt}></TranquilCourt>
            </InView>
            <InView
                className="bgPattern"
                onChange={setInViewOutdoorSeating}
                triggerOnce={true}
            >
                <OutdoorSeating inView={inViewOutdoorSeating}></OutdoorSeating>
            </InView>

            {/* <InView
        className="bgPattern"
        onChange={setInViewAmenities}
        triggerOnce={true}
      >
        <Amenities inView={inViewAmenities}></Amenities>
      </InView> */}

            <InView
                className="bgPattern"
                onChange={setInViewSmartGreenMinimal}
                triggerOnce={true}
            >
                <SmartGreenMinimal
                    inView={inViewSmartGreenMinimal}
                ></SmartGreenMinimal>
            </InView>

            <InView
                className="bgPattern bgPattern--leftSquare"
                onChange={setInViewExcellentConnectivity}
                triggerOnce={true}
            >
                <ExcellentConnectivity
                    inView={inViewExcellentConnectivity}
                ></ExcellentConnectivity>
            </InView>

            <InView
                className="bgPattern bgPattern--rightSquare"
                onChange={setInViewCampus}
                triggerOnce={true}
            >
                <Campus inView={inViewCampus}></Campus>
            </InView>

            <InView
                className="bgPattern bgPattern--leftSquare"
                onChange={setInViewSchools}
                triggerOnce={true}
            >
                <Schools inView={inViewSchools}></Schools>
            </InView>

            <InView
                className="bgPattern bgPattern--leftSquare"
                onChange={setInViewSocialEntertainment}
                triggerOnce={true}
            >
                <SocialEntertainment
                    inView={inViewSocialEntertainment}
                ></SocialEntertainment>
            </InView>

            <InView
                className="bgPattern bgPattern--rightSquare"
                onChange={setInViewDevelop}
                triggerOnce={true}
            >
                <Develop inView={inViewDevelop}></Develop>
            </InView>

            <InView
                className="bgPattern "
                onChange={setInViewBlog}
                triggerOnce={true}
            >
                <Blog inView={inViewBlog}></Blog>
            </InView>
            <InView
                className="bgPattern bgPattern--black bgPattern--leftSquareFull"
                onChange={setInViewGalleryTabs}
                triggerOnce={true}
            >
                <GalleryTabs inView={inViewGalleryTabs}></GalleryTabs>
            </InView>

            <InView
                className="bgPattern bgPattern--black"
                onChange={setInViewSpecifications}
                triggerOnce={true}
            >
                <Specifications inView={inViewSpecifications}></Specifications>
            </InView>

            {/* <InView
        className="bgPattern "
        onChange={setInViewOtherProject}
        triggerOnce={true}
      >
        <OtherProject inView={inViewOtherProject}></OtherProject>
      </InView> */}

            <InView
                className="bgPattern bgPattern--HrGrassPattern"
                onChange={setInViewContactUs}
                triggerOnce={true}
            >
                <ContactUs inView={inViewContactUs}></ContactUs>
            </InView>
            <FooterLogo />
            <InView
                className="bgPattern "
                onChange={setInViewFooter}
                triggerOnce={true}
            >
                <Footer inView={inViewFooter}></Footer>
            </InView>
        </>
    );
}
