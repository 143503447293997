import "./Specifications.scss";
import React, { useState } from "react";
import {
    Row,
    Container,
    Accordion,
    Card,
    Col,
    useAccordionToggle,
} from "react-bootstrap";
import { Desc, Heading, DescSmall } from "../../Global/Typography";
import Minus from "../../../images/Common/MinusWhite.svg";
import Plus from "../../../images/Common/PlusWhite.svg";

function CustomToggle({ children, eventKey, handleClick }) {
    const decoratedOnClick = useAccordionToggle(eventKey, () => {
        handleClick();
    });

    return (
        <div className="cardHeader" onClick={decoratedOnClick}>
            {children}
        </div>
    );
}
export default function Specifications({ inView }) {
    const [activeKey, setActiveKey] = useState(0);

    const SpecificationsItems = [
        {
            title: "Architecture",
            list: [
                {
                    label: "STRUCTURE ",
                    desc: "Full R.C.C framed structure, designed as per relevant BIS codes for earth quake resistance and structurally efficient systems.",
                    items: [
                    ],
                },

                {
                    label: "WALLS ",
                    desc: "",
                    items: [
                        "Internal walls: Smooth putty finish with emulsion paint finish",
                        "Kitchen & Utility: No dadoing above kitchen counter and in utility",
                        "Toilets: Ceramic tiles wall dadoing up to false ceiling",
                        "External walls: Texture finish with external grade weatherproof paint ",
                        "Balcony/terraces: External grade weatherproof paint"
                    ],
                },
                {
                    label: "DOORS",
                    desc: "",
                    items: [
                        "Main door: Engineered door frame and engineered wooden shutter with veneer finish",
                        "Internal doors: Engineered door frame and engineered wooden shutter with laminate/veneer finish",
                        "Balcony access: UPVC sliding doors with provision for mosquito mesh shutter"
                    ],
                },
                {
                    label: "WINDOWS",
                    desc: "",
                    items: [
                        "UPVC sliding windows with provision for mosquito mesh shutter",
                        "Kitchen Window: Sliding UPVC with exhaust fan provision & without mesh",
                    ],
                },
                {
                    label: "CEILING",
                    desc: "",
                    items: [
                        "All internal ceiling: Smooth putty with emulsion paint.",
                        "Toilets: Modular grid false ceiling & white wash above false ceiling.",
                        "Utility & Balcony: Painted with emulsion paint"
                    ],
                },
                {
                    label: "FLOORING",
                    desc: "",
                    items: [
                        "All Corridors and Lobbies: Anti-skid vitrified tiles",
                        "Living/Dining, Bedrooms, Kitchen & Utility: Vitrified double-charged tiles & granite for main door threshold",
                        "Toilets & Balconies: Anti-skid ceramic tiles"
                    ],
                },
            ],
        },
        {
            title: "ELECTRICAL & PLUMBING",
            list: [
                {
                    label: "INTERNET",
                    desc: "Data point provided – Living room – TV connection (MBR wired connection)",
                    items: [],
                },
                {
                    label: "ELECTRIC WORKS",
                    desc: "",
                    items: ["General:Power outlet for water purifier, washing machine, and dishwasher,Adequate EB power will be provided",
                        "DG Power Backup:1KW DG backup for individual units and 100% for common area lighting, pump, lift and fire services",
                        "AC Provision:Provision for powerpoint and drain outlet for AC in all bedrooms and living-dining areas."
                    ],
                },
                {
                    label: "ELEVATORS",
                    desc: "Elevators with automatic rescue device and emergency call facility to security",
                    items: [],
                },
                {
                    label: "ALL BATHROOMS",
                    desc: "",
                    items: ["EWC - White coloured wall mounted EWC with seat cover, flush valve, health faucet ",
                        "Wash basin - White color counter top wash basin with faucet",
                        "Shower - Shower with single lever diverter",
                        "Faucets - Water efficient CP fixtures"],
                },
                {
                    label: "UTILITY",
                    desc: "Plumbing lines provision for Water purifier, Washing machine & Dishwasher",
                    items: [],
                },
            ],
        },
        {
            title: "OTHER SERVICES",
            list: [
                {
                    label: "CCTV",
                    desc: "At selected locations as per design (all lobbies, all entry/exits, common areas)",
                    items: [],
                },
                {
                    label: "EV CHARGING & CAR WASH",
                    desc: "",
                    items: ["Car Charging Points: Power outlet provision for EV charging at dedicated parking spaces.",
                        "Car Wash Facility: Car wash point provided at a dedicated space with plumbing points (water inlet and water outlet)."],
                },
                {
                    label: "FIRE & SAFETY",
                    desc: "Fire systems will be provided as per the fire norms.",
                    items: [
                    ],
                },
                {
                    label: "LPG CONNECTION",
                    desc: "Gas bank with reticulated piped gas system to individual apartment monitored with gas meter from any pvt. Designed provider.",
                    items: [
                    ],
                },
            ],
        },

    ];

    return (
        <section
            className={`section  ${inView ? "inView" : "outView"}
  `}
        >
            {/* <Container> */}
            <Container className="Specifications Specifications--black">
                <Row>
                    <Col xs={12} lg={6} className="VerticalDivider">
                        <Heading
                            variant="h1"
                            classList="-title"
                            text="Specifications"
                        />
                        <Desc
                            classList="-desc my-3 "
                            text="The finer details."
                        />
                    </Col>
                    <Col xs={12}>
                        <Accordion className="Accordion" defaultActiveKey={1}>
                            {SpecificationsItems.map((item, index) => (
                                <Card key={index}>
                                    <CustomToggle
                                        className="AccordionHeading"
                                        as={Card.Header}
                                        eventKey={index + 1}
                                        handleClick={() => {
                                            if (activeKey === index) {
                                                setActiveKey(null);
                                            } else {
                                                setActiveKey(index);
                                            }
                                        }}
                                    >
                                        <Heading
                                            variant="h4"
                                            classList="-title my-0 mr-5 pr-5"
                                            text={item.title}
                                        />
                                        <img
                                            className="PlusMinus"
                                            src={
                                                activeKey === index
                                                    ? Minus
                                                    : Plus
                                            }
                                            alt={"Minus Plus"}
                                        />
                                    </CustomToggle>
                                    <Accordion.Collapse eventKey={index + 1}>
                                        <Card.Body className="">
                                            <ul className="Accordion__list px-3">
                                                {item.list.map(
                                                    (
                                                        { label, desc, items },
                                                        i0
                                                    ) => {
                                                        return (
                                                            <div key={i0}>
                                                                <Heading
                                                                    variant="h7"
                                                                    // classList="-desc"
                                                                    text={label}
                                                                />

                                                                <li className="mb-2">
                                                                    {desc ? (
                                                                        <DescSmall
                                                                            classList="my-2"
                                                                            text={
                                                                                desc
                                                                            }
                                                                        />
                                                                    ) : null}

                                                                    {items
                                                                        ? items.map(
                                                                            (
                                                                                it,
                                                                                i1
                                                                            ) => {
                                                                                return (
                                                                                    <DescSmall
                                                                                        key={
                                                                                            i1
                                                                                        }
                                                                                        classList="mt-2 mb-0"
                                                                                        text={
                                                                                            it
                                                                                        }
                                                                                    />
                                                                                );
                                                                            }
                                                                        )
                                                                        : null}
                                                                </li>
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </ul>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            ))}
                        </Accordion>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}
