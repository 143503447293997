// import EnergyEfficient from "../../src/images/Blogs/EnergyEfficientBuilding.png";
// import GoingGreen from "../../src/images/Blogs/goingGreen.png"
// import Glimpse from "../../src/images/Blogs/glimpse.png"

const EnergyEfficient = "/images/Blogs/energy-efficient-building-banner.png";
const GoingGreen = "/images/Blogs/green-spaces-bangalore-banner.png";
const Glimpse = "/images/Blogs/modern-spaaces-green-initiatives-banner.png";



const data = [
  {
    id: "abc104",
    slug: "choose-between-green-traditional-energy-efficient-buildings",
    imgAlt:"Energy Efficient Buildings in Real Estate",
    image: EnergyEfficient,
    image2: GoingGreen,
    image2Alt: "Remaining Green spaces in Bangalore",
    image3:Glimpse,
    image3Alt: "Modern Spaaces Green Initiatives",
    label: "Real Estate",
    title: " Choose between Green, Traditional and Energy efficient buildings  ",
    publishedOn: "Jan, 2024",
    time: "5 min read",
    description:
      "Experience the future with smart homes! Discover how smart home technology enhances convenience, security, and energy efficiency.Lets find out more about it in this blog.",
    authorImage:
      "https://images.unsplash.com/photo-1600188769099-d25b4ec79659?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    authorName: "Noveen Reddy",
    department: "Marketing Head",
    contactNumber: "987654321",
    // imageOther: "https://modernspaaces.com/BlogListImages/BlogFour/Img01.webp",
    
    title1:"Introduction",
    description1:"The global wave of urbanization has profoundly reshaped landscapes, impacting natural resources and intensifying carbon emissions. According to the UN, a staggering 55% of the world's population currently resides in urban areas, a figure projected to surge to 68% by 2050. This projected figure signifies a future where two-thirds of humanity will call cities their home.",
    title2:"Why Sustainability and Going Green Matter?",
    description2:"Examining Bengaluru's swift urbanization, we see it ranked third among India's most populous cities with 13,608,000 residents, marking a 3.15% increase from the previous year. Known as India's Silicon Valley, Bengaluru attracts IT firms, startups, and global corporations. Oxford Economics anticipates it as the Asia-Pacific region's fastest-growing city in 2023. This emphasizes the need for sustainable choices in our development projects.",

    title3:"Bengaluru's Disappearing Green Spaces: A Concern for the City",
    description31:"However, this rapid growth presents challenges. The city's infrastructure struggles with the rising population, causing traffic congestion, insufficient public transport, and urban discomfort. Moreover, as urban expansion outpaces infrastructure development, pressing environmental concerns like disappearing green spaces, pollution, and water scarcity have emerged.",
    description32:"In the real estate sector, many organizations are addressing the downsides of unchecked urbanization. Sustainable and energy-efficient building practices are gaining prominence, leading to various building styles like green buildings, traditional structures, and energy-efficient designs. This blog aims to comprehensively compare these three building types, highlighting their unique traits, benefits, and challenges.",
    
    title4:"What is a Green Building?",
    list41:[
      {
        label:"Green Buildings",
        description:"In simple terms, green buildings are constructed with sustainability in mind, integrating strategies like renewable energy sources and responsibly sourced materials. This approach ensures sustainability throughout the building's lifecycle – from conception and construction to preservation, renovation, and even demolition. The primary goal of a green building is to coexist harmoniously with its natural environment."

      },
    ],
    title42:"Key Traits of Green Buildings:",
    list42:[
      {
        label:"Resource Optimization",
        description:"Incorporating recycled materials, efficient water systems, and reduced energy consumption."
      },
      {
        label:"Renewable Energy",
        description:"Relying on sources like solar panels and wind turbines for clean power generation, minimizing environmental impact"
      },
      {
        label:"Indoor Air Quality",
        description:"Prioritizing occupants' well-being through low-VOC paints and proper ventilation systems."
      },
      {
        label:"Water Conservation",
        description:"Implementing water-efficient fixtures, rainwater harvesting, and wastewater treatment technologies."
      },
      {
        label:"Sustainable Design",
        description:"Extending sustainability to outdoor spaces through native plantings, permeable surfaces, and green roofs."
      }
    ],
    title43:"Benefits and Challenges of Green Buildings:",
    list43:[
      {
        label:"Environmental Impact",
      description:"Significant reduction in carbon footprint, waste production, and reliance on non-renewable energy sources."
     },
     {
      label:"Energy Savings",
    description:"Lower energy consumption and operating costs due to efficient insulation, lighting, and HVAC systems."
   },
   {
    label:"Health and Well-being",
  description:"Improved indoor air quality and access to natural light enhance occupants' quality of life."
 },
 {
  label:"Market Value",
description:"Greater resale and rental values due to sustainable features and reduced operational costs."
}
    ],

    title44:"Challenges",
    description44:"Higher initial costs, awareness challenges, and complexities in integrating multiple sustainable technologies.",
  

 title5:"Why is the significance of green buildings emphasized, even though their construction cost is typically higher?",
 description5:"Even though the initial investment for a green building may seem to be higher, in the long run, green buildings are not only a smart choice for the environment but also for economic benefits and society as a whole. It helps create a more sustainable and enduring future while providing benefits to both occupants and investors.",

 title6:"Conventional Buildings: A Glimpse",
 description6:"Conventional Buildings: Conventional buildings adhere to traditional construction practices that often disregard energy efficiency and eco-friendly considerations. Sustainable concepts are rarely incorporated, leading to structures that consume excess energy and resources.",
 title61:"Characteristics of conventional building",
 list61:[
  {
    label:"Materials",
    description:"Minimal Use of Sustainable Materials.",
  },
  { 
    label:"Energy Inefficiency",
    description:"Lack of energy-efficient features and systems, leading to higher operational costs.",
  },
  { 
    label:"Familiarity",
    description:"Established and understood by industry professionals.",
  },
  { 
    label:"Lower Initial Costs",
    description:"Generally associated with lower upfront costs.",
  }
  
 ],
 title62:"Challenges and Impacts",
 list62:[
  { 
    label:"Elevated Energy Consumption",
    description:"Outdated systems and inadequate insulation contribute to higher energy consumption.",
  },
  { 
    label:"Environmental Impact",
    description:"Conventional buildings contribute significantly to greenhouse gas emissions, resource depletion, and waste generation.",
  },
  { 
    label:"Lack of Strength",
    description:" These structures often lack durability and fail to withstand natural disasters.",
  }
  
 ],

 title7:"What is the difference between Green building and Conventional building?",
 description7:"The distinction between Green buildings and Conventional buildings lies primarily in their design, construction, and environmental impact. Green buildings emphasize sustainability, energy efficiency, and environmental responsibility, while conventional buildings follow traditional construction practices with less emphasis on these factors.",

 title8:"Unlocking Energy-Efficient Buildings",
 description8:"Buildings account for a substantial amount of energy-related Co2 emissions. According to the International Energy Agency (IEA), the operations of buildings account for 30% of global final energy consumption and 26% of global energy-related emissions. Thus it is important to design and invest in buildings that use energy proficiently. Energy-efficient buildings are designed to minimize energy consumption while maintaining comfort and functionality, benefiting both the occupants as well as the investor.",

 title9:"Advantages of Energy-Efficient Buildings",
 list9:[
  {
    label:"Reduced Energy Consumption",
    description:" Lower energy usage for heating, cooling, and lighting, resulting in reduced utility bills."
  },
  {
    label:"Lower Environmental Impact",
    description:"Contributes to reduced greenhouse gas emissions and overall environmental footprint."
  },
  {
    label:"Cost Savings",
    description:"Despite higher initial costs, long-term energy savings provide a positive return on investment."
  },
  {
    label:"Improved Indoor Comfort",
    description:"Prioritizes thermal comfort, lighting, and indoor air quality."
  },
  {
    label:"Regulatory Compliance and Incentives",
    description:"Many regions offer incentives for energy-efficient construction, promoting sustainable practices."
  },
 ],

 title10:"Challenges and Considerations:",
 list10:[
  {
    label:"Higher Initial Costs",
    description:"Incorporating advanced technologies and materials can lead to higher upfront expenses."
  },
  {
    label:"Technical Complexity",
    description:"Specialized expertise might be required for implementing energy-efficient systems."
  },
  {
    label:"Design Limitations",
    description:"Some strategies could impose constraints on aesthetics or architectural freedom."
  }
 ],

 title11:"Zero Energy Buildings: Striving for Balance",
 list11:[
  {
    label:"Net Zero Energy Buildings (NZEBs)",
    list:[
      "The concept of net zero energy buildings (NZEBs) is a relatively recent development; nonetheless, it is gaining popularity because of the immense benefits to our environment and sustainability.",
      "Several countries are swiftly adopting NZEB principles and considering making them mandatory for new constructions within a few years. In 2021, the worldwide market size for zero-energy buildings reached $71.7 billion. Projections indicate that by 2031, this figure will surge to $403 billion, demonstrating a robust Compound Annual Growth Rate (CAGR) of 18.7%. ",
      "This signifies the swift transition from an innovative concept to an imminent standard in the construction industry. NZEBs aim to generate as much energy as they consume, often utilizing renewable sources to offset grid consumption."
    ]
  },
 ],

 title111:"Features and Benefits of NZEBs",
 list111:[
  {
    label:"Energy Equilibrium",
    description:"Generates on-site energy through renewable sources, offsetting consumption",
  },
  {
    label:"Environmental Impact",
    description:"Minimizes carbon emissions and resource consumption.",
  },
  {
    label:"Reduced Energy Bills",
    description:"Despite initial costs, long-term energy savings lead to reduced utility bills.",
  },
  {
    label:"Improved Comfort",
    description:"Prioritizes occupant comfort.",
  }
 ],

 title112:"Net Zero Energy Building In India",
 description112:"The Indira Paryavaran Bhavan in New Delhi, which was built in 2014, holds the distinction of being India's first Net Zero Energy Building (NZEB). It was designed with smart energy-saving techniques and a powerful solar PV system of 930 kW capacity. This solar system generates around 1,491,000 units of electricity annually. Interestingly, the building's total energy requirement is approximately 1,421,000 units per year.",

 title113:"Challenges and Feasibility",
 description1131:"Despite the potential benefits of utilizing solar power and tackling environmental concerns, only seven Indian states, including Delhi, have successfully constructed NZEBs so far.",
 description1132:"The major challenge in setting up a Net zero energy building is a high initial investment and technical complexity. It is important to note Implementing and maintaining such systems can be intricate.",

 title12:"What is the difference between Green buildings and Zero-energy buildings?",
 description121:"Green buildings aim to be environmentally friendly through energy-efficient technologies and sustainable materials, whereas zero-energy buildings (ZEBs) go a step further.",
 description122:"ZEBs strive to produce as much energy from renewable sources as they use over a period, like a year. This means they try to balance out their energy consumption by generating their own energy on-site, making them super energy-efficient.",
 description123:"So in simple terms, green buildings deploy sustainable methods in constructing a building, but a zero-energy building generates its own energy and uses sustainable materials for construction.",

 title13:"A Glimpse into Modern Spaaces' Green Initiatives",
 description13:(
  <><a href="/">Modern Spaaces </a>{`is dedicated to sustainability through various initiatives incorporated into all our projects. Our commitment to reducing our carbon footprint is evident in features such as intelligent water meters, sewage treatment, and rainwater harvesting. It is noteworthy that every project we undertake is RERA certified, ensuring transparency, compliance, and reliability in our endeavors.`}</>
 ),

 title14:"Conclusion:   Balancing Innovation and Tradition",
 description141:"The path to a sustainable future is evident. With every brick laid, we're shaping a tomorrow that respects our planet's resources. As a society, we should realize the importance of shrinking our carbon footprint and embracing sustainable practices.",
 description142:"By embracing these innovative building practices, we're not just creating buildings; we're fostering a world where humans and the environment thrive in a mutually beneficial liaison.",
 description143:"The comparison between Green, Conventional, and Energy-Efficient Buildings paints a vivid picture of the various avenues at our disposal. From heightened environmental consciousness to traditional norms, each path offers a unique perspective. However, the journey towards a greener world is non-negotiable.",

 title15:"Building a Sustainable Tomorrow",
 description15:"In conclusion, the building that we build today is a reflection of our tomorrow. With every decision and every blueprint, we're molding a world that respects our environment and supports our sustainable and green practices. Through conscious choices today, we're paving the way for a harmonious tomorrow – a tomorrow where humans and nature coexist in perfect equilibrium.",
  },
];

export default data;
