import React, { useState } from "react";
import { Row, Container, Tab, Tabs } from "react-bootstrap";
import { Heading } from "../../Global/Typography";
// import Gallery1 from "../../../images/SoulTree/Gallery/Gallery1.webp";
// import GalleryImage1 from "../../../images/SoulTree/Gallery/GalleryImage1.png";
// import GalleryImage2 from "../../../images/SoulTree/Gallery/GalleryImage2.png";
import "./GalleryTabs.scss";

import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import ModalVideo from "react-modal-video";
import VideoIconImage from "../../../images/SoulTree/VideoIconImage.svg";
import GSlider from "./GSlider";

import Blocks77EastPahse1Image from "../../../images/Project77/77-East-phase-02.webp";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

export default function GalleryTabs({ inView }) {
  const [isOpen, setOpen] = useState(false);
  const GalleryTabsItems = [
    {
      title: "Gallery",
      image: <GSlider />,
      // lists: [Gallery1, GalleryImage2, GalleryImage1],
    },
    {
      title: "Walkthrough",
      video: "hClGN2CwSnA",
      placholder: Blocks77EastPahse1Image,
    },
    // { title: "Image", image: GalleryImage2 },
  ];

  return (
    <section
      className={`section  ${inView ? "inView" : "outView"}
  `}
    >
      <Container
        //  fluid
        className="GalleryTabs-Container"
      >
        <Row>
          <Tabs
            defaultActiveKey="Gallery"
            className="GalleryTabs GalleryTabs--black container"
          >
            {GalleryTabsItems.map((item, idx) => {
              return (
                <Tab
                  key={idx}
                  eventKey={item.title}
                  title={
                    <Heading
                      classList="-title"
                      variant="h1"
                      text={item.title}
                    />
                  }
                  className="event-tab"
                >
                  {item.lists ? (
                    <Swiper
                      className="GallerySlider"
                      id="GallerySlider"
                      tag="section"
                      wrapperTag="ul"
                      spaceBetween={10}
                      loop={false}
                      speed={3400}
                      autoplay={false}
                      // centeredSlides={true}
                      keyboard={{
                        enabled: true,
                      }}
                      navigation={{
                        prevEl: ".ArrowButtonBlock__ArrowPrevGallery",
                        nextEl: ".ArrowButtonBlock__ArrowNextGallery",
                      }}
                      onSwiper={(swiper) => console.log(swiper)}
                      // onSlideChange={(swiper) =>
                      //   console.log(
                      //     "slide index changed to: ",
                      //     swiper.activeIndex
                      //   )
                      // }
                      onInit={(swiper) =>
                        console.log("Swiper initialized", swiper)
                      }
                      breakpoints={{
                        300: {
                          slidesPerView: 1,
                          spaceBetween: 30,
                        },
                        450: {
                          slidesPerView: 2,
                          spaceBetween: 10,
                        },

                        768: {
                          slidesPerView: 2,
                          spaceBetween: 10,
                        },
                        1024: {
                          slidesPerView: 2,
                          spaceBetween: 5,
                        },
                        1300: {
                          slidesPerView: 1.3,
                          spaceBetween: 5,
                        },
                      }}
                    >
                      {item.lists.map((item, index) => (
                        <SwiperSlide tag="li" key={index}>
                          <img
                            src={item}
                            alt="Gallery"
                            className="GalleryImageSlide img-responsive w-100"
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  ) : null}

                  {item.image}
                  {/* {item.image ? (
                    <img
                      src={item.image}
                      alt="Gallery"
                      className="GalleryImage img-responsive w-100"
                    />
                  ) : null} */}

                  {item.video ? (
                    <div className="TheHeartAndSoul__image-block ele">
                      <ModalVideo
                        channel="youtube"
                        autoPlay
                        isOpen={isOpen}
                        // videoId="L61p2uyiMSo"
                        videoId={item.video}
                        onClose={() => setOpen(false)}
                      />
                      <img
                        src={item.placholder}
                        alt="Gallery"
                        className="GalleryImageSlide img-responsive w-100"
                      />
                      <div
                        className="videoIconImage videoIconImage--lg"
                        onClick={() => setOpen(true)}
                      >
                        <img
                          src={VideoIconImage}
                          className="TheHeartAndSoul__videoIcon"
                          alt="TheHeartAndSoul"
                        />
                      </div>
                    </div>
                  ) : null}
                </Tab>
              );
            })}
          </Tabs>
        </Row>
      </Container>
    </section>
  );
}
