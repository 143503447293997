import { Container } from "react-bootstrap";
import TataBannerImage from "../images/TataBanner.jpeg";

export default function TataBanner() {
  return (
    <section className="">
      <Container className=" ">
        <img
          className="TataBannerImage"
          src={TataBannerImage}
          alt="FeaturedBlogBanner"
        />
      </Container>
    </section>
  );
}
