import { Col, Container, Row } from "react-bootstrap";
import DownloadBrochure from "../../Global/DownloadBrochure";
import { AnchorButton, Desc, Heading } from "../../Global/Typography";
import "./ProjectAtGlance.scss";
import { useState } from "react";
export default function ProjectAtGlance({ inView }) {
  const [show, setShow] = useState(false);
  const handleShow = (e) => {
    e.preventDefault();
    setShow(true);
  };
  const handleClose = () => setShow(false);

  const dataset = {
    subject: "Project302 Brochure",
    fileName: "Urbanest-302-West-Brochure.pdf",
    filePath: "./documents/Urbanest-302-West-Brochure.pdf",
  };
  // ProjectAtGlanceListitems
  const ProjectAtGlanceListitems = [
    {
      info: "40.8 Lacs",
      desc: "Onwards",
    },
    {
      info: "2 & 3 BHK",
      desc: "1021-1469 sq.ft. & 122 Apartment units",
    },
    {
      info: "50+ Amenities",
      desc: "Indoor & Outdoor",
    },
    {
      info: "Home Loan",
      desc: "5 Preapproved Banks",
    },
    {
      info: "RERA Approved",
      desc: "RERA number available for all blocks",
    },
    {
      info: "Sompura Gate",
      desc: "Sarjapur Road",
    },
    {
      info: "Legal Compliant",
      desc: "BMRDA Approved, CC Received & OC Compliant",
    },
    {
      info: "Possession by",
      desc: "Dec 2022",
    },
  ];
  return (
    <section className={`section ${inView ? "inView" : "outView"}`}>
      <Container className="text-left">
        <Row>
          <Col xs={12} lg={10} className="">
            <Heading
              // classList="my-4 my-md-4 pr-5 pr-md-3"
              classList="my-4 my-md-5 pb-md-4 pr-5 pr-md-3 "
              variant="h1"
              text="Project at glance"
            />
            {/* <Desc
              classList="mb-3 pb-3 mb-md-5 pb-md-5"
              text="Enjoy the comfort and calm atmosphere of Modern living apartments. Fully Automated homes with an ultimate sense of convenience, safety and luxury. Homes with more space and light, fewer unnecessary details, more functional & nearly ideal minimalism."
            /> */}
          </Col>
        </Row>
        <Row>
          {ProjectAtGlanceListitems.map(({ info, desc }, idx) => {
            return (
              <Col
                className="mb-4 pb-4 mb-md-5 pb-md-5 ProjectAtGlanceList"
                xs={12}
                md={6}
                lg={4}
                key={idx}
              >
                <Heading
                  classList="ProjectAtGlance__info"
                  variant="h4"
                  text={info}
                />
                <Desc classList="ProjectAtGlance__desc mb-0" text={desc} />
              </Col>
            );
          })}
        </Row>
        <Row>
          <Col>
            <AnchorButton
              href="#"
              classList="-btn mt-3 text-orange"
              text="Download Brochure"
              onClick={handleShow}
            />
          </Col>
        </Row>
      </Container>

      {/* DownloadBrochure */}
      <DownloadBrochure
        title={"Download Brochure"}
        show={show}
        handleClose={handleClose}
        content={dataset}
      />
    </section>
  );
}
