import "./OtherBlogs.scss";
// import img1 from "https://modernspaaces.com/BlogListImages/BlogOne/Banner.webp";
// import img2 from "https://modernspaaces.com/BlogListImages/BlogTwo/Banner.webp";
// import img3 from "https://modernspaaces.com/BlogListImages/BlogThree/Banner.webp";

// import ComingSoon from "../../components/Global/ComingSoon";
// import { useState } from "react";
import { CardDeck } from "react-bootstrap";
import { Carousel } from "react-responsive-carousel";
import BlogCard from "../../BlogCard";

export default function OtherBlogs({ inView, blogs }) {
    // const [show, setShow] = useState(false);

    // const handleShow = (e) => {
    //   e.preventDefault();
    //   setShow(true);
    // };
    // const handleClose = () => setShow(false);

    return (
        <section
            className={`section section-other-blogs pt-0 ${
                inView ? "inView" : "outView"
            }
    `}
        >
            <div className="container content h-100 ">
                <h2 className="sub-heading text-left m-auto">Other Blogs</h2>

                <CardDeck className="blogs noScroll pb-2 d-none d-sm-flex mx-0">
                    {blogs.map((item, idx) => {
                        return <BlogCard blogData={item} />;
                    })}
                </CardDeck>
            </div>
            <Carousel
                className="d-sm-none margin"
                swipeable
                showArrows={false}
                showStatus={false}
                interval={1e11}
            >
                {blogs.map((item, idx) => {
                    return <BlogCard blogData={item} />;
                })}
            </Carousel>

            {/* ComingSoon */}
            {/* <ComingSoon show={show} handleClose={handleClose} /> */}
        </section>
    );
}
