import { Col, Container, Row } from "react-bootstrap";
import { Desc, DescLabel } from "../../../Global/Typography";
import BannerNew from "../../BannerNew";
import { Helmet } from "react-helmet-async";
import "../../BlogA&Bkhata/Banner/BlogKhata.scss";
import { Accordion, Card, Button } from "react-bootstrap";

import BlogFourData from "../../../../db/A&BKhata";
import ShareButton from "../../Share/ShareButton";

export default function Banner({ inView }) {
  const currentPage =
    "Learn about A Khata and B Khata properties in Bangalore, their impact on real estate, the process of Khata transfer, and how property tax is calculated. Get insights into the legal aspects of property ownership in the city.";
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>
          Difference between A khata and B khata and its impact on Property | Modern Spaaces
        </title>
        <meta
          name="Keywords"
          content="What is khata, what is A khata, What is B khata,What is A khata and B Khata, Difference between A Khata and B Khata, Khata transfer, BBMP katha"
        />
        <link
          rel="canonical"
          href="https://modernspaaces.com/blogs/defference-between-a-khata-b-khata-and-its-impact-on-property"
        />
      </Helmet>
    <section className={`section  ${inView ? "inView" : "outView"}`}>

      <BannerNew
        image={BlogFourData[0].image}
        title={BlogFourData[0].title}
        publishedOn={BlogFourData[0].publishedOn}
        time={BlogFourData[0].time}
      />

      <Container className="blog-detail-container">
        {BlogFourData.map((item, idx) => {
          return (
            <Row key={idx} className="justify-content-around">

              <Col xs={12} md={11} lg={9} className="pb-4 pr-md-5">
                <div className="blog-header margin">

                  {item.title11 && (
                    <p className="-descBlack font-benton-book fw-bold  margin">
                      {item.title11}
                    </p>
                  )}
                  {item.description11 && (
                    <Desc
                      classList="font-benton-book header"
                      text={item.description11}
                    />
                  )}
                  {item.description12 && (
                    <Desc
                      classList="font-benton-book header"
                      text={item.description12}
                    />
                  )}
                  {item.description13 && (
                    <Desc
                      classList="font-benton-book header"
                      text={item.description13}
                    />
                  )}
                </div>

                {item.typesofkhata &&
                  item.typesofkhata.map((i0, idx) => {
                    return (
                      <div key={idx}>
                        <DescLabel
                          classList="my-1 py-2 font-benton-book header"
                          text={i0.description}
                          label={i0.label}
                        />
                        {i0.list && (
                          <ul className="blog-list-circle header">
                            {i0.list.map((i1, idx1) => {
                              return (
                                <li key={idx1}>
                                  <p className="-descBlack mb-1 font-benton-book header">
                                    {i1}
                                  </p>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </div>
                    );
                  })}
                {item.conclusion1 && (
                  <Desc
                    classList="font-benton-book header"
                    text={item.conclusion1}
                  />
                )}
                <div>
                  {item.title2 && (
                    <p className="-descBlack font-benton-book fw-bold  margin">
                      {item.title2}
                    </p>
                  )}
                  <div className="imagepadding">
                    <img src={item.image2} alt="" className="image" />
                  </div>
                </div>

                <div className="blog-content ">
                  {item.title3 && (
                    <p className="-descBlack font-benton-book fw-bold  margin">
                      {item.title3}
                    </p>
                  )}

                  {item.title3list &&
                    item.title3list.map((i0, idx) => {
                      return (
                        <div key={idx}>
                          <DescLabel
                            classList="my-1 py-2 font-benton-book header"
                            text={i0.description}
                            label={i0.label}
                          />
                          {i0.list && (
                            <ul className="blog-list-circle header">
                              {i0.list.map((i1, idx1) => {
                                return (
                                  <li key={idx1}>
                                    <p className="-descBlack mb-1 font-benton-book header">
                                      {i1}
                                    </p>
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                        </div>
                      );
                    })}
                </div>

                <div className="blog-content ">
                  {item.title4 && (
                    <p className="-descBlack font-benton-book fw-bold margin">
                      {item.title4}
                    </p>
                  )}
                  {item.description41 && (
                    <p className="-descBlack font-benton-book header">
                      {item.description41}
                    </p>
                  )}
                  {item.description42 && (
                    <p className="-descBlack font-benton-book header">
                      {item.description42}
                    </p>
                  )}
                  {item.description43 && (
                    <p className="-descBlack font-benton-book header">
                      {item.description43}
                    </p>
                  )}
                  {item.description44 && (
                    <p className="-descBlack font-benton-book header">
                      {item.description44}
                    </p>
                  )}
                </div>

                <div className="blog-content ">
                  {item.title5 && (
                    <p className="-descBlack font-benton-book fw-bold margin">
                      {item.title5}
                    </p>
                  )}
                  {item.Conclusion && (
                    <p className="-descBlack font-benton-book header">
                      {item.Conclusion}
                    </p>
                  )}
                </div>

                <section className="shareLinkBlock -only-mobile ">
                  <ShareButton shareUrl="https://modernspaaces.com/blogs/defference-between-a-khata-b-khata-and-its-impact-on-property" />

                </section>
              </Col>
              <Col xs={12} md={4} lg={4} className="my-0 py-0 -aside-block">
                {/* <Fade>
                  <div className="-blog-author">
                    <p className="--label">Written By</p>
                    <img
                      src={item.authorImage}
                      alt={item.authorName}
                      className="-authorImage"
                    />

                    <h2 className="my-3 -authorName">{item.authorName}</h2>
                    <h3 className="my-1 -department">{item.department}</h3>

                    <AnchorGradientLinkAhref
                      href={`tel:${item.contactNumber}`}
                      classList="-btn-link mt-4 d-block"
                      text="Ask expert"
                      // text={"Read More"}
                      target="_blank"
                    />
                  </div>
                </Fade> */}
              </Col>
            </Row>
          );
        })}
        <Row className="simpleShadow my-3 py-3 justify-content-around">
          <Col xs={12} md={11} lg={9} className="pb-4 pr-md-5 p-2">
            <div
              className="shareLinkBlock1 d-flex flex-row p-4 "
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.04)",
                gap: "1rem",
                borderRadius: "1rem",
              }}
            >
              <ShareButton shareUrl="https://modernspaaces.com/blogs/defference-between-a-khata-b-khata-and-its-impact-on-property" />
            </div>
          </Col>
        </Row>
        <Row className="simpleShadow my-3 py-3 justify-content-around">
          <Col xs={12} md={11} lg={9} className="pb-4 pr-md-5 p-2">
            {BlogFourData.map((item, idx) => (
              <div key={idx}>
                {item.Faq && (
                  <p className="-descBlack font-benton-book fw-bold Margin header2">
                    {item.Faq}
                  </p>
                )}
                <Row>
                  <div
                    className="blog-content px-3"
                    style={{ width: "-webkit-fill-available" }}
                  >
                    {item.Faqs && (
                      <Accordion defaultActiveKey="0">
                        {item.Faqs.map((faq, faqIdx) => (
                          <Card
                            key={faqIdx}
                            className="my-2 p-2 "
                            style={{
                              width: "fit",
                              border: "none",
                              backgroundColor: "rgba(0, 0, 0, 0.02)",
                              borderRadius: "0.5rem",
                            }}
                          >
                            <Accordion.Toggle
                              as={Card.Header}
                              eventKey={`${faqIdx}`}
                              className="font-benton-book faq"
                              style={{
                                border: "none",
                                background: "none",

                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  cursor: "pointer",
                                  fontWeight: "bolder",
                                  borderBottom: "none",
                                }}
                              >
                                {faq.label}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="21"
                                  viewBox="0 0 20 21"
                                  fill="none"
                                >
                                  <path
                                    d="M16.5984 7.96094L11.1651 13.3943C10.5234 14.0359 9.47344 14.0359 8.83177 13.3943L3.39844 7.96094"
                                    stroke="black"
                                    strokeOpacity="0.32"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={`${faqIdx}`}>
                              <Card.Body className="font-benton-book header p-3">
                                {faq.description[0]}
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        ))}
                      </Accordion>
                    )}
                  </div>
                </Row>
              </div>
            ))}
          </Col>
        </Row>
      </Container>
    </section>
    </>
  );
}
