import ImgB2 from "../images/Blogs/ImgB2.png";
import img2 from "../images/Blogs/blog-img-2.webp";

const data = [
    {
        id: "abc102",
        slug: "things-to-consider-buying-your-first-home",
        image: img2,
        label: "Real estate",
        time: "2 min read",
        desc: "After years of hard work and saving up, you have finally decided to buy your first house, your dream home. Buying the first home is always special…",
        title: "Everything you need to know before buying your first home",
        publishedOn: "December, 2021",
        description:
            "After years of hard work and saving up, you have finally decided to buy your first house – your dream home. Buying the first home is always special, and quite understandably, a huge deal on multiple levels.  The whole process could be intimidating, given that this would be the biggest financial investment of your life. Before you jump into the sea of uncertainties that the entire home buying process is, let our guide help you kick-start your process.",
        authorImage:
            "https://images.unsplash.com/photo-1600188769099-d25b4ec79659?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
        authorName: "Noveen Reddyy",
        department: "Marketing HD",
        contactNumber: "9876543210",
        // imageOther: "https://modernspaaces.com/BlogListImages/BlogTwo/Img01.webp",
        lightDescription: "",
        lightDescriptionList: [
            {
                label: "Calculate your monthly expenditure",
                list: [
                    "Before you begin with anything else, sit down and list all the major expenses you have each month. It's wise to understand all the potential expenses you'll be looking at when you actually buy the house. Buying a home isn't just about the mortgage and price, it's a lot more – utility bills, property taxes, insurance premiums, and other recurring expenses, which will significantly vary depending on the kind of house, so ensure that you have all your numbers right. It's crucial to understand your budget.",
                    "Going for a high-taxed home will require a continual commitment, and it might be stressful to manage the finances later on. Speaking of finances, another intelligent move to save up on money would be to look for houses with smart features with energy-efficient amenities which are solar-equipped. That way, you're going to end up saving a lot!",
                ],
            },
            {
                label: "Connectivity, commute time, and transportation",
                list: [
                    "One of the most crucial factors to consider while buying your home is ensuring that the commute time for you and your family's daily routine is minimal. Buying a home isn't just an investment – it's a lifestyle upgrade that you're getting. One of the aspects of upgrading lifestyle is not spending most of your day being stuck in traffic or traveling long distances. You also want this for your family members. While looking for houses, test-drive all the essential routes you will be taking daily and note down the time and distance. Make sure to do that at appropriate hours to get an idea about the traffic during those hours. In case you have odd working hours, make sure there's transportation available.",
                    "Connectivity to major hubspots of the city is also essential. Ensure your house is well-connected to good supermarkets, shopping malls, movie theaters, restaurants and bars, and other essential lifestyle places.",
                ],
            },
            {
                label: "Research about the local schools and community life",
                list: [
                    "For homeowners with kids or those who plan to have kids, having good schools nearby your home is an essential consideration to factor in. Make sure you have options of well-rated schools around the home you buy. Apart from schools, the next factor that significantly impacts social life is the community living near the house. In times of uncertainties that the global pandemic has imposed upon us, it's essential to find your tribe to have a meaningful community life. The kind of people you live with will shape your future social life. At this point, it also checks the noise levels of the surrounding areas. You definitely want to make some 'listening visits' late at night or early in the morning to check for any noises like trains or trucks, as that's when their sounds go the farthest.",
                ],
            },
            {
                label: "Amenities that suit your lifestyle",
                list: [
                    "As home buying is a lifestyle upgrade, list down the amenities that you are looking for. Next, check if they fit in your budget. While looking for amenities, don't get overwhelmed by the massive number of options. Pick the ones that you need. The most basic amenities every homebuyer should look for are power backup, security, lift, and 24*7 water supply. To know more about how to look for the right kind of amenities, read our blog here.",
                ],
            },
            {
                label: "Construction quality and resale value",
                list: [
                    "Lastly, regardless of whether you're buying the house to build a home there or just for an investment, it's vital to look at its construction quality and resale value. Properties with good construction quality ensure a good return of investment and a good resale value. A good resale value is guaranteed for houses that pack smart features, world-class amenities, ecological and community living, spots for socialization for people of all demographics, and good connectivity to important spots of the city.",
                ],
            },
        ],

        ClosingThoughts: "Closing thoughts",
        noteStart:
            "Buying a dream house is a noteworthy milestone in everyone's life. There's no need to make the process tedious by getting overwhelmed at everything that needs to be done. Get a plan of action in place, and then check off the tasks on it one thing at a time. Remember, every aspect, be it the basic or social infrastructure, locality, or quality of construction – ",
        link: "everything matters in picking the perfect dream home!",
    },
];

export default data;
