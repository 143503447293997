import ImgTopReasonsSarjapura from "../images/Blogs/TopReasonsSarjapura.jpeg";

const data = [
    {
        id: "abc104",
        slug: "why-sarjapura-best-area-to-buy-house",
        image: ImgTopReasonsSarjapura,
        label: "Real Estate",
        desc: "Bangalore being the center of India's high-tech industry, also rightfully known as the Silicon Valley of India, is home to about 9 million people…",
        title: "Top reasons why Sarjapur Road is the best area for buying a home",
        publishedOn: "December, 2023",
        time: "3 min read",
        description:
            "Bangalore being the center of India's high-tech industry, also rightfully known as the Silicon Valley of India, is home to about 9 million people. With the abundance of work opportunities, happening nightlife, culture, and lifestyle, Bangalore truly has become one of the best cities in India to live in. In fact, the government's Ease of Living (EOL) Index listed Bangalore as the top Indian city for living.",
        authorImage:
            "https://images.unsplash.com/photo-1600188769099-d25b4ec79659?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
        authorName: "Noveen Reddy",
        department: "Marketing Head",
        contactNumber: "987654321",
        // imageOther: "https://modernspaaces.com/BlogListImages/BlogFour/Img01.webp",
        lightDescription:
            "However, finding a home in Bangalore may seem like a daunting task, given all the traffic and traveling. With the gaining popularity, the city is swarming up with new real estate projects each day. While many of those options may seem viable, eventually, it comes down to two things – the area and the budget.",
        lightDescriptionList: [
            {
                label: "Bangalore's Sarjapur Road is one of the most upcoming residential areas thanks to many reasons, the top one being affordability. Let's find out the other reasons why Sarjapur Road has become a hot-favorite area in the real estate sector:",
            },
            {
                label: "Best connectivity to the IT belt of the city",
                list: [
                    "Being located in the South-Eastern part of the city, Sarjapur Road provides excellent connectivity to the entire IT belt of Bangalore – Marathahalli, Outer Ring Road, Whitefield, Koramangala, and Electronic City. Moreover, the transportation facilities to travel to these places from here are also excellent, so daily commuting becomes convenient. Peripheral Ring Road and the proposed metro railway extension add value to the real estate properties here. Wipro, the IT giant, has purchased a new 50-acres campus in Sarjapur for shifting their employees in the next two years. The entire area is booming with new housing projects, and hence the market value is also constantly increasing. For the people working in the IT industry (which makes for the most people in Bangalore!) Sarjapur Road is the perfect location to buy a home.",
                ],
            },
        ],
        paragraph: "Here are some of the most prominent hubs in Sarjapur Road:",
        list: [
            {
                label: "Metro connectivity",
                description:
                    "The forthcoming Bellandur station of the Namma Metro Phase 2A is just 3 km away from Harlur road",
            },
            {
                label: "Malls",
                description: "Forum mall and Total mall",
            },
            {
                label: "Hospitals",
                description:
                    "St.John's Medical College Hospital, Bmax Hospital, Narayana Health, and Columbia Asia Hospital",
            },
            {
                label: "Educational Institutions",
                description:
                    "Delhi Public School, National Institute of Fashion Technology, Oxford Institute, Orchids The International School, Greenwood High International School, and Indus International School",
            },
            {
                label: "IT/ITES companies",
                description: "IBM, Wipro, Infosys, and Accenture",
            },
            {
                label: "Banks",
                description:
                    "Axis Bank, Canara Bank, ICICI Bank, and HDFC Bank",
            },
            {
                label: "Theatres",
                description:
                    "PVR Cinemas, Theatre Dots, Thirumala Theatre, Sandhya Digital 4k Cinema, and more",
            },
        ],
        ClosingThoughts: "Excellent schools, hospitals, and entertainment hubs",
        noteStartItems: [
            "For people with kids or those who plan to have kids in the future, Sarjapur Road is a great option. There are many reputed schools - Greenwood High International School, Oakridge International School, Inventure Academy, Indus International School, and more. At Sarjapur, you can be ensured about providing your children with a world-class education from top-notch schools without having to worry about sending them to far-off schools.",
            "Health is just as essential as education, and as humans, we're always vulnerable to health emergencies. Living in Sarjapur would ensure that you'll be surrounded by top-notch hospitals and medical facilities, as mentioned in the list above.",
            "For cinema lovers, Sarjapur offers some excellent multiplex options to enjoy movies as listed earlier. And what goes great with movies? Good food! Sarjapur is a foodie's paradise as there are many options for restaurants of different food cuisines from all over the world. The area also packs some kickass entertainment centers and gaming arenas. For fitness enthusiasts, some of the best gyms in the city are located in this area.",
            "Over the years, Sarjapur Road has gained massive popularity in the real estate market. For people looking to buy their dream homes at an affordable budget, Sarjapur is the best option. Don't wait for years to save up to buy a house – the housing project here has some of the most top-notch, luxurious projects with world-class amenities. This area is just perfect for all kinds of homeowners – bachelors or families to lead a comfortable yet lavish lifestyle at a budget that won't burn your pocket!",
        ],
    },
];

export default data;
