import { Container, Row, Col } from "react-bootstrap";
// AnchorButtonIcon
import { Desc, Heading } from "../../Global/Typography";
// import ExcellentConnectivityImage from "../../../images/Urbanest/ExcellentConnectivity/ExcellentConnectivityImage.png";
import "./ExcellentConnectivity.scss";

const ExcellentConnectivityImage = "/images/Urbanest/ExcellentConnectivity/excellent-connectivity-sompura-gate-banner.png"

export default function ExcellentConnectivity({ inView }) {
  const ExcellentConnectivityData = [
    {
      title: "Why Sompura Gate, Sarjapur Road?",
      desc: "Nestled between Whitefield, Bellandur & Electronic City, Urbanest at Sompura Gate offers close proximity to major roads & hotspots like HSR, Marathahalli, Koramangala all while being conveniently tucked away from the concrete jungle of today’s Bangalore.",
      desc1:"The planned Metro rail extension, Outer Ring Road and the proposed eight-lane Peripheral Ring Road [PRR] which will reduce the travel time to the airport considerably are expected to boost the realty sector here.",
      // button: "View on map",
      image: ExcellentConnectivityImage,
    },
  ];

  return (
    <section
      className={`section section--paddingBottom0 ${
        inView ? "inView" : "outView"
      }
    `}
    >
      {ExcellentConnectivityData.map((item, idx) => {
        return (
          <Container key={idx} className="ExcellentConnectivity">
            <Row className="align-items-center ">
              <Col xs={12} md={6} lg={6} xl={5} className="pr-md-5">
                <div className="imagePatch">
                  <img
                    src={item.image}
                    className="ExcellentConnectivity__image w-100 mb-4 mb-md-5"
                    alt="Why Sompura Gate, Sarjapur"
                  />
                </div>
              </Col>
              <Col xs={12} md={6} lg={6} xl={7} className="px-md-5">
                <Heading
                  variant="h1"
                  classList="ExcellentConnectivity__title"
                  text={item.title}
                />
                <Desc
                  classList="ExcellentConnectivity__desc my-3 "
                  text={item.desc}
                />
                <Desc
                  classList="ExcellentConnectivity__desc my-3 "
                  text={item.desc1}
                />
                {/* <AnchorButtonIcon
                  // RECORD AUDIO BASED CHANGE
                  target="_blank"
                  href="https://www.google.com/maps/place/Modern+Spaaces/@12.8775205,77.7620395,17z/data=!3m1!4b1!4m5!3m4!1s0x3bae73049a057571:0x4c0462bc63b1246c!8m2!3d12.8775153!4d77.7642282"
                  // RECORD AUDIO BASED CHANGE
                  classList="ExcellentConnectivity__btn my-3 text-orange"
                  text={item.button}
                  icon={true}
                /> */}
              </Col>
            </Row>
          </Container>
        );
      })}
    </section>
  );
}
