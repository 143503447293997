// import MivanTechnology from "../images/Blogs/MivanTechnology.jpeg";
// import ModularFormwork from "../images/Blogs/ModularFormWork.png";
// import Mivancomparison from "../../src/images/Blogs/Mivancomparison.png";
// import MivanAdvancement from "../images/Blogs/Mivanadvancement.jpg";

const MivanTechnology = "/images/Blogs/mivan-technology-banner.jpeg";
const ModularFormwork = "/images/Blogs/modular-form-work-banner.png";
const Mivancomparison =
  "/images/Blogs/mivan-vs-traditional-construction-banner.png";
const MivanAdvancement =
  "/images/Blogs/mivan-technology-advancement-banner.jpg";

const data = {
  id: "abc101",
  slug: "/exploring-the-mivan-technology",
  image: MivanTechnology,
  imgAlt: "Mivan Technology",
  image2: ModularFormwork,
  img2Alt: "Modular Form Work Construction",
  image3: Mivancomparison,
  image3Alt: "Mivan vs Traditional Masonry Construction",
  image4: MivanAdvancement,
  image4Alt: "Advancement In Mivan Technology",
  title: "Revolutionizing Construction: Exploring the Mivan Technology",
  title1: "What is Mivan Technology? ",
  title2: "What is Modular Formwork?",
  title3: "Mivan construction Vs Traditional Masonry",
  Advantages: "Advantages Of Mivan Technology ",
  Advantages1: "Below listed are some of the key points :",
  Disadvantages: "Disadvantages Of Mivan Technology",
  Disadvantages1:
    "While Mivan technology offers several advantages, it also has some drawbacks and limitations that should be considered:",
  Advancement: "Advancement In Mivan Technology",
  conclusion: "Conclusion",
  publishedOn: "Jan, 2024",
  time: "5 min read",
  label: "Real Estate",
  description:
    "The construction industry witnessed an unprecedented surge in technological investment in 2021,  with funding reaching a record of $2.1 billion. This figure marked a 100% surge compared to the previous year.This surge emphasized the importance of cutting-edge technologies like Mivan and Prefabrication in revolutionizing the construction of various structures.",
  lightDescription: (
    <>
      {`The global community was amazed as a 30-story T30 hotel arose in Changsha, China, in just 15 days, prefabrication technique making it fit like a jig-saw puzzle. Similarly, a 10-story Instacon building emerged in Mohali, India, in a mere 48 hours. These instances highlight the significance of innovation in real estate and how their integration in everyday construction is imperative.`}
    </>
  ),
  lightDescription2: (
    <>
      {`One such innovation in construction that has gained considerable attention is Mivan Technology, a revolutionary approach that reinvents traditional construction methods making it highly efficient and sustainable in its approach.`}
    </>
  ),
  Mivandescription: [
    "Mivan technology is a departure from conventional techniques by introducing a streamlined, efficient, and precise methodology for constructing various types of buildings, high-rise apartments and mass housing projects.",
    "The fundamental component of Mivan formwork consists of an extruded aluminum rail section fused with an aluminum sheet. This combination results in a lightweight panel that offers an ideal balance between stiffness and weight. As a result, the formwork exhibits minimal deflection when subjected to the load of concrete.",
    <>
      <a href="/soulace">Soulace Villas by Modern Spaaces</a> are premium villas
      nestled within 26.5 acres of expansive land. These villas are meticulously
      constructed using state-of-the-art Mivan technology, showcasing a fusion
      of modern engineering and architectural brilliance. The implementation of
      Mivan technology ensures the construction process is not only precise and
      efficient but also notably faster.,
    </>,
  ],
  content: [
    {
      label: "What is Formwork?",
      list: [
        "Formwork is a temporary support structure used during construction to hold fresh concrete until it becomes strong enough to bear weight. Once the concrete has solidified,the formwork is taken down, giving it its desired shape. Formwork expenses typically constitute about 25% to 30% of the total construction cost, though this percentage can vary depending on project complexity.",
      ],
    },
    {
      label:
        "Various types of formwork are used to shape and support fresh concrete until it solidifies. These include",
      list: [
        "Timber formwork",
        "Fabric formwork",
        "Plastic formwork",
        "Aluminum formwork",
        "Steel formwork",
      ],
    },
    {
      label: "Timber formwork",
      description:
        "This is one of the traditional and most commonly used types of formwork. It involves using timber as the primary material and it effectively holds the concrete unit it sets.",
    },
    {
      label: "Fabric formwork",
      description:
        " A flexible and cost-effective technique involving fabric sheets to shape concrete. It is commonly utilized for creating curved or irregular structures.",
    },
    {
      label: "Plastic formwork",
      description:
        "Lightweight and easily manageable, plastic formwork systems are modular and adaptable to different structures. They are known for their reusability and simple maintenance.",
    },
    {
      label: "Aluminum formwork",
      description:
        " Aluminum formwork systems are renowned for their exceptional strength-to-weight ratio, allowing them to effectively withstand the immense pressure exerted by wet concrete while guaranteeing a seamless finish. This makes them exceptionally suitable for construction projects involving high-rise buildings and mass housing developments.",
    },
    {
      label: "Steel formwork",
      description:
        "Steel is a strong and durable option suitable for complex structures and situations where repeated use is expected.",
    },
  ],
  // desc: "Bangalore being the center of India's high-tech industry, also rightfully known as the Silicon Valley of India, is home to about 9 million people…",

  authorImage:
    "https://images.unsplash.com/photo-1600188769099-d25b4ec79659?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
  authorName: "Noveen Reddy",
  department: "Marketing Head",
  contactNumber: "987654321",
  // imageOther: "https://modernspaaces.com/BlogListImages/BlogFour/Img01.webp",
  lightDescriptionList: [
    {
      label:
        "Bangalore's Sarjapur Road is one of the most upcoming residential areas thanks to many reasons, the top one being affordability. Let's find out the other reasons why Sarjapur Road has become a hot-favorite area in the real estate sector:",
    },
    {
      label: "Best connectivity to the IT belt of the city",
      list: [
        "Being located in the South-Eastern part of the city, Sarjapur Road provides excellent connectivity to the entire IT belt of Bangalore – Marathahalli, Outer Ring Road, Whitefield, Koramangala, and Electronic City. Moreover, the transportation facilities to travel to these places from here are also excellent, so daily commuting becomes convenient. Peripheral Ring Road and the proposed metro railway extension add value to the real estate properties here. Wipro, the IT giant, has purchased a new 50-acres campus in Sarjapur for shifting their employees in the next two years. The entire area is booming with new housing projects, and hence the market value is also constantly increasing. For the people working in the IT industry (which makes for the most people in Bangalore!) Sarjapur Road is the perfect location to buy a home.",
      ],
    },
  ],
  ModularFrameworkdata:
    "Modular formwork represents a contemporary construction methodology employing standardized panels and interconnected components. This innovative approach aims to minimize on-site alterations and adjustments, resulting in a remarkable 50% acceleration of the construction process and a substantial 50% reduction in labor requirements.  ",
  ModularFramework: [
    {
      label: "Examples",
      list: [
        "Mivan or Aluminum formwork",
        "Steel formwork and Plastic formwork ",
      ],
    },
    {
      label: "Difference between Aluform and Mivan technology",
      list: [
        "'Aluform' refers to the aluminum forming process utilized in construction, much like 'Mivan' a company that pioneered this technology. Similar to how 'Xerox' is often used as a term for photocopying in India, both 'Aluform' and 'Mivan' are synonymous, representing the innovative construction method involving aluminum formwork. ",
      ],
    },
  ],

  title4: "Mivan Construction Technology",
  description41: (
    <>
      {`Mivan technology has been widely used to construct houses in the US and the gulf countries because`}{" "}
      <a href="/soulace"> Mivan technology</a>{" "}
      {`is fast, efficient and durable.,`}
    </>
  ),
  description42: "The key characteristics of Mivan construction are:",
  list4: [
    {
      label: "Load-bearing capability",
      description:
        "The load carrying capacity of MIVAN  is about 7 – 8 tonnes/Sq.M which is higher than traditional brick walls.",
    },
    {
      label: "Demolding time",
      description:
        " For vertical formwork after 12 hours or concrete is 2N/mm and horizontal formwork after 36 hours or concrete is 10N/mm.",
    },
    {
      label: "Longevity",
      description:
        " As the Mivan formwork is made up of structural grade aluminum they are extremely durable. A single unit can be used around 150-200 times depending on the use.",
    },
    {
      label: "Construction cycle duration",
      description:
        "  With the Mivan formwork system, the time needed to pour and solidify a floor is approximately 7 days thus accelerating the construction process.",
    },
  ],

  tableData: [
    {
      heading: "Time taken for construction",
      description1: (
        <p>
          The time taken for construction is higher because the set has to be
          done manually and the waiting period for curing is anywhere between 14
          to 28 days.
        </p>
      ),
      description2: (
        <p>
          In this system, the walls and floor are molded together in one
          continuous operation in a matter of a few hours and the structure can
          be used within 3 to 7 days.
        </p>
      ),
    },
    {
      heading: "Wastage",
      description1: <p>Higher.</p>,
      description2: <p>Lesser than RCC/Brick wall.</p>,
    },
    {
      heading: "External Finishes",
      description1: (
        <p>
          Post construction extra time is required to plaster the wall before it
          can be painted.
        </p>
      ),
      description2: (
        <ul>
          <li>Once the walls are assembled, it can be painted directly.</li>
        </ul>
      ),
    },
    {
      heading: "Maintenance",
      description1: (
        <p>
          High due to repairs in plaster of wall and ceiling. Repainting and
          leakages required due to plumbing installations.
        </p>
      ),
      description2: (
        <p>
          The walls and ceiling are of high quality concrete, so repairs for
          plastering and leakage are not required often.
        </p>
      ),
    },
  ],

  Difference: [
    {
      label:
        "In Mivan construction the formwork panels are well-made, ensuring consistent dimensions and a smooth concrete finish",
      list: [
        "The technology uses pre-engineered aluminum forms making each structure precise and accurate.",
        "Concrete is mixed under strict quality control and delivered to the site. ",
        "Before pouring the concrete, the forms are set up with windows, doors, and frames. ",
        "This makes construction faster and more efficient.",
        "The aluminum formwork is modular, making it easy to set up and remove.",
        "After the formwork is removed it does not require plaster, making the walls ready to be painted.",
      ],
    },
    {
      label:
        "Traditional masonry makes use of bricks, stones and concrete blocks to construct walls and structures. ",
      list: [
        "This method is time consuming and relies heavily on the skill of the mason.    ",
        "In regions with high seismic activity brick masonry is unsuitable. ",
        "A prerequisite step involves plastering the wall before it can be painted.",
        "Absorption of water by bricks can lead to white depositions on the bricks known as efflorescence. This can affect both appearance and durability of the bricks.",
      ],
    },
    {
      label: "Does Mivan construction have a longer lifespan?",
      list: [
        "Yes, Mivan construction is exceptionally durable and low maintenance. Buildings constructed using Mivan are resistant to wear and tear, leading to an extended lifespan in comparison to conventional buildings.",
      ],
    },
  ],

  AdvantagesData: [
    {
      label: "Lightning fast Construction",
      description:
        "Mivan construction surpasses traditional beam, column, and brick construction methods in terms of speed due to its utilization of prefabricated panels.",
    },
    {
      label: "Smooth Finish",
      description:
        "Mivan construction provides a smoother finish on structures.It also provides a smooth finish devoid of plasters that is quicker than traditional masonry.",
    },
    {
      label: "Efficiency and Precision",
      description:
        " Mivan construction follows a pre-planned technique, which helps streamline the construction process and improve efficiency. Also the result achieved is consistent and precise for multiple uses.",
    },
    {
      label: "Durability",
      description:
        " The panels for Mivan formwork are made of high grade aluminum thus making them stronger and resilient than load bearing walls. A single component of Mivan can be reused around 150 times making it sustainable as well.",
    },
    {
      label: "Reduced Labour Cost",
      description:
        " While the initial set-up of Mivan technology demands skilled laborers, the assembly and setup are straightforward,  thereby requiring a reduced workforce.",
    },
    {
      label: "Earthquake Resistant",
      description:
        " The construction through Mivan Technology exhibits heightened earthquake resistance, making it considerably safer in the face of seismic activity. This safeguard arises from the fact that Mivan-built structures take the form of a unified monolithic concrete entity functioning as a singular unit.",
    },
    {
      label: "Higher carpet area ",
      description:
        " Technology offers more carpet area because walls constructed through this method do not require an additional plaster.",
    },
    {
      label: "Reduced Pour Joints",
      description:
        " Pour joints are areas where newly poured concrete meets existing, hardened concrete. In conventional construction, these joints can become vulnerable points and sources of leaks. In the context of Mivan construction, the necessity for pour joints is notably diminished owing to the method's continuous casting approach and the incorporation of formwork panels that yield uniform and seamless surfaces.",
    },
  ],
  DisadvantagesData: [
    {
      label: "High Initial Investment",
      description:
        "The initial cost of setting up the aluminum formwork system and training workers in the Mivan construction method can be relatively high. This can pose a challenge for projects with limited budgets.",
    },
    {
      label: "Limited Flexibility",
      description:
        "Mivan formwork systems are typically designed for specific project requirements. Changes to the design or layout during construction can be complex and costly compared to traditional methods that allow for more flexibility.",
    },
    {
      label: "Minimal Energy efficiency",
      description:
        " Mivan construction relies solely on reinforced cement concrete. The entire structure is composed of concrete and steel and has poor thermal insulation properties. As a result, buildings constructed with Mivan technology exhibit minimal energy efficiency.",
    },
    {
      label: "Skilled Labor Requirement",
      description:
        " Mivan construction requires specialized skills and training for workers to properly assemble and dismantle the formwork system at the inception level. This can be a limitation in areas with a shortage of skilled labor.",
    },
    {
      label: "Maintenance and Repair",
      description:
        " While Mivan-built structures are generally durable, damage to the formwork system or the aluminum panels can require specialized repairs. Obtaining replacement parts or repairing damaged components might be challenging and time-consuming",
    },
  ],
  AdvancementData: [
    "Recent advancements in Mivan technology have brought about significant improvements. These include integrating 3D printing to create complex formwork components, and using Building Information Modeling (BIM) for precise digital planning before construction is initiated. Robotics has also been introduced to automate tasks like placing reinforcing bars and pouring concrete, reducing manual labor.",
    "Quality control has been enhanced through advanced sensors and monitoring systems, minimizing errors and ensuring better construction quality.",
    "Sustainability is becoming a priority in real estate thereby eco-friendly raw materials and practices are increasingly being reinforced through Mivan Technology.",
    "Overall, these advancements lead to faster construction, greater efficiency, improved sustainability, and reduced reliance on manual labor. However, before choosing Mivan technology, it's crucial to consider its advantages and disadvantages, as well as the specific needs of each construction project.",
  ],

  conclusiondata: [
    <>
      {`At Modern Spaaces, we strongly believe in employing the power of modern-age technology. We make sure each of our projects are`}
      <a href="https://rera.karnataka.gov.in/"> RERA </a>{" "}
      {` certified and our newest venture, Soulace, an elegant Villa project has been made using the innovative Mivan technology.`}
    </>,
    <>
      {
        "Mivan technology offers several advantages in terms of speed, efficiency, and quality, it's important to weigh these benefits against its disadvantages and consider the specific requirements of each construction project before deciding to implement this method."
      }
    </>,
  ],
};

export default data;
