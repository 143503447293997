import React from "react";
import "./ArchitecturalSlider.scss";
import ReadytoOccupy from "./ReadytoOccupy";
// import UpcomingProjects from "./UpcomingProjects";
import { Tab, Tabs, Container } from "react-bootstrap";
import { Heading } from "../../Global/Typography";

export default function Common({ inView }) {
  return (
    <section className={`mb-4 mb-md-5 ${inView ? "inView" : "outView"}`}>
      <Container>
        <Tabs
          defaultActiveKey="ReadytoOccupy"
          id="newProjectTabs"
          className="my-2 py-2 newProjectTabs"
        >
          <Tab
            eventKey="ReadytoOccupy"
            title={
              <Heading classList="-title" variant="h1" text="Ready to Occupy" />
            }
          >
            <ReadytoOccupy />
          </Tab>
          <Tab
            eventKey="UpcomingProjects"
            title={
              <Heading
                classList="-title"
                variant="h1"
                text="Upcoming Projects"
              />
            }
          >
            Upcoming Projects
          </Tab>
        </Tabs>
      </Container>
    </section>
  );
}
