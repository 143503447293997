import React, { Component } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

// import Gallery1 from "../../../images/SoulTree/Gallery/Latest/Gallery1.webp";
// import BannerImage from "../../../images/SoulTree/Banner.webp";

// import Gallery1 from "../../../images/SoulTree/Gallery/Latest/Gallery1.webp";
// import Gallery2 from "../../../images/SoulTree/Gallery/Latest/Gallery2.webp";
// import Gallery3 from "../../../images/SoulTree/Gallery/Latest/Gallery3.webp";
// import Gallery4 from "../../../images/SoulTree/Gallery/Latest/Gallery4.webp";
// import Gallery5 from "../../../images/SoulTree/Gallery/Latest/Gallery5.webp";
// import Gallery6 from "../../../images/SoulTree/Gallery/Latest/Gallery6.webp";
// import Gallery7 from "../../../images/SoulTree/Gallery/Latest/Gallery7.webp";

// import engrace1 from "../../../images/Engrace/gallery/engrace1.webp";
// import engrace2 from "../../../images/Engrace/gallery/engrace2.webp";

// import engrace7 from "../../../images/Engrace/gallery/engrace7.webp";

// import VideoIconImage from "../../../images/SoulTree/VideoIconImage.svg";

const coverImage = "/images/SereneHeights/architectural/low-density-min.jpg";

const images = [
    "/images/SereneHeights/architectural/low-density-min.jpg",
    "/images/SereneHeights/Gallery/serene-gallery-1.jpg",
    "/images/SereneHeights/architectural/green-legacy.jpg",
    "/images/SereneHeights/architectural/three-balconies.jpg",
    "/images/SereneHeights/Gallery/serene-gallery-2.jpg",
    "/images/SereneHeights/Banner/serene-heights-project-detail-page-banner.jpg",
    "/images/SereneHeights/clubconnect/swimming-pool.webp",
];

const imagesCaption = [
    "Luxury Villa with Premium Specifications",
    "Soulace Luxury Villas in Sarjapur",
    "Soulace Premium Villas with Private Elevators",
    "Soulace build using Aluform Technology",
];

export default class GSlider extends Component {
    constructor(props) {
        super(props);

        this.state = {
            photoIndex: 0,
            isOpen: false,
        };
    }

    render() {
        const { photoIndex, isOpen } = this.state;

        return (
            <div className="GSliderHoverEle">
                <img
                    // title="Click to View More Images"
                    className="LightHouseImage"
                    src={coverImage}
                    alt="LightHouseImage"
                    onClick={() => this.setState({ isOpen: true })}
                />

                <div
                    className="videoIconImage videoIconImage--text"
                    onClick={() => this.setState({ isOpen: true })}
                >
                    View
                </div>
                {/* <div className="divBl">Click to View More Images</div> */}
                {isOpen && (
                    <Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={
                            images[
                                (photoIndex + images.length - 1) % images.length
                            ]
                        }
                        // imageTitle={imagesCaption[photoIndex]}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex:
                                    (photoIndex + images.length - 1) %
                                    images.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % images.length,
                            })
                        }
                    />
                )}
            </div>
        );
    }
}
