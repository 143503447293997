const soulace = "/images/AllProjects/soulace-homepage-banner.webp";
const engrace = "/images/AllProjects/engrace-homepage-banner.webp";
const vista = "/images/AllProjects/EngraceVista.jpg";
const sereneHeights = "/images/AllProjects/serene-heights-homepage-banner.webp";
const onyx = "/images/AllProjects/onyx-homepage-banner.webp";
const walkTownResidences =
    "/images/AllProjects/walk-town-residences-coming-soon-banner.png";
const tranquilTerraces =
    "/images/AllProjects/tranquil-terraces-coming-soon-banner.png";
const ivyCounty = "/images/AllProjects/ivy_county-coming-soon-banner.png";
const forest = "/images/AllProjects/forest-of-ur-lyf-coming-soon-banner.png";
const stellersprings =
    "/images/AllProjects/stellar-springs-coming-soon-banner.png";
const soulTree = "/images/AllProjects/soul-tree-homepage-banner.webp";
const urbanest = "/images/AllProjects/urbanest-homepage-banner.webp";
const centralPark = "/images/AllProjects/centralPark.webp";
const greenStoreys = "/images/AllProjects/greenstoreys.webp";
const windmere = "/images/AllProjects/windmere.webp";

export const ongoingProjects = [
    {
        projectImage: soulace,
        imgAlt: "Soulace by Modern Spaaces",
        link: "soulace",
        projectName: "Soulace",
        projectDesc:
            "Premium Villas with a right balance between indoor & outdoor living",
        propertyUnits: 266,
        propertyStartingPrice: "3.3 Cr",
        propertySize: { size: "4 & 5 BHK", type: "Villas" },
        propertyLocation: {
            addressLine1: "Sompura Gate",
            addressLine2: "Sarjapur Road",
            city: "Bengaluru",
        },
    },
    {
        projectImage: sereneHeights,
        imgAlt: "Serene Heights by Modern Spaaces ",
        link: "serene-heights",
        projectName: "Serene Heights",
        projectDesc: "Spacious Apartments. Adjacent to the main road!",
        propertyUnits: 420,
        propertyStartingPrice: "1.6 Cr",
        propertySize: { size: "2.5 & 3 BHK", type: "Apartments" },
        propertyLocation: {
            addressLine1: "Sompura Gate",
            addressLine2: "Sarjapur Road",
            city: "Bengaluru",
        },
    },
    {
        projectImage: vista,
        imgAlt: "Engrace Vista by Modern Spaaces",
        link: "engrace-vista",
        projectName: "Engrace Vista",
        projectDesc: "Exclusive Spacious all 3 BHK community.",
        propertyUnits: 392,
        propertyStartingPrice: "1.55 Cr",
        propertySize: { size: "3 BHK", type: "Apartments" },
        propertyLocation: {
            addressLine1: "Sompura Gate",
            addressLine2: "Sarjapur Road",
            city: "Bengaluru",
        },
    },
];

export const upcomingProjects = [
    {
        projectImage: centralPark,
        imgAlt: "Central Park by Modern Spaaces ",
        link: "central-park",
        newLaunch: true,
        projectName: "Central park",
        projectDesc: "Engrace a Balanced, new perspective on urban living!",
        propertyUnits: 700,
        propertyStartingPrice: "1.7 Cr",
        propertySize: { size: "3 & 4 BHK", type: "Apartments" },
        propertyLocation: {
            addressLine1: "Kasavanahalli",
            addressLine2: "Off Sarjapur Road",
            city: "Bengaluru",
        },
    },
    {
        projectImage: onyx,
        imgAlt: "Onyx by Modern Spaaces ",
        link: "onyx",
        newLaunch: true,
        projectName: "Onyx",
        projectDesc: "Engrace a Balanced, new perspective on urban living!",
        propertyUnits: 624,
        propertyStartingPrice: "70 Lacs",
        propertySize: { size: "2.5 & 3 BHK", type: "Apartments" },
        propertyLocation: {
            addressLine1: "Mugalur",
            addressLine2: "Sarjapur Road",
            city: "Bengaluru",
        },
    },

    {
        projectImage: greenStoreys,
        imgAlt: "Green Storeys by Modern Spaaces ",
        link: "green-storeys",
        newLaunch: true,
        projectName: "Green Storeys",
        projectDesc: "Engrace a Balanced, new perspective on urban living!",
        propertyUnits: 132,
        propertyStartingPrice: "1 Cr",
        propertySize: { size: "2 & 3 BHK", type: "Apartments" },
        propertyLocation: {
            addressLine1: "Sompura gate",
            addressLine2: "Sarjapur Road",
            city: "Bengaluru",
        },
    },
    {
        projectImage: windmere,
        imgAlt: "Windmere by Modern Spaaces ",
        link: "windmere",
        newLaunch: true,
        projectName: "Windmere",
        projectDesc: "Engrace a Balanced, new perspective on urban living!",
        propertyUnits: 24,
        propertyStartingPrice: "2 Cr",
        propertySize: { size: "3 & 4 BHK", type: "Premium Apartments" },
        propertyLocation: {
            addressLine1: "Haralur Road",
            addressLine2: " Off Sarjapur Road",
            city: "Bengaluru",
        },
    },
    {
        projectImage: walkTownResidences,
        imgAlt: "Walk Town Residences Coming Soon ",
        projectName: "Walk Town Residences",
        projectDesc: "16 acres (Part of 60 Acres)",
        // propertyUnits: 962,
        // propertyStartingPrice: "3.2 Cr",
        propertySize: { size: "2 & 3 BHK", type: "G+30 - Apartments " },
        propertyLocation: {
            addressLine1: "Chambenahhalli",
            addressLine2: "Sarjapur Road",
            city: "Bengaluru",
        },
    },
    {
        projectImage: tranquilTerraces,
        imgAlt: "Tranquil Terraces Coming Soon",
        projectName: "Tranquil Terraces",
        projectDesc: "Engrace a Balanced, new perspective on urban living!",
        // propertyUnits: 750,
        // propertyStartingPrice: "80 Lacs",
        propertySize: { size: "2 & 3 BHK", type: "G+18 - Apartments" },
        propertyLocation: {
            addressLine1: "Dommasandra",
            addressLine2: "Sarjapur Road",
            city: "Bengaluru",
        },
    },
    {
        projectImage: ivyCounty,
        imgAlt: "Ivy County Coming Soon",
        projectName: "Ivy County",
        projectDesc: "Premium Luxe Villas - 37 Acres",
        // propertyUnits: 250,
        // propertyStartingPrice: "80 Lacs",
        propertySize: { size: "4 & 5 BHK ", type: "Villas" },
        propertyLocation: {
            addressLine1: "Gunjur",
            addressLine2: "Behind Greenwood High",
            city: "",
        },
    },
    {
        projectImage: forest,
        imgAlt: "Fo'Rest of ur lyf Coming Soon",
        projectName: "Fo’Rest of ur lyf",
        projectDesc: "55 acres  Plots",
        // propertyUnits: 750,
        // propertyStartingPrice: "80 Lacs",
        propertySize: { size: "Plotted development", type: "Society" },
        propertyLocation: {
            addressLine1: "Mugalur",
            addressLine2: "On NH 648",
            city: "Bengaluru",
        },
    },
    // {
    //     projectImage: tranquilTerraces,
    //     projectName: "Stellar Springs",
    //     projectDesc: "Engrace a Balanced, new perspective on urban living!",
    //     propertyUnits: 750,
    //     propertyStartingPrice: "80 Lacs",
    //     propertySize: { size: "2 & 3 BHK", type: "Apartments" },
    //     propertyLocation: {
    //         addressLine1: "Sompura gate",
    //         addressLine2: "Sarjapur Road",
    //         city: "Bengaluru",
    //     },
    // },
];

export const completedProjects = [
    {
        projectImage: engrace,
        imgAlt: "Engrace by Modern Spaaces ",
        link: "engrace",
        projectName: "Engrace",
        projectDesc: "Engrace a Balanced, new perspective on urban living!",
        propertyUnits: 705,
        // propertyStartingPrice: "80 Lacs",
        propertySize: { size: "2, 2.5 & 3 BHK", type: "Apartments" },
        propertyLocation: {
            addressLine1: "Sompura Gate",
            addressLine2: "Sarjapur Road",
            city: "Bengaluru",
        },
    },
    {
        projectImage: soulTree,
        imgAlt: "Soul Tree by Modern Spaaces",
        link: "soultree",
        projectName: "Soul Tree",
        projectDesc: "Come, live the good life in a panorama of greenery.",
        propertyUnits: 564,
        // propertyStartingPrice: "3.2 Cr",
        propertySize: { size: "1, 2 & 3 BHK", type: "Apartments" },
        propertyLocation: {
            addressLine1: "Sompura gate",
            addressLine2: "Sarjapur Road",
            city: "Bengaluru",
        },
    },
    {
        projectImage: urbanest,
        imgAlt: "Urbanest by Modern Spaaces",
        link: "urbanest",
        projectName: "Urbanest",
        projectDesc: "A delectable community that celebrates light and space.",
        propertyUnits: 330,
        // propertyStartingPrice: "23 Lacs",
        propertySize: { size: "1, 2 & 3 BHK", type: "Apartments" },
        propertyLocation: {
            addressLine1: "Sompura gate",
            addressLine2: "Sarjapur Road",
            city: "Bengaluru",
        },
    },
];
