import { Col, Container, Row } from "react-bootstrap";
import { Desc, Heading } from "../../Global/Typography";
import UrbanestLogo from "../../../images/Urbanest/UrbanestLogo.svg";
import "./Banner.scss";
import BreadcrumbCard from "../../Global/Breadcrumb";

export default function Banner({ inView }) {
  const currentPage = "302 West";
  return (
    <section
      className={`section ProjectBanner-bg ${inView ? "inView" : "outView"}`}
    >
      <Container className="ProjectBanner">
        <Row className="align-items-center">
          <Col xs={11} md={10} lg={8} className="">
            <BreadcrumbCard item={currentPage} />

            <Heading
              classList="my-4 pr-5 pr-md-3"
              variant="h1Lg"
              text={currentPage}
            />
            <img src={UrbanestLogo} alt="logo" className="UrbanestLogo mb-4 mb-md-5" />
            <Desc
              classList="pr-md-5 mr-md-5 text-black"
              text="Luxury 2 and 3 BHK apartments at Sompura Gate on Sarjapur Road. Each detail of the project reflects the modern spirit and contributes to forming a special & unique style. Homes that are aesthetically crafted to keep people connected to what means the most."
            />
          </Col>
        </Row>
      </Container>
      <div className="-bgShadow302"></div>
    </section>
  );
}
