import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { useState } from "react";

export default function DisclaimerDialog({ show, onClose, onCancel }) {
    const [showMore, setShowMore] = useState(false);

    if (!show) return null;

    return (
        <Modal show={show} className="disclaimer font-benton-book">
            <Modal.Header
                className="disclaimer-header"
                closeButton
                onHide={onCancel}
                {...{
                    ...(showMore && {
                        style: {
                            boxShadow: "0 10px 15px -3px rgba(0,0,0,0.1)",
                        },
                    }),
                }}
            >
                <div className="heading">
                    <div className="line-start rounded-full" />
                    <span className="disclaimer-heading font-benton-bold">
                        IMPORTANT NOTICE
                    </span>
                    <div className="line-end rounded-full" />
                </div>
            </Modal.Header>
            <Modal.Body className="disclaimer-body">
                <div className="content position-relative disclaimer-content">
                    <p>
                        This is to inform user / customer(s) that
                        <span className="font-benton-bold">
                            {" www.modernspaaces.com "}
                        </span>
                        is the only official website of Modern Spaaces.
                    </p>
                    <p>
                        Users and customers are advised not to rely on
                        information from any website that appears similar to the
                        Company's official websites, even if it contains the
                        company's name or logo. Information on such websites may
                        be misleading or false, and users may suffer loss if
                        they rely on it.
                    </p>
                    <p>
                        If you encounter any website similar to the Company's
                        official website, containing its brand name, logo, or
                        other information, please contact us at
                        info@modernspaaces.com so the Company can take necessary
                        action.
                    </p>
                    {!showMore && (
                        <span
                            className="font-benton-bold"
                            style={{
                                color: "#2974EA",
                                cursor: "pointer",
                                fontWeight: 400,
                            }}
                            onClick={() => setShowMore(true)}
                        >
                            Read More
                        </span>
                    )}
                    {showMore && (
                        <>
                            <div className="font-benton-bold">
                                Dealing with Real Estate Agents
                            </div>
                            <p>
                                Please ensure you only deal with “Real Estate
                                Agents registered” under RERA (Real Estate
                                Regulation and Development Act). You can verify
                                their registration on the RERA website by
                                checking if their name appears as a real estate
                                agent under the project name.
                            </p>
                            <p>
                                If you receive any unsolicited calls or text
                                messages that violate regulatory norms or come
                                from agents not registered with Modern Spaaces,
                                please report them to us at
                                info@modernspaaces.com for further
                                investigation.
                            </p>
                            <div className="font-benton-bold">
                                Partner Websites
                            </div>
                            <p>
                                While we strive for accuracy, Modern Spaces is
                                not responsible for any misleading information
                                found on partner websites. If you have accessed
                                our site through a third-party link, please
                                refer to our official website, modernspaces.com,
                                for the most accurate and up-to-date
                                information.
                            </p>
                            <div className="font-benton-bold">Agreement</div>
                            <p>
                                By continuing, you acknowledge and agree to the
                                terms of this disclaimer.
                            </p>
                        </>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer
                className="d-flex"
                style={{
                    width: "100%",

                    boxShadow: showMore
                        ? "0 -10px 15px -3px rgba(0,0,0,0.1)"
                        : "none",
                    // shadow-[0_-10px_15px_-3px_rgba(0,0,0,0.1)]
                }}
            >
                <div>
                    <div className="d-flex">
                        <Button
                            className="btn btn-primary mr-3 cancel-btn"
                            type="button"
                            onClick={onCancel}
                            // style={{fontSize: "14px"}}
                        >
                            <span className="btn-text">Cancel</span>
                        </Button>
                        <Button
                            className="btn btn-primary submit-btn"
                            type="button"
                            onClick={onClose}
                        >
                            <span className="btn-text">Okay, Got It</span>
                        </Button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
}
