import { Col, Container, Row } from "react-bootstrap";
// import { Col, Container, Row } from "react-bootstrap";
// import ReactTooltip from "react-tooltip";
import "./MasterPlan.scss";

// import Culture from "../../../images/SoulTree/Architectural/Culture.webp";
// import MoreThanJust from "../../../images/SoulTree/Architectural/MoreThanJust.webp";
// import Genrationoftoday from "../../../images/SoulTree/Architectural/Genrationoftoday.webp";

// import Nirvana from "../../../images/Master/Nirvana.webp";
// import Serenity from "../../../images/Master/Serenity.webp";
// import Bliss from "../../../images/Master/Bliss.webp";
// import Clubhouse from '../../../images/Master/Clubhouse.webp'

// import Top from "./SVG/Top";
// import Bottom from "./SVG/Bottom";
// import Right from "./SVG/Right";
// import Base1 from "../../../images/Master/Base1.svg";
// import SoulTreeMasterPlanNew from "/images/SoulTree/soul-tree-master-plan-banner.jpg";
// import { Heading } from "../../Global/Typography";
// import MasterPlanFooter from "./MasterPlanFooter";
import MasterPlanHeader from "./MasterPlanHeader";

const SoulTreeMasterPlanNew = "/images/SoulTree/soul-tree-master-plan-banner.jpg"

export default function MasterPlan({ inView }) {
  return (
    <section className={`section  ${inView ? "inView" : "outView"}`}>
      <Container className="text-center">
        <MasterPlanHeader />
        {/* <Row className="H100vh"> */}
        <Row className="my-3 py-5 py-md-2">
          <Col xs={12}>
            <img
              className="SoulTreeMasterPlanNew"
              src={SoulTreeMasterPlanNew}
              alt="Soul Tree Master Plan"
            />
          </Col>
        </Row>
        {/* <Row className="H100vh">
          <Col xs={12} lg={10} className="mx-auto pos-rel">
            <a
              data-tip
              // href="#"
              href="javascript;"
              onClick={(e) => e.preventDefault()}
              data-for="noOverridePositionTop"
              className="Top"
            >
              <Top />
            </a>

            <ReactTooltip id="noOverridePositionTop">
              <Heading variant="h4" text="Nirvana" />
              <img className="tooltip-fig" src={Nirvana} alt="Nirvana" />
            </ReactTooltip>

            <a
              data-tip
              // href="#"
              href="javascript;"
              onClick={(e) => e.preventDefault()}
              data-for="noOverridePositionBottom"
              className="Bottom"
            >
              <Bottom />
            </a>

            <ReactTooltip id="noOverridePositionBottom">
              <Heading variant="h4" text="Serenity" />
              <img className="tooltip-fig" src={Serenity} alt="Serenity" />
            </ReactTooltip>

            <a
              data-tip
              // href="#"
              href="javascript;"
              onClick={(e) => e.preventDefault()}
              data-for="noOverridePositionRight"
              className="Right"
            >
              <Right />
            </a>

            <ReactTooltip id="noOverridePositionRight">
              <Heading variant="h4" text="Bliss" />
              <img className="tooltip-fig" src={Bliss} alt="Bliss" />
            </ReactTooltip>
            <img className="Base1" src={Base1} alt="Base1" />
          </Col>
        </Row> */}
        {/* <MasterPlanFooter /> */}
      </Container>
    </section>
  );
}
