import { useState } from "react";
import GetInTouchForm from "../../components/Global/ContactUsModal/GetInTouchForm";
import horizontalSeparator from "../../images/Common/horizontal-separator.svg";
import "./hero.scss";
// import GetInTouchForm from "../../components/Global/GetInTouchForm";

// import { useState } from "react";

export default function Hero({ inView }) {
    // const [show, setShow] = useState(false);
    const [showContactUsModal, setShowContactUsModal] = useState(false);
    // const handleShow = (e) => {
    //   e.preventDefault();
    //   setShow(true);
    // };
    // const handleClose = () => setShow(false);

    return (
        <>
            <section
                className={`section position-relative section-hero mt-4 ${
                    inView ? "inView" : "outView"
                }`}
                style={{}}
            >
                <img
                    src="/images/Soulace/soulace-homepage-banner.webp"
                    style={{
                        pointerEvents: "none",
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        zIndex: "0",
                    }}
                    alt="Soulace by Modern Spaaces"
                />
                <div className="content-fluid">
                    {/* <div className="d-none d-md-flex justify-content-left mb-2 pb-4">
                    <Fade bottom>
                        <span className="separator-v"></span>
                        <a
                            href="tel:+91 9008 288 288"
                            className="list-item ml-4 fc-black text-black"
                        >
                            +91 9008 288 288
                        </a>
                        <a
                            href="mailto:info@modernspaaces.com"
                            className="list-item ml-4 fc-black text-black"
                        >
                            info@modernspaaces.com
                        </a>
                    </Fade>
                </div> */}
                    {/* <div className="d-none d-md-flex justify-content-between mb-4">
                    <Fade bottom>
                        <div>
                            <div className="feature-label">
                                Thoughtfully Designed
                            </div>
                            <span className="feature">
                                Homes crafted with care
                            </span>
                        </div>
                        <div>
                            <div className="feature-label">Best Built</div>
                            <span className="feature">
                                Quality Construction, built with precision and
                                attention to detail
                            </span>
                        </div>
                        <div>
                            <div className="feature-label">
                                Value Added Homes
                            </div>
                            <span className="feature">
                                Premium, budget-friendly homes
                            </span>
                        </div>
                    </Fade>
                </div> */}
                </div>
                <div className="hero-content content mb-4">
                    <div
                        className="position-absolute"
                        style={{ bottom: "6rem" }}
                    >
                        <div className="heading">
                            Rewriting the <br />
                            Story of Spaces.
                        </div>
                        <img
                            src={horizontalSeparator}
                            alt="separator"
                            className="h-separator"
                        />
                        <div className="contact-details d-flex align-items-center">
                            <span>+91 9008 288 288</span>
                            <span className="v-separator"></span>
                            <span>info@modernspaaces.com</span>
                        </div>
                        <button
                            className="app-btn--hollow--primary app-btn--small"
                            onClick={() => {
                                setShowContactUsModal(true);
                            }}
                        >
                            <span className="btn-text">Contact Us</span>
                        </button>
                    </div>

                    {/* <div className="fw-bold  content">We make homes happen.</div> */}
                </div>
            </section>
            <GetInTouchForm
                show={showContactUsModal}
                IsNotAPlan={true}
                handleClose={() => setShowContactUsModal(false)}
            />
        </>
    );
}
