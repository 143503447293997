import { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import Modal from "react-bootstrap/Modal";
import { useCookies } from "react-cookie";
import { Heading } from "./Typography";
// SoulTreeBrochure

// import West302 from "../../documents/Project302/West302.pdf";
import EngraceBrochure from "../../documents/Engrace/Engrace_Brochure.pdf";
import SoulaceBrochure from "../../documents/Soulace/Soulace_Brochure.pdf";
import VistaBrochure from "../../documents/EngraceVista/vista-brochure.pdf";
import SHBrochure from "../../documents/SereneHeights/Main_Brochure-low.pdf";

function php_email_form_submit(action, formData) {
    fetch(action, {
        method: "POST",
        body: formData,
        headers: { "X-Requested-With": "XMLHttpRequest" },
    })
        .then((response) => {
            if (response.ok) {
                return response.text();
            } else {
                throw new Error(
                    `${response.status} ${response.statusText} ${response.url}`
                );
            }
        })
        .then((data) => {
            if (data.trim() === '"OK"') {
                console.log("message sent");
                //thisForm.reset();
            } else {
                throw new Error(
                    data
                        ? data
                        : "Form submission failed and no error message returned from: " +
                          action
                );
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

export default function DownloadBrochure({
    show,
    handleClose,
    content,
    title,
    resourceType = "brochure",
}) {
    const [cookies, setCookie] = useCookies(["FormData", "isAvailable"]);
    const [validated, setValidated] = useState(false);
    console.log("download:", content);
    /*const docs = {
    'Soultree_Brochure':'./static/documents/Soul_Tree_Brochure.pdf',
    'Urbenest':'./Urbanest_302_West_Brochure.pdf'
  };*/

    if (cookies.isAvailable === "true" && show === true) {
        handleClose();
        let item = cookies.FormData;
        let form_data = new FormData();
        for (var key in item) {
            form_data.append(key, item[key]);
        }
        console.log(item, form_data.get("message"));
        console.log(content["subject"]);
        form_data.set("subject", "Download Brochure - " + content["subject"]);
        console.log(form_data.get("subject"));
        php_email_form_submit(
            "https://mdrn.deta.dev/contactformbaseone",
            form_data
        );
        console.log("Keshav");
        var link = document.createElement("a");
        console.log("link:", link);
        link.href = content["filePath"];

        console.log(link.href);
        link.setAttribute("download", content["fileName"]);
        console.log("point2");
        if (resourceType === "brochure") {
            if (window.location.pathname === "/soultree") {
                link.href = "SoulTreeBrochure.pdf";
                link.setAttribute("download", "SoulTreeBrochure.pdf");
                //link.target='_blank';
            } else if (window.location.pathname === "/urbanest") {
                link.href = "Urbanest-Brochure.pdf";
                link.setAttribute("download", "Urbanest-Brochure.pdf");
                //link.target='_blank';
            } else if (window.location.pathname === "/project77") {
                link.href = "./Urbanest_77East_Phase02_Brochure.pdf";
                link.setAttribute(
                    "download",
                    "Urbanest_77East_Phase02_Brochure.pdf"
                );
                //link.target='_blank';
            } else if (window.location.pathname === "/engrace") {
                link.href = EngraceBrochure;
                link.setAttribute("download", "Engrace_Brochure.pdf");
            } else if (window.location.pathname === "/soulace") {
                link.href = SoulaceBrochure;
                link.setAttribute("download", "Soulace_Brochure.pdf");
            } else if (window.location.pathname === "/engrace-vista") {
                link.href = VistaBrochure;
                link.setAttribute("download", "Vista_Brochure.pdf");
            } else if (window.location.pathname === "/serene-heights") {
                link.href = SHBrochure;
                link.setAttribute("download", "Main_Brochure-low.pdf");
            } else {
                link.href = "./Urbanest_302_West_Brochure.pdf";
                link.download = "Urbanest_302_West_Brochure.pdf";
            }
        }

        link.dispatchEvent(new MouseEvent("click"));
        return <></>;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(e);
        const form = e.currentTarget;
        // if (form.checkValidity() === false) {
        //   e.preventDefault();
        //   e.stopPropagation();
        //   return;
        // }

        setValidated(true);

        if (form.checkValidity() === true) {
            let action = e.target.getAttribute("action");
            console.log(
                "🚀 e.targetgetAttribute ",
                e.target.getAttribute("action")
            );
            if (!action) {
                console.log("The form action property is not set!", action);
                return;
            }
            /* e.target.querySelector('.loading').classList.add('d-block');
     e.target.querySelector('.error-message').classList.remove('d-block');
     e.target.querySelector('.sent-message').classList.remove('d-block');*/
            let formData = new FormData(e.target);
            let loc = window.location.pathname;
            formData.append("subject", "Download Brochure - " + loc);
            let message = formData.get("message");
            formData.set("message", message);
            let Data = JSON.stringify(Object.fromEntries(formData));
            setCookie("FormData", Data, { path: "/" });
            setCookie("isAvailable", "true", { path: "/" });
            php_email_form_submit(action, formData);
            //console.log("this is form data",typeof(cookies.FormData));
            // console.log("this is form data keshav")
            var link = document.createElement("a");
            link.href = content["filePath"];
            link.setAttribute("download", content["fileName"]);
            // console.log("Rajath " + link)
            // console.log(window.location);
            if (resourceType === "brochure") {
                if (window.location.pathname === "/soultree") {
                    // console.log(window.location.pathname);
                    link.href = "SoulTreeBrochure.pdf";
                    link.setAttribute("download", "SoulTreeBrochure.pdf");
                    //link.target='_blank';
                } else if (window.location.pathname === "/urbanest") {
                    link.href = "Urbanest-Brochure.pdf";
                    link.setAttribute("download", "Urbanest-Brochure.pdf");
                    //link.target='_blank';
                } else if (window.location.pathname === "/engrace") {
                    // console.log("point1");
                    // console.log(window.location.pathname)
                    link.href = EngraceBrochure;
                    link.setAttribute("download", "Engrace_Brochure.pdf");
                    //link.target='_blank';
                } else if (window.location.pathname === "/soulace") {
                    link.href = SoulaceBrochure;
                    link.setAttribute("download", "Soulace_Brochure.pdf");

                    //link.target='_blank';
                } else if (window.location.pathname === "/engrace-vista") {
                    link.href = VistaBrochure;
                    link.setAttribute("download", "Vista_Brochure.pdf");
                } else if (window.location.pathname === "/serene-heights") {
                    link.href = SHBrochure;
                    link.setAttribute("download", "Main_Brochure-low.pdf");
                } else {
                    link.href = "./Urbanest_302_West_Brochure.pdf";
                    link.download = "Urbanest_302_West_Brochure.pdf";
                }
            }

            link.dispatchEvent(new MouseEvent("click"));
            handleClose();
            window.location.href = "/thank-you-contact";
        }
    };

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Heading variant="h3" classList="" text={title} />
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row className="py-0">
                            <Col xs={12} md={12} lg={12} className="px-0">
                                <Form
                                    action="https://mdrn.deta.dev/contactformbaseone"
                                    method="post"
                                    role="form"
                                    className="ContactUsForm php-email-form"
                                    noValidate
                                    validated={validated}
                                    onSubmit={handleSubmit}
                                >
                                    <Row className="my-0">
                                        <Form.Group
                                            as={Col}
                                            xs={12}
                                            md={12}
                                            controlId="validationCustom01"
                                        >
                                            <Form.Label>Name *</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                name="name"
                                                // placeholder="Enter Name"
                                                placeholder=""
                                                defaultValue=""
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please enter your number.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group
                                            as={Col}
                                            xs={12}
                                            md={12}
                                            controlId="validationCustom02"
                                        >
                                            <Form.Label>
                                                Phone Number *
                                            </Form.Label>
                                            <InputGroup>
                                                <InputGroup.Text id="PhoneNumber">
                                                    +91
                                                </InputGroup.Text>
                                                <Form.Control
                                                    required
                                                    type="tel"
                                                    pattern="[0-9]{10}"
                                                    name="phone"
                                                    // placeholder="Enter Phone Number"
                                                    placeholder=""
                                                    aria-label="PhoneNumber"
                                                    aria-describedby="PhoneNumber"
                                                />

                                                <Form.Control.Feedback type="invalid">
                                                    Please enter your number.
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group
                                            as={Col}
                                            xs={12}
                                            md={12}
                                            controlId="validationCustom01"
                                        >
                                            <Form.Label>Email *</Form.Label>
                                            <Form.Control
                                                required
                                                type="email"
                                                name="email"
                                                // placeholder="Enter Email"
                                                placeholder=""
                                                defaultValue=""
                                            />

                                            <Form.Control.Feedback type="invalid">
                                                Please enter your email.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group
                                            as={Col}
                                            xs={12}
                                            md={12}
                                            controlId="validationCustom02"
                                            className=""
                                        >
                                            <Form.Label>
                                                What would you like to discuss?
                                            </Form.Label>
                                            <Form.Control
                                                // required
                                                type="text"
                                                name="message"
                                                // placeholder="Enter Message"
                                                placeholder=""
                                                defaultValue=""
                                            />
                                        </Form.Group>
                                    </Row>

                                    <Button
                                        className="btn btn--submit w-100"
                                        type="submit"
                                        // onClick={handleClose}
                                    >
                                        Download Now
                                    </Button>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>
        </>
    );
}
