import VistaBrochure from "../../../documents/EngraceVista/vista-brochure.pdf";
import VistaMasterPlan from "../../../documents/EngraceVista/vista-master-plan.pdf";
import VistaFloorPlan from "../../../documents/EngraceVista/vista-floor-plans.pdf";

const floorPlan = "/images/Common/floor-plan-banner.svg";
const masterPlan = "/images/Common/master-plan-banner.svg";
const projectBrochure = "/images/Common/project-brochure-banner.svg";

export const ProjectResourcesData = [
    {
        key: "floorPlan",
        name: "Floor Plan",
        icon: floorPlan,
        IconAlt: "Floor Plan: Modern and spacious layout.",
        resourceContent: {
            subject: "Vista Floor Plan",
            fileName: "Vista_Floor_plans.pdf",
            filePath: VistaFloorPlan,
        },
    },
    {
        key: "masterPlan",
        name: "Master Plan",
        icon: masterPlan,
        IconAlt:
            "Engrace Vista Master plan: Well-designed layout with green spaces.",
        resourceContent: {
            subject: "Vista Master Plan",
            fileName: "Engrace_Vista_Master_Plan.pdf",
            filePath: VistaMasterPlan,
        },
    },
    {
        key: "projectBrochure",
        name: "Project Brochure",
        icon: projectBrochure,
        IconAlt: " Engrace Vista Brochure Explore our amenities and elegance.",
        resourceContent: {
            subject: "vista Brochure",
            fileName: "Vista_Brochure.pdf",
            filePath: VistaBrochure,
        },
    },
];
