import EMobile from "../../src/images/Blogs/sci-fi.png";
import Smarthometech from "../../src/images/Blogs/smarthometech.png"
import SmartLock from "../../src/images/Blogs/Smartlock.jpg"



const data = [
  {
    id: "abc104",
    slug: "the-evolution-of-smart-homes",
    image: EMobile,
    image1:Smarthometech,
    image2:SmartLock,
    label: "Industry",
    title: " The Evolution of Smart homes - From Sci-Fi dream to Reality ",
    publishedOn: "Dec, 2021",
    time: "2 min read",
    description:
      "Experience the future with smart homes! Discover how smart home technology enhances convenience, security, and energy efficiency.Lets find out more about it in this blog.",
    authorImage:
      "https://images.unsplash.com/photo-1600188769099-d25b4ec79659?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    authorName: "Noveen Reddy",
    department: "Marketing Head",
    contactNumber: "987654321",
    // imageOther: "https://modernspaaces.com/BlogListImages/BlogFour/Img01.webp",
    description11: [
      <>
        {`Between 2023 and 2027, It is  projected that there will be a steady rise in global user adoption of`}
        <a href="https://docs.google.com/document/d/1PSkmMXQ7JHpyfaP6A8UqrryXw6r42KQDaFHdxhErLVY/edit#heading=h.wt1h276hgwbl"> smart home technology </a>{" "}
        {`leading to a 12.5% increase. Following four consecutive years of continuous growth, user penetration is expected to hit a new high of 28.84% in 2027.`}
      </>,
    ],
    title2:"What is a Smart Home?",
    
    description21:
      "A smart home refers to a residence with advanced technology and devices that can be controlled remotely or set up to work automatically. This makes life more convenient, comfortable, energy-efficient,  secure and enhances overall quality of life.",
    description22:"These technologies are interconnected through a central system or network, controlled generally by a smartphone, tablet, or voice commands, allowing homeowners to manage and monitor various aspects of their home remotely.",
    description23:"Examples of smart home devices include smart thermostats, lighting systems, security cameras, smart locks, voice assistants, and appliances that can be controlled and customized to suit the homeowner's preferences",
    title3:"Evolution of Smart Home Technology",
    description31:"The concept of a smart home dates back to the 1900s when the idea of a house that could function autonomously began emerging. An article in Popular Mechanics Magazine titled 'The Electric House of the Future' by George H.Bucher had envisioned a networked home where doors would open automatically, an intercom would greet guests, and lighting would adjust based on the preference of the dwellers.",
    description32:"In the 1930s, early notions of home automation emerged, but it wasn't until 1966 that the Echo IV introduced the first practical smart system. This system allowed people to manage shopping lists, control home temperature, and operate appliances.",
    description33:"By 1971, the development of microcontrollers brought down electronic costs, making technology more accessible. In 1991, the term 'gerontechnology' emerged, combining gerontology and technology to improve the lives of seniors and aged population.",
    description34:"The 1990s saw the introduction of various senior-friendly technologies.It is important to note that smart home technology was directed towards improving the quality of life of the geriatric population in general.",

    title4:
      "The rise of Smart Home Technologies",
    description41: "Recent years have witnessed significant growth in the implementation of smart home technologies in India.",
    description42:"Advancements in telecommunications infrastructure, increased internet connectivity, and readily available and affordable smart devices have accelerated its growth.",
    description43:"Homebuyers are now actively seeking properties equipped with state-of-the-art technology, making it a vital differentiator for developers",

    title5: [
      <>
        <a href="https://docs.google.com/document/d/1xf5n9UCC1Iiry_lIvUfvTOav3q3BYXNFFF7jnSb-Yvc/edit#heading=h.m8zznj4y1g7n">How does IoT</a>{" "}
        {`and Smart Home automation work?`}
      </>,
    ],
   description51:
      "A home automation system functions by creating a central control point or Internet of Things (IoT) hub within the home.",
   description52:"IoT (Internet of Things) and smart home automation work together to create a connected system where various devices, appliances, and systems collaborate together to enhance convenience, efficiency, and control within a household.",
   description53:"It's also capable of managing more complex tasks, such as setting up a network of surveillance. Homeowners now have a wide range of options to automate their homes, contributing to an increasing demand for these technologies.",

   title6:"How to make your home smarter?",
   list6:[
    {
      label:"Surveillance camera",
      description:"The first step towards achieving a smart home is by installing a smart security camera, making the property safe from any kind of trespassing and unauthorized entry."
    },
    {
      label:"Smart locks",
      description:"These locks add another layer of security to our homes making it safer for our loved ones.Smart locks add benefits to elderly or people with limited mobility, smart locks can unlock your doors with the push of a button or a simple voice command."
    },
    {
      label:"Smart water meters",
      description:"Smart water meters are digital water meters used to measure and monitor water consumption of a building. These meters can give accurate reading of water consumption, detect leaks and predict future consumption."
    },
    {
      label:"Smart Thermostats",
      description:"Heating, ventilation, and air conditioning (HVAC) systems can account for up to 40% of a building's energy expenses. Poor habits like keeping exterior doors and windows open, neglecting air filter changes while simultaneously operating heating and cooling systems can lead to unnecessary energy consumption.Smart thermostats devices allow you to control your home's heating, ventilation, and air conditioning (HVAC) systems remotely. They can learn your preferences, adjust settings based on your habits, and help save energy and reduce bills."
    },
    {
      label:"Smart speakers",
      description:"There are plenty of smart speakers available in the market managing a set of household chores from switching lights on and off to pairing with the other smart home devices, adjusting the light setting,  playing music and in general making your life easier. Smart speakers act as assistants thereby simplifying day to day activities."
    }
   ],

   title7:"Do smart homes save money?",
   description71:"Smart home devices not only make your work around the house easier but   also reduce electricity bills",
   description72:"For example, a simple smart bulb can help you reduce energy consumption by 75% whilst making your life easier.Smart bulbs have sensors and can also be controlled remotely",
   description73:[
    <>
          {`Another way to save money is installing smart water meters or thermostats to optimize energy consumption, leading to reduced utility bills and smaller carbon footprints making it a`}
      <a href="/blogs/choose-between-green-traditional-energy-efficient-buildings"> sustainable choice. </a>{" "}
    </>
   ],

   title8:"Do smart homes use more electricity?",
   description81:"It is a flawed perception that smart homes use more electricity but on the contrary it uses less electricity, typically up to 30-40% less than non-smart homes.",
    
   title9:"How can smart homes help the elderly?",
   description91:"Smart homes have revolutionized elderly care by offering technologies like fall detection sensors, health monitoring wearables, and medication management systems.",
   description92:"Smart locks and smart security cameras enhance safety and Voice-activated assistants enable emergency calls, and automation systems provide energy efficiency.",

   title10:"Benefits of Smart homes",
   list10:[
    {
      label:"Target Audience",
      description:"Smart home technology resonates with tech-savvy individuals, particularly the younger generation, who are increasingly adopting these advancements.",
    },
    {
      label:"Enhanced Security",
      description:"Smart security cameras, door locks, and motion sensors provide homeowners with improved surveillance and access control, enhancing property safety.",
    },
    {
      label:"Accessibility and Comfort",
      description:"Automating various chores enables homeowners to manage their homes remotely and enjoy a more convenient and comfortable living experience. Virtual assistants like Alexa or Amazon Echo facilitate easy access to home appliances.",
    },
    {
      label:"Increased Property Value",
      description:"Homes equipped with smart technologies generally have higher resale values, making them attractive investments.",
    }
   ],


   title11:"Challenges in adoption of smart home technology",
   list11:[
    {
      label:"Array of Devices",
      description:"Manufacturers often develop distinct systems, making it difficult to integrate new devices with existing brands or those manufactured by different companies.",
    },
    {
      label:"Security Concerns",
      description:"A significant challenge is the potential hacking of the security system. For efficient remote operation, the security system connects to the internet, making it susceptible to hackers who can gain control of private and sensitive homeowner information.",
    },
    {
      label:"Affordability",
      description:"The process of home automation can be expensive for homeowners due to the need to make the home compatible with the device setup.",
    },
   ],

   title12:"Are Smart Homes Safe?",
   description121:"Smart homes, like any technology, are safe if set up, configured, and maintained according to the manual. However, potential security risks associated with smart home devices need to be considered.",
   list12:[
    {
      label:"Data Breaches and Identity Theft",
      list:[
        "Problem: Smart-home devices can expose personal data to hackers which leads to identity theft.",
        "Solution: To prevent this, avoid sharing any kind of sensitive information with devices and disconnect apps containing sensitive data when not in use."

      ]
    },
    {
      label:"Weak Hub Security",
      list:[
        "Problem: Insecure passwords for smart-home hubs can lead to unauthorized access.",
        "Solution:To enhance security, always use strong, unique passwords and enable two-factor authentication for all devices."

      ]
    },
    {
      label:"Location Privacy",
      list:[
        "Problem: Vulnerabilities in devices can reveal your location.",
        "Solution: Do not click on unknown links and consider a separate network for smart devices."

      ]
    },
    {
      label:"Physical Break-ins",
      list:[
        "Problem:Security device vulnerabilities can allow hackers to disable cameras or unlock doors, compromising home security.",
        "Solution: Consider traditional security measures like door braces and non-smart alarms."

      ]
    },
    {
      label:"Smart Speaker Privacy",
      list:[
        "Problem:Smart speakers might listen and be exploited by hackers.",
        "Solution:Delete recordings, avoid pairing with security devices, and turn off when not in use."

      ]
    },
    {
      label:"Unauthorized App Control",
      list:[
        "Problem: Insecure apps could let unauthorized users control devices.",
        "Solution:Stick to authorized apps and platforms for device control."

      ]
    },
    {
      label:"Undetectable Hijacking",
      list:[
        "Problem: Unencrypted data transmission can allow hackers to alter device data.",
        "Solution:The home owners should use advanced network monitoring tools to detect any kind of suspicious activities."

      ]
    },
   ],
  description122:"By addressing these vulnerabilities, you can enhance the security of your smart-home devices and safeguard your privacy.",
  description123:"Soulace by Modern Spaaces located off Sarjapur, Bangalore has incorporated cutting-edge technology into its real estate properties, including the installation of intelligent water meters, smart locks and solar panels.",
  description124:"Soulace villas offer ample opportunities for the integration of a wide range of smart home devices, allowing homeowners to tailor the smart home features according to their individual preferences and creative visions.",



   
    
  },
];

export default data;
