import ImgB4 from "../../images/Blogs/ImgB4.png";

import ImgTopReasonsSarjapura from "../../images/Blogs/TopReasonsSarjapura.jpeg";

const ImgMivanTechnology = "/images/Blogs/mivan-technology-banner.jpeg";
const ImgEnergyEfficientBuilding =
    "/images/Blogs/energy-efficient-building-banner.png";
const ImgAdvOfVillas = "/images/Blogs/advantages-of-luxury-villas-banner.png";
const ImgFactorsImpactingRELocation =
    "/images/Blogs/impact-on-real-estate-location-banner.png";
const ImgExclusive3BHKCommunity =
    "/images/Blogs/benefits-of-living-in-an-exclusive-3-BHK-community.png";

const budget2024 = "/images/Blogs/budget-2024.jpg";

export const FeaturedBlogs = [
    {
        id: "budget2024",
        slug: "budget-2024",
        image: budget2024,
        imgAlt: "Budget 2024 - Your Wallet's New BFF or Your Worst Enemy",
        label: "Real Estate",
        desc: "Discover how Budget 2024 reshapes India's real estate landscape. Understand the potential implications for property buyers, sellers, and investors.",
        title: "Budget 2024 : Your Wallet's New BFF or Your Worst Enemy",
        date: "September, 2024",
        time: "7 min read",
    },
    {
        id: "abc100",
        slug: "benefits-of-living-in-an-exclusive-3-BHK-community",
        title: "Benefits of living in an exclusive 3 BHK community",
        imgAlt: "Exclusive 3 BHK community",
        image: ImgExclusive3BHKCommunity,
        desc: "Discover Engrace Vista, an exclusive 3 BHK community off Sarjapur Road, Bangalore. Experience the best of urban living with spacious apartments, top-tier amenities, enhanced security, and a vibrant community atmosphere",
        date: "May,2024",
        time: "8 min read",
    },
    {
        id: "abc101",
        slug: "exploring-the-mivan-technology",
        // image: "https://modernspaaces.com/BlogListImages/BlogFour/Banner.webp",
        image: ImgMivanTechnology,
        imgAlt: "Mivan Technology - Aluform Technology",
        label: "REAL ESTATE",
        desc: "Exploring the technology of Mivan and its impact on construction. Learn more about the technology through a detailed analysis.",
        title: "Revolutionizing Construction: Exploring the Mivan Technology",
        date: "Jan, 2024",
        time: "5 min read",
    },
    {
        id: "abc102",
        slug: "choose-between-green-traditional-energy-efficient-buildings",
        // image: "https://modernspaaces.com/BlogListImages/BlogFour/Banner.webp",
        image: ImgEnergyEfficientBuilding,
        imgAlt: "Energy Efficent Buildings",
        label: "REAL ESTATE",
        desc: "Urbanization and the Changing Landscape The global wave of urbanization has profoundly reshaped landscapes, impacting natural resources and intensifying carbon emissions.",
        title: "Choosing Between Green, Traditional, and Energy-Efficient Buildings in Real Estate",
        date: "Jan, 2024",
        time: "5 min read",
    },
    // {
    //     id: "abc103",
    //     slug: "why-sarjapura-best-area-to-buy-house",
    //     // image: "https://modernspaaces.com/BlogListImages/BlogFour/Banner.webp",
    //     image: ImgTopReasonsSarjapura,
    //     label: "REAL ESTATE",
    //     desc: "For any real estate project, its amenities are the most crucial part, as that’s what the builders use to lure potential home buyers For any real estate project, its amenities are the most crucial part, as that’s what the builders use to lure…",
    //     title: "Top reasons why Sarjapur Road is the best area for buying a home",
    //     date: "Dec, 2023",
    //     time: "3 min read",
    // },
    {
        id: "abc104",
        slug: "advantage-of-villas-exploring-luxury-living",
        // image: "https://modernspaaces.com/BlogListImages/BlogFour/Banner.webp",
        image: ImgAdvOfVillas,
        imgAlt: "Advantages of Luxury Living Villas",
        label: "REAL ESTATE",
        desc: "Are you feeling overwhelmed by all the choices for your new home? Unsure whether you should construct your own home or buy a ready-to-move-in Villa? Are you confused between an apartment and a villa? We have got you covered in this blogs...",
        title: "Prime Advantage of Villas: Exploring Luxury Living",
        date: "Jan, 2024",
        time: "3 min read",
    },
    {
        id: "abc104",
        slug: "factors-impacting-real-estate-location",
        // image: "https://modernspaaces.com/BlogListImages/BlogFour/Banner.webp",
        image: ImgFactorsImpactingRELocation,
        imgAlt: "Factors Impacting Real Estate Location",
        label: "REAL ESTATE",
        desc: "Explore the vital role of location in real estate investments. Learn how factors like amenities, economic trends, and growth potential shape a property's value.",
        title: "Factors Impacting Real Estate Location",
        date: "Jan, 2024",
        time: "3 min read",
    },
];
