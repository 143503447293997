import { Container, Row, Col } from "react-bootstrap";
import { Desc, Heading } from "../../Global/Typography";
// import { Desc, Heading } from "../../Global/Typography";
// import TranquilCourtImage from "../../../images/SoulTree/TranquilCourt/TranquilCourtImage.png";
// import TranquilCourtImageLarge from "../../../images/Urbanest/RecreationZone/TranquilCourtImageLarge.png";
import TranquilCourtImageSmall from "../../../images/Urbanest/RecreationZone/TranquilCourtImageSmall.png";
import patternVideo from "../../../images/Urbanest/RecreationZone/patternVideo.mp4";
// import patternGIF from "../../../images/Urbanest/RecreationZone/patternGIF.gif";

import "./TranquilCourt.scss";

const TranquilCourtImageLarge  = "/images/Engrace/RecreationZone/engrace-amenities-swimming-pool.png";
const patternGIF = "/images/Engrace/RecreationZone/engrace-amenities.gif";

export default function TranquilCourt({ inView }) {
    const TranquilCourtData = [
        {
            title: "Amenities that define lifestyle",
            desc: "Engrace offers a lifestyle that promotes wellness and friendships, equipped with top-notch amenities that transform the way we experience contemporary living.",
            imageLarge: TranquilCourtImageLarge,
            imageSmall: TranquilCourtImageSmall,
            patternGIF: patternGIF,
            patternVideo: patternVideo,
            // image: TranquilCourtImage,
            listItems: [
                "Built-in BBQ",
                "Swimming pool",
                "Terrace Party Area",
                "Gym",
                "Squash",
                "Seniors Zone",
                "Tree House",
                "Yoga Deck",
                "Badminton",
                "Soccer",
                "Cricket",
            ],
        },
    ];

    return (
        <>
            <section
                className={`section bg-white ${inView ? "inView" : "outView"}
    `}
            >
                {TranquilCourtData.map((item, idx) => {
                    return (
                        <Container
                            key={idx}
                            className="TranquilCourt TranquilCourt-Engrace"
                        >
                            <Row>
                                <Col
                                    xs={12}
                                    md={5}
                                    lg={5}
                                    xl={6}
                                    className="pr-md-4 pr-xl-4"
                                >
                                    {/* AS OF NOW */}
                                    <Heading
                                        variant="h1"
                                        classList="TranquilCourt-Engrace__title my-3"
                                        text={item.title}
                                    />
                                    <Desc
                                        classList="TranquilCourt-Engrace__desc my-3 "
                                        text={item.desc}
                                    />
                                    <ul className="TranquilCourtList">
                                        {item.listItems.map((i, j) => (
                                            <li key={j}>
                                                <Desc
                                                    classList="my-1"
                                                    text={i}
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                </Col>
                            </Row>
                            <div className="TranquilCourtImage -RecreationZone imagePatch">
                                {/* <img
                src={item.image}
                className="TranquilCourt__image"
                alt="TranquilCourt"
              /> */}
                                {/* SEPARATE */}
                                <img
                                    src={item.imageLarge}
                                    className="TranquilCourt-Engrace__imageLarge"
                                    alt="Engrace - Swimming Pool"
                                />

                                <img
                                    src={item.patternGIF}
                                    className="TranquilCourt-Engrace__imageSmall"
                                    alt="Engrace Amenities"
                                />

                                {/* <video
                controls={false}
                className="TranquilCourt__imageSmall"
                loop={true}
                muted={true}
                autoPlay={true}
                playsInline={true}
              >
                <source src={item.patternVideo} type="video/mp4" />
              </video> */}
                            </div>
                        </Container>
                    );
                })}
            </section>
        </>
    );
}
