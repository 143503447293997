import { useState, useRef, useEffect } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import { Desc, Heading } from "../../Global/Typography";
import "./ContactUs.scss";

function php_email_form_submit(thisForm, action, formData) {
    fetch(action, {
        method: "POST",
        body: formData,
        headers: { "X-Requested-With": "XMLHttpRequest" },
    })
        .then((response) => {
            if (response.ok) {
                return response.text();
            } else {
                throw new Error(
                    `${response.status} ${response.statusText} ${response.url}`
                );
            }
        })
        .then((data) => {
            if (data.trim() === '"OK"') {
                console.log("message sent");
                thisForm.reset();
            } else {
                throw new Error(
                    data
                        ? data
                        : "Form submission failed and no error message returned from: " +
                          action
                );
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

const lsqLeadGeneration = (formData) => {
    const lsqObj = [
        {
            Attribute: "FirstName",
            Value: formData.get("name"),
        },
        {
            Attribute: "EmailAddress",
            Value: formData.get("email"),
        },
        {
            Attribute: "Phone",
            Value: formData.get("phone"),
        },
        // {
        //     Attribute: "SearchBy",
        //     Value: formData.get("mode"),
        // },
    ];
    const host = "https://api-in21.leadsquared.com/v2/";
    const accessKey = "u$rc8cdb15caad13149ec5e59639ae32ccb";
    const secretKey = "ac596128b63260bd1be2e3aff6036e728604fc6b";
    const captureLead = `LeadManagement.svc/Lead.Capture?accessKey=${accessKey}&secretKey=${secretKey}`;

    fetch(host + captureLead, {
        method: "POST",
        body: JSON.stringify(lsqObj),
        headers: {
            "Content-Type": "application/json",
        },
    }).then((res) => {
        console.log(res.json, "Lead Captured");
    });
};

export default function ContactUs({ inView }) {
    const [validated, setValidated] = useState(false);
    let formRef = useRef(null);
    let [formData, setFormData] = useState({ moc: "phone" });
    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log(
        //   "🚀 ~ file: validate.js ~ line 10 ~ e.addEventListener ~ event",
        //   e
        // );

        const form = e.currentTarget;

        // if (form.checkValidity() === false) {
        //   e.preventDefault();
        //   e.stopPropagation();
        //   setValidated(false);
        //   // return;
        // }
        setValidated(true);
        if (form.checkValidity() === true) {
            let action = e.target.getAttribute("action");
            console.log(
                "🚀 e.targetgetAttribute ",
                e.target.getAttribute("action")
            );
            if (!action) {
                console.log("The form action property is not set!", action);
                return;
            }
            /* e.target.querySelector('.loading').classList.add('d-block');
      e.target.querySelector('.error-message').classList.remove('d-block');
      e.target.querySelector('.sent-message').classList.remove('d-block');*/
            const formData = new FormData(e.target);
            let loc = window.location.hash;
            formData.append("subject", "Meet us in person - " + loc);
            console.log(formData.get("subject"));
            lsqLeadGeneration(formData);
            php_email_form_submit(e.target, action, formData);
            // window.location.href = "#/thank-you-contact";
            window.location.href = "/thank-you-contact";
        }
    };

    const handleChange = (e) => {
        let currFormData = new FormData(formRef.current);
        currFormData = Object.fromEntries(currFormData);
        // Display the key/value pairs

        setFormData(currFormData);
    };

    return (
        <section
            id="RequestExposeNow"
            className={`section connect-with-us pt-0 ${
                inView ? "inView" : "outView"
            }`}
        >
            <div className="background">
                <Container className="content">
                    <Row className=" mx-0">
                        <Col xs={12} md={4} lg={5} className="pl-md-0">
                            <Heading
                                variant="h1"
                                classList="-title"
                                text={"Connect With Us"}
                            />
                            <Desc
                                classList="-desc"
                                text="We’ll get back to you"
                            />
                        </Col>
                        <Col xs={12} md={8} lg={7} className="pl-md-3 pr-md-0 ">
                            <Form
                                ref={formRef}
                                action="https://mdrn.deta.dev/contactformbaseone"
                                method="post"
                                role="form"
                                className="ContactUsForm php-email-form get-in-touch"
                                noValidate
                                validated={validated}
                                onChange={handleChange}
                                onSubmit={handleSubmit}
                            >
                                <Row className="mb-3">
                                    {/* <Form.Group as={Col} xs={12}> */}
                                    {/* <div
                                            className="mb-3 form-label"
                                            style={{ fontSize: "0.875rem" }}
                                        >
                                            Preferred Mode of Contact
                                        </div> */}
                                    {/* <div
                                            className="d-flex"
                                            style={{ gap: "4rem" }}
                                        >
                                            <Form.Check
                                                type="radio"
                                                name="moc"
                                                value="phone"
                                                label="Phone"
                                                checked={
                                                    formData.moc === "phone"
                                                }

                                            // checked
                                            // checked={
                                            //     formData.moc === "phone"
                                            // }
                                            />
                                            <Form.Check
                                                type="radio"
                                                name="moc"
                                                value="email"
                                                label="Email"
                                            />
                                        </div> */}
                                    {/* </Form.Group> */}
                                    <Form.Group
                                        as={Col}
                                        xs={12}
                                        md="6"
                                        controlId="validationCustom01"
                                    >
                                        <Form.Label>Full Name</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            name="name"
                                            // placeholder="Enter Name"
                                            placeholder=""
                                            defaultValue=""
                                            pattern="^[a-zA-Z\s]*$"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter your name.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group
                                        as={Col}
                                        xs={12}
                                        md="6"
                                        controlId="validationCustom02"
                                    >
                                        <Form.Label>Phone Number</Form.Label>
                                        <InputGroup>
                                            <InputGroup.Text
                                                id="PhoneNumber"
                                                required
                                                style={{ marginRight: "16px" }}
                                            >
                                                +91
                                                {/* <svg
                                                    width="13"
                                                    height="7"
                                                    viewBox="0 0 13 7"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M12 0.5L6.5 5.5L1 0.5"
                                                        stroke="#000"
                                                    />
                                                </svg> */}
                                            </InputGroup.Text>
                                            <Form.Control
                                                required
                                                type="tel"
                                                pattern="[0-9]{10}"
                                                name="phone"
                                                // required={
                                                //     formData?.moc === "phone"
                                                // }
                                                // placeholder="Enter Phone Number"
                                                placeholder=""
                                                aria-label="PhoneNumber"
                                                aria-describedby="PhoneNumber"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please enter your number.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>

                                    <Form.Group
                                        as={Col}
                                        xs={12}
                                        md="6"
                                        controlId="validationCustom01"
                                    >
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            // required
                                            type="email"
                                            name="email"
                                            // placeholder="Enter Email"
                                            placeholder=""
                                            defaultValue=""

                                            // required={true}
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            Please enter vaild email ID.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group
                                        as={Col}
                                        xs={12}
                                        md="6"
                                        controlId="validationCustom02"
                                    >
                                        <Form.Label>Message</Form.Label>
                                        <Form.Control
                                            // required
                                            type="text"
                                            name="message"
                                            // placeholder="Enter Message"
                                            placeholder=""
                                            defaultValue=""
                                        />
                                    </Form.Group>
                                </Row>
                                <Button
                                    className="btn btn--submit py-2"
                                    type="submit"
                                >
                                    <span className="btn-text">
                                        Get in Touch
                                    </span>
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    );
}
