import "./Share.scss";
import ShareButton from "./ShareButton";

// import StickyBox from "react-sticky-box";

export default function ShareBlogOne({ inView }) {
  return (
    <section className="shareLinkBlock -only-desktop ">
      <ShareButton
        shareUrl={
          "https://modernspaaces.com/blogs/list-of-amenities-to-consider-buying-home"
        }
      />
    </section>
  );
}
