import "./Share.scss";
import ShareButton from "./ShareButton";

// import StickyBox from "react-sticky-box";

export default function ShareBlogTwo({ inView }) {
  return (
    <section className="shareLinkBlock -only-desktop ">
      <ShareButton
        shareUrl={
          "https://modernspaaces.com/blogs/things-to-consider-buying-your-first-home"
        }
      />
    </section>
  );
}
