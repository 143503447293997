import { Col, Container, Row } from "react-bootstrap";
import { Desc, DescLabel } from "../../../Global/Typography";
import BannerNew from "../../BannerNew";
import "../../BlogChoosenGreen/Banner/BlogChoosenGreen.scss";
import { Helmet } from "react-helmet-async";

import BlogFourData from "../../../../db/BlogChoosenGreen";
import ShareButton from "../../Share/ShareButton";

export default function Banner({ inView }) {
    const currentPage =
        "Comparing the pros and cons of buying a home vs buying an apartment. This blog aims to guide you in making an informed decision.";
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0"
                />
                <title>
                    Choose between Green, Traditional and Energy efficient
                    buildings | Modern Spaaces
                </title>
                <meta
                    name="Keywords"
                    content="What is energy efficient building, Green building concept, What is Green Building, Green building energy efficient, energy efficient building materials, advantages of green buildings"
                />
                <meta
                    name="description"
                    content="Learn more about eco-friendly and energy-efficient designs and green buildings. Creating a future where every structure in real estate contributes to a greener planet."
                />
                <link
                    rel="canonical"
                    href="https://modernspaaces.com/blogs/choose-between-green-traditional-energy-efficient-buildings"
                />
            </Helmet>
            <section className={`section  ${inView ? "inView" : "outView"}`}>
                {/* <Container className="container">
        <Row className="align-items-center">
          <Col xs={12} md={12} lg={12} className="py-2">
            <BreadcrumbBlogCard item={currentPage} />
          </Col>
        </Row>
      </Container> */}

                <BannerNew
                    image={BlogFourData[0].image}
                    title={BlogFourData[0].title}
                    publishedOn={BlogFourData[0].publishedOn}
                    time={BlogFourData[0].time}
                    alt={BlogFourData[0].imgAlt}
                />

                <Container className="blog-detail-container">
                    {BlogFourData.map((item, idx) => {
                        return (
                            <Row key={idx} className="justify-content-around">
                                {/* {item.image && (
                <Col xs={12} md={12} lg={12} className="pb-4">
                  <img
                    src={item.image}
                    alt={item.title}
                    className="-blog-banner"
                  />
                </Col>
              )} */}
                                <Col
                                    xs={12}
                                    md={11}
                                    lg={9}
                                    className="pb-4 pr-md-5"
                                >
                                    <div className="blog-header ">
                                        {/* {item.title && (
                    <Heading classList="my-3" variant="h1" text={item.title} />
                  )}
                  {item.publishedOn && (
                    <Desc
                      classList=""
                      text={`Published on ${item.publishedOn}`}
                    />
                  )} */}
                                        {item.title1 && (
                                            <p className="-descBlack font-benton-bold  margin">
                                                {item.title1}
                                            </p>
                                        )}
                                        {item.description1 && (
                                            <Desc
                                                classList="font-benton-book header"
                                                text={item.description1}
                                            />
                                        )}
                                    </div>

                                    <div>
                                        {item.title2 && (
                                            <p className="-descBlack font-benton-bold  margin">
                                                {item.title2}
                                            </p>
                                        )}
                                        {item.description2 && (
                                            <Desc
                                                classList="font-benton-book header"
                                                text={item.description2}
                                            />
                                        )}
                                        <div className="imagepadding">
                                            <img
                                                src={item.image2}
                                                alt={item.image2Alt}
                                                className="image"
                                                style={{
                                                    objectFit: "contain"
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        {item.title3 && (
                                            <p className="-descBlack font-benton-bold  margin">
                                                {item.title3}
                                            </p>
                                        )}
                                        {item.description31 && (
                                            <Desc
                                                classList="font-benton-book header"
                                                text={item.description31}
                                            />
                                        )}
                                        {item.description32 && (
                                            <Desc
                                                classList="font-benton-book header"
                                                text={item.description32}
                                            />
                                        )}
                                    </div>

                                    <div>
                                        {item.title4 && (
                                            <p className="-descBlack font-benton-bold  margin">
                                                {item.title4}
                                            </p>
                                        )}
                                        {item.list41 &&
                                            item.list41.map((i0, idx) => {
                                                return (
                                                    <div key={idx}>
                                                        <DescLabel
                                                            classList="my-1 py-2 font-benton-book header"
                                                            text={
                                                                i0.description
                                                            }
                                                            label={i0.label}
                                                        />
                                                        {i0.list1 && (
                                                            <ul className="blog-list-circle header">
                                                                {i0.list1.map(
                                                                    (
                                                                        i1,
                                                                        idx1
                                                                    ) => {
                                                                        return (
                                                                            <li
                                                                                key={
                                                                                    idx1
                                                                                }
                                                                            >
                                                                                <p className="-descBlack mb-1 font-benton-book header">
                                                                                    {
                                                                                        i1
                                                                                    }
                                                                                </p>
                                                                            </li>
                                                                        );
                                                                    }
                                                                )}
                                                            </ul>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        {item.title42 && (
                                            <p className="-descBlack font-benton-bold  margin">
                                                {item.title42}
                                            </p>
                                        )}
                                        {item.list42 &&
                                            item.list42.map((i0, idx) => {
                                                return (
                                                    <div key={idx}>
                                                        <DescLabel
                                                            classList="my-1 py-2 font-benton-book header"
                                                            text={
                                                                i0.description
                                                            }
                                                            label={i0.label}
                                                        />
                                                        {i0.list1 && (
                                                            <ul className="blog-list-circle header">
                                                                {i0.list1.map(
                                                                    (
                                                                        i1,
                                                                        idx1
                                                                    ) => {
                                                                        return (
                                                                            <li
                                                                                key={
                                                                                    idx1
                                                                                }
                                                                            >
                                                                                <p className="-descBlack mb-1 font-benton-book header">
                                                                                    {
                                                                                        i1
                                                                                    }
                                                                                </p>
                                                                            </li>
                                                                        );
                                                                    }
                                                                )}
                                                            </ul>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        {item.title43 && (
                                            <p className="-descBlack font-benton-bold  margin">
                                                {item.title43}
                                            </p>
                                        )}
                                        {item.list43 &&
                                            item.list43.map((i0, idx) => {
                                                return (
                                                    <div key={idx}>
                                                        <DescLabel
                                                            classList="my-1 py-2 font-benton-book header"
                                                            text={
                                                                i0.description
                                                            }
                                                            label={i0.label}
                                                        />
                                                        {i0.list1 && (
                                                            <ul className="blog-list-circle header">
                                                                {i0.list1.map(
                                                                    (
                                                                        i1,
                                                                        idx1
                                                                    ) => {
                                                                        return (
                                                                            <li
                                                                                key={
                                                                                    idx1
                                                                                }
                                                                            >
                                                                                <p className="-descBlack mb-1 font-benton-book header">
                                                                                    {
                                                                                        i1
                                                                                    }
                                                                                </p>
                                                                            </li>
                                                                        );
                                                                    }
                                                                )}
                                                            </ul>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        <p className="-descBlack font-benton-bold  margin">
                                            {item.title44}
                                        </p>
                                        {item.description44 && (
                                            <Desc
                                                classList="font-benton-book header"
                                                text={item.description44}
                                            />
                                        )}
                                    </div>

                                    <div>
                                        {item.title5 && (
                                            <p className="-descBlack font-benton-bold  margin">
                                                {item.title5}
                                            </p>
                                        )}
                                        {item.description5 && (
                                            <Desc
                                                classList="font-benton-book header"
                                                text={item.description5}
                                            />
                                        )}
                                    </div>

                                    <div>
                                        {item.title6 && (
                                            <p className="-descBlack font-benton-bold  margin">
                                                {item.title6}
                                            </p>
                                        )}
                                        {item.description6 && (
                                            <Desc
                                                classList="font-benton-book header"
                                                text={item.description6}
                                            />
                                        )}
                                        {item.title61 && (
                                            <p className="-descBlack font-benton-bold  margin">
                                                {item.title61}
                                            </p>
                                        )}
                                        {item.list61 &&
                                            item.list61.map((i0, idx) => {
                                                return (
                                                    <div key={idx}>
                                                        <DescLabel
                                                            classList="my-1 py-2 font-benton-book header"
                                                            text={
                                                                i0.description
                                                            }
                                                            label={i0.label}
                                                        />
                                                        {i0.list1 && (
                                                            <ul className="blog-list-circle header">
                                                                {i0.list1.map(
                                                                    (
                                                                        i1,
                                                                        idx1
                                                                    ) => {
                                                                        return (
                                                                            <li
                                                                                key={
                                                                                    idx1
                                                                                }
                                                                            >
                                                                                <p className="-descBlack mb-1 font-benton-book header">
                                                                                    {
                                                                                        i1
                                                                                    }
                                                                                </p>
                                                                            </li>
                                                                        );
                                                                    }
                                                                )}
                                                            </ul>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        {item.title62 && (
                                            <p className="-descBlack font-benton-bold  margin">
                                                {item.title62}
                                            </p>
                                        )}
                                        {item.list62 &&
                                            item.list62.map((i0, idx) => {
                                                return (
                                                    <div key={idx}>
                                                        <DescLabel
                                                            classList="my-1 py-2 font-benton-book header"
                                                            text={
                                                                i0.description
                                                            }
                                                            label={i0.label}
                                                        />
                                                        {i0.list1 && (
                                                            <ul className="blog-list-circle header">
                                                                {i0.list1.map(
                                                                    (
                                                                        i1,
                                                                        idx1
                                                                    ) => {
                                                                        return (
                                                                            <li
                                                                                key={
                                                                                    idx1
                                                                                }
                                                                            >
                                                                                <p className="-descBlack mb-1 font-benton-book header">
                                                                                    {
                                                                                        i1
                                                                                    }
                                                                                </p>
                                                                            </li>
                                                                        );
                                                                    }
                                                                )}
                                                            </ul>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                    </div>
                                    <div>
                                        {item.title7 && (
                                            <p className="-descBlack font-benton-bold  margin">
                                                {item.title7}
                                            </p>
                                        )}
                                        {item.description7 && (
                                            <Desc
                                                classList="font-benton-book header"
                                                text={item.description7}
                                            />
                                        )}
                                    </div>
                                    <div>
                                        {item.title8 && (
                                            <p className="-descBlack font-benton-bold  margin">
                                                {item.title8}
                                            </p>
                                        )}
                                        {item.description8 && (
                                            <Desc
                                                classList="font-benton-book header"
                                                text={item.description8}
                                            />
                                        )}
                                    </div>

                                    <div>
                                        {item.title9 && (
                                            <p className="-descBlack font-benton-bold  margin">
                                                {item.title9}
                                            </p>
                                        )}
                                        {item.list9 &&
                                            item.list9.map((i0, idx) => {
                                                return (
                                                    <div key={idx}>
                                                        <DescLabel
                                                            classList="my-1 py-2 font-benton-book header"
                                                            text={
                                                                i0.description
                                                            }
                                                            label={i0.label}
                                                        />
                                                        {i0.list1 && (
                                                            <ul className="blog-list-circle header">
                                                                {i0.list1.map(
                                                                    (
                                                                        i1,
                                                                        idx1
                                                                    ) => {
                                                                        return (
                                                                            <li
                                                                                key={
                                                                                    idx1
                                                                                }
                                                                            >
                                                                                <p className="-descBlack mb-1 font-benton-book header">
                                                                                    {
                                                                                        i1
                                                                                    }
                                                                                </p>
                                                                            </li>
                                                                        );
                                                                    }
                                                                )}
                                                            </ul>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                    </div>

                                    <div>
                                        {item.title10 && (
                                            <p className="-descBlack font-benton-bold  margin">
                                                {item.title10}
                                            </p>
                                        )}
                                        {item.list10 &&
                                            item.list10.map((i0, idx) => {
                                                return (
                                                    <div key={idx}>
                                                        <DescLabel
                                                            classList="my-1 py-2 font-benton-book header"
                                                            text={
                                                                i0.description
                                                            }
                                                            label={i0.label}
                                                        />
                                                        {i0.list1 && (
                                                            <ul className="blog-list-circle header">
                                                                {i0.list1.map(
                                                                    (
                                                                        i1,
                                                                        idx1
                                                                    ) => {
                                                                        return (
                                                                            <li
                                                                                key={
                                                                                    idx1
                                                                                }
                                                                            >
                                                                                <p className="-descBlack mb-1 font-benton-book header">
                                                                                    {
                                                                                        i1
                                                                                    }
                                                                                </p>
                                                                            </li>
                                                                        );
                                                                    }
                                                                )}
                                                            </ul>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                    </div>

                                    <div>
                                        {item.title11 && (
                                            <p className="-descBlack font-benton-bold  margin">
                                                {item.title11}
                                            </p>
                                        )}
                                        {item.list11 &&
                                            item.list11.map((i0, idx) => {
                                                return (
                                                    <div key={idx}>
                                                        <DescLabel
                                                            classList="my-1 py-2 font-benton-book header "
                                                            text={
                                                                i0.description
                                                            }
                                                            label={i0.label}
                                                        />
                                                        {i0.list && (
                                                            <ul className="blog-list-circle  header">
                                                                {i0.list.map(
                                                                    (
                                                                        i1,
                                                                        idx1
                                                                    ) => {
                                                                        return (
                                                                            <li
                                                                                key={
                                                                                    idx1
                                                                                }
                                                                            >
                                                                                <p className="font-benton-book header">
                                                                                    {
                                                                                        i1
                                                                                    }
                                                                                </p>
                                                                            </li>
                                                                        );
                                                                    }
                                                                )}
                                                            </ul>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        {item.title111 && (
                                            <p className="-descBlack font-benton-bold  margin">
                                                {item.title111}
                                            </p>
                                        )}
                                        {item.list111 &&
                                            item.list111.map((i0, idx) => {
                                                return (
                                                    <div key={idx}>
                                                        <DescLabel
                                                            classList="my-1 py-2 font-benton-book header"
                                                            text={
                                                                i0.description
                                                            }
                                                            label={i0.label}
                                                        />
                                                        {i0.list1 && (
                                                            <ul className="blog-list-circle header">
                                                                {i0.list1.map(
                                                                    (
                                                                        i1,
                                                                        idx1
                                                                    ) => {
                                                                        return (
                                                                            <li
                                                                                key={
                                                                                    idx1
                                                                                }
                                                                            >
                                                                                <p className="-descBlack mb-1 font-benton-book header">
                                                                                    {
                                                                                        i1
                                                                                    }
                                                                                </p>
                                                                            </li>
                                                                        );
                                                                    }
                                                                )}
                                                            </ul>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        {item.title112 && (
                                            <p className="-descBlack font-benton-bold  margin">
                                                {item.title112}
                                            </p>
                                        )}
                                        {item.description112 && (
                                            <Desc
                                                classList="font-benton-book header"
                                                text={item.description112}
                                            />
                                        )}
                                        {item.title113 && (
                                            <p className="-descBlack font-benton-bold  margin">
                                                {item.title113}
                                            </p>
                                        )}
                                        {item.description1131 && (
                                            <Desc
                                                classList="font-benton-book header"
                                                text={item.description1131}
                                            />
                                        )}
                                        {item.description1132 && (
                                            <Desc
                                                classList="font-benton-book header"
                                                text={item.description1132}
                                            />
                                        )}
                                    </div>

                                    <div>
                                        {item.title12 && (
                                            <p className="-descBlack font-benton-bold  margin">
                                                {item.title12}
                                            </p>
                                        )}
                                        {item.description121 && (
                                            <Desc
                                                classList="font-benton-book header"
                                                text={item.description121}
                                            />
                                        )}
                                        {item.description122 && (
                                            <Desc
                                                classList="font-benton-book header"
                                                text={item.description122}
                                            />
                                        )}
                                        {item.description123 && (
                                            <Desc
                                                classList="font-benton-book header"
                                                text={item.description123}
                                            />
                                        )}
                                    </div>

                                    <div>
                                        {item.title13 && (
                                            <p className="-descBlack font-benton-bold  margin">
                                                {item.title13}
                                            </p>
                                        )}
                                        <div className="imagepadding">
                                            <img
                                                src={item.image3}
                                                alt={item.image3Alt}
                                                className="image"
                                            />
                                        </div>
                                        {item.description13 && (
                                            <Desc
                                                classList="font-benton-book header"
                                                text={item.description13}
                                            />
                                        )}
                                    </div>

                                    <div>
                                        {item.title14 && (
                                            <p className="-descBlack font-benton-bold  margin">
                                                {item.title14}
                                            </p>
                                        )}
                                        {item.description141 && (
                                            <Desc
                                                classList="font-benton-book header"
                                                text={item.description141}
                                            />
                                        )}
                                        {item.description142 && (
                                            <Desc
                                                classList="font-benton-book header"
                                                text={item.description142}
                                            />
                                        )}
                                        {item.description143 && (
                                            <Desc
                                                classList="font-benton-book header"
                                                text={item.description143}
                                            />
                                        )}
                                    </div>

                                    <div>
                                        {item.title15 && (
                                            <p className="-descBlack font-benton-bold  margin">
                                                {item.title15}
                                            </p>
                                        )}
                                        {item.description15 && (
                                            <Desc
                                                classList="font-benton-book header"
                                                text={item.description15}
                                            />
                                        )}
                                    </div>
                                    <section className="shareLinkBlock -only-mobile ">
                                        <ShareButton shareUrl="https://modernspaaces.com/blogs/choose-between-green-traditional-energy-efficient-buildings" />
                                    </section>
                                </Col>
                                <Col
                                    xs={12}
                                    md={4}
                                    lg={4}
                                    className="my-0 py-0 -aside-block"
                                >
                                    {/* <Fade>
                  <div className="-blog-author">
                    <p className="--label">Written By</p>
                    <img
                      src={item.authorImage}
                      alt={item.authorName}
                      className="-authorImage"
                    />

                    <h2 className="my-3 -authorName">{item.authorName}</h2>
                    <h3 className="my-1 -department">{item.department}</h3>

                    <AnchorGradientLinkAhref
                      href={`tel:${item.contactNumber}`}
                      classList="-btn-link mt-4 d-block"
                      text="Ask expert"
                      // text={"Read More"}
                      target="_blank"
                    />
                  </div>
                </Fade> */}
                                </Col>
                            </Row>
                        );
                    })}
                    <Row className="simpleShadow my-3 py-3 justify-content-around">
                        <Col
                            xs={12}
                            md={11}
                            lg={9}
                            className="pb-4 pr-md-5 p-2"
                        >
                            <div
                                className="shareLinkBlock1 d-flex flex-row p-4 "
                                style={{
                                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                                    gap: "1rem",
                                    borderRadius: "1rem",
                                }}
                            >
                                <ShareButton shareUrl="https://modernspaaces.com/blogs/choose-between-green-traditional-energy-efficient-buildings" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}
