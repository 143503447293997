import { Col, Container, Row } from "react-bootstrap";
import { Desc, DescLabel } from "../../../Global/Typography";
import "../../BlogOne/Banner/Banner.scss";
import { Helmet } from "react-helmet-async";

import BlogTwoData from "../../../../db/BlogTwoData";
import BannerNew from "../../BannerNew";
import ShareButton from "../../Share/ShareButton";

export default function Banner({ inView }) {
    const currentPage =
        "Everything you need to know before buying your first home";
    return (
        <>
        <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>
          Things to consider before buying your first home? | Modern Spaaces


        </title>
        <meta
          name="description"
          content="Gated communities come with their own perks that make up for a better lifestyle and comfortable living. Here is a list of  things to consider before buying your first home. So that it  goes a long way in caring for us and our family members. 

"
        />
        
      </Helmet>
        <section className={`section  ${inView ? "inView" : "outView"}`}>
            {/* <Container className="">
        <Row className="align-items-center">
          <Col xs={12} md={12} lg={12} className="py-2">
            <BreadcrumbBlogCard item={currentPage} />
          </Col>
        </Row>
      </Container> */}
            <BannerNew
                image={BlogTwoData[0].image}
                title={BlogTwoData[0].title}
                publishedOn={BlogTwoData[0].publishedOn}
                time={BlogTwoData[0].time}
            />
            <Container className="blog-detail-container">
                {BlogTwoData.map((item, idx) => {
                    return (
                        <Row key={idx} className="justify-content-around">
                            {/* {item.image && (
                <Col xs={12} md={12} lg={12} className="pb-4">
                  <img
                    src={item.image}
                    alt={item.title}
                    className="-blog-banner"
                  />
                </Col>
              )} */}
                            <Col
                                xs={12}
                                md={11}
                                lg={9}
                                className="pb-4 pr-md-5"
                            >
                                <div className="blog-header">
                                    {/* {item.title && (
                    <Heading classList="my-3" variant="h1" text={item.title} />
                  )}
                  {item.publishedOn && (
                    <Desc
                      classList=""
                      text={`Published on ${item.publishedOn}`}
                    />
                  )} */}
                                    {item.description && (
                                        <Desc
                                            classList="font-benton-book"
                                            text={item.description}
                                        />
                                    )}
                                    {item.lightDescription && (
                                        <Desc
                                            classList="font-benton-book"
                                            text={item.lightDescription}
                                        />
                                    )}
                                </div>

                                <section className="shareLinkBlock -only-mobile ">
                                    <ShareButton shareUrl="https://modernspaaces.com/blogs/things-to-consider-buying-your-first-home" />
                                </section>

                                <div className="blog-content">
                                    {item.lightDescriptionList &&
                                        item.lightDescriptionList.map(
                                            (i0, idx) => {
                                                return (
                                                    <div key={idx}>
                                                        <DescLabel
                                                            classList="my-1 py-2 font-benton-book"
                                                            text={
                                                                i0.description
                                                            }
                                                            label={i0.label}
                                                        />
                                                        {i0.list && (
                                                            <ul className="blog-list-circle">
                                                                {i0.list.map(
                                                                    (
                                                                        i1,
                                                                        idx1
                                                                    ) => {
                                                                        return (
                                                                            <li
                                                                                key={
                                                                                    idx1
                                                                                }
                                                                            >
                                                                                <p className="-descBlack mb-1 font-benton-book">
                                                                                    {
                                                                                        i1
                                                                                    }
                                                                                </p>
                                                                            </li>
                                                                        );
                                                                    }
                                                                )}
                                                            </ul>
                                                        )}
                                                    </div>
                                                );
                                            }
                                        )}
                                </div>

                                {item.paragraph && (
                                    <p className="-descBlack font-benton-book">
                                        {item.paragraph}
                                    </p>
                                )}
                                {item.list && (
                                    <div className="blog-content">
                                        {item.list.map((i, idx) => {
                                            return (
                                                <DescLabel
                                                    classList="my-1 py-2 font-benton-book"
                                                    text={i.description}
                                                    label={i.label}
                                                />
                                            );
                                        })}
                                    </div>
                                )}
                                {item.imageOther && (
                                    <img
                                        src={item.imageOther}
                                        alt={item.title}
                                        className="-imageOther "
                                    />
                                )}
                                {item.ClosingThoughts && (
                                    <DescLabel
                                        classList="my-1 py-2 font-benton-book"
                                        label={item.ClosingThoughts}
                                    />
                                )}
                                <div className="blog-footer">
                                    <p className="-descBlack font-benton-book">
                                        {item.noteStart && (
                                            <span>{item.noteStart}</span>
                                        )}
                                        {item.link && (
                                            <strong className="px-2">
                                                {item.link}
                                            </strong>
                                        )}
                                        {item.noteEnd && (
                                            <span>{item.noteEnd}</span>
                                        )}
                                    </p>
                                </div>
                                {item.noteStartItems && (
                                    <ul className="blog-list-circle">
                                        {item.noteStartItems.map(
                                            (i9, idx11) => {
                                                return (
                                                    <li key={idx11}>
                                                        <p className="-descBlack mb-1 font-benton-book">
                                                            {i9}
                                                        </p>
                                                    </li>
                                                );
                                            }
                                        )}
                                    </ul>
                                )}
                            </Col>
                            <Col
                                xs={12}
                                md={4}
                                lg={4}
                                className="my-0 py-0 -aside-block"
                            >
                                {/* <Fade>
                  <div className="-blog-author">
                    <p className="--label">Written By</p>
                    <img
                      src={item.authorImage}
                      alt={item.authorName}
                      className="-authorImage"
                    />

                    <h2 className="my-3 -authorName">{item.authorName}</h2>
                    <h3 className="my-1 -department">{item.department}</h3>

                    <AnchorGradientLinkAhref
                      href={`tel:${item.contactNumber}`}
                      classList="-btn-link mt-4 d-block"
                      text="Ask expert"
                      // text={"Read More"}
                      target="_blank"
                    />
                  </div>
                </Fade> */}
                            </Col>
                        </Row>
                    );
                })}
                <Row className="simpleShadow my-3 py-3 justify-content-around">
                    <Col xs={12} md={11} lg={9} className="pb-4 pr-md-5 p-2">
                        <div
                            className="shareLinkBlock1 d-flex flex-row p-4 "
                            style={{
                                backgroundColor: "rgba(0, 0, 0, 0.04)",
                                gap: "1rem",
                                borderRadius: "1rem",
                            }}
                        >
                            <ShareButton shareUrl="https://modernspaaces.com/blogs/things-to-consider-buying-your-first-home" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        </>
    );
}
