import { useState } from "react";
import { InView } from "react-intersection-observer";
import { AllResources } from "../components/BlogList";
import FeaturedBlog from "../components/BlogList/FeaturedBlog";
import { ContactUs } from "../components/SoulTree";
import { Footer, FooterLogo } from "../components/index";
import { Helmet } from "react-helmet-async";

export default function BlogList() {
  const [inViewFeaturedBlog, setInViewFeaturedBlog] = useState(false);
  const [inViewAllResources, setInViewAllResources] = useState(false);
  const [inViewFooter, setInViewFooter] = useState(false);
  const [inViewContactUs, setInViewContactUs] = useState(false);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Real Estate Blogs | Modern Spaaces</title>
        <meta
          name="description"
          content="Real Estate blogs on the Modern Spaaces website has informations needed by home buyers before, during and after investing in a home.
"
        />
      </Helmet>
      <InView
        className="bgPattern"
        onChange={setInViewFeaturedBlog}
        triggerOnce={true}
      >
        <FeaturedBlog inView={inViewFeaturedBlog}></FeaturedBlog>
      </InView>
      <InView
        className="bgPattern"
        onChange={setInViewAllResources}
        triggerOnce={true}
      >
        <AllResources inView={inViewAllResources}></AllResources>
      </InView>

      <InView
        className="bgPattern bgPattern--HrGrassPattern"
        onChange={setInViewContactUs}
        triggerOnce={true}
      >
        <ContactUs inView={inViewContactUs}></ContactUs>
      </InView>
      <FooterLogo />
      <InView
        className="bgPattern "
        onChange={setInViewFooter}
        triggerOnce={true}
      >
        <Footer inView={inViewFooter}></Footer>
      </InView>
    </>
  );
}
