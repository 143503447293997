import "./Share.scss";
import ShareButton from "./ShareButton";

// import StickyBox from "react-sticky-box";

export default function ShareBlogThree({ inView }) {
  return (
    <section className="shareLinkBlock -only-desktop ">
      <ShareButton
        shareUrl={
          "https://modernspaaces.com/blogs/buying-your-house-in-neighborhood"
        }
      />
    </section>
  );
}
