import { Col, Container, Row } from "react-bootstrap";
import { Desc, Heading } from "../../Global/Typography";
import GrassPattern from "../GrassPattern";
// import SoulTreeLogo from "../../../images/SoulTree/SoulTreeLogo.svg";
// import logo from "../../../images/Engrace/Banner/EngraceLogo.webp";
// import BannerImage from "../../../images/Engrace/Banner/EngraceBanner.webp";
// import BannerImage from "../../../images/SoulTree/Banner.webp";
import "./Banner.scss";

const logo = "/images/Engrace/Banner/engrace-logo.webp";
const BannerImage = "/images/Engrace/Banner/engrace-project-detail-banner.webp";

export default function Banner({ inView }) {
    return (
        <section
            className={`section overflow-hidden pb-0 ${
                inView ? "inView" : "outView"
            }`}
        >
            <Container>
                <Row className="mt-5">
                    <Col xs={11} md={10} lg={7} className="pr-5 mt-5 mt-md-0">
                        <img
                            src={logo}
                            alt="Engrace "
                            className="EngraceLogo mt-n4"
                            style={{ marginLeft: "-14px" }}
                        />
                        <Heading
                            classList="mb-4 mt-n3 pr-5 pr-md-3"
                            variant="h1"
                            text="HOMES WITH EXTRA SPACE"
                        />
                        <Desc
                            classList="max-width-380"
                            text="Uniquely designed homes setting a new standard in spacious living."
                        />
                    </Col>
                </Row>
            </Container>
            <Row className="d-block">
                <div className="md-container-wrap">
                    <img
                        src={BannerImage}
                        alt="Engrace by Modern Spaaces"
                        className="-banner-image-engrace pb-0"
                    />
                </div>
            </Row>
            <GrassPattern />
        </section>
    );
}
