import { Row } from "react-bootstrap";
import { Desc, Heading } from "../../Global/Typography";
import PrevArrow from "../../../images/Common/PrevArrow.svg";
import NextArrow from "../../../images/Common/NextArrow.svg";
import "./SmartGreenMinimal.scss";

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import SMTPTransport from "nodemailer/lib/smtp-transport";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const SmartWaterMeter = "/images/EngraceVista/smartgreenminimal/vista-smart-water-meter-banner.png";
const crossVentilation = "/images/EngraceVista/smartgreenminimal/vista-cross-ventilation-banner.webp";
const waterConservation = "/images/EngraceVista/smartgreenminimal/vista-water-conservation-banner.webp";
const NaturalLight = "/images/EngraceVista/smartgreenminimal/vista-natural-light-banner.webp";

export default function SmartGreenMinimal({ inView }) {

    const SmartGreenMinimalData = [
        {
            title: "Smart water meter",
            desc: "Discover the innovative Smart Water Meters: designed to monitor, manage, and minimize your water usage. These advanced meters come with remote tap control, leakage detection, and smart analytics for a comprehensive approach to water conservation.",
            image: SmartWaterMeter,
            imgAlt: "Smart Water Meters",
        },
        {
            title: "Cross ventilation",
            desc: "Our homes are thoughtfully designed to enhance cross ventilation, significantly reducing the need for artificial cooling and lowering energy bills.",
            image: crossVentilation,
            imgAlt: "Cross Ventilation Villas",
        },

        {
            title: "Water Conservation",
            desc: "Dual piping & dual flush system for sanitary. All landscapes maintained through recycled water, water efficient fixtures, rain water harvesting and groundwater recharge.",
            image: waterConservation,
            imgAlt: "Water Conservation",
        },
        {
            title: "Natural Light",
            desc: "Our rooms,terraces & balconies are designed to take advantage of natural light.The homes are compact and smart with ample light and fresh air.",
            image: NaturalLight,
            imgAlt: "Natural Light",
        },
    ];
    return (
        <section
            className={`section bg-white SmartGreenMinimal ${inView ? "inView" : "outView"
                }
    `}
        >
            <div className="SmartGreenMinimal__Container">
                <div className="SmartGreenMinimal__Row">
                    <div className="SmartGreenMinimal__Content">
                        <Heading
                            variant="h1"
                            classList="SmartGreenMinimal__title"
                            text="Smart, Sustainable, Efficient"
                        />
                        <Desc
                            classList="SmartGreenMinimal__desc my-3 "
                            text="Explore Engrace Vista"
                        />
                        {/* <AnchorButtonIcon
              href="#"
              classList="SmartGreenMinimal__btn mt-3"
              text="Download Brochure"
              icon={false}
            /> */}
                    </div>
                    <div className="SmartGreenMinimal__Slider">
                        <Swiper
                            className="SmartGreenMinimalSlider"
                            id="SmartGreenMinimalSlider"
                            tag="section"
                            wrapperTag="ul"
                            loop={true}
                            spaceBetween={10}
                            keyboard={{
                                enabled: true,
                            }}
                            navigation={{
                                prevEl: ".ArrowButtonBlock__ArrowPrevSmart",
                                nextEl: ".ArrowButtonBlock__ArrowNextSmart",
                            }}
                            onSwiper={(swiper) => console.log(swiper)}
                            // onSlideChange={(swiper) =>
                            //   console.log("slide index changed to: ", swiper.activeIndex)
                            // }
                            onInit={(swiper) =>
                                console.log("Swiper initialized", swiper)
                            }
                            breakpoints={{
                                300: {
                                    slidesPerView: 1.2,
                                },

                                768: {
                                    slidesPerView: 3,
                                    spaceBetween: 3,
                                },
                                1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 3,
                                },
                                1300: {
                                    slidesPerView: 3,
                                    spaceBetween: 3,
                                },
                            }}
                        >
                            {SmartGreenMinimalData.map(
                                ({ title, desc, image, imgAlt }, index) => (
                                    <SwiperSlide tag="li" key={index}>
                                        <div className="-image-block">
                                            <img
                                                src={image}
                                                className="SmartGreenMinimal__image"
                                                alt={imgAlt}
                                            />
                                            <div className="-content-block">
                                                <Heading
                                                    variant="h5"
                                                    classList="-title"
                                                    text={title}
                                                />
                                                <Desc
                                                    classList="-desc mt-3 mb-0"
                                                    text={desc}
                                                />
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )
                            )}
                        </Swiper>
                        <Row className="ArrowButtons">
                            <div className="ArrowButtonBlock">
                                <div className="ArrowButtonBlock__Arrow ArrowButtonBlock__ArrowPrevSmart">
                                    <img
                                        src={PrevArrow}
                                        className="-image"
                                        alt="SmartGreenMinimal"
                                    />
                                </div>
                                <div className="ArrowButtonBlock__Arrow ArrowButtonBlock__ArrowNextSmart">
                                    <img
                                        src={NextArrow}
                                        className="-image"
                                        alt="SmartGreenMinimal"
                                    />
                                </div>
                            </div>
                        </Row>
                    </div>
                </div>
            </div>
        </section>
    );
}
