// import TextMaskImage from "../../../images/SoulTree/ClubconnectTextMask.svg";
import "./ClubCo.scss";
import { Col, Container, Row } from "react-bootstrap";

import Clubs from "./Clubs";
import { Heading } from "../../Global/Typography";
export default function ClubConnect() {
    return (
        <>
            <Container className="py-4 mt-5 mb-3">
                <Row className="">
                    <Col xs={12} md={6} lg={6} xl={12} className=" text-center">
                        <Heading variant="h1" classList="" text="Clubhouse" />
                        <Heading
                            variant="h5"
                            classList=""
                            text="A fabulously fun place to hangout, workout or simply relax and unwind.
"
                        />
                    </Col>
                </Row>
            </Container>

            {/* <div className="bg-linearGradient1">
        <div className="ClubconnectScroll">
          <div className="ExpTextBackground">
            <img src={TextMaskImage} alt="mask" />
          </div>
        </div>
      </div> */}
            <Clubs />
        </>
    );
}
