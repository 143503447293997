import { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "./StoryOfGrowth.scss";
import { StoryStats } from "./constants";

const StoryOfGrowth = ({ inView }) => {
    const [index, setIndex] = useState(0);

    const handleScroll = () => {
        setIndex((prev) => prev + 1);
    };
    return (
        <>
            <div id="about-us"></div>
            <section
                className={`section section-StoryOfGrowth  bg-white ${
                    inView ? "inView" : "outView"
                }
`}
            >
                <div className="StoryOfGrowth">
                    <div className="content section-1">
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                            }}
                            className="px-0 pb-5 pb-sm-0"
                        >
                            <div className="StoryOfGrowth__heading">
                                <span>Story of Growth</span>
                            </div>

                            <div className="mt-3 StoryOfGrowth__subheading">
                                “ We are a collection of people & We are defined
                                by our people ”
                            </div>
                            <div className="mt-2 StoryOfGrowth__desc">
                                Our story is never about founders or partners.
                                We wanted to create a platform for people to
                                join in and be successful. <br />
                                We started in November 2019, but we have built a
                                good story to talk about. We've learned and
                                improved every day, taking each step forward to
                                become better.
                            </div>
                        </div>
                    </div>
                    <div className="StoryOfGrowth__know-more-container">
                        <div className="StoryOfGrowth__stats-container ">
                            {StoryStats.map((statsItem) => (
                                <div className="stat-card">
                                    <div className="stat-stat stat-card-item">
                                        {statsItem.stat}
                                    </div>
                                    <div className="stat-unit stat-card-item">
                                        {statsItem.unit}
                                    </div>
                                    <div className="stat-info stat-card-item">
                                        {statsItem.info}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="StoryOfGrowth__stats-container-mobile  d-sm-none ">
                            <Carousel
                                swipeable
                                autoPlay={false}
                                interval={1e11}
                            >
                                {StoryStats.map((statsItem) => (
                                    <div className="stat-card">
                                        <div className="stat-stat stat-card-item">
                                            {statsItem.stat}
                                        </div>
                                        <div className="stat-unit stat-card-item">
                                            {statsItem.unit}
                                        </div>
                                        <div className="stat-info stat-card-item">
                                            {statsItem.info}
                                        </div>
                                    </div>
                                ))}
                            </Carousel>
                        </div>
                        <div className="content section-2">
                            <div className="know-more-content">
                                <h3>
                                    <span className="d-none d-md-inline-block">
                                        We're now &nbsp;
                                    </span>
                                    <span>Modern Spaaces 2.0</span>
                                    <span className="d-inline-block d-md-none">
                                        !
                                    </span>
                                </h3>

                                <h5 className="mt-sm-2 ">
                                    Our learnings have been plenty. Our team is
                                    now stronger and thrives on continuous
                                    improvement. <br />
                                    <br className="d-flex d-sm-none" />
                                    We are focusing on understanding, planning,
                                    designing and constructing manageable human
                                    homes backed{" "}
                                    <br className="d-none d-sm-flex" /> by
                                    extensive research data. Leveraging
                                    technology to improve customer experience &
                                    relationship.
                                </h5>
                                {/* <Button variant="primary">Know more</Button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default StoryOfGrowth;
