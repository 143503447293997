import { Button, Col, Form, Row } from "react-bootstrap";
import "./contact-form.scss";
// import { Link } from "react-router-dom";
import moment from "moment";
import { useState } from "react";
import DatePicker from "react-datepicker";
// useRef
import InputGroup from "react-bootstrap/InputGroup";

function php_email_form_submit(thisForm, action, formData) {
  fetch(action, {
    method: "POST",
    body: formData,
    headers: { "X-Requested-With": "XMLHttpRequest" },
  })
    .then((response) => {
      if (response.ok) {
        return response.text();
      } else {
        throw new Error(
          `${response.status} ${response.statusText} ${response.url}`
        );
      }
    })
    .then((data) => {
      if (data.trim() === '"OK"') {
        console.log("message sent");
        thisForm.reset();
        window.location.href = "/thank-you-contact";
      } else {
        throw new Error(
          data
            ? data
            : "Form submission failed and no error message returned from: " +
              action
        );
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export default function ContactForm({ inView }) {
  const initialFormData = Object.freeze({
    name: "",
    phone: "",
    email: "",
    type: "SoulTree",
    message: "",
  });
  const [formData, updateFormData] = useState(initialFormData);
  const [startDate, setStartDate] = useState(new Date());
  const [validated, setValidated] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(e);

    setValidated(true);
    const form = e.currentTarget;
    if (form.checkValidity() === true) {
      let action = e.target.getAttribute("action");
      console.log("🚀 e.targetgetAttribute ", e.target.getAttribute("action"));
      if (!action) {
        console.log("The form action property is not set!", action);
        return;
      }
      /* e.target.querySelector('.loading').classList.add('d-block');
     e.target.querySelector('.error-message').classList.remove('d-block');
     e.target.querySelector('.sent-message').classList.remove('d-block');*/

      let formData = new FormData();
      formData.append("name", e.target[0].value);
      formData.append("email", e.target[1].value);
      formData.append("phone", e.target[2].value);
      formData.append("property", e.target[3].value);
      formData.append("date", e.target[4].value);
      formData.append("message", e.target[5].value);
      for (let [name, value] of formData) {
        console.log(`${name} = ${value}`); // key1 = value1, then key2 = value2
      }
      let message =
        formData.get("message") +
        " Customer wants to Visit On: " +
        formData.get("date");
      formData.set("message", message);
      php_email_form_submit(e.target, action, formData);

      // function myGreeting() {
      //   window.location.href = "/thank-you-contact";
      // }

      // setTimeout(myGreeting, 2000);
      // window.location.href = "/thank-you-contact";
      // window.location.href = "#/thank-you-contact";

      // function resolveAfter2Seconds() {
      //   return new Promise(resolve => {
      //     setTimeout(() => {
      //       resolve('resolved');
      //     }, 2000);
      //   });
      // }

      // async function asyncCall() {
      //   console.log('calling');
      //   const result = await resolveAfter2Seconds();
      //   window.location.href = "/thank-you-contact";
      //   // expected output: "resolved"
      // }

      // asyncCall();
    }
  };
  const handleChange = (e) => {
    updateFormData({
      ...formData,

      // Trimming any whitespace
      [e.target.name]: e.target.value.trim(),
    });
  };
  return (
    <section
      className={`section section-contact-form ${
        inView ? "inView" : "outView"
      }`}
    >
      <div className="content d-flex position-relative align-items-center">
        <div className="form-bg d-md-flex d-none"></div>
        <div className="form-container p-5 d-inline-block bg-white">
          <div>
            <div className="heading fc-black mb-2">
              We’d love to hear from you!
            </div>
            <p className="fc-black mb-4 d-inline-block msg-text fw-bold">
              Send us a message!
            </p>
            <Form
              action="https://mdrn.deta.dev/contactformbase"
              method="post"
              role="form"
              classname="php-email-form"
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
            >
              <Row className="my-0">
                <Form.Group
                  as={Col}
                  xs={12}
                  md={6}
                  controlId="validationCustom01"
                >
                  <Form.Label className="mb-3 mb-md-0">Name *</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="name"
                    // placeholder="Enter Name"
                    placeholder=""
                    defaultValue=""
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter your name.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  as={Col}
                  xs={12}
                  md={6}
                  controlId="validationCustom02"
                >
                  <Form.Label className="mb-3 mb-md-0">
                    Phone Number *
                  </Form.Label>
                  <InputGroup>
                    <InputGroup.Text id="PhoneNumber">+91</InputGroup.Text>
                    <Form.Control
                      required
                      type="tel"
                      pattern="[0-9]{10}"
                      name="phone"
                      // placeholder="Enter Phone Number"
                      placeholder=""
                      onChange={handleChange}
                      defaultValue=""
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter your number.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>

                <Form.Group
                  as={Col}
                  xs={12}
                  md={6}
                  controlId="validationCustom03"
                >
                  <Form.Label className="mb-3 mb-md-0">Email *</Form.Label>
                  <Form.Control
                    required
                    type="email"
                    name="email"
                    onChange={handleChange}
                    // placeholder="Enter Email"
                    placeholder=""
                    defaultValue=""
                  />

                  <Form.Control.Feedback type="invalid">
                    Please enter vaild email ID.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  as={Col}
                  xs={12}
                  // md={IsNotAPlan === false ? 12 : 6}
                  md={6}
                  controlId="validationCustom04"
                  className=""
                >
                  <Form.Label className="mb-2">Select Property </Form.Label>
                  <select name="property" required className="form-control">
                    <option value="">Select Property</option>
                    <option value="Engrace">Engrace</option>
                    <option value="SoulTree">SoulTree</option>
                    <option value="302West">Urbanest - 302 West</option>
                    <option value="Urbanest77EastPhase2">
                      Urbanest - 77 East Phase 2
                    </option>
                  </select>

                  <Form.Control.Feedback type="invalid">
                    Please Select Property.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  as={Col}
                  xs={12}
                  // md={IsNotAPlan === false ? 12 : 6}
                  md={6}
                  controlId="validationCustom05"
                  className=""
                >
                  <Form.Label className="mb-2">Pick Date </Form.Label>

                  <DatePicker
                    className="form-control"
                    minDate={moment().toDate()}
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    name="date"
                  />

                  <Form.Control.Feedback type="invalid">
                    Please pick the Date.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  as={Col}
                  xs={12}
                  md={6}
                  controlId="validationCustom05"
                  className=""
                >
                  <Form.Label className="mb-3 mb-md-0">
                    What would you like to discuss?
                  </Form.Label>
                  <Form.Control
                    className="mt-2"
                    // required
                    type="text"
                    name="message"
                    onChange={handleChange}
                    placeholder=""
                    defaultValue=""
                  />
                </Form.Group>
              </Row>

              <Button className="btn btn--submit" type="submit">
                Submit
              </Button>
              {/* <Row>
                <Col xs={12} md={6} lg={6}>
                  <div className="mb-4">
                    <label className="mb-2 fc-dark-4 d-block">Name*</label>
                    <input type="text" name="name" />
                  </div>
                </Col>

                <Col xs={12} md={6} lg={6}>
                  <div className="mb-4">
                    <label className="mb-2 fc-dark-4 d-block">Email ID*</label>
                    <input type="text" name="email" />
                  </div>
                </Col>

                <Col xs={12} md={6} lg={6}>
                  <div className="mb-4">
                    <label className="mb-2 fc-dark-4 d-block">
                      Phone Number*
                    </label>
                    <input type="text" name="phone" />
                  </div>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <div className="mb-4">
                    <label className="mb-2 fc-dark-4 d-block">
                      Select Property
                    </label>
                    <select className="" name="property">
                      <option value="">Select Property</option>
                      <option value="SoulTree">SoulTree</option>
                      <option value="302West">Urbanest - 302 West</option>
                      <option value="Urbanest77EastPhase2">
                        Urbanest - 77 East Phase 2
                      </option>
                    </select>
                  </div>
                </Col>

                <Col xs={12} md={6} lg={6}>
                  <div className="mb-4">
                    <label className="mb-2 fc-dark-4 d-block">Pick Date </label>
                    <DatePicker
                      minDate={moment().toDate()}
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      name="date"
                    />
                  </div>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <div className="mb-4 pt-3">
                    <label className="mb-2 fc-dark-4 d-block">
                      What would you like to discuss?
                    </label>
                    <input type="text" name="message" />
                  </div>
                </Col>
              </Row>
              <button className="app-btn--solid--primary mt-4" type="submit">
                <span className="app-btn-text">Submit</span>
              </button> */}
            </Form>
            {/* LINK - thank-you-contact */}
          </div>
          {/* ms-thank-you-block */}
          {/* <div className="my-4 my-md-5 ms-thank-you-block">
            <Fade bottom>
              <div className="heading fc-black mb-2">Thank You</div>
              <p className="fc-black my-4 summary mb-2">
                Thanks for getting in touch. We’ll be in contact soon!
              </p>
              <Link to="/">
                <button className="app-btn--solid--primary my-2">
                  <span className="app-btn-text">Back to Home</span>
                </button>
              </Link>
            </Fade>
          </div> */}
          {/* ms-thank-you-block */}
          <Row className="mt-5" xs={1} sm={1} md={2}>
            <Col className="pb-4">
              <p className="mb-2 menu-head fc-black fw-bold">Address</p>
              <address className="menu-item fc-black">
                73/2, Chambenahalli
                <br />
                Near Asset serene apartments,
                <br />
                Sarjapur Main Road,
                <br />
                Bangalore - 562125
              </address>
            </Col>
            <Col>
              <p className="mb-2 menu-head fc-black fw-bold">Contact</p>
              <p className="mb-1 menu-item fc-black">+91 9008 76 7070</p>
              <p className="mb-3 menu-item fc-black">
                <a href="mailto:info@modernspaaces.com">
                  info@modernspaaces.com
                </a>
              </p>
              <a
                target="_blank"
                href="https://www.google.com/maps/place/Modern+Spaaces/@12.8775205,77.7620395,17z/data=!3m1!4b1!4m5!3m4!1s0x3bae73049a057571:0x4c0462bc63b1246c!8m2!3d12.8775153!4d77.7642282"
                rel="noreferrer"
              >
                <button className="app-btn--hollow--dark px-4 py-2 app-btn--small">
                  <span className="app-btn-text">
                    <svg
                      width="12"
                      height="18"
                      viewBox="0 0 12 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5.5 14C5.5 14 10.5 7.55895 10.5 4.86957C10.5 2.18018 8.26142 0 5.5 0C2.73858 0 0.5 2.18018 0.5 4.86957C0.5 7.55895 5.5 14 5.5 14ZM5.5 8C7.15685 8 8.5 6.65685 8.5 5C8.5 3.34315 7.15685 2 5.5 2C3.84315 2 2.5 3.34315 2.5 5C2.5 6.65685 3.84315 8 5.5 8Z"
                        fill="black"
                      />
                    </svg>
                    <span className="ml-2">View on map</span>
                  </span>
                </button>
              </a>
            </Col>
          </Row>
        </div>
      </div>
    </section>
  );
}
