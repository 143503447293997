import React, { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ProjectCard from "../ProjectCard";
import "./index.scss";

const Projects = ({ projectsData, onNewLaunchProjectClick }) => {
    const [activeTab, setActiveTab] = useState("ongoing");
    return (
        <div className="projects-tabs">
            <Tabs
                defaultActiveKey={activeTab}
                onSelect={(tab) => setActiveTab(tab)}
                className=""
            >
                <Tab eventKey="ongoing" title="Ongoing Projects">
                    <div className="projects-grid">
                        {projectsData.ongoing.map((project) => (
                            //   <div>
                            <ProjectCard
                                onNewLaunchProjectClick={
                                    onNewLaunchProjectClick
                                }
                                projectData={project}
                                status="ONGOING"
                            ></ProjectCard>
                            //   </div>
                        ))}
                    </div>
                </Tab>
                <Tab eventKey="upcoming" title="Upcoming Projects">
                    <div className="projects-grid">
                        {projectsData.upcoming.map((project) => (
                            <ProjectCard
                                onNewLaunchProjectClick={
                                    onNewLaunchProjectClick
                                }
                                status="UPCOMING"
                                projectData={project}
                            ></ProjectCard>
                        ))}
                    </div>
                </Tab>
                <Tab eventKey="completed" title="Completed Projects">
                    <div className="projects-grid">
                        {projectsData.completed.map((project) => (
                            <ProjectCard
                                projectData={project}
                                status="COMPLETED"
                            ></ProjectCard>
                        ))}
                    </div>
                </Tab>
            </Tabs>
        </div>
    );
};

export default Projects;
