import { Container, Row, Col } from "react-bootstrap";
import { Desc, Heading } from "../../Global/Typography";
// AnchorButton,
import { useState } from "react";

import PrevArrow from "../../../images/Common/PrevArrow.svg";
import NextArrow from "../../../images/Common/NextArrow.svg";

// import SoultreeCourt from "../../../images/SoulTree/Court/SoultreeCourt.webp";
// import SunriseCourt from "../../../images/SoulTree/Court/SunriseCourt.webp";
// import TheTranquilCourt from "../../../images/SoulTree/Court/TheTranquilCourt.webp";
// import MultiPurposeCourt from "../../../images/SoulTree/Court/MultiPurposeCourt.webp";
// import TheRejuvenationCourt from "../../../images/SoulTree/Court/TheRejuvenationCourt.webp";
// import KidsPlayArea from "../../../images/SoulTree/ThereIsSomething/KidsPlayArea.webp";
import OutdoorSeating1 from "../../../images/SoulTree/ThereIsSomething/OutdoorSeating1.webp";

import engrace3 from "../../../images/Engrace/outdoorseating/engrace3.webp";
import well_lit from "../../../images/Engrace/outdoorseating/well_lit.webp";
import engrace4 from "../../../images/Engrace/outdoorseating/engrace4.webp";
import engrace5 from "../../../images/Engrace/outdoorseating/engrace5.webp";
import interaction from "../../../images/Engrace/outdoorseating/interaction.webp";
// import engrace6 from "../../../images/Engrace/outdoorseating/engrace6.webp";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "./OutdoorSeating.scss";
import DownloadBrochure from "../../Global/DownloadBrochure";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

export default function OutdoorSeating({ inView }) {
  const [show, setShow] = useState(false);
  const handleShow = (e) => {
    e.preventDefault();
    setShow(true);
  };
  console.log(
    "🚀 ~ file: OutdoorSeating.js ~ line 31 ~ handleShow ~ handleShow",
    handleShow
  );
  const handleClose = () => setShow(false);

  const OutdoorSeatingData = [
    {
      title: "There is a little something for each member of your family",
      desc: "Area designed to satisfy your outdoor needs and keep you motivated to work and socialize. With the various facilities available at your fingertips, you can enjoy a calm space to work or inspire yourself to break a sweat.",
      // button: "Download Clubhouse Brochure",
      button: "Download Amenities Brochure",
      imageList: [
        {
          image: engrace3,
          // label: "Soultree Court",
          title:"Open Floor Layout",
          desc:"Seamless perception of Space achieved by clear softit ceiling with no beams cutting through",
        },
        {
          image: engrace4,
          // label: "Kids Play Area",
          title: "Multiple Balconies",
          desc: " The design ofers Multiple Balconies of varying sizes for each unit, catering to defferent activities depending on your lifestyle needs.",
        },
        {
          image: interaction,
          // label: "Sports Area",
          title: "Interaction spaces",
          desc: "Multiple community interaction spaces that allow you to mingle and grow your social network.",
        },
        {
          image: engrace5,
          // label: "Sports Area",
          title: "Ample Landscaped Spaces",
          desc:" Major extend of driveways and parking have been restricted beneath the building blocks thus ensuring ample laondscaped/open spaces in the setback and buffer areas.",
        },
        {
          image: well_lit,
          // label: "Sports Area",
          title: "Efficient Lit & Ventilated Homes",
          desc: "Depending on the the function of the spaces, windows and opneing sof varying sizes have been planned, which contribute to making spaces brighter and any.",
        },
      ],
      image: OutdoorSeating1,
      label: "Outdoor seating",
    },
  ];

  return (
    <section
      className={`section bg-white ${inView ? "inView" : "outView"}
    `}
    >
      {OutdoorSeatingData.map((item, idx) => {
        return (
          <Container key={idx} className="OutdoorSeating OutdoorSeating--small">
            <div className="OutdoorSeating__Slider">
              <Swiper
                className="OutdoorSeatingSlider"
                id="OutdoorSeatingSlider"
                tag="section"
                wrapperTag="ul"
                spaceBetween={0}
                keyboard={{
                  enabled: true,
                }}
                speed={500}
                loop={true}
                autoplay={true}
                pagination={{
                  clickable: true,
                  el: ".swiper-pagination-OutdoorSeating",
                }}
                navigation={{
                  prevEl: ".ArrowButtonBlock__ArrowPrevWhite",
                  nextEl: ".ArrowButtonBlock__ArrowNextWhite",
                }}
                onSwiper={(swiper) => console.log(swiper)}
                // onSlideChange={(swiper) =>
                //   console.log("slide index changed to: ", swiper.activeIndex)
                // }
                onInit={(swiper) => console.log("Swiper initialized", swiper)}
                breakpoints={{
                  300: {
                    slidesPerView: 1,
                    spaceBetween: 30,
                  },
                }}
              >
                {item.imageList.map((item, index) => (
                  <SwiperSlide tag="li" key={index}>
                    <Row className="align-items-center">
                      <Col
                        xs={12}
                        md={6}
                        lg={6}
                        xl={5}
                        className="px-3 px-md-5"
                      >
                        <div className="imagePatch">
                          <img
                            src={item.image}
                            className="OutdoorSeating__image w-100 mb-0"
                            alt="OutdoorSeating"
                          />
                          <span className="-label">{item.title}</span>
                        </div>
                      </Col>
                      <Col
                        xs={12}
                        md={6}
                        lg={6}
                        xl={7}
                        className="pt-5 pt-md-0 px-4 px-md-5"
                      >
                        <Heading
                          variant="h1"
                          classList="OutdoorSeating__title"
                          text={item.title}
                        />
                        <Desc
                          classList="OutdoorSeating__desc mt-3 mb-5 pb-5"
                          text={item.desc}
                        />
                        {/* <AnchorButton
                          href="#"
                          classList="OutdoorSeating__btn my-3"
                          text={item.button}
                          onClick={handleShow}
                        /> */}
                      </Col>
                    </Row>
                  </SwiperSlide>
                ))}
              </Swiper>
              <Row className="ArrowButtons pl-md-3">
                <div className="swiper-pagination swiper-pagination-OutdoorSeating"></div>
                {/* <div className="swiper-pagination swiper-pagination-OutdoorSeating bg-trans"></div> */}
                <div className="ArrowButtonBlock">
                  <div className="ArrowButtonBlock__Arrow ArrowButtonBlock__ArrowPrevWhite">
                    <img src={PrevArrow} className="-image" alt="Blocks" />
                  </div>
                  <div className="ArrowButtonBlock__Arrow ArrowButtonBlock__ArrowNextWhite">
                    <img src={NextArrow} className="-image" alt="Blocks" />
                  </div>
                </div>
              </Row>
            </div>
          </Container>
        );
      })}

      {/* DownloadBrochure */}
      <DownloadBrochure
        title={"Download Brochure"}
        show={show}
        handleClose={handleClose}
      />
    </section>
  );
}
