import { Container, Row, Col } from "react-bootstrap";
import { Desc, Heading } from "../../Global/Typography";
// AnchorButton,
import { useState } from "react";

import PrevArrow from "../../../images/Common/PrevArrow.svg";
import NextArrow from "../../../images/Common/NextArrow.svg";

// import outdoorDining from "../../../images/Soulace/outdoorseating/image 13.webp";
// import solitudeNook from "../../../images/Soulace/outdoorseating/image 14.webp";
// import chitChatZone from "../../../images/Soulace/outdoorseating/image 15.webp";
// import silentWorkPod from "../../../images/Soulace/outdoorseating/image 16.webp";
// import seniorsCourt from "../../../images/Soulace/outdoorseating/image 17.webp";
// import meditationGarden from "../../../images/Soulace/outdoorseating/image 18.webp";
// import gym from "../../../images/Soulace/outdoorseating/image 19.webp";
// import produceGarden from "../../../images/Soulace/outdoorseating/image 20.webp";

import OutdoorSeating1 from "../../../images/SoulTree/ThereIsSomething/OutdoorSeating1.webp";

// import engrace6 from "../../../images/Engrace/outdoorseating/engrace6.webp";
import SwiperCore, {
    Navigation,
    Pagination,
    Scrollbar,
    A11y,
    Autoplay,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "./OutdoorSeating.scss";
import DownloadBrochure from "../../Global/DownloadBrochure";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);


const outdoorDining = "/images/Soulace/outdoorseating/soulace-outdoor-dining-banner.webp";
const solitudeNook ="/images/Soulace/outdoorseating/soulace-solitude-nook-banner.webp";
const chitChatZone = "/images/Soulace/outdoorseating/soulace-chit-chat-zone-banner.webp";
const silentWorkPod = "/images/Soulace/outdoorseating/soulace-silent-work-pod-banner.webp";
const seniorsCourt = "/images/Soulace/outdoorseating/soulace-seniors-court-banner.webp";
const meditationGarden = "/images/Soulace/outdoorseating/soulace-meditaion-garden-banner.webp";
const gym = "/images/Soulace/outdoorseating/soulace-gym-banner.webp";
const produceGarden = "/images/Soulace/outdoorseating/soulace-produce-garden-banner.webp";

export default function OutdoorSeating({ inView }) {
    const [show, setShow] = useState(false);
    const handleShow = (e) => {
        e.preventDefault();
        setShow(true);
    };
    // console.log(
    //   "🚀 ~ file: OutdoorSeating.js ~ line 31 ~ handleShow ~ handleShow",
    //   handleShow
    // );
    const handleClose = () => setShow(false);

    const OutdoorSeatingData = [
        {
            title: "There is a little something for each member of your family",
            desc: "Area designed to satisfy your outdoor needs and keep you motivated to work and socialize. With the various facilities available at your fingertips, you can enjoy a calm space to work or inspire yourself to break a sweat.",
            // button: "Download Clubhouse Brochure",
            button: "Download Amenities Brochure",
            imageList: [
                {
                    image: outdoorDining,
                    imgAlt:"Soulace - Outdoor Dining",
                    // label: "Soultree Court",
                    title: "Outdoor Dining",
                    desc: "Find the joy in dining outdoors amidst the starlit sky.",
                    position: "70%",
                },
                {
                    image: solitudeNook,
                    imgAlt:"Solitude Nook",
                    // label: "Kids Play Area",
                    title: "Solitude Nook",
                    desc: "Discover your personal retreat, designed for relaxation and personal reflection.",
                    position: "100%",
                },
                {
                    image: chitChatZone,
                    imgAlt:"Chit-Chat Zone",
                    // label: "Sports Area",
                    title: "Chit-Chat Zone",
                    desc: "Catch up on the latest news or simply exchange friendly hellos.",
                    position: "80%",
                },
                {
                    image: silentWorkPod,
                    imgAlt:"Silent Work Pod",
                    // label: "Sports Area",
                    title: "Silent Work Pod",
                    desc: "Slip into work mode with our distraction-free enclosed work pod.",
                    position: "80%",
                },
                {
                    image: seniorsCourt,
                    imgAlt:"Seniors Court for Social Spacing",
                    // label: "Sports Area",
                    title: "Seniors Court",
                    desc: "A comforting social space for seniors to thrive and forge connections.",
                    position: "90%",
                },
                {
                    image: meditationGarden,
                    imgAlt:"Meditation Garden",
                    // label: "Sports Area",
                    title: "Meditation Garden",
                    desc: "Rebalance your chakras in the tranquil setting of the meditation.",
                    position: "20%",
                },
                {
                    image: gym,
                    imgAlt:"Gym to boost your Fitness ",
                    // label: "Sports Area",
                    title: "Gym",
                    desc: "Our state-of-the-art gym designed to inspire and boost your fitness journey.",
                    position: "",
                },

                {
                    image: produceGarden,
                     imgAlt:"Produce Garden to harvest fresh fruits and vegetables",
                    // label: "Sports Area",
                    title: "Produce Garden",
                    desc: "Savor the bounty of seasonal fruits and vegetables harvested fresh from the produce garden.",
                    position: "",
                },
            ],
            image: OutdoorSeating1,
            label: "Outdoor seating",
        },
    ];

    return (
        <section
            className={`section bg-white ${inView ? "inView" : "outView"}
    `}
        >
            {OutdoorSeatingData.map((item, idx) => {
                return (
                    <Container
                        key={idx}
                        className="OutdoorSeating OutdoorSeating--small"
                    >
                        <div className="OutdoorSeating__Slider">
                            <Swiper
                                className="OutdoorSeatingSlider"
                                id="OutdoorSeatingSlider"
                                tag="section"
                                wrapperTag="ul"
                                spaceBetween={0}
                                keyboard={{
                                    enabled: true,
                                }}
                                speed={500}
                                loop={true}
                                autoplay={true}
                                pagination={{
                                    clickable: true,
                                    el: ".swiper-pagination-OutdoorSeating",
                                }}
                                navigation={{
                                    prevEl: ".ArrowButtonBlock__ArrowPrevWhite",
                                    nextEl: ".ArrowButtonBlock__ArrowNextWhite",
                                }}
                                onSwiper={(swiper) => console.log(swiper)}
                                // onSlideChange={(swiper) =>
                                //   console.log("slide index changed to: ", swiper.activeIndex)
                                // }
                                onInit={(swiper) =>
                                    console.log("Swiper initialized", swiper)
                                }
                                breakpoints={{
                                    300: {
                                        slidesPerView: 1,
                                        spaceBetween: 30,
                                    },
                                }}
                            >
                                {item.imageList.map((item, index) => (
                                    <SwiperSlide tag="li" key={index}>
                                        <Row className="align-items-center">
                                            <Col
                                                xs={12}
                                                md={6}
                                                lg={6}
                                                xl={5}
                                                className="px-3 px-md-5"
                                            >
                                                <div className="imagePatch">
                                                    <img
                                                        src={item.image}
                                                        className="OutdoorSeating__image w-100 mb-0"
                                                        alt={item.imgAlt}
                                                        style={{
                                                            objectPosition: `${item.position}`,
                                                        }}
                                                    />
                                                    {/* <span className="-label">
                                                        {item.title}
                                                    </span> */}
                                                </div>
                                            </Col>
                                            <Col
                                                xs={12}
                                                md={6}
                                                lg={6}
                                                xl={7}
                                                className="pt-5 pt-md-0 px-4 px-md-5"
                                            >
                                                <Heading
                                                    variant="h1"
                                                    classList="OutdoorSeating__title"
                                                    text={item.title}
                                                />
                                                <Desc
                                                    classList="OutdoorSeating__desc mt-3 mb-5 pb-5"
                                                    text={item.desc}
                                                />
                                                {/* <AnchorButton
                          href="#"
                          classList="OutdoorSeating__btn my-3"
                          text={item.button}
                          onClick={handleShow}
                        /> */}
                                            </Col>
                                        </Row>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                            <Row className="ArrowButtons pl-md-3">
                                <div className="swiper-pagination swiper-pagination-OutdoorSeating"></div>
                                {/* <div className="swiper-pagination swiper-pagination-OutdoorSeating bg-trans"></div> */}
                                <div className="ArrowButtonBlock">
                                    <div className="ArrowButtonBlock__Arrow ArrowButtonBlock__ArrowPrevWhite">
                                        <img
                                            src={PrevArrow}
                                            className="-image"
                                            alt="Blocks"
                                        />
                                    </div>
                                    <div className="ArrowButtonBlock__Arrow ArrowButtonBlock__ArrowNextWhite">
                                        <img
                                            src={NextArrow}
                                            className="-image"
                                            alt="Blocks"
                                        />
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </Container>
                );
            })}

            {/* DownloadBrochure */}
            <DownloadBrochure
                title={"Download Brochure"}
                show={show}
                handleClose={handleClose}
            />
        </section>
    );
}
