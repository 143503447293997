import { useState } from "react";
import { InView } from "react-intersection-observer";
import {
    Banner,
    // MasterPlan,
    // Blocks,
    MasterPlanDOwnloadItems,
    ProjectAtGlance,
} from "../components/Project302";
// import {
//   Campus,
//   ExcellentConnectivity,
//   GreenFeatures,
//   Schools,
//   TranquilCourt,
// } from "../components/Project77";
import { ContactUs } from "../components/SoulTree";
import { MasterPlan } from "../components/Urbanest";
import { Footer, FooterLogo } from "../components/index";
import TataBanner from "./TataBanner";

export default function Project302() {
    const [inViewBanner, setInViewBanner] = useState(false);
    const [inViewProjectAtGlance, setInViewProjectAtGlance] = useState(false);
    const [inViewMasterPlan, setInViewMasterPlan] = useState(false);
    // const [inViewBlocks, setInViewBlocks] = useState(false);

    // const [inViewCampus, setInViewCampus] = useState(false);
    // const [inViewExcellentConnectivity, setInViewExcellentConnectivity] =
    //   useState(false);
    // const [inViewGreenFeatures, setInViewGreenFeatures] = useState(false);
    // const [inViewSchools, setInViewSchools] = useState(false);
    // const [inViewTranquilCourt, setInViewTranquilCourt] = useState(false);

    const [inViewFooter, setInViewFooter] = useState(false);
    const [inViewContactUs, setInViewContactUs] = useState(false);
    return (
        <>
            <InView
                className="bgPattern"
                onChange={setInViewBanner}
                triggerOnce={true}
            >
                <Banner inView={inViewBanner}></Banner>
            </InView>

            <InView
                className="bgPattern"
                onChange={setInViewProjectAtGlance}
                triggerOnce={true}
            >
                <ProjectAtGlance
                    inView={inViewProjectAtGlance}
                ></ProjectAtGlance>
            </InView>

            <InView
                className="bgPattern"
                onChange={setInViewMasterPlan}
                triggerOnce={true}
            >
                <MasterPlan
                    inView={inViewMasterPlan}
                    isProject302={true}
                ></MasterPlan>
            </InView>

            <MasterPlanDOwnloadItems />

            <TataBanner />

            {/* <InView
        className="bgPattern"
        onChange={setInViewBlocks}
        triggerOnce={true}
      >
        <Blocks inView={inViewBlocks}></Blocks>
      </InView> 

      <InView
        className="bgPattern"
        onChange={setInViewGreenFeatures}
        triggerOnce={true}
      >
        <GreenFeatures inView={inViewGreenFeatures}></GreenFeatures>
      </InView>

      <InView
        className="bgPattern mt-md-5 pt-md-5"
        onChange={setInViewTranquilCourt}
        triggerOnce={true}
      >
        <TranquilCourt inView={inViewTranquilCourt}></TranquilCourt>
      </InView>
      
      <InView
        className="bgPattern bgPattern--PatternRight"
        onChange={setInViewCampus}
        triggerOnce={true}
      >
        <Campus inView={inViewCampus}></Campus>
      </InView>

      <InView
        className="bgPattern bgPattern--bottom"
        onChange={setInViewExcellentConnectivity}
        triggerOnce={true}
      >
        <ExcellentConnectivity
          inView={inViewExcellentConnectivity}
        ></ExcellentConnectivity>
      </InView>


      <InView
        className="bgPattern bgPattern--leftSquare"
        onChange={setInViewSchools}
        triggerOnce={true}
      >
        <Schools inView={inViewSchools}></Schools>
      </InView>
*/}
            <InView
                className="bgPattern bgPattern--HrGrassPattern"
                onChange={setInViewContactUs}
                triggerOnce={true}
            >
                <ContactUs inView={inViewContactUs}></ContactUs>
            </InView>
            <FooterLogo />
            <InView
                className="bgPattern "
                onChange={setInViewFooter}
                triggerOnce={true}
            >
                <Footer inView={inViewFooter}></Footer>
            </InView>
        </>
    );
}
