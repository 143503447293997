import { Col, Container, Row } from "react-bootstrap";
import { Desc, Heading } from "../../Global/Typography";
import GrassPattern from "../GrassPattern";
// import SoulTreeLogo from "../../../images/SoulTree/SoulTreeLogo.svg";
// import logo from "../../../images/Soulace/Banner/soulace.png";
// import BannerImage from "../../../images/SoulTree/Banner.png";
// import BannerImage from "../../../images/Soulace/Banner/SoulaceBanner.webp";
import "./Banner.scss";

const logo = "/images/SereneHeights/serene-heights-logo.svg";
const BannerImage =
    "/images/SereneHeights/Banner/serene-heights-project-detail-page-banner.jpg";

export default function Banner({ inView }) {
    return (
        <section
            className={`section overflow-hidden pb-0 ${
                inView ? "inView" : "outView"
            }`}
        >
            <GrassPattern />
            <Container className="pos-rel my-md-3">
                <Row>
                    <Col xs={11} md={10} lg={7} className="pr-5 py-5 mt-5">
                        <img
                            src={logo}
                            alt="logo"
                            className="SoulTreeLogo msSoulTreeBannerLogo msSoulTreeBannerLogo--sm"
                            style={{ height: "6rem" }}
                        />

                        <Heading
                            classList="my-4 pr-md-3"
                            variant="h1"
                            text="Spacious Multi-Gen Homes"
                        />
                        <Desc
                            classList="pr-md-3"
                            text="Serene Heights by Modern Spaaces offering 2.5 & 3 BHK Homes, located on Sarjapur Road, Sompura Gate, is designed to cater to multiple generations under one roof. 

              "
                        />
                    </Col>
                </Row>

                <img
                    src={logo}
                    alt="Soulace"
                    className="SoulTreeLogo msSoulTreeBannerLogo msSoulTreeBannerLogo--md"
                    style={{ height: "10rem" }}
                />

                {/* NEW IMAGE */}
                {/* <Row>
          <Col md={12} className="p-0">
            <div className="md-container-wrap">
              <img src={BannerImage} alt="logo" className="-banner-image" />
            </div>
          </Col>
        </Row> */}
            </Container>
            <div className="md-container-wrap">
                <img
                    src={BannerImage}
                    alt="Serene Heights by Modern Spaaces"
                    className="-banner-image"
                />
            </div>
            <GrassPattern />
        </section>
    );
}
