import { Container, Row, Col } from "react-bootstrap";
import { Desc, Heading } from "../../Global/Typography";
import "./Schools.scss";

const SchoolsImage = "/images/EngraceVista/Schools/education-centers-banner.png";

export default function Schools({ inView }) {
    const SchoolsData = [
        {
            title: "What about Education Centers?",
            desc: "The Engrace Vista community is surrounded by Bangalore's premier schools and colleges, all located within a convenient 5 to 8 kilometers from your doorstep ensuring access to quality education without the hassle of long commutes.",
            image: SchoolsImage,
            listItems: [
                "TISB",
                "Greenwood High (ICSE) School",
                "Inventure Academy",
                "Indus International School",
                "Shlok International",
                "ISME Management School",
                "Azim Premji University (Wipro)",
            ],
        },
    ];

    return (
        <section
            className={`section ${inView ? "inView" : "outView"}
    `}
        >
            {SchoolsData.map((item, idx) => {
                return (
                    <Container key={idx} className="Schools">
                        <Row className="align-items-center">
                            <Col
                                xs={12}
                                md={6}
                                lg={6}
                                xl={5}
                                className="pr-md-5"
                            >
                                <div className="imagePatch">
                                    <img
                                        src={item.image}
                                        className="Schools__image w-100 mb-4 mb-md-5"
                                        alt="Education Centers in Sarjapur"
                                    />
                                </div>
                            </Col>
                            <Col
                                xs={12}
                                md={6}
                                lg={6}
                                xl={7}
                                className="px-md-5"
                            >
                                <Heading
                                    variant="h1"
                                    classList="Schools__title"
                                    text={item.title}
                                />
                                <Desc
                                    classList="Schools__desc my-3 "
                                    text={item.desc}
                                />
                                <ul className="TranquilCourtList">
                                    {item.listItems.map((i, j) => (
                                        <li key={j}>
                                            <Desc classList="my-1" text={i} />
                                        </li>
                                    ))}
                                </ul>
                                {/* <AnchorButtonIcon
                  // RECORD AUDIO BASED CHANGE
                  target="_blank"
                  href="https://www.google.com/maps/place/Soul+Tree+by+Modern+Spaaces/@12.86056,77.7643898,17z/data=!3m1!4b1!4m5!3m4!1s0x3bae738c023db8b3:0x1c0e1c2d5d103375!8m2!3d12.8605548!4d77.7665785"
                  // RECORD AUDIO BASED CHANGE
                  classList="Schools__btn my-3"
                  text={item.button}
                  icon={true}
                /> */}
                            </Col>
                        </Row>
                    </Container>
                );
            })}
        </section>
    );
}
